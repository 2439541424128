import { getEnv, getSnapshot, types } from 'mobx-state-tree'
import { fromPromise, FULFILLED } from 'mobx-utils'
import { when } from 'mobx'
import { curry } from 'ramda'

import { ServiceStore, StudentGroupModel, GroupProfileModel } from 'internal'

const StudentGroupStore = ServiceStore.named('StudentGroupStore')
  .props({
    _groups: types.optional(
      types.model('PaginationGroups', {
        data: types.optional(types.array(StudentGroupModel), []),
        total: types.optional(types.number, 0)
      }),
      {}
    ),
    _group: types.optional(GroupProfileModel, {})
  })
  .actions(self => {
    const apiV1 = getEnv(self).apiV1

    const studentGroupApi = curry(url => '/api/StudentsGroupApi' + url)

    const getGroupDataByEnrollmentCode = enrollmentCode => {
      return fromPromise(
        apiV1.get(
          studentGroupApi(
            `/SendRequestToGroupByEnrollmentCode?enrollmentCode=${enrollmentCode}`
          )
        )
      )
    }

    function getGroups(reqBody) {
      let prom = fromPromise(apiV1.post(studentGroupApi('/Get'), reqBody))

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                if (reqBody.page === 1) self._groups = resp.data
                else
                  self._groups.data = self._groups.data.concat(resp.data.data)
              })
            }
          })
        }
      )
      return prom
    }

    function list(reqBody) {
      let prom = fromPromise(apiV1.post(studentGroupApi('/Get'), reqBody))

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._groups = resp.data
              })
            }
          })
        }
      )
      return prom
    }


    const getGroupById = (groupId, problemTypeId) => {
      const prom = fromPromise(
        apiV1.get(
          studentGroupApi(
            `/GetGroupById?groupId=${groupId}&problemTypeId=${problemTypeId}`
          )
        )
      )
      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._group = resp.data
              })
            }
          })
        }
      )

      return prom
    }


    function leave(groupId) {
      const prom = fromPromise(
        apiV1.delete(studentGroupApi('/Delete'), { params: { groupId } })
      )

      return prom
    }

    return {
      getGroupDataByEnrollmentCode,
      list,
      leave,
      getGroups,
      getGroupById
    }
  })
  .views(self => ({
    get groups() {
      return getSnapshot(self._groups)
    },
    get group() {
      return getSnapshot(self._group)
    }
  }))

export default StudentGroupStore
