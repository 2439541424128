import { types } from 'mobx-state-tree'

const DbSubjectDataCodeForProblemBusinessModel = types.model(
  'DbSubjectDataCodeForProblemBusinessModel',
  {
    id: types.optional(types.identifierNumber, 0),
    dataCode: types.optional(types.string, ''),
    judgeTypeId: types.optional(types.integer, 0),
    judgeTypeName: types.optional(types.string, '')
  }
)

export default DbSubjectDataCodeForProblemBusinessModel
