import { toast } from 'react-toastify'

export const waitingCode = [
  'ALSUB0002ST',
  'ALSUB0003ST',
  'ALSUB0004ST',
  'ALSUB0013ST',
  'ALSUB0014ST'
]
export const errorCode = [
  'ALSUB0001ST',
  'ALSUB0005ST',
  'ALSUB0006ST',
  'ALSUB0007ST',
  'ALSUB0008ST',
  'ALSUB0009ST',
  'ALSUB0010ST',
  'ALSUB0011ST',
  'ALSUB0015ST',
  'ALSUB0017ST',
  'ALSUB0018ST'
]
export const correctCode = ['ALSUB0012ST']
export const grayCode = ['ALSUB0016ST']

export const statusCodes = [
  { code: waitingCode, toast: toast.info, name: 'waiting' },
  { code: errorCode, toast: toast.error, name: 'error' },
  { code: correctCode, toast: toast.success, name: 'success' },
  { code: grayCode, toast: toast.warn, name: 'gray' }
]

export const submissionErrorCodes = [
  'SUBMIS0007ER',
  'SUBMIS0008ER',
  'SUBMIS0009ER',
  'SUBMIS0010ER',
  'SUBMIS0011ER',
  'SUBMIS0012ER',
  'SUBMIS0013ER',
  'SUBMIS0014ER'
]

export const anchorTypes = [
  {
    icon: 'arrow_forward',
    label: {
      id: 'anchor.algo_submission',
      defaultMessage: 'Алго решение'
    }
  },
  {
    icon: 'arrow_forward',
    label: {
      id: 'anchor.sql_submission',
      defaultMessage: 'Решение SQL'
    }
  },
  {
    icon: 'list',
    label: {
      id: 'anchor.algo_problem',
      defaultMessage: 'Алго задача'
    }
  },
  {
    icon: 'list',
    label: {
      id: 'anchor.sql_problem',
      defaultMessage: 'Задача SQL'
    }
  },
  {
    icon: 'group',
    label: {
      id: 'anchor.group_request',
      defaultMessage: 'Запрос на вступление в группу'
    }
  }
]
