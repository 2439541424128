import React from 'react'
import Select from 'react-select'

const sortStyles = {
  option: (styles, { isSelected }) => {
    const hover = {
      background: '#E0E0E0',
      borderBottom: '1px solid transparent',
      margin: '0px -15px',
      paddingLeft: '15px',
      paddingRight: '15px',
      cursor: 'pointer'
    }
    const selectStyle = isSelected ? hover : {}

    return {
      ':not(:last-child)': {
        borderBottom: '1px solid #F2F2F2'
      },
      ':hover': hover,
      color: '#4F4F4F',
      padding: '5px 0',
      ...selectStyle
    }
  },
  menu: () => ({
    marginTop: '2px',
    position: 'absolute',
    width: '100%',
    zIndex: 1
  }),
  menuList: styles => ({
    background: 'white',
    padding: '0 15px',
    border: '2px solid #E9E9E9',
    borderRadius: '3px',
    overflow: 'hidden'
  }),
  singleValue: styles => ({
    ...styles,
    color: '#4F4F4F'
  }),
  indicatorsContainer: styles => ({
    ...styles,
    padding: 0,
    display: 'inline-block'
  }),
  dropdownIndicator: (styles, state) => {
    const rotate = state.selectProps.menuIsOpen ? 'rotate(180deg)' : null

    return {
      ...styles,
      padding: '3px',
      color: '#4F4F4F',
      transform: rotate
    }
  },
  control: styles => ({
    ...styles,
    background: '#F9F9F9',
    border: '2px solid #E9E9E9',
    borderRadius: '3px',
    padding: '0',
    minHeight: '30px'
  }),
  container: styles => ({
    ...styles,
    width: '250px'
  })
}

export default props => (
  <Select isSearchable={false} styles={sortStyles} {...props} />
)
