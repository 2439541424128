import React, { Component, cloneElement, Children } from 'react'
import classNames from 'classnames'
import t from 'prop-types'

import { utils } from 'internal'

import AccordionBlock from './AccordionBlock'
import AccordionBody from './AccordionBody'
import AccordionHeader from './AccordionHeader'
import AccordionContext from './AccordionContext'

import './Accordion.scss'

class Accordion extends Component {
  componentDidMount() {
    const { addAccordion } = this.context
    const { id, isOpen } = this.props

    addAccordion(id, isOpen)
  }

  render() {
    const { tag: Tag, className, id, children, isOpen, ...props } = this.props
    const classes = classNames('sw-accordion', className)

    return (
      <Tag className={classes} {...props}>
        {Children.map(children, accordion => cloneElement(accordion, { id }))}
      </Tag>
    )
  }
}

Accordion.contextType = AccordionContext

Accordion.propTypes = {
  tag: utils.tagPropType,
  className: t.string,
  id: t.oneOfType([t.string, t.number]).isRequired,
  children: t.any.isRequired,
  isOpen: t.bool
}

Accordion.defaultProps = {
  tag: 'div',
  isOpen: false
}

Accordion.Block = AccordionBlock
Accordion.Header = AccordionHeader
Accordion.Body = AccordionBody

export default Accordion
