import { types, applySnapshot, getSnapshot, getParent } from 'mobx-state-tree'

import {
  BaseUIStore,
  DbProblemSubmissionsFilterModel,
  AUTH_TEACHER_ROLE,
  AUTH_AUTHOR_ROLE
} from 'internal'

const ProblemSubmissionsUIStore = BaseUIStore.named('ProblemSubmissionsUIStore')
  .props({
    hydrated: false,
    _filterDbStudent: types.optional(DbProblemSubmissionsFilterModel, {}),
    _filterDbTeacher: types.optional(DbProblemSubmissionsFilterModel, {}),
    _filterDbAuthor: types.optional(DbProblemSubmissionsFilterModel, {}),

    _filterSandboxDbStudent: types.optional(
      DbProblemSubmissionsFilterModel,
      {}
    ),
    _filterSandboxDbTeacher: types.optional(
      DbProblemSubmissionsFilterModel,
      {}
    ),
    _filterSandboxDbAuthor: types.optional(DbProblemSubmissionsFilterModel, {}),

    _filterAlgoStudent: types.optional(DbProblemSubmissionsFilterModel, {}),
    _filterAlgoTeacher: types.optional(DbProblemSubmissionsFilterModel, {}),
    _filterAlgoAuthor: types.optional(DbProblemSubmissionsFilterModel, {}),
    _submissionDbId: types.optional(types.number, 0),
    _submissionAlgoId: types.optional(types.number, 0),
    _firstPage: types.optional(types.boolean, false)
  })
  .actions(self => {
    const afterHydration = () => (self.hydrated = true)

    const getUserRole = () => {
      const { appStore } = getParent(self)

      return appStore.currentRole.role
    }

    const checkIsTeacher = () => self.getUserRole() === AUTH_TEACHER_ROLE

    const checkIsAuthor = () => getUserRole() === AUTH_AUTHOR_ROLE

    const resetFilter = () => {
      applySnapshot(self._filterDbTeacher, self.emptyFilter)
      applySnapshot(self._filterDbAuthor, self.emptyFilter)
      applySnapshot(self._filterDbStudent, self.emptyFilter)
      applySnapshot(self._filterAlgoTeacher, self.emptyFilter)
      applySnapshot(self._filterAlgoAuthor, self.emptyFilter)
      applySnapshot(self._filterAlgoStudent, self.emptyFilter)
    }

    const applyFilter = (newFilterValue, isDbProblemType, isSandbox) => {
      if (isDbProblemType && isSandbox) {
        if (checkIsTeacher())
          applySnapshot(self._filterSandboxDbTeacher, newFilterValue)
        else if (checkIsAuthor())
          applySnapshot(self._filterSandboxDbAuthor, newFilterValue)
        else applySnapshot(self._filterSandboxDbStudent, newFilterValue)
      }

      if (isDbProblemType && !isSandbox) {
        if (checkIsTeacher())
          applySnapshot(self._filterDbTeacher, newFilterValue)
        else if (checkIsAuthor())
          applySnapshot(self._filterDbAuthor, newFilterValue)
        else applySnapshot(self._filterDbStudent, newFilterValue)
      }

      if (!isDbProblemType && !isSandbox) {
        if (checkIsTeacher())
          applySnapshot(self._filterAlgoTeacher, newFilterValue)
        else if (checkIsAuthor())
          applySnapshot(self._filterAlgoAuthor, newFilterValue)
        else applySnapshot(self._filterAlgoStudent, newFilterValue)
      }
    }

    const setSubmissionId = (submissionId, isDbProblemType) => {
      if (submissionId) {
        submissionId = parseInt(submissionId)
        return isDbProblemType
          ? (self._submissionDbId = submissionId)
          : (self._submissionAlgoId = submissionId)
      }
    }

    const resetCurrentPage = firstPage => {
      self._firstPage = firstPage
    }

    return {
      afterHydration,
      resetFilter,
      getUserRole,
      applyFilter,
      setSubmissionId,
      resetCurrentPage
    }
  })
  .views(self => ({
    get filterDbTeacher() {
      return getSnapshot(self._filterDbTeacher)
    },
    get filterDbStudent() {
      return getSnapshot(self._filterDbStudent)
    },
    get filterDbAuthor() {
      return getSnapshot(self._filterDbAuthor)
    },
    get filterSandboxDbTeacher() {
      return getSnapshot(self._filterSandboxDbTeacher)
    },
    get filterSandboxDbStudent() {
      return getSnapshot(self._filterSandboxDbStudent)
    },
    get filterSandboxDbAuthor() {
      return getSnapshot(self._filterSandboxDbAuthor)
    },
    get filterAlgoTeacher() {
      return getSnapshot(self._filterAlgoTeacher)
    },
    get filterAlgoStudent() {
      return getSnapshot(self._filterAlgoStudent)
    },
    get filterAlgoAuthor() {
      return getSnapshot(self._filterAlgoAuthor)
    },
    get emptyFilter() {
      return getSnapshot(DbProblemSubmissionsFilterModel.create())
    },
    get submissionDbId() {
      return self._submissionDbId
    },
    get submissionAlgoId() {
      return self._submissionAlgoId
    },
    get firstPage() {
      return self._firstPage
    }
  }))

export default ProblemSubmissionsUIStore
