import React, { Component } from "react";
import classNames from "classnames";
import t from "prop-types";

import { utils } from 'internal';

import TabContext, { checkActive } from "./TabContext";

class TabPane extends Component {
  static contextType = TabContext;

  render() {
    const {
      context: { active, isLazy },
      props: { tag: Tag, tabKey, className, ...props }
    } = this;

    const activePane = checkActive(tabKey, active);

    if (isLazy && !activePane) return null;

    const classes = classNames(
      "sw-tabs__pane",
      activePane && "sw-tabs__pane--active",
      className
    );

    return <Tag className={classes} {...props} />;
  }
}

TabPane.propTypes = {
  tag: utils.tagPropType,
  tabKey: t.oneOfType([t.string, t.number]).isRequired,
  className: t.string
};

TabPane.defaultProps = {
  tag: "div"
};

export default TabPane;
