import { types } from 'mobx-state-tree'

import InstituteModel from './Institute.model'

const UserPersonalModel = types
  .model('UserModel', {
    usersFirstName: types.maybeNull(types.string),
    usersLastName: types.maybeNull(types.string),
    usersDateOfBirth: types.maybeNull(types.string),
    userPhotoLocation: types.maybeNull(types.string),
    userInstitutes: types.optional(types.array(InstituteModel), []),
    timeZoneId: types.optional(types.integer, 0),
    timeZoneValue: types.maybeNull(types.string), 
    defaultLanguageId: types.optional(types.integer, 0),
    defaultLanguageName: types.maybeNull(types.string)
  })
  .actions(self => {
    const setInfo = userInfo => {
      self.usersFirstName = userInfo.usersFirstName
      self.usersLastName = userInfo.usersLastName
      self.usersDateOfBirth = userInfo.usersDateOfBirth
      self.userPhotoLocation = userInfo.userPhotoLocation
      self.userInstitutes = userInfo.userInstitutes
      self.timeZoneId = userInfo.timeZoneId
      self.timeZoneValue = userInfo.timeZoneValue
      self.defaultLanguageId = userInfo.defaultLanguageId
      self.defaultLanguageName = userInfo.defaultLanguageName
    }

    return {
      setInfo
    }
  })

export default UserPersonalModel
