import { types } from 'mobx-state-tree'

export default types.model('ChatMessageModel', {
  channelId: types.optional(types.integer, 0),
  threadId: types.optional(types.integer, 0),
  anchorId: types.maybeNull(types.integer),
  anchorType: types.maybeNull(types.integer),
  messageId: types.optional(types.integer, 0),
  senderUserId: types.maybeNull(types.optional(types.string, '')),
  messageText: types.maybeNull(types.optional(types.string, '')),
  isRead: types.optional(types.boolean, true),
  messagesAmount: types.optional(types.number, 0),
  sentDate: types.maybeNull(types.optional(types.string, '')),
  participantUserId: types.maybeNull(types.optional(types.string, '')),
  participantUserName: types.maybeNull(types.optional(types.string, '')),
  participantFirstName: types.maybeNull(types.optional(types.string, '')),
  participantLastName: types.maybeNull(types.optional(types.string, '')),
  participantUserPhoto: types.maybeNull(types.optional(types.string, '')),
  unreadMessageAmount: types.optional(types.integer, 0),
  anchorData: types.maybeNull(types.string)
})
