import React from "react";
import t from "prop-types";

import {
  utils
} from 'internal';


function Body(props) {

  const {
    tag: Tag,
    className,
    ...attrs
  } = props;

  return (
    <Tag className={className} {...attrs}/>
  );
}

Body.propTypes = {
  tag: utils.tagPropType,
  className: t.string,
};

Body.defaultProps = {
  tag: "tbody"
};

export default Body;
