import { types, getSnapshot } from 'mobx-state-tree'

import { BaseUIStore, UsersFilterModel } from 'internal'

const UsersUIStore = BaseUIStore.named('UsersUIStore')
  .props({
    _usersFilter: types.optional(UsersFilterModel, {})
  })
  .actions(self => {
    const setUsersFilter = filter => {
      self._usersFilter = filter
    }

    const resetUsersFilter = () => {
      self._usersFilter = {}
    }

    return {
      setUsersFilter,
      resetUsersFilter
    }
  })
  .views(self => ({
    get usersFilter() {
      return getSnapshot(self._usersFilter)
    }
  }))

export default UsersUIStore
