import React from "react";
import t from "prop-types";

import {
  utils
} from 'internal';


function Footer(props) {
  const {
    tag: Tag,
    className,
    ...attrs
  } = props;

  return (
    <Tag className={className} {...attrs}/>
  );
}

Footer.propTypes = {
  tag: utils.tagPropType,
  className: t.string,
};

Footer.defaultProps = {
  tag: "tfoot",
};

export default Footer;
