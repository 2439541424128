import React from "react";
import t from "prop-types";
import classNames from "classnames";

import {utils} from 'internal';


const FilterContainerHeader = ({tag: Tag, className, ...attributes}) => {
  const classes = classNames("sw-filter-container__heading", className);

  return (<Tag className={classes} {...attributes} />);
};

FilterContainerHeader.propTypes = {
  className: t.string,
  tag: utils.tagPropType
};

FilterContainerHeader.defaultProps = {
  tag: "header"
};

export default FilterContainerHeader;
