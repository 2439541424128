import { types } from 'mobx-state-tree'

import { DbProblemLookUpModel, DbSubjectLookUpModel } from 'internal'

export default types.model('DbRejudgeBatchesModel', {
  id: types.identifierNumber,
  batchStatus: types.optional(types.string, ''),
  name: types.optional(types.string, ''),
  author: types.maybeNull(types.string),
  authorId: types.maybeNull(types.string),
  isDeleted: types.optional(types.boolean, false),
  solutions: types.optional(types.integer, 0),
  modifiedSolutions: types.optional(types.integer, 0),
  toError: types.optional(types.integer, 0),
  toCorrect: types.optional(types.integer, 0),
  students: types.optional(types.integer, 0),
  problems: types.optional(types.array(DbProblemLookUpModel), []),
  subjects: types.optional(types.array(DbSubjectLookUpModel), [])
})
