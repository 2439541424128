import { types } from 'mobx-state-tree'

export default types.model('AlgoProblemSolutionModel', {
  id: types.maybeNull(types.integer),
  problemId: types.maybeNull(types.integer),
  problemName: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  statusCode: types.maybeNull(types.string),
  submitDateTime: types.maybeNull(types.string),
  checkDateTime: types.maybeNull(types.string),
  submissionCode: types.maybeNull(types.string),
  logText: types.maybeNull(types.string),
  compilerId: types.maybeNull(types.integer),
  executionTime: types.maybeNull(types.integer),
  executionMemory: types.maybeNull(types.integer),
  studentName: types.optional(types.string, ''),
  hasAccessToProblem: types.optional(types.boolean, true)
})
