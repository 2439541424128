import { types } from 'mobx-state-tree'

import { DbSubjectDataCodeForProblemBusinessModel } from 'internal'

const DbSubjectDatabasesForProblemBusinessModel = types.model(
  'DbSubjectDatabasesForProblemBusinessModel',
  {
    id: types.identifierNumber,
    subjectId: types.optional(types.integer, 0),
    dbPublicName: types.string,
    subjectDataCodeForProblem: types.optional(
      types.array(DbSubjectDataCodeForProblemBusinessModel),
      []
    )
  }
)

export default DbSubjectDatabasesForProblemBusinessModel
