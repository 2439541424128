import React from 'react'
import t from 'prop-types'
import classNames from 'classnames'

import { utils } from 'internal'

import './FormFeedback.scss'

const FormFeedback = props => {
  const { tag: Tag, className, valid, invalid, ...attrs } = props

  const classes = classNames(
    'sw-form-feedback',
    valid && 'sw-form-feedback--valid sw-form-feedback--validated',
    invalid && 'sw-form-feedback--invalid sw-form-feedback--validated',
    className
  )

  return <Tag className={classes} {...attrs} />
}

FormFeedback.propTypes = {
  tag: utils.tagPropType,
  className: t.string,
  valid: t.bool,
  invalid: t.bool
}

FormFeedback.defaultProps = {
  tag: 'div'
}

export default FormFeedback
