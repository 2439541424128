import React from 'react'
import { FormattedMessage } from 'react-intl'
import t from 'prop-types'
import { Modal, Button } from 'react-bootstrap'
import './ModalNotification.scss'

const ModalNotification = ({
  isOpen,
  onClose,
  modalMethod,
  text,
  isDisabled
}) => {
  return (
    isOpen && (
      <Modal show={isOpen} className="modal-notification" centered onHide={onClose}>
        <Modal.Header className="d-flex justify-content-center text-center mb-4 p-0">
          <Modal.Title>{text}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-row justify-content-around mb-4 p-0">
          <Button variant="success" onClick={modalMethod} disabled={isDisabled}>
            <FormattedMessage id="button.yes" defaultMessage="Да" />
          </Button>
          <Button variant="outline-secondary" onClick={onClose}>
            <FormattedMessage id="button.no" defaultMessage="Нет" />
          </Button>
        </Modal.Body>
      </Modal>
    )
  )
}

ModalNotification.propTypes = {
  isOpen: t.bool,
  onClose: t.func,
  modalMethod: t.func,
  text: t.any
}

ModalNotification.defaultProps = {
  tag: 'div'
}

export default ModalNotification
