import React from "react";
import t from "prop-types";
import classNames from "classnames";

import {utils} from 'internal';

import "./Icon.scss";


const Icon = ({centered, color, tag: Tag, className, ...attributes}) => {
  const classes = classNames(
    "sw-icon",
    `sw-text-${color}`,
    centered && "sw-icon--centered",
    className
  );

  return (<Tag className={classes} {...attributes}/>);
};

Icon.propTypes = {
  tag: utils.tagPropType,
  className: t.string,
  centered: t.bool,
  color: t.oneOf(utils.colors),
};

Icon.defaultProps = {
  tag: "span",
  color: "white"
};

export default Icon;
