import React from "react";
import t from "prop-types";
import classNames from "classnames";

import {utils} from 'internal';
import "./Container.scss";

const Container = ({className, fluid, tag: Tag, ...attributes}) => {

  const classes = classNames(
    "sw-container" + ((fluid && "-fluid") || ""),
    className
  );

  return (<Tag className={classes} {...attributes} />);
};

Container.propTypes = {
  className: t.string,
  fluid: t.bool,
  tag: utils.tagPropType
};

Container.defaultProps = {
  fluid: false,
  tag: "div"
};

export default Container;
