import React from 'react'
import { FormattedMessage } from 'react-intl'
import Select from 'react-select'
import Pagination from 'react-pagination-js'
import t from 'prop-types'
import './SimplePagination.scss'
import { Input } from 'components'

const pagStyles = {
  indicatorsContainer: styles => ({
    ...styles,
    padding: 0,
    display: 'inline-block',
    '*': {
      padding: '0 !important'
    }
  }),
  dropdownIndicator: (styles, state) => ({
    ...styles,
    padding: '3px',
    color: '#4F4F4F',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null
  }),
  control: styles => ({
    ...styles,
    background: '#FFFFFF',
    border: '1px solid #E9E9E9',
    borderRadius: '3px',
    padding: '0',
    minHeight: '20px'
  }),
  indicatorSeparator: styles => ({
    ...styles,
    width: '0'
  }),
  valueContainer: styles => ({
    ...styles,
    height: '20px',
    padding: '0 0 0 4px'
  }),
  singleValue: styles => ({
    ...styles,
    color: '#333333',
    fontSize: '1rem',
    margin: '0'
  })
}

const SimplePagination = ({
  from,
  until,
  totalSize,
  perPage,
  currentPage,

  updatePerPage,
  updateCurrentPage,

  containerStyles
}) => {
  const pagOptions = [
    { value: 10, label: '10' },
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 100, label: '100' }
  ]

  const sizePerPage = perPage.value

  if (!totalSize || totalSize < 10) {
    return null
  }

  const searchValid = event => {
    if (!event.target.validity.valid) {
      event.target.value = ''
    }
  }

  const searchHandle = event => {
    if (event.key === 'Enter') {
      const valueValidated = +event.target.value
      const pageCount = Math.ceil(totalSize / sizePerPage)

      if (
        0 < valueValidated &&
        valueValidated <= pageCount &&
        valueValidated !== currentPage
      ) {
        currentPage = valueValidated
        updateCurrentPage(currentPage)
      }
    }
  }

  return (
    <React.Fragment>
      <div className="pagination-filter" style={containerStyles}>
        <span className="pagination-filter__info">
          <FormattedMessage id="pagination.text.total" defaultMessage="Всего" />

          <span className="pagination-filter__count">{totalSize}</span>
          <span className="pagination-filter__info">
            <FormattedMessage
              id="pagination.text.records"
              defaultMessage="записей"
            />
          </span>
        </span>
        <span className="pagination-filter__info right">
          <FormattedMessage id="pagination.text.per" defaultMessage="По" />
        </span>
        <Select
          className="pag-select"
          styles={pagStyles}
          value={perPage}
          onChange={updatePerPage}
          options={pagOptions}
          isSearchable={false}
          menuPlacement="top"
        />
      </div>
      <div className="pagination-wrapper">
        <span className="pagination-filter__info">
          <FormattedMessage
            id="pagination.text.search"
            defaultMessage="Перейти"
          />
        </span>
        <Input
          type="text"
          pattern="[0-9]*"
          className="pagintaion-search"
          onKeyPress={searchHandle}
          onInput={searchValid}
        />
        <Pagination
          totalSize={totalSize}
          currentPage={currentPage}
          sizePerPage={sizePerPage}
          changeCurrentPage={updateCurrentPage}
        />
      </div>
    </React.Fragment>
  )
}

SimplePagination.propTypes = {
  from: t.number.isRequired,
  until: t.number.isRequired,
  totalSize: t.number.isRequired,
  currentPage: t.number.isRequired,
  perPage: t.any.isRequired,

  updatePerPage: t.func.isRequired,
  updateCurrentPage: t.func.isRequired,
  containerStyles: t.any
}

export default SimplePagination
