import React from 'react'
import t from 'prop-types'
import classNames from 'classnames'

import { utils } from 'internal'

import FilterContainerHeader from './FilterContainerHeader'
import FilterContainerTitle from './FilterContainerTitle'
import FilterContainerBody from './FilterContainerBody'
import FilterContainerFooter from './FilterContainerFooter'
import './FilterContainer.scss'

const FilterContainer = ({ tag: Tag, className, ...attributes }) => {
  const classes = classNames('sw-filter-container', className)

  return <Tag className={classes} {...attributes} />
}

FilterContainer.propTypes = {
  className: t.string,
  tag: utils.tagPropType
}

FilterContainer.defaultProps = {
  tag: 'div'
}

FilterContainer.Header = FilterContainerHeader
FilterContainer.Title = FilterContainerTitle
FilterContainer.Body = FilterContainerBody
FilterContainer.Footer = FilterContainerFooter

export default FilterContainer
