import {types} from 'mobx-state-tree';


const LinkForTagLookUpModel = types.model('LinkForTagLookUpModel', {
  linkId: types.optional(types.identifierNumber, 0),
  linkName: types.optional(types.string, ''),
  linkType: types.optional(types.string, ''),
  isApproved: types.optional(types.boolean, false),
  linkDescription: types.optional(types.string, '')
});

export default LinkForTagLookUpModel;
