import Table from "./Table";
import Header from "./Header";
import Body from "./Body";
import Footer from "./Footer";
import Row from "./Row";
import Cell from "./Cell";

import "./Table.scss";


Table.Header = Header;
Table.Body = Body;
Table.Footer = Footer;
Table.Row = Row;
Table.Cell = Cell;

export default Table;
