import { types } from "mobx-state-tree";

import { ServerEngineModel } from 'internal';

const ServerModel = types.model("ServerModel", {
  id: types.optional(types.identifierNumber, 0),
  serverName: types.optional(types.string, ""),
  isEnabled: types.optional(types.boolean, false),
  connectionString: types.optional(types.string, ""),
  engineId: types.maybeNull(types.number),
  engineName: types.optional(types.string, ""),
  engine: types.optional(ServerEngineModel, {})
});

export default ServerModel;
