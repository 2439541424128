import React from "react";
import classNames from "classnames";
import t from "prop-types";

import { utils } from 'internal';

const WidgetControlGroup = ({ tag: Tag, className, ...attributes }) => {
  const classes = classNames("sw-widget__heading-tools", className);

  return <Tag className={classes} {...attributes} />;
};

WidgetControlGroup.propTypes = {
  tag: utils.tagPropType,
  className: t.string
};

WidgetControlGroup.defaultProps = {
  tag: "div"
};

export default WidgetControlGroup;
