import { getEnv, types, getSnapshot } from 'mobx-state-tree'
import { fromPromise, FULFILLED } from 'mobx-utils'
import { when } from 'mobx'
import { curry } from 'ramda'

import { ServiceStore, UserRoleBusinessModel } from 'internal'

const UserRoleStore = ServiceStore.named('UserRoleStore')
  .props({
    _userRoles: types.optional(types.array(UserRoleBusinessModel), [])
  })
  .actions(self => {
    const apiV1 = getEnv(self).apiV1

    const userRoleApi = curry(url => '/api/UserGetRoleApi' + url)

    function list() {
      const prom = fromPromise(apiV1.get(userRoleApi('/Get')))

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._userRoles = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    return {
      list
    }
  })
  .views(self => ({
    get userRoles() {
      return getSnapshot(self._userRoles)
    }
  }))

export default UserRoleStore
