import { getEnv, types, getSnapshot } from 'mobx-state-tree'
import { fromPromise, FULFILLED } from 'mobx-utils'
import { when } from 'mobx'
import { curry } from 'ramda'

import {
  ServiceStore,
  DbProblemModel,
  DbTagsBusinessModel,
  DbProblemLookUpModel,
  DiagramAndDatabaseForProblemBusinessModel,
  SubjectJudgeTypesModel,
  DbProblemListModel,
  RequestModel,
  SortingModel,
  DbProblemAuthorFilterModel,
  ProblemSubTypeLookUpModel,
  DbProblemCollectionsLookupModel,
  DbDetailProblemModel
} from 'internal'

export default ServiceStore.named('DbProblemStore')
  .props({
    _problem: types.optional(DbProblemModel, {}),
    _problems: types.optional(
      types.model('PaginationProblems', {
        data: types.optional(types.array(DbProblemModel), []),
        total: types.optional(types.number, 0)
      }),
      {}
    ),
    _tags: types.optional(types.array(DbTagsBusinessModel), []),
    _notAttachedTags: types.optional(types.array(DbTagsBusinessModel), []),
    _judgeTypes: types.optional(types.array(SubjectJudgeTypesModel), []),
    _dbProblemsForTagLookUp: types.optional(
      types.array(DbProblemLookUpModel),
      []
    ),
    _dbSecProfiles: types.optional(
      types.array(
        types.model('DbProblemStore__dbSecProfiles', {
          id: types.identifierNumber,
          name: ''
        })
      ),
      []
    ),
    _dbProblemSubjectArea: types.optional(
      DiagramAndDatabaseForProblemBusinessModel,
      {}
    ),
    _sortProblems: types.optional(
      types.model('PaginationSortProblems', {
        total: types.optional(types.number, 0),
        data: types.optional(types.array(DbProblemListModel), [])
      }),
      {}
    ),
    _dbProblemsLookUp: types.optional(
      types.model('LookUpProblems', {
        total: types.optional(types.number, 0),
        data: types.optional(types.array(DbProblemLookUpModel), [])
      }),
      {}
    ),
    _problemTranslate: types.optional(
      types.array(
        types.model('DbProblemTranslationModel', {
          id: types.optional(types.integer, 0),
          problemId: types.optional(types.integer, 0),
          languageId: types.optional(types.integer, 0),
          problemName: types.optional(types.string, ''),
          problemText: types.optional(types.string, '')
        })
      ),
      []
    ),
    _problemSubTypes: types.optional(
      types.array(ProblemSubTypeLookUpModel),
      []
    ),
    _dbProblemCollectionsLookUp: types.optional(
      DbProblemCollectionsLookupModel,
      {}
    ),
    _detailProblem: types.optional(DbDetailProblemModel, {})
  })
  .actions(self => {
    const apiV1 = getEnv(self).apiV1

    const dbProblemApi = curry(url => '/api/DbProblemApi' + url)
    const dbProblemTagLookUpUrl = curry(
      (url = '') => '/api/DbProblemForTagLookUp' + url
    )
    const problemLookUpApi = curry(url => '/api/ProblemsLookUp' + url)

    const getProblemTranslate = problemId => {
      const prom = fromPromise(
        apiV1.post(
          dbProblemApi('/GetProblemTranslate'),
          RequestModel.create({
            entityId: problemId
          })
        )
      )

      when(
        () => prom.state === FULFILLED,
        () =>
          prom.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                self._problemTranslate = data
              })
            }
          })
      )

      return prom
    }

    const addProblemToCollections = data => {
      const prom = fromPromise(
        apiV1.post(
          dbProblemApi('/ProblemConnectCollection'),
          data.collectionList,
          {
            params: {
              problemId: data.problemId
            }
          }
        )
      )
      return prom
    }

    const editProblemTranslate = data => {
      const prom = fromPromise(
        apiV1.post(dbProblemApi('/EditProblemTranslate'), data)
      )

      return prom
    }

    const getById = (problemId, languageId) => {
      const prom = fromPromise(
        apiV1.get(
          dbProblemApi(
            `/GetById?problemId=${problemId}&languageId=${languageId}`
          )
        )
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._problem = resp.data || {}
              })
            }
          })
        }
      )

      return prom
    }

    function getDetailByProblemId(problemId) {
      const prom = fromPromise(
        apiV1.get(
          dbProblemApi(`/GetProblemInfoByProblemId?problemId=${problemId}`)
        )
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._detailProblem = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    function getDetailBySubmissionId(submissionId) {
      const prom = fromPromise(
        apiV1.get(
          dbProblemApi(
            `/GetProblemInfoBySubmissionId?submissionId=${submissionId}`
          )
        )
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._detailProblem = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    function getDbProblemCollectionsById(id) {
      const prom = fromPromise(
        apiV1.get(
          dbProblemApi(`/GetCollectionsAttachetProblem?problemId=${id}`)
        )
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._dbProblemCollectionsLookUp = resp.data || {}
              })
            }
          })
        }
      )

      return prom
    }

    const list = (request, filter, sort) => {
      const prom = fromPromise(
        apiV1.post(dbProblemApi('/GetAllAuthorDbProblems'), {
          ...RequestModel.create(request),
          ...DbProblemAuthorFilterModel.create(filter),
          sorting: SortingModel.create(sort)
        })
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._problems = resp.data
              })
            }
          })
        }
      )
      return prom
    }

    const sortList = filter => {
      const prom = fromPromise(
        apiV1.post(dbProblemApi('/SortDbProblems'), filter)
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._sortProblems = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    const save = data => {
      const prom = fromPromise(apiV1.put(dbProblemApi('/Put'), data))
      return prom
    }

    function create(data) {
      const prom = fromPromise(apiV1.post(dbProblemApi('/Post'), data))
      return prom
    }

    function copy(problemId) {
      const prom = fromPromise(
        apiV1.post(dbProblemApi(`/CopyProblem?id=${problemId}`))
      )
      return prom
    }

    function getTags(problemId) {
      const prom = fromPromise(
        apiV1.get(dbProblemApi('/GetProblemTags'), {
          params: {
            problemId
          }
        })
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._tags = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    function getNotAttachedTags(problemId) {
      const prom = fromPromise(
        apiV1.get(dbProblemApi('/GetProblemNotAttachedTags'), {
          params: {
            problemId
          }
        })
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._notAttachedTags = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    function getJudgeTypesBySubjectId(subjectId) {
      const prom = fromPromise(
        apiV1.get(dbProblemApi('/GetJudgeTypeSubject'), {
          params: {
            subjectId
          }
        })
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._judgeTypes = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    const getDbProblemsForTagLookUp = () => {
      const prom = fromPromise(apiV1.get(dbProblemTagLookUpUrl('/Get')))

      when(
        () => prom.state === FULFILLED,
        () =>
          prom.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                self._dbProblemsForTagLookUp = data
              })
            }
          })
      )

      return prom
    }

    function getSecProfSubject(subjId) {
      const prom = fromPromise(
        apiV1.get(dbProblemApi('/GetSecurityProfileSubject'), {
          params: {
            id: subjId
          }
        })
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._dbSecProfiles = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    const getDiagramsAndDatabases = (subjectId, languageId) => {
      const prom = fromPromise(
        apiV1.get(
          dbProblemApi(
            `/GetDiagramsAndDatabases?subjectId=${subjectId}&languageId=${languageId}`
          )
        )
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._dbProblemSubjectArea = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    const deleteById = problemId => {
      const prom = fromPromise(
        apiV1.delete(dbProblemApi('/Delete'), {
          params: {
            id: problemId
          }
        })
      )

      return prom
    }

    const lookup = reqBody => {
      const prom = fromPromise(
        apiV1.post(problemLookUpApi('/GetAllProblems'), reqBody)
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                if (reqBody.page === 1) self._dbProblemsLookUp = resp.data
                else
                  self._dbProblemsLookUp.data = self._dbProblemsLookUp.data.concat(
                    resp.data.data
                  )
              })
            }
          })
        }
      )

      return prom
    }

    const getDbProblemSubTypes = () => {
      const prom = fromPromise(apiV1.get(dbProblemApi('/GetDbProblemSubTypes')))

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._problemSubTypes = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    const clearDetailProblem = () => {
      self._detailProblem = {}
    }

    return {
      getById,
      list,
      sortList,
      create,
      copy,
      save,
      getTags,
      deleteById,
      getProblemTranslate,
      editProblemTranslate,
      getNotAttachedTags,
      getJudgeTypesBySubjectId,
      addProblemToCollections,
      getDbProblemsForTagLookUp,
      getSecProfSubject,
      getDiagramsAndDatabases,
      lookup,
      getDbProblemSubTypes,
      getDbProblemCollectionsById,
      getDetailByProblemId,
      getDetailBySubmissionId,
      clearDetailProblem
    }
  })
  .views(self => ({
    get problem() {
      return getSnapshot(self._problem)
    },
    get problems() {
      return getSnapshot(self._problems)
    },
    get sortProblems() {
      return getSnapshot(self._sortProblems)
    },
    get problemTranslate() {
      return getSnapshot(self._problemTranslate)
    },
    get tags() {
      return getSnapshot(self._tags)
    },
    get notAttachedTags() {
      return getSnapshot(self._notAttachedTags)
    },
    get judgeTypes() {
      return getSnapshot(self._judgeTypes)
    },
    get dbProblemsForTagLookUp() {
      return getSnapshot(self._dbProblemsForTagLookUp)
    },
    get dbSecProfiles() {
      return getSnapshot(self._dbSecProfiles)
    },
    get dbProblemSubjectArea() {
      return getSnapshot(self._dbProblemSubjectArea)
    },
    get judgeTypeDefault() {
      if (!getSnapshot(self._judgeTypes).find(judge => judge.isDefault))
        return SubjectJudgeTypesModel.create({
          id: 0
        })

      return getSnapshot(self._judgeTypes).find(judge => judge.isDefault)
    },
    get dbProblemsLookUp() {
      return getSnapshot(self._dbProblemsLookUp)
    },
    get dbProblemSubTypesLookUp() {
      return getSnapshot(self._problemSubTypes)
    },
    getJudgeTypeByJudgeTypeId(judgeTypeId) {
      if (!(judgeTypeId && getSnapshot(self._judgeTypes).length)) return false
      return getSnapshot(self._judgeTypes).find(
        judgeType => judgeType.id === judgeTypeId
      )
    },
    get dbProblemCollectionsLookUp() {
      return getSnapshot(self._dbProblemCollectionsLookUp)
    },
    get detailProblem() {
      return getSnapshot(self._detailProblem)
    }
  }))
