import React from "react";
import t from "prop-types";

import {utils} from 'internal';
import PanelLinks from "./PanelLinks";
import PanelIcon from "./PanelIcon";
import PanelText from "./PanelText";
import "./Panel.scss";


const Panel = ({tag: Tag, ...attributes}) => {
  return (<Tag {...attributes}/>);
};

Panel.Links = PanelLinks;
Panel.Icon = PanelIcon;
Panel.Text = PanelText;

Panel.propTypes = {
  className: t.string,
  tag: utils.tagPropType
};

Panel.defaultProps = {
  tag: 'div'
};

export default Panel;
