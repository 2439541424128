import React from "react";
import t from "prop-types";
import classNames from "classnames";

import { utils } from 'internal';

const CardHeading = ({ tag: Tag, className, ...attributes }) => {
  const classes = classNames("sw-card__heading", className);

  return <Tag className={classes} {...attributes} />;
};

CardHeading.propTypes = {
  tag: utils.tagPropType,
  className: t.string
};

CardHeading.defaultProps = {
  tag: "div"
};

export default CardHeading;
