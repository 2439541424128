import { types, getSnapshot, getEnv } from 'mobx-state-tree'
import { fromPromise, FULFILLED } from 'mobx-utils'
import { when } from 'mobx'
import { curry } from 'ramda'

import { ServiceStore, StatisticModel } from 'internal'

const TestTableStore = ServiceStore.named('TestTableStore')
  .props({
    _statistics: types.optional(StatisticModel, {})
  })
  .actions(self => {
    const apiV1 = getEnv(self).apiV1
    const statisticsUrl = curry(url => '/api/StatisticsApi' + url)

    const getAllStatistics = languageId => {
      const prom = fromPromise(
        apiV1.get(
          statisticsUrl(`/GetFilteredStatistic?languageId=${languageId}`)
        )
      )

      when(
        () => prom.state === FULFILLED,
        () =>
          prom.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                self._statistics = data
              })
            }
          })
      )
    }

    return {
      getAllStatistics
    }
  })
  .views(self => ({
    get statistics() {
      return getSnapshot(self._statistics)
    }
  }))

export default TestTableStore
