import React from 'react'
import classNames from 'classnames'
import t from 'prop-types'

import { Icon, MaterialIcon } from '../index'
import './Checkbox.scss'

const Checkbox = ({
  className,
  checkClass,
  type,
  label,
  color,
  circle,
  bordered,
  isToggle,
  onlyIndicator,
  onClickLabel,
  children,
  ...attributes
}) => {
  const wrapperClass = classNames(`sw-${type}`, className)
  const checkClasses = classNames('sw-checkbox__check', checkClass)

  const renderIndicator = () => {
    return isToggle || circle ? (
      <span
        className={`sw-checkbox__${isToggle ? 'toggle' : 'circular'}`}
      ></span>
    ) : (
      <Icon color={color} className={checkClasses}>
        <MaterialIcon name="check" />
      </Icon>
    )
  }

  return (
    <label
      className={classNames(
        wrapperClass,
        bordered && 'sw-checkbox__bordered',
        attributes.disabled && 'sw-checkbox__disabled'
      )}
      onClick={onClickLabel}
    >
      <input type={type} {...attributes} />
      {renderIndicator()}
      {children}
      {label}
    </label>
  )
}

Checkbox.propTypes = {
  className: t.string,
  checkClass: t.string,
  type: t.oneOf(['checkbox', 'radio']),
  label: t.string,
  color: t.oneOf(['grey', 'green', 'red', 'white']),
  circle: t.bool,
  bordered: t.bool,
  disabled: t.bool,
  isToggle: t.bool,
  onlyIndicator: t.func,
  onClickLabel: t.func,
  children: t.any
}

Checkbox.defaultProps = {
  type: 'checkbox'
}

export default Checkbox
