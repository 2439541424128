import React from "react";
import DatePicker from "react-datepicker";
import classNames from 'classnames';
import "./SimpleDatePicker.scss";


const SimpleDatePicker = ({field, className, ...props}) =>{
    const classes = classNames("react-datepicker-input", className)

    return(
      <DatePicker
        className={classes}
        selected={field.value}
        onChange={field.sync}
        {...props}
      />
    )
  }


export default SimpleDatePicker;
