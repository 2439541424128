import { types } from 'mobx-state-tree'

export default types.model('RequestModel', {
  entityId: types.optional(types.integer, 0),
  entityName: types.maybeNull(types.string, ''),
  languageId: types.optional(types.integer, 2),
  page: types.optional(types.integer, 1),
  pageSize: types.optional(types.integer, 10),
  searchProblems: types.optional(types.string, '')
})
