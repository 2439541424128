import React from "react";
import t from "prop-types";
import classNames from "classnames";

import {utils} from 'internal';

const PanelLinks = ({tag: Tag, className, ...attributes}) => {
  const classes = classNames("c-panel-links", className);

  return <Tag className={classes} {...attributes} />;
};

PanelLinks.propTypes = {
  className: t.string,
  tag: utils.tagPropType,
};

PanelLinks.defaultProps = {
  tag: "div"
};

export default PanelLinks;
