import React from "react";
import { observer } from "mobx-react";
import t from "prop-types";

import {
  Column,
  Row,
  Form,
  Label,
  CustomInput,
  FormFeedback
} from "../index";

const SimpleAsyncSelect = observer(({ field, form, isRequired, ...props }) => {
  const formValidated = form.validated > 0;

  return (
    <Form.Group>
      <Row >
        <Column col={3}>
          <Label className="center-label" for={field.id}>
            {field.label}
            {isRequired && <span className="required">*</span>}
          </Label>
        </Column>
        <Column fluid>
          <CustomInput
            {...field.bind()}
            type="select"
            isClearable
            backspaceRemovesValue
            name={field.name}
            placeholder={field.placeholder}
            value={field.value}
            valid={formValidated && field.isValid}
            invalid={formValidated && field.hasError}
            {...props}
          />
          <FormFeedback invalid={formValidated && field.hasError}>
            {field.error}
          </FormFeedback>
        </Column>
      </Row>
    </Form.Group>
  );
});

SimpleAsyncSelect.propTypes = {
  field: t.any.isRequired,
  form: t.any.isRequired,
  isRequired: t.bool
};

export default SimpleAsyncSelect;
