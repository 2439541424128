import { types } from 'mobx-state-tree'

const GroupRequestModel = types.model('GroupRequestModel', {
  id: types.identifierNumber,
  studentId: types.maybeNull(types.string),
  studentName: types.maybeNull(types.string),
  studentLastName: types.maybeNull(types.string),
  studentFirstName: types.maybeNull(types.string),
  studentMimeType: types.maybeNull(types.string),
  studentPhoto: types.maybeNull(types.string),
  teacherId: types.maybeNull(types.string),
  teacherName: types.maybeNull(types.string),
  teacherLastName: types.maybeNull(types.string),
  teacherFirstName: types.maybeNull(types.string),
  teacherMimeType: types.maybeNull(types.string),
  teacherPhoto: types.maybeNull(types.string),
  groupId: types.maybeNull(types.integer),
  groupName: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  message: types.maybeNull(types.string),
  isStudentSent: false,
  isDeleted: false
})

export default GroupRequestModel
