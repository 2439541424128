import React from "react";

const Thumb = ({ src, alt, ...props }) => {
  return (
    <figure className="sw-thumbnails__thumb" {...props}>
      <div className="sw-thumbnails__inner">
        <img className="sw-thumbnails__img" src={src} alt={alt} />
        {/* <div
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
          }}
          className="sw-form-images__blur"
        >
          <i className="material-icons">zoom_out_map</i>
        </div> */}
      </div>
    </figure>
  );
};

export default Thumb;
