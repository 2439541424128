import { types } from "mobx-state-tree";

import { ResultCellModel } from 'internal';

const ResultRowModel = types.model("ResultRowModel", {
  cells: types.optional(types.array(ResultCellModel), []),
  isHeader: types.optional(types.boolean, false)
});

export default ResultRowModel;
