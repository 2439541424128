import React from 'react'
import classNames from 'classnames'
import t from 'prop-types'

import { utils } from 'internal'
import './Label.scss'

const Label = ({
  tag: Tag,
  for: htmlFor,
  className,
  circular,
  color,
  textColor,
  size,
  ...attributes
}) => {
  const classes = classNames(
    'sw-label',
    circular && 'sw-label--circular',
    color && `sw-${color}`,
    textColor && `sw-text-${textColor}`,
    size && `sw-label--${size}`,
    className
  )

  return <Tag className={classes} htmlFor={htmlFor} {...attributes} />
}

Label.propTypes = {
  className: t.string,
  tag: utils.tagPropType,
  circular: t.bool,
  color: t.oneOf(utils.colors),
  textColor: t.oneOf(utils.colors),
  for: t.string,
  size: t.string
}

Label.defaultProps = {
  tag: 'label'
}

export default Label
