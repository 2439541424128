import { types } from 'mobx-state-tree'
import {
  StudentsInGroupModel,
  DbCollectionsTeachersGroupModel,
  AllTeachersModel
} from 'internal'

export default types.model('GroupProfileModel', {
  userId: types.maybeNull(types.string),
  userName: types.maybeNull(types.string),
  groupName: types.maybeNull(types.string),
  groupId: types.optional(types.integer, 0),
  description: types.maybeNull(types.string),
  groupBackgroundImageLocation: types.maybeNull(types.string),
  studentsCount: types.optional(types.number, 0),
  studentsInGroup: types.optional(types.array(StudentsInGroupModel), []),
  collectionsGroup: types.optional(
    types.array(DbCollectionsTeachersGroupModel),
    []
  ),
  teachersInGroup: types.optional(types.array(AllTeachersModel), []),
  institutionId: types.maybeNull(types.integer),
  institutionLogoLocation: types.maybeNull(types.string),
  groupProblemsType: types.optional(types.integer, 0),
  enrollmentCode: types.maybeNull(types.string),
  isRequestsAutoApproved: types.optional(types.boolean, false)
})
