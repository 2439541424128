import { when } from 'mobx'
import { getEnv, types } from 'mobx-state-tree'
import { fromPromise, FULFILLED } from 'mobx-utils'
import { curry } from 'ramda'

import { HintModel, ServiceStore } from 'internal'

const HintStore = ServiceStore.named('DbTagsStore')
  .props({
    _hints: types.optional(types.array(HintModel), [])
  })
  .actions(self => {
    const apiV1 = getEnv(self).apiV1
    const hintsUrl = curry((url = '') => '/api/HintsApi' + url)

    const getHints = (problemId, languageId) => {
      const prom = fromPromise(
        apiV1.get(
          hintsUrl(
            `/GetSubjectHintsByProblemId?problemId=${problemId}&languageId=${languageId}`
          )
        )
      )

      when(
        () => prom.state === FULFILLED,
        () =>
          prom.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                self._hints = data
              })
            }
          })
      )

      return prom
    }

    return {
      getHints
    }
  })
  .views(self => ({
    get hints() {
      return self._hints
    }
  }))

export default HintStore
