import { types, getEnv, getSnapshot } from 'mobx-state-tree'
import { fromPromise, FULFILLED } from 'mobx-utils'
import { when } from 'mobx'
import { curry } from 'ramda'

import {
  ServiceStore,
  DbSubjectLookUpModel,
  SubjectJudgeTypesModel,
  SubjectTranslateModel,
  DbSubjectsModel,
  DbSubjectDiagramModel,
  RequestModel,
  DbSubjectAllModel,
  DbSubjectModel,
  DbSubjectSecurityProfilesModel,
  DbSubjectHintModel,
  DbSubjectJudgeTypeModel,
  DbSubjectDatabasesModel
} from 'internal'

const DbSubjectStore = ServiceStore.named('DbSubjectStore')
  .props({
    _subjectLookups: types.optional(types.array(DbSubjectLookUpModel), []),
    _authorsSubjectLookups: types.optional(
      types.array(DbSubjectLookUpModel),
      []
    ),
    _subjectJudgeTypes: types.optional(types.array(SubjectJudgeTypesModel), []),
    _subjects: types.optional(
      types.model('PaginationSubjects', {
        total: 0,
        data: types.optional(types.array(DbSubjectsModel), [])
      }),
      {}
    ),
    _subjectAreaFull: types.optional(DbSubjectAllModel, {}),
    _subjectArea: types.optional(DbSubjectModel, {}),
    _subjectAreaDiagrams: types.optional(
      types.array(DbSubjectDiagramModel),
      []
    ),
    _subjectTranslate: types.optional(types.array(SubjectTranslateModel), []),
    _subjectAreaSecurityProfiles: types.optional(
      types.array(DbSubjectSecurityProfilesModel),
      []
    ),
    _subjectAreaHints: types.optional(types.array(DbSubjectHintModel), []),
    _subjectDatabaseScripts: types.optional(
      types.array(DbSubjectJudgeTypeModel),
      []
    ),
    _subjectDatabases: types.optional(types.array(DbSubjectDatabasesModel), []),
    _subjectEngines: types.optional(types.array(SubjectJudgeTypesModel), [])
  })
  .actions(self => {
    const apiV1 = getEnv(self).apiV1

    const getSLUUrl = curry((url = '') => '/api/SubjectsLookUp' + url)
    const dbSubjectsUrl = curry((url = '') => '/api/DbSubjectsApi' + url)
    const dbSubjectsDiagramsUrl = curry((url = '') => '/api/ImageApi' + url)
    const dbSubjectsHintsUrl = curry((url = '') => '/api/HintsApi' + url)

    function lookup(languageId) {
      const prom = fromPromise(
        apiV1.get(getSLUUrl(`/Get?languageId=${languageId}`))
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._subjectLookups = resp.data.map(
                  ({ subjectName, subjectId }) => ({
                    subId: subjectId,
                    subjectName
                  })
                )
              })
            }
          })
        }
      )

      return prom
    }

    const getAuthorsSubjectLookups = languageId => {
      const promise = fromPromise(
        apiV1.get(getSLUUrl(`/GetAllSubjectLookups?languageId=${languageId}`))
      )

      when(
        () => promise.state === FULFILLED,
        () =>
          promise.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                self._authorsSubjectLookups = data.map(
                  ({ subjectName, subjectId }) => ({
                    subId: subjectId,
                    subjectName
                  })
                )
              })
            }
          })
      )

      return promise
    }

    const getDbSubjects = (languageId, page, pageSize) => {
      const prom = fromPromise(
        apiV1.post(
          dbSubjectsUrl('/GetDbSubjectsAll'),
          RequestModel.create({
            languageId,
            page,
            pageSize,
            entityId: 0
          })
        )
      )

      when(
        () => prom.state === FULFILLED,
        () =>
          prom.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                self._subjects = data
              })
            }
          })
      )
      return prom
    }

    const getDbSubjectFullModel = (subjectId, languageId) => {
      const prom = fromPromise(
        apiV1.post(
          dbSubjectsUrl('/GetSubjectFullModel'),
          RequestModel.create({
            languageId,
            entityId: subjectId
          })
        )
      )

      when(
        () => prom.state === FULFILLED,
        () =>
          prom.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                self._subjectAreaFull = data
              })
            }
          })
      )
      return prom
    }

    const getSubjectFullTranslate = subjectId => {
      const prom = fromPromise(
        apiV1.post(
          dbSubjectsUrl('/GetSubjectFullTranslate'),
          RequestModel.create({
            entityId: subjectId
          })
        )
      )

      when(
        () => prom.state === FULFILLED,
        () =>
          prom.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                self._subjectTranslate = data
              })
            }
          })
      )

      return prom
    }

    const getDbSubject = (subjectId, languageId) => {
      const prom = fromPromise(
        apiV1.get(
          dbSubjectsUrl(
            `/GetSubjectArea?subjectId=${subjectId}&languageId=${languageId}`
          )
        )
      )

      when(
        () => prom.state === FULFILLED,
        () =>
          prom.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                self._subjectArea = data
              })
            }
          })
      )
      return prom
    }

    const getDbSubjectSecurityProfiles = subjectId => {
      const prom = fromPromise(
        apiV1.get(
          dbSubjectsUrl(`/GetDbSubjectSecurityProfiles?subjectId=${subjectId}`)
        )
      )

      when(
        () => prom.state === FULFILLED,
        () =>
          prom.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                self._subjectAreaSecurityProfiles = data
              })
            }
          })
      )
      return prom
    }

    const getDbSubjectHints = (subjectId, languageId) => {
      const prom = fromPromise(
        apiV1.get(
          dbSubjectsHintsUrl(
            `/GetSubjectHintsBySubjectId?subjectId=${subjectId}&languageId=${languageId}`
          )
        )
      )

      when(
        () => prom.state === FULFILLED,
        () =>
          prom.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                self._subjectAreaHints = data
              })
            }
          })
      )
      return prom
    }

    const getDbSubjectDiagrams = subjectId => {
      const prom = fromPromise(
        apiV1.get(
          dbSubjectsDiagramsUrl(
            `/GetDiagramLocationBySubjectId?subjectId=${subjectId}`
          )
        )
      )

      when(
        () => prom.state === FULFILLED,
        () =>
          prom.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                self._subjectAreaDiagrams = data
              })
            }
          })
      )
      return prom
    }

    const getSubjectEngines = subjectId => {
      const prom = fromPromise(
        apiV1.get(dbSubjectsUrl(`/GetDbSubjectEngines?subjectId=${subjectId}`))
      )

      when(
        () => prom.state === FULFILLED,
        () =>
          prom.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                self._subjectEngines = data
              })
            }
          })
      )
      return prom
    }

    const addDbSubject = newDbSubject => {
      const prom = fromPromise(
        apiV1.post(dbSubjectsUrl('/CreateSubjectArea'), newDbSubject)
      )
      return prom
    }

    const addDbSubjectSecurityProfile = newDbSubjectSecurityProfile => {
      const prom = fromPromise(
        apiV1.post(
          dbSubjectsUrl('/CreateDbSubjectSecurityProfile'),
          newDbSubjectSecurityProfile
        )
      )
      return prom
    }

    const addDbSubjectHints = newDbSubjectHint => {
      const prom = fromPromise(
        apiV1.post(dbSubjectsHintsUrl('/CreateSubjectHint'), newDbSubjectHint)
      )
      return prom
    }

    const editDbSubject = newDbSubject => {
      const prom = fromPromise(
        apiV1.put(dbSubjectsUrl('/EditSubjectArea'), newDbSubject)
      )
      return prom
    }

    const editSubjectFullTranslate = data => {
      const prom = fromPromise(
        apiV1.post(dbSubjectsUrl('/EditSubjectFullTranslate'), data)
      )
      return prom
    }

    const uploadDbSubjectDiagram = formData => {
      const prom = fromPromise(
        apiV1.post(dbSubjectsDiagramsUrl('/UploadDiagram'), formData)
      )
      return prom
    }

    const editDbSubjectJudgeType = newDbSubjectJudgeType => {
      const prom = fromPromise(
        apiV1.post(
          dbSubjectsUrl('/EditSubjectJudgeType'),
          newDbSubjectJudgeType
        )
      )
      when(
        () => prom.state === FULFILLED,
        () =>
          prom.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                self._subjectAreaFull.dbSubjectJudgeTypeBusinessModels =
                  data.model
              })
            }
          })
      )

      return prom
    }

    const editDbSubjectDataBasesAndDataCode = newDbSubjectDataBasesAndDataCode => {
      const prom = fromPromise(
        apiV1.post(
          dbSubjectsUrl('/EditSubjectDataBasesAndDataCode'),
          newDbSubjectDataBasesAndDataCode
        )
      )
      return prom
    }

    const editDbSubjectSecurityProfile = newDbSubjectSecurityProfile => {
      const prom = fromPromise(
        apiV1.put(
          dbSubjectsUrl('/EditDbSubjectSecurityProfile'),
          newDbSubjectSecurityProfile
        )
      )
      return prom
    }

    const editDbSubjectHints = newDbSubjectHints => {
      const prom = fromPromise(
        apiV1.put(dbSubjectsHintsUrl('/EditSubjectHint'), newDbSubjectHints)
      )
      return prom
    }

    const editDbSubjectEngines = (subjectId, newDbSubjectEngines) => {
      const prom = fromPromise(
        apiV1.put(
          dbSubjectsUrl(`/EditDbSubjectEngines?subjectId=${subjectId}`),
          newDbSubjectEngines
        )
      )
      return prom
    }

    const deleteDbSubject = subjectId => {
      const prom = fromPromise(
        apiV1.delete(dbSubjectsUrl('/DeleteSubjectArea'), {
          params: {
            id: subjectId
          }
        })
      )
      return prom
    }

    const deleteDbSubjectSecurityProfile = securityProfileId => {
      const prom = fromPromise(
        apiV1.delete(dbSubjectsUrl('/DeleteDbSubjectSecurityProfile'), {
          params: {
            securityProfileId
          }
        })
      )
      return prom
    }

    const deleteDbSubjectDiagram = (subjectId, diagramId) => {
      const prom = fromPromise(
        apiV1.delete(dbSubjectsUrl('/DeleteDiagram'), {
          params: {
            diagramId,
            subjectId
          }
        })
      )
      return prom
    }

    const deleteDbSubjectHint = id => {
      const prom = fromPromise(
        apiV1.delete(dbSubjectsHintsUrl('/DeleteDbSubjectHint'), {
          params: {
            hintId: id
          }
        })
      )
      return prom
    }

    const createSubjectDb = id => {
      const prom = fromPromise(
        apiV1.get(dbSubjectsUrl('/CreateDataBase'), {
          params: {
            id
          }
        })
      )

      return prom
    }

    const getSubjectJudgeTypes = () => {
      const prom = fromPromise(apiV1.get(dbSubjectsUrl('/GetJudgeTypes')))
      when(
        () => prom.state === FULFILLED,
        () =>
          prom.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                self._subjectJudgeTypes = data
              })
            }
          })
      )

      return prom
    }

    const resetSubject = () => (self._subjectArea = {})

    const getSubjectDatabaseScripts = subjectId => {
      const promise = fromPromise(
        apiV1.get(
          dbSubjectsUrl(`/GetDbSubjectJudgeTypes?subjectId=${subjectId}`)
        )
      )
      when(
        () => promise.state === FULFILLED,
        () =>
          promise.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                self._subjectDatabaseScripts = data
              })
            }
          })
      )
      return promise
    }

    const getSubjectDatabasesAndDataCode = (subjectId, languageId) => {
      const promise = fromPromise(
        apiV1.get(
          dbSubjectsUrl(
            `/GetDbSubjectDataBasesAndDatacodes?subjectId=${subjectId}&languageId=${languageId}`
          )
        )
      )

      when(
        () => promise.state === FULFILLED,
        () =>
          promise.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                self._subjectDatabases = data
              })
            }
          })
      )

      return promise
    }

    const editSubjectJudgeTypes = subjectJudgeType =>
      fromPromise(
        apiV1.put(dbSubjectsUrl('/EditDbSubjectJudgeTypes'), subjectJudgeType)
      )

    const editSubjectDatabaseAndDataCode = subjectDatabaseAndDatacode =>
      fromPromise(
        apiV1.put(
          dbSubjectsUrl('/EditDbSubjectDataBaseAndDatacode'),
          subjectDatabaseAndDatacode
        )
      )

    const createSubjectDatabaseAndDataCode = subjectDatabaseAndDatacode =>
      fromPromise(
        apiV1.post(
          dbSubjectsUrl('/CreateDbSubjectDataBaseAndDatacode'),
          subjectDatabaseAndDatacode
        )
      )

    const deleteSubjectDatabaseAndDataCode = databaseAndDatacodeId =>
      fromPromise(
        apiV1.delete(
          dbSubjectsUrl(
            `/DeleteDbSubjectDataBaseAndDatacode?dbSubjectDataBaseAndDatacodeId=${databaseAndDatacodeId}`
          )
        )
      )

    return {
      lookup,
      getAuthorsSubjectLookups,
      getDbSubjects,
      getDbSubjectFullModel,
      getSubjectFullTranslate,
      getDbSubject,
      getDbSubjectSecurityProfiles,
      getDbSubjectHints,
      getDbSubjectDiagrams,
      getSubjectEngines,
      editSubjectFullTranslate,
      editDbSubjectEngines,
      addDbSubjectSecurityProfile,
      addDbSubject,
      addDbSubjectHints,
      editDbSubject,
      uploadDbSubjectDiagram,
      editDbSubjectHints,
      editDbSubjectJudgeType,
      editDbSubjectDataBasesAndDataCode,
      editDbSubjectSecurityProfile,
      deleteDbSubject,
      deleteDbSubjectSecurityProfile,
      deleteDbSubjectDiagram,
      deleteDbSubjectHint,
      createSubjectDb,
      getSubjectJudgeTypes,
      resetSubject,
      getSubjectDatabaseScripts,
      getSubjectDatabasesAndDataCode,
      editSubjectDatabaseAndDataCode,
      createSubjectDatabaseAndDataCode,
      deleteSubjectDatabaseAndDataCode,
      editSubjectJudgeTypes
    }
  })
  .views(self => ({
    get subjectLookups() {
      return getSnapshot(self._subjectLookups)
    },
    get authorsSubjectLookups() {
      return getSnapshot(self._authorsSubjectLookups)
    },
    get subjectTranslate() {
      return getSnapshot(self._subjectTranslate)
    },
    get subjectJudgeTypes() {
      return getSnapshot(self._subjectJudgeTypes)
    },
    get subjects() {
      return getSnapshot(self._subjects)
    },
    get subjectAreaDiagrams() {
      return getSnapshot(self._subjectAreaDiagrams)
    },
    get subjectAreaFull() {
      return getSnapshot(self._subjectAreaFull)
    },
    get subjectArea() {
      return getSnapshot(self._subjectArea)
    },
    get subjectAreaSecurityProfiles() {
      return getSnapshot(self._subjectAreaSecurityProfiles)
    },
    get subjectAreaHints() {
      return getSnapshot(self._subjectAreaHints)
    },
    get subjectEngines() {
      return getSnapshot(self._subjectEngines)
    },
    get diagrams() {
      return (
        self._subjectArea.dbSubjectDiagram &&
        getSnapshot(self._subjectArea.dbSubjectDiagram)
      )
    },
    get subjectDatabaseScripts() {
      return getSnapshot(self._subjectDatabaseScripts)
    },
    get subjectDatabases() {
      return getSnapshot(self._subjectDatabases)
    },
    get emptyDatabaseModel() {
      return getSnapshot(DbSubjectDatabasesModel.create())
    }
  }))

export default DbSubjectStore
