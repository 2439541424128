import React from 'react'
import loadable from '@loadable/component'

import { ErrorBoundary } from './components'

import {
  AUTH_STUDENT_ROLE,
  AUTH_TEACHER_ROLE,
  AUTH_AUTHOR_ROLE,
  AUTH_ADMIN_ROLE
} from './core/constants'

const Loading = () => <div>Loading...</div>

// pages
const DefaultLayout = loadable(() => import('./containers/DefaultLayout/'), {
  fallback: <Loading />
})
const Auth = loadable(() => import('./views/pages/Auth/'), {
  fallback: <Loading />
})
const ForgotPassword = loadable(() => import('./views/pages/ForgotPassword/'), {
  fallback: <Loading />
})
const ConfirmEmail = loadable(() => import('./views/pages/ConfirmEmail/'), {
  fallback: <Loading />
})
const NewPassword = loadable(() => import('./views/pages/NewPassword/'), {
  fallback: <Loading />
})
const Page404 = loadable(() => import('./views/pages/404/'), {
  fallback: <Loading />
})
const Greeting = loadable(() => import('./views/pages/Greeting/'), {
  fallback: <Loading />
})
const ConfirmRequest = loadable(() => import('./views/pages/ConfirmRequest/'), {
  fallback: <Loading />
})
const ForgotPasswordSuccess = loadable(
  () => import('./views/pages/ForgotPasswordSuccess/'),
  {
    fallback: <Loading />
  }
)
const NewPasswordSuccess = loadable(
  () => import('./views/pages/NewPasswordSuccess/'),
  {
    fallback: <Loading />
  }
)
const UserAgreement = loadable(() => import('./views/pages/UserAgreement/'), {
  fallback: <Loading />
})

// views
const DbProblems = loadable(() => import('./views/DbProblems/'), {
  fallback: <Loading />
})
const DbProblemSubmissions = loadable(
  () => import('./views/DbProblemSubmissions'),
  {
    fallback: <Loading />
  }
)
const SuggestedDbTags = loadable(
  () => import('./views/Suggested/SuggestedDbTags'),
  {
    fallback: <Loading />
  }
)
const SuggestedSimilarProblems = loadable(
  () => import('./views/Suggested/SuggestedSimilarProblems'),
  {
    fallback: <Loading />
  }
)
const SuggestedLinks = loadable(
  () => import('./views/Suggested/SuggestedLinks'),
  {
    fallback: <Loading />
  }
)
const ControlLinks = loadable(() => import('./views/control/Links'), {
  fallback: <Loading />
})
const ControlDbCollections = loadable(
  () => import('./views/control/DbCollections'),
  {
    fallback: <Loading />
  }
)
const ControlAlgoCollections = loadable(
  () => import('./views/control/AlgoCollections'),
  {
    fallback: <Loading />
  }
)
const SubjectAreas = loadable(() => import('./views/control/SubjectArea'), {
  fallback: <Loading />
})
const Requests = loadable(() => import('./views/Requests'), {
  fallback: <Loading />
})
const Users = loadable(() => import('./views/Users'), {
  fallback: <Loading />
})
const Groups = loadable(() => import('./views/Groups'), {
  fallback: <Loading />
})
const GroupProfile = loadable(() => import('./views/Groups/Profile'), {
  fallback: <Loading />
})
const SolveDbProblem = loadable(() => import('./views/SolveProblem'), {
  fallback: <Loading />
})
const SolveAlgoProblem = loadable(() => import('./views/AlgoSolveProblem'), {
  fallback: <Loading />
})
const FAQ = loadable(() => import('./views/FAQ'), {
  fallback: <Loading />
})
const ControlDbTags = loadable(() => import('./views/control/DbTags'), {
  fallback: <Loading />
})
const ControlAlgoTags = loadable(() => import('./views/control/AlgoTags'), {
  fallback: <Loading />
})
const Links = loadable(() => import('./views/Links'), {
  fallback: <Loading />
})
const Profile = loadable(() => import('./views/Profile'), {
  fallback: <Loading />
})
const Rejudge = loadable(() => import('./views/control/Rejudge'), {
  fallback: <Loading />
})
const Result = loadable(() => import('./views/ResultTable'), {
  fallback: <Loading />
})
const Chat = loadable(() => import('./views/Chat'), {
  fallback: <Loading />
})
const AlgoProblems = loadable(() => import('./views/AlgoProblems'), {
  fallback: <Loading />
})
const AlgoProblemSubmissions = loadable(
  () => import('./views/AlgoProblemSubmissions'),
  {
    fallback: <Loading />
  }
)
const Institutes = loadable(() => import('./views/Institutes'), {
  fallback: <Loading />
})

const InstituteProfile = loadable(() => import('./views/InstituteProfile'), {
  fallback: <Loading />
})
const StudentProfile = loadable(() => import('./views/StudentProfile'), {
  fallback: <Loading />
})

const SandboxDbProblems = loadable(() => import('./views/SandboxDbProblems/'), {
  fallback: <Loading />
})
const SandboxDbProblemSubmissions = loadable(
  () => import('./views/SandboxDbProblemSubmissions/'),
  {
    fallback: <Loading />
  }
)

const Reports = loadable(() => import('./views/Reports'), {
  fallback: <Loading />
})

const Statistic = loadable(() => import('./views/Statistic'), {
  fallback: <Loading />
})

const TestTable = loadable(() => import('./views/Statistic/TestTable'), {
  fallback: <Loading />
})

const UserActivity = loadable(() => import('./views/UserActivity'), {
  fallback: <Loading />
})

// root route
export const ROOT_ROUTE = '/'
// pages
export const SIGN_IN_ROUTE = '/auth/signin'
export const SIGN_UP_ROUTE = '/auth/signup'
export const AUTH_ROUTE = '/auth'
export const CONFIRM_REQUEST_ROUTE = '/confirm-request'
export const FORGOT_PASSWORD_ROUTE = '/forgot-password'
export const CONFIRM_EMAIL_ROUTE = '/confirm-email'
export const FORGOT_PASSWORD_SUCCESS_ROUTE = '/forgot-password-success'
export const NEW_PASSWORD_ROUTE = '/new-password'
export const NEW_PASSWORD_SUCCESS_ROUTE = '/new-password-success'
export const NOT_FOUND_ROUTE = '/404'
export const NOT_FOUND_VIEW_ROUTE = '/not-found'
export const FAQ_ROUTE = '/faq'
export const USER_AGREEMENT_ROUTE = '/user-agreement'
// views
export const CONTROL_DB_COLLECTIONS_ROUTE = '/control/db-collections'
export const CONTROL_ALGO_COLLECTIONS_ROUTE = '/control/algo-collections'
export const CONTROL_LINKS_ROUTE = '/control/links'
export const CONTROL_DB_TAGS_ROUTE = '/control/db-tags'
export const CONTROL_ALGO_TAGS_ROUTE = '/control/algo-tags'
export const CONTROL_ALGO_LINKS_ROUTE = '/control/algo-links'
export const ALGO_GREETING_PAGE = '/algo-greeting'
export const DB_GREETING_PAGE = '/db-greeting'
export const SANDBOX_GREETING_PAGE = '/sandbox-db-greeting'

export const LINKS_ROUTE = '/links/:tagId'
export const DB_PROBLEM_SUBMISSIONS_ROUTE = '/db-problem/submissions'
export const ALGO_PROBLEM_SUBMISSIONS_ROUTE = '/algo-problem/submissions'
export const SANDBOX_DB_PROBLEM_SUBMISSIONS_ROUTE =
  '/sandbox/db-problem/submissions'

export const DB_PROBLEMS_ROUTE = '/db-problems'
export const ALGO_PROBLEMS_ROUTE = '/algo-problems'
export const REQUESTS_ROUTE = '/requests'
export const SOLVE_DB_PROBLEM_ROUTE = '/db/problem/:problemId'
export const SOLVE_DB_PROBLEM_SUBMISSION_ROUTE = '/db/submission/:submissionId'
export const SOLVE_SANDBOX_DB_PROBLEM_SUBMISSION_ROUTE =
  '/sandbox/db/submission/:submissionId'
export const SOLVE_ALGO_PROBLEM_ROUTE = '/algo/problem/:problemId'
export const SOLVE_ALGO_PROBLEM_SUBMISSION_ROUTE =
  '/algo/submission/:submissionId'

export const SANDBOX_DB_PROBLEMS_ROUTE = '/sandbox/db-problems'
export const SOLVE_SANDBOX_DB_PROBLEM_ROUTE = '/sandbox/db/problem/:problemLink'

export const SOLVE_ALGO_PROBLEM_ROUTE_TEST = '/algo/problem/test'

export const GROUPS_ROUTE = '/groups'
export const GROUP_PROFILE_ROUTE = '/group/profile/:groupId'
export const ALGO_GROUPS_ROUTE = '/algo-groups'
export const SUBJECT_AREAS_ROUTE = '/subjectAreas'
export const SUGGESTED_LINKS_ROUTE = '/suggested/links'
export const SUGGESTED_PROBLEMS_ROUTE = '/suggested/problems'
export const SUGGESTED_DB_TAGS_ROUTE = '/suggested/db-tags'
export const USERS_ROUTE = '/admin/users'
export const PROFILE_ROUTE = '/profile'
export const REJUDGE_ROUTE = '/rejudge'
export const RESULT_ROUTE = '/contest-rank-list'
export const CHAT_ROUTE = '/chat'
export const INSTITUTES_ROUTE = '/admin/institutes'
export const INSTITUTE_PROFILE_ROUTE = '/institute-profile/:instituteId'
export const STUDENT_PROFILE_ROUTE = '/student-profile/:studentId'

export const REPORTS_ROUTE = '/reports'
export const DB_STATISTIC_ROUTE = '/db-statistic'
export const ALGO_STATISTIC_ROUTE = '/algo-statistic'
export const TEST_TABLE_ROUTE = '/test-table'
export const USER_ACTIVITY_ROUTE = '/user-activity'

export const TYPE_ROUTE = 'route'
export const TYPE_REDIRECT = 'redirect'

export const pages = [
  {
    key: 'auth-page',
    type: TYPE_ROUTE,
    isAuth: false,
    path: AUTH_ROUTE,
    exact: false,
    name: 'Auth',
    render: props => (
      <ErrorBoundary>
        <Auth {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'confirm-email-page',
    type: TYPE_ROUTE,
    path: CONFIRM_EMAIL_ROUTE,
    exact: true,
    isAuth: false,
    name: 'Confirm email',
    render: props => (
      <ErrorBoundary>
        <ConfirmEmail {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'forgot-password-page',
    type: TYPE_ROUTE,
    path: FORGOT_PASSWORD_ROUTE,
    exact: true,
    isAuth: false,
    name: 'Forgot password',
    render: props => (
      <ErrorBoundary>
        <ForgotPassword {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'forgot-password-success-page',
    type: TYPE_ROUTE,
    isAuth: false,
    path: FORGOT_PASSWORD_SUCCESS_ROUTE,
    exact: true,
    name: 'Forgot password success',
    render: props => (
      <ErrorBoundary>
        <ForgotPasswordSuccess {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'new-password-page',
    type: TYPE_ROUTE,
    isAuth: false,
    path: NEW_PASSWORD_ROUTE,
    exact: true,
    name: 'New password',
    render: props => (
      <ErrorBoundary>
        <NewPassword {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'new-password-success-page',
    type: TYPE_ROUTE,
    isAuth: false,
    path: NEW_PASSWORD_SUCCESS_ROUTE,
    exact: true,
    name: 'New password success',
    render: props => (
      <ErrorBoundary>
        <NewPasswordSuccess {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'confirm-request-page',
    type: TYPE_ROUTE,
    isAuth: false,
    path: CONFIRM_REQUEST_ROUTE,
    exact: true,
    name: 'Sign up',
    render: props => (
      <ErrorBoundary>
        <ConfirmRequest {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'faq-page',
    type: TYPE_ROUTE,
    path: FAQ_ROUTE,
    isAuth: true,
    exact: true,
    name: 'FAQ page',
    render: props => (
      <ErrorBoundary>
        <FAQ {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'user-agreement',
    type: TYPE_ROUTE,
    path: USER_AGREEMENT_ROUTE,
    isAuth: false,
    name: 'User agreement',
    render: props => (
      <ErrorBoundary>
        <UserAgreement {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'main-page',
    type: TYPE_ROUTE,
    isAuth: true,
    path: ROOT_ROUTE,
    exact: false,
    name: 'Main',
    render: props => (
      <ErrorBoundary>
        <DefaultLayout {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: '404-page',
    type: TYPE_ROUTE,
    isAuth: false,
    name: 'Page 404',
    render: props => (
      <ErrorBoundary>
        <Page404 {...props} />
      </ErrorBoundary>
    )
  }
]

export const views = [
  {
    key: 'greeting-page',
    type: TYPE_ROUTE,
    private: false,
    path: [SANDBOX_GREETING_PAGE, DB_GREETING_PAGE, ALGO_GREETING_PAGE],
    roles: [
      AUTH_AUTHOR_ROLE,
      AUTH_TEACHER_ROLE,
      AUTH_ADMIN_ROLE,
      AUTH_STUDENT_ROLE
    ],
    exact: true,
    name: 'Greeting page',
    render: props => (
      <ErrorBoundary>
        <Greeting {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'problem-list-page',
    type: TYPE_ROUTE,
    private: false,
    roles: [AUTH_STUDENT_ROLE, AUTH_TEACHER_ROLE, AUTH_AUTHOR_ROLE],
    path: DB_PROBLEMS_ROUTE,
    exact: true,
    name: 'Problems page',
    render: props => (
      <ErrorBoundary>
        <DbProblems {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'problem-submissions-page',
    type: TYPE_ROUTE,
    private: false,
    roles: [AUTH_STUDENT_ROLE, AUTH_TEACHER_ROLE, AUTH_AUTHOR_ROLE],
    path: [DB_PROBLEM_SUBMISSIONS_ROUTE],
    exact: true,
    name: 'Problem submissions page',
    render: props => (
      <ErrorBoundary>
        <DbProblemSubmissions {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'algo-problem-submissions-page',
    type: TYPE_ROUTE,
    private: false,
    roles: [AUTH_STUDENT_ROLE, AUTH_TEACHER_ROLE, AUTH_AUTHOR_ROLE],
    path: ALGO_PROBLEM_SUBMISSIONS_ROUTE,
    exact: true,
    name: 'Algo Problem submissions page',
    render: props => (
      <ErrorBoundary>
        <AlgoProblemSubmissions {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'sandbox-db-problem-submissions-page',
    type: TYPE_ROUTE,
    private: false,
    roles: [AUTH_STUDENT_ROLE, AUTH_TEACHER_ROLE, AUTH_AUTHOR_ROLE],
    path: SANDBOX_DB_PROBLEM_SUBMISSIONS_ROUTE,
    exact: true,
    name: 'Sandbox Db problem submissions page',
    render: props => (
      <ErrorBoundary>
        <SandboxDbProblemSubmissions {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'suggested-db-tags-page',
    type: TYPE_ROUTE,
    private: false,
    roles: [AUTH_AUTHOR_ROLE],
    path: SUGGESTED_DB_TAGS_ROUTE,
    exact: true,
    name: 'SuggestedDbTags',
    render: props => (
      <ErrorBoundary>
        <SuggestedDbTags {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'suggested-problems-page',
    type: TYPE_ROUTE,
    private: false,
    roles: [AUTH_AUTHOR_ROLE],
    path: SUGGESTED_PROBLEMS_ROUTE,
    exact: true,
    name: 'SuggestedSimilarProblems',
    render: props => (
      <ErrorBoundary>
        <SuggestedSimilarProblems {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'suggested-links-page',
    type: TYPE_ROUTE,
    private: false,
    roles: [AUTH_AUTHOR_ROLE],
    path: SUGGESTED_LINKS_ROUTE,
    exact: true,
    name: 'SuggestedLinks',
    render: props => (
      <ErrorBoundary>
        <SuggestedLinks {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'control-db-tags-page',
    type: TYPE_ROUTE,
    private: false,
    roles: [AUTH_AUTHOR_ROLE],
    path: CONTROL_DB_TAGS_ROUTE,
    exact: true,
    name: 'Db tags page',
    render: props => (
      <ErrorBoundary>
        <ControlDbTags {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'control-algo-tags-page',
    type: TYPE_ROUTE,
    private: false,
    roles: [AUTH_AUTHOR_ROLE],
    path: CONTROL_ALGO_TAGS_ROUTE,
    exact: true,
    name: 'Algo tags page',
    render: props => (
      <ErrorBoundary>
        <ControlAlgoTags {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'control-algo-links-page',
    type: TYPE_ROUTE,
    private: false,
    roles: [AUTH_AUTHOR_ROLE],
    path: CONTROL_ALGO_LINKS_ROUTE,
    exact: true,
    name: 'Algo links page',
    render: props => (
      <ErrorBoundary>
        <ControlLinks {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'subjects-area-page',
    type: TYPE_ROUTE,
    private: false,
    roles: [AUTH_AUTHOR_ROLE],
    path: SUBJECT_AREAS_ROUTE,
    exact: true,
    name: 'Subject areas page',
    render: props => (
      <ErrorBoundary>
        <SubjectAreas {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'users-page',
    type: TYPE_ROUTE,
    private: false,
    roles: [AUTH_ADMIN_ROLE],
    path: USERS_ROUTE,
    exact: true,
    name: 'Users page',
    render: props => (
      <ErrorBoundary>
        <Users {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'control-db-collections-page',
    type: TYPE_ROUTE,
    private: false,
    roles: [AUTH_TEACHER_ROLE],
    path: CONTROL_DB_COLLECTIONS_ROUTE,
    exact: true,
    name: 'Db collections page',
    render: props => (
      <ErrorBoundary>
        <ControlDbCollections {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'control-algo-collections-page',
    type: TYPE_ROUTE,
    private: false,
    roles: [AUTH_TEACHER_ROLE],
    path: CONTROL_ALGO_COLLECTIONS_ROUTE,
    exact: true,
    name: 'Algo collections page',
    render: props => (
      <ErrorBoundary>
        <ControlAlgoCollections {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'control-links-page',
    type: TYPE_ROUTE,
    private: false,
    roles: [AUTH_AUTHOR_ROLE],
    path: CONTROL_LINKS_ROUTE,
    exact: true,
    name: 'Control links page',
    render: props => (
      <ErrorBoundary>
        <ControlLinks {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'groups-page',
    type: TYPE_ROUTE,
    private: false,
    roles: [AUTH_STUDENT_ROLE, AUTH_TEACHER_ROLE],
    path: GROUPS_ROUTE,
    exact: true,
    name: 'Groups page',
    render: props => (
      <ErrorBoundary>
        <Groups {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'group-profile-page',
    type: TYPE_ROUTE,
    private: false,
    roles: [AUTH_STUDENT_ROLE, AUTH_TEACHER_ROLE],
    path: GROUP_PROFILE_ROUTE,
    exact: true,
    name: 'Group profile page',
    render: props => (
      <ErrorBoundary>
        <GroupProfile {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'algo-groups-page',
    type: TYPE_ROUTE,
    private: false,
    roles: [AUTH_STUDENT_ROLE, AUTH_TEACHER_ROLE],
    path: ALGO_GROUPS_ROUTE,
    exact: true,
    name: 'Algo groups page',
    render: props => (
      <ErrorBoundary>
        <Groups {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'requests-page',
    type: TYPE_ROUTE,
    private: false,
    roles: [AUTH_TEACHER_ROLE, AUTH_STUDENT_ROLE],
    path: REQUESTS_ROUTE,
    exact: true,
    name: 'Requests page',
    render: props => (
      <ErrorBoundary>
        <Requests {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'solve-db-problem-page',
    type: TYPE_ROUTE,
    private: false,
    roles: [AUTH_STUDENT_ROLE, AUTH_TEACHER_ROLE],
    path: [SOLVE_DB_PROBLEM_ROUTE],
    exact: true,
    name: 'Solve db problem page',
    render: props => (
      <ErrorBoundary>
        <SolveDbProblem {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'solve-sandbox-db-problem-page',
    type: TYPE_ROUTE,
    private: false,
    roles: [AUTH_STUDENT_ROLE, AUTH_TEACHER_ROLE, AUTH_AUTHOR_ROLE],
    path: [SOLVE_SANDBOX_DB_PROBLEM_ROUTE],
    exact: true,
    name: 'Solve sandbox db problem page',
    render: props => (
      <ErrorBoundary>
        <SolveDbProblem {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'solve-db-problem-submission-page',
    type: TYPE_ROUTE,
    private: false,
    roles: [AUTH_STUDENT_ROLE, AUTH_TEACHER_ROLE, AUTH_AUTHOR_ROLE],
    path: SOLVE_DB_PROBLEM_SUBMISSION_ROUTE,
    exact: true,
    name: 'Solve db problem submission page',
    render: props => (
      <ErrorBoundary>
        <SolveDbProblem {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'solve-sandbox-db-problem-submission-page',
    type: TYPE_ROUTE,
    private: false,
    roles: [AUTH_STUDENT_ROLE, AUTH_TEACHER_ROLE, AUTH_AUTHOR_ROLE],
    path: SOLVE_SANDBOX_DB_PROBLEM_SUBMISSION_ROUTE,
    exact: true,
    name: 'Solve sandbox db problem submission page',
    render: props => (
      <ErrorBoundary>
        <SolveDbProblem {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'link-page',
    type: TYPE_ROUTE,
    private: false,
    roles: [AUTH_STUDENT_ROLE, AUTH_TEACHER_ROLE, AUTH_AUTHOR_ROLE],
    path: LINKS_ROUTE,
    exact: true,
    name: 'Link page',
    render: props => (
      <ErrorBoundary>
        <Links {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'profile-page',
    type: TYPE_ROUTE,
    private: false,
    roles: [
      AUTH_STUDENT_ROLE,
      AUTH_TEACHER_ROLE,
      AUTH_AUTHOR_ROLE,
      AUTH_ADMIN_ROLE
    ],
    path: PROFILE_ROUTE,
    exact: true,
    name: 'Profile page',
    render: props => (
      <ErrorBoundary>
        <Profile {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: '404-page-view',
    type: TYPE_ROUTE,
    private: false,
    roles: [
      AUTH_STUDENT_ROLE,
      AUTH_TEACHER_ROLE,
      AUTH_AUTHOR_ROLE,
      AUTH_ADMIN_ROLE
    ],
    path: NOT_FOUND_VIEW_ROUTE,
    exact: true,
    name: 'Not found view',
    render: props => (
      <ErrorBoundary>
        <Page404 {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'rejudge-page',
    type: TYPE_ROUTE,
    private: false,
    roles: [AUTH_AUTHOR_ROLE],
    path: REJUDGE_ROUTE,
    exact: true,
    name: 'Rejudge page',
    render: props => (
      <ErrorBoundary>
        <Rejudge {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'result-page',
    type: TYPE_ROUTE,
    private: false,
    roles: [
      AUTH_STUDENT_ROLE,
      AUTH_TEACHER_ROLE,
      AUTH_AUTHOR_ROLE,
      AUTH_ADMIN_ROLE
    ],
    path: RESULT_ROUTE,
    exact: true,
    name: 'Result page',
    render: props => (
      <ErrorBoundary>
        <Result {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'chat-page',
    type: TYPE_ROUTE,
    roles: [
      AUTH_STUDENT_ROLE,
      AUTH_TEACHER_ROLE,
      AUTH_AUTHOR_ROLE,
      AUTH_ADMIN_ROLE
    ],
    path: CHAT_ROUTE,
    exact: true,
    name: 'Chat page',
    render: props => (
      <ErrorBoundary>
        <Chat {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'algo-problem-list-page',
    type: TYPE_ROUTE,
    private: false,
    roles: [AUTH_STUDENT_ROLE, AUTH_TEACHER_ROLE, AUTH_AUTHOR_ROLE],
    path: ALGO_PROBLEMS_ROUTE,
    exact: true,
    name: 'Algo Problems page',
    render: props => (
      <ErrorBoundary>
        <AlgoProblems {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'solve-algo-problem-page',
    type: TYPE_ROUTE,
    private: false,
    roles: [AUTH_STUDENT_ROLE, AUTH_TEACHER_ROLE],
    path: SOLVE_ALGO_PROBLEM_ROUTE,
    exact: true,
    name: 'Solve algo problem page',
    render: props => (
      <ErrorBoundary>
        <SolveAlgoProblem {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'solve-algo-problem-submission-page',
    type: TYPE_ROUTE,
    private: false,
    roles: [AUTH_STUDENT_ROLE, AUTH_TEACHER_ROLE, AUTH_AUTHOR_ROLE],
    path: SOLVE_ALGO_PROBLEM_SUBMISSION_ROUTE,
    exact: true,
    name: 'Solve algo problem submission page',
    render: props => (
      <ErrorBoundary>
        <SolveAlgoProblem {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'institutes-page',
    type: TYPE_ROUTE,
    private: false,
    roles: [AUTH_ADMIN_ROLE],
    path: INSTITUTES_ROUTE,
    exact: true,
    name: 'Institutes page',
    render: props => (
      <ErrorBoundary>
        <Institutes {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'sandbox-problem-list-page',
    type: TYPE_ROUTE,
    private: false,
    roles: [AUTH_STUDENT_ROLE, AUTH_TEACHER_ROLE, AUTH_AUTHOR_ROLE],
    path: SANDBOX_DB_PROBLEMS_ROUTE,
    exact: true,
    name: 'Sandbox problems page',
    render: props => (
      <ErrorBoundary>
        <SandboxDbProblems {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'institute-profile-page',
    type: TYPE_ROUTE,
    private: false,
    roles: [
      AUTH_ADMIN_ROLE,
      AUTH_STUDENT_ROLE,
      AUTH_TEACHER_ROLE,
      AUTH_AUTHOR_ROLE
    ],
    path: INSTITUTE_PROFILE_ROUTE,
    exact: true,
    name: 'Institute profile page',
    render: props => (
      <ErrorBoundary>
        <InstituteProfile {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'student-profile-page',
    type: TYPE_ROUTE,
    private: false,
    roles: [
      AUTH_ADMIN_ROLE,
      AUTH_STUDENT_ROLE,
      AUTH_TEACHER_ROLE,
      AUTH_AUTHOR_ROLE
    ],
    path: STUDENT_PROFILE_ROUTE,
    exact: true,
    name: 'Student profile page',
    render: props => (
      <ErrorBoundary>
        <StudentProfile {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'reports-page',
    type: TYPE_ROUTE,
    private: false,
    roles: [AUTH_TEACHER_ROLE, AUTH_ADMIN_ROLE],
    path: REPORTS_ROUTE,
    exact: true,
    name: 'Reports page',
    render: props => (
      <ErrorBoundary>
        <Reports {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'db-statistic-page',
    type: TYPE_ROUTE,
    private: false,
    roles: [AUTH_TEACHER_ROLE],
    path: DB_STATISTIC_ROUTE,
    exact: true,
    name: 'Statistic page',

    render: props => (
      <ErrorBoundary>
        <Statistic {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'algo-statistic-page',
    type: TYPE_ROUTE,
    private: false,
    roles: [AUTH_TEACHER_ROLE],
    path: ALGO_STATISTIC_ROUTE,
    exact: true,
    name: 'Statistic page',

    render: props => (
      <ErrorBoundary>
        <Statistic {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'test-table',
    type: TYPE_ROUTE,
    private: false,
    roles: [
      AUTH_ADMIN_ROLE,
      AUTH_STUDENT_ROLE,
      AUTH_TEACHER_ROLE,
      AUTH_AUTHOR_ROLE
    ],
    path: TEST_TABLE_ROUTE,
    exact: true,
    name: 'Test table',
    render: props => (
      <ErrorBoundary>
        <TestTable {...props} />
      </ErrorBoundary>
    )
  },
  {
    key: 'user-activity-page',
    type: TYPE_ROUTE,
    private: false,
    roles: [AUTH_ADMIN_ROLE],
    path: USER_ACTIVITY_ROUTE,
    exact: true,
    name: 'User activity page',

    render: props => (
      <ErrorBoundary>
        <UserActivity {...props} />
      </ErrorBoundary>
    )
  },

  // redirects
  {
    key: 'redirect-from-root-to-greeting-page',
    type: TYPE_REDIRECT,
    from: ROOT_ROUTE,
    to: DB_GREETING_PAGE
  }
]

export const checkEmptySeacrh = (props, userRole) => {
  if (props.location.search === '') {
    props.replace({ search: `?current_page=1&role=${userRole}` })
  }
}
