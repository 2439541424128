import { getEnv, getSnapshot, types } from 'mobx-state-tree'
import { fromPromise, FULFILLED } from 'mobx-utils'
import { when } from 'mobx'
import { curry } from 'ramda'

import {
  ServiceStore,
  DbCollectionModel,
  DbProblemLookUpModel,
  RequestModel,
  SortingModel
} from 'internal'

const CollectionsStore = ServiceStore.named('CollectionsStore')
  .props({
    _collectionLookups: types.optional(
      types.model('CollectionLookups', {
        data: types.optional(
          types.array(
            types.model('CollectionsStore_TaskCollectionLookup', {
              collectionId: types.maybeNull(types.integer),
              collectionName: types.maybeNull(types.string)
            })
          ),
          []
        ),
        total: types.optional(types.number, 0)
      }),
      {}
    ),
    _collections: types.optional(
      types.model('PaginationGroups', {
        data: types.optional(types.array(DbCollectionModel), []),
        total: types.optional(types.number, 0)
      }),
      {}
    ),
    _notAttachedProblems: types.optional(
      types.model('PaginationProblem', {
        data: types.optional(types.array(DbProblemLookUpModel), []),
        total: types.optional(types.number, 0)
      }),
      {}
    ),
    _collectionGroupsLookup: types.optional(
      types.array(
        types.model('CollectionGroupLookUp', {
          dbGroupId: types.optional(types.number, 0),
          dbGroupName: types.optional(types.string, '')
        })
      ),
      []
    ),
    _groupCollectionsLookup: types.optional(
      types.array(
        types.model('GroupCollectionsLookupModel', {
          collectionId: types.optional(types.number, 0),
          collectionName: types.optional(types.string, '')
        })
      ),
      []
    )
  })
  .actions(self => {
    const apiV1 = getEnv(self).apiV1

    const groupsLookUpApi = curry(url => '/api/GroupsLookUpApi' + url)
    const colApi = curry(url => '/api/CollectionsApi' + url)
    const getDbCLUUrl = curry(url => '/api/DbCollectionsLookUp' + url)

    function lookupByRole(reqBody) {
      const prom = fromPromise(
        apiV1.post(getDbCLUUrl('/GetCollectionByRole'), reqBody)
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                if (reqBody.page === 1) self._collectionLookups = resp.data
                else
                  self._collectionLookups.data = self._collectionLookups.data.concat(
                    resp.data.data
                  )
              })
            }
          })
        }
      )

      return prom
    }

    const getCollectionLookupById = (collectionId, role, collectionType) => {
      const prom = fromPromise(
        apiV1.post(getDbCLUUrl('/GetCollection'), {
          collectionId,
          role,
          collectionType
        })
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                if (
                  data.collectionId &&
                  !self._collectionLookups.data.some(
                    collectionLookup =>
                      collectionLookup.collectionId === data.collectionId
                  )
                ) {
                  self._collectionLookups.data = [
                    ...self._collectionLookups.data,
                    data
                  ]
                }
              })
            }
          })
        }
      )

      return prom
    }

    function list(page, pageSize, problemTypeId) {
      const prom = fromPromise(
        apiV1.post(colApi('/Get'), {
          ...RequestModel.create({
            page,
            pageSize,
            entityId: 0
          }),
          problemTypeId,
          subjectIds: [],
          levelIds: [],
          tagIds: [],
          sorting: SortingModel.create()
        })
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._collections = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    function create(data) {
      const prom = fromPromise(apiV1.post(colApi('/Post'), data))
      return prom
    }

    function save(data) {
      const prom = fromPromise(apiV1.put(colApi('/Put'), data))
      return prom
    }

    function getCollection(collectionId, languageId) {
      const prom = fromPromise(
        apiV1.post(
          colApi('/GetProblemCollection'),
          RequestModel.create({
            entityId: collectionId,
            languageId
          })
        )
      )

      return prom
    }

    function deleteCollection(collectionId) {
      const prom = fromPromise(
        apiV1.delete(colApi('/Delete'), { params: { id: collectionId } })
      )

      return prom
    }

    function lookupNotAttachedProblem(reqBody) {
      const prom = fromPromise(
        apiV1.post(colApi('/NotAttachedProblems'), reqBody)
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                if (reqBody.page === 1) self._notAttachedProblems = resp.data
                else
                  self._notAttachedProblems.data = self._notAttachedProblems.data.concat(
                    resp.data.data
                  )
              })
            }
          })
        }
      )

      return prom
    }

    function addNotAttachedCollection(newCollections) {
      const notAttachedProblems = self._notAttachedProblems.data.concat(
        newCollections
      )
      self._notAttachedProblems.data = notAttachedProblems
    }

    const getGroupLookupsByCollectionId = collectionId => {
      const prom = fromPromise(
        apiV1.get(
          groupsLookUpApi(
            `/GetGroupsByCollectionId?collectionId=${collectionId}`
          )
        )
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                self._collectionGroupsLookup = data
              })
            }
          })
        }
      )

      return prom
    }

    const getCollectionLookupsByGroupId = (groupId, collectionType) => {
      const promise = fromPromise(
        apiV1.get(
          getDbCLUUrl(
            `/GetCollectionsByGroupId?groupId=${groupId}&collectionType=${collectionType}`
          )
        )
      )

      when(
        () => promise.state === FULFILLED,
        () => {
          promise.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                self._groupCollectionsLookup = data
              })
            }
          })
        }
      )

      return promise
    }

    return {
      list,
      lookupByRole,
      create,
      save,
      getCollection,
      deleteCollection,
      lookupNotAttachedProblem,
      addNotAttachedCollection,
      getGroupLookupsByCollectionId,
      getCollectionLookupById,
      getCollectionLookupsByGroupId
    }
  })
  .views(self => ({
    get collectionLookups() {
      return getSnapshot(self._collectionLookups)
    },
    get collections() {
      return getSnapshot(self._collections)
    },
    get notAttachedProblems() {
      return getSnapshot(self._notAttachedProblems)
    },
    get groupLookups() {
      return getSnapshot(self._collectionGroupsLookup)
    },
    get groupCollectionsLookup() {
      return getSnapshot(self._groupCollectionsLookup)
    }
  }))

export default CollectionsStore
