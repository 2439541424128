import { types } from 'mobx-state-tree'

export default types.model('AlgoProblemSubmissionsAuthorFilterModel', {
  correct: types.maybeNull(types.boolean),
  mistake: types.maybeNull(types.boolean),
  send: types.optional(types.boolean, false),
  fromDate: types.maybeNull(types.Date),
  toDate: types.maybeNull(types.Date),
  problemCode: types.maybeNull(types.string),
  studentIdList: types.maybeNull(types.array(types.string)),
  userId: types.maybeNull(types.string),
  languageId: types.maybeNull(types.integer, 2),
  page: 1,
  pageSize: 10
})
