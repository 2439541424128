import { types } from "mobx-state-tree";

import { ResultTableStructureModel } from 'internal';

const TaskSubmissionPostModel = types.model("TaskSubmissionPostModel", {
  result: types.optional(types.boolean, false),
  description: types.optional(types.array(types.string), []),
  userResult: types.optional(types.array(ResultTableStructureModel), []),
  solutionResult: types.optional(ResultTableStructureModel, {})
});

export default TaskSubmissionPostModel;
