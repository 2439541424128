import React from 'react'

import { utils } from 'internal'
import './ErrorBoundary.scss'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, error: null, errorInfo: null }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo
    })

    if (error.name === 'ChunkLoadError') window.location.reload()

    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.hasError) {
      const { error, errorInfo } = this.state
      return (
        this.props.fallback || (
          <DefaultErrorHandler error={error} info={errorInfo} />
        )
      )
    }

    return this.props.children
  }
}

function DefaultErrorHandler({ error, info }) {
  return (
    <div>
      <h2>Something went wrong.</h2>
      <details style={{ whiteSpace: 'pre-wrap' }}>
        {error && error.toString()}
        <br />
        {info && info.componentStack}
      </details>
    </div>
  )
}

ErrorBoundary.propTypes = {
  fallback: utils.tagPropType
}

export default ErrorBoundary
