import { types } from 'mobx-state-tree'

export default types.model('DbSubjectSecurityProfilesModel', {
  id: types.optional(types.identifierNumber, 0),
  subjectId: types.optional(types.integer, 0),
  name: types.optional(types.string, ''),
  isDeleted: types.optional(types.boolean, true),
  isDefault: types.optional(types.boolean, true),
  grantSelect: types.maybeNull(types.string),
  grantInsert: types.maybeNull(types.string),
  grantUpdate: types.maybeNull(types.string),
  grantDelete: types.maybeNull(types.string)
})
