import { getEnv, types, getSnapshot } from 'mobx-state-tree'
import { fromPromise, FULFILLED } from 'mobx-utils'
import { when } from 'mobx'
import { curry } from 'ramda'

import {
  ServiceStore,
  DbSubmissionModel,
  DbProblemSolutionModel,
  DbProblemUserModel,
  AUTH_STUDENT_ROLE
} from 'internal'

const DbSubmissionStore = ServiceStore.named('DbSubmissionStore')
  .props({
    _submissions: types.optional(types.array(DbSubmissionModel), []),
    _submission: types.optional(
      types.model('DbSubmissionStore__submission', {
        solution: '',
        logText: types.maybeNull(types.string),
        userResult: types.frozen(),
        judgeType: types.maybeNull(types.integer),
        studentUserName: types.maybeNull(types.string),
        submitDateTime: types.maybeNull(types.string),
        hasAccessToProblem: types.optional(types.boolean, true)
      }),
      {}
    ),
    _solution: types.optional(DbProblemSolutionModel, {}),
    _dbSubmissionUserProblem: types.optional(DbProblemUserModel, {})
  })
  .actions(self => {
    const apiV1 = getEnv(self).apiV1

    const dbSubmissionsApi = curry(url => '/api/DbSubmissionsApi' + url)
    const dbCheckSolution = curry(url => '/api/DbCheckSolution' + url)

    function getByProblemId(problemId) {
      const prom = fromPromise(
        apiV1.get(
          dbSubmissionsApi(
            `/GetDbSubmissionsByProblemId?problemId=${problemId}`
          )
        )
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._submissions = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    function getBySubmissionId(submissionId) {
      const prom = fromPromise(
        apiV1.get(
          dbSubmissionsApi(
            `/GetDbSubmissionsBySubmissionId?submissionId=${submissionId}`
          )
        )
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._submissions = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    function getSubmissionByRole(subId, userRole) {
      const prom = fromPromise(
        apiV1.get(dbSubmissionsApi('/GetSubmission'), {
          params: {
            id: subId,
            role: userRole
          }
        })
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._submission = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    function processSolution(subData, userRole) {
      const checkIsStudent = userRole === AUTH_STUDENT_ROLE
      const prom = fromPromise(
        apiV1.post(
          checkIsStudent
            ? dbCheckSolution('/Create')
            : dbSubmissionsApi('/CreateAuthorTeacherSubmission'),
          subData
        )
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._solution = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    function list(filters) {
      const prom = fromPromise(
        apiV1.post(dbSubmissionsApi('/ListSubmissions'), filters)
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._submissions = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    function addComment(_data) {
      const prom = fromPromise(
        apiV1.post(dbSubmissionsApi('/AddUserComment'), _data)
      )

      return prom
    }

    const getDbSubmissionUserProblem = (problemId, userId) => {
      const prom = fromPromise(
        apiV1.get(dbSubmissionsApi('/GetUserProblem'), {
          params: {
            problemId,
            userId
          }
        })
      )

      when(
        () => prom.state === FULFILLED,
        () =>
          prom.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                if (!data) return
                self._dbSubmissionUserProblem = data
              })
            }
          })
      )

      return prom
    }

    const correctResult = reqBody => {
      const prom = fromPromise(
        apiV1.post(dbCheckSolution('/ShowCorrectResult'), reqBody)
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                self._solution = {
                  ...data,
                  userResult: null,
                  solutionResult: data.userResult
                }
              })
            }
          })
        }
      )

      return prom
    }

    const clearSubmission = () => {
      self._submission = {}
    }

    const clearSolution = () => {
      self._solution = {}
    }

    const clearUserProblem = () => {
      self._dbSubmissionUserProblem = {}
    }

    const setRating = newRating => {
      self._dbSubmissionUserProblem.raiting = newRating
    }

    return {
      getByProblemId,
      getBySubmissionId,
      getSubmissionByRole,
      processSolution,
      list,
      addComment,
      getDbSubmissionUserProblem,
      clearSubmission,
      clearSolution,
      setRating,
      clearUserProblem,
      correctResult
    }
  })
  .views(self => ({
    get submissions() {
      return getSnapshot(self._submissions)
    },
    get submission() {
      return getSnapshot(self._submission)
    },
    get solution() {
      return getSnapshot(self._solution)
    },
    get dbProblemsForTagLookUp() {
      return self._dbProblemsForTagLookUp
    },
    get dbSubmissionUserProblem() {
      return self._dbSubmissionUserProblem
    }
  }))

export default DbSubmissionStore
