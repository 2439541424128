import React from "react";
import t from "prop-types";
import classNames from "classnames";

import {utils} from 'internal';


const FormGroup = ({tag: Tag, check, disabled, className, ...attributes}) => {
  const classes = classNames(
    'sw-form-group',
    check && "sw-form-checkbox",
    check && disabled ? "sw-form-checkbox__disabled" : false,
    className
  );

  return (<Tag className={classes} {...attributes}/>);
};

FormGroup.propTypes = {
  tag: utils.tagPropType,
  className: t.string,
  check: t.bool,
  disabled: t.bool
};

FormGroup.defaultProps = {
  tag: "div"
};

export default FormGroup;
