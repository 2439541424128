import {types} from "mobx-state-tree";


const BaseStore = types.model(
  'BaseStore'
).actions(() => ({
  runInAction(fn) {
    return fn()
  }
}));

export default BaseStore;
