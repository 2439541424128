import React from "react";
import classNames from "classnames";
import t from "prop-types";

import {utils} from 'internal';


const HeaderInner = ({tag: Tag, className, ...attributes}) => {
  const classes = classNames("l-header__inner", className);

  return <Tag className={classes} {...attributes} />;
};

HeaderInner.propTypes = {
  className: t.string,
  tag: utils.tagPropType
};

HeaderInner.defaultProps = {
  tag: 'div'
};


export default HeaderInner;
