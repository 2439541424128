import { getEnv, types, getSnapshot } from 'mobx-state-tree'
import { fromPromise, FULFILLED } from 'mobx-utils'
import { when } from 'mobx'
import { curry } from 'ramda'

import {
  ServiceStore,
  RequestModel,
  InstituteModel,
  InstitutesLookUpModel,
  UsersInstitutionModel
} from 'internal'

const InstituteStore = ServiceStore.named('InstituteStore')
  .props({
    _institutes: types.optional(
      types.model('PaginationGroups', {
        data: types.optional(types.array(InstituteModel), []),
        total: types.optional(types.number, 0)
      }),
      {}
    ),
    _institutesLookUp: types.optional(
      types.model('InstitutesLookUp', {
        data: types.optional(types.array(InstitutesLookUpModel), []),
        total: types.optional(types.number, 0)
      }),
      {}
    ),
    _instituteTeachers: types.optional(
      types.model('PaginationGroups', {
        data: types.optional(types.array(UsersInstitutionModel), []),
        total: types.optional(types.number, 0)
      }),
      {}
    ),
    _instituteStudents: types.optional(
      types.model('PaginationGroups', {
        data: types.optional(types.array(UsersInstitutionModel), []),
        total: types.optional(types.number, 0)
      }),
      {}
    ),
    _instituteStatistic: types.optional(
      types.array(
        types.model('InstitutesStatistic', {
          acceptedCount: types.optional(types.number, 0),
          totalCount: types.optional(types.number, 0),
          date: types.optional(types.string, '')
        }),
        {}
      ),
      []
    ),
    _institute: types.optional(InstituteModel, {})
  })
  .actions(self => {
    const apiV1 = getEnv(self).apiV1

    const instituteApi = curry(url => '/api/InstitutionApi' + url)
    const institutesLookUpApi = curry(url => '/api/InstitutionsLookUpApi' + url)
    const accountApi = curry(url => '/api/ManageApi' + url)
    const userProfileApi = curry(url => '/api/UserProfileApi' + url)

    const list = (page, pageSize) => {
      const prom = fromPromise(
        apiV1.post(
          instituteApi('/Get'),
          RequestModel.create({
            page,
            pageSize
          })
        )
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._institutes = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    const listUsers = data => {
      const prom = fromPromise(
        apiV1.post(instituteApi('/GetUsersByRoleInInstitution'), {
          ...data
        })
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          const role = data.role
          prom.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                role === 'Student'
                  ? (self._instituteStudents = data)
                  : (self._instituteTeachers = data)
              })
            }
          })
        }
      )

      return prom
    }

    const edit = formData => {
      const prom = fromPromise(apiV1.put(instituteApi('/Put'), formData))
      return prom
    }

    const create = formData => {
      const prom = fromPromise(apiV1.post(instituteApi('/Post'), formData))
      return prom
    }

    const deleteById = instituteId => {
      const prom = fromPromise(
        apiV1.delete(instituteApi('/Delete'), {
          params: {
            id: instituteId
          }
        })
      )
      return prom
    }

    const getById = institutionId => {
      const prom = fromPromise(
        apiV1.get(instituteApi('/GetInstitutionById'), {
          params: {
            institutionId: institutionId
          }
        })
      )
      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._institute = resp.data.model
              })
            }
          })
        }
      )
      return prom
    }

    const leave = institutionId => {
      const prom = fromPromise(
        apiV1.delete(instituteApi('/LeaveUserInstitute'), {
          params: { institutionId }
        })
      )
      return prom
    }

    const getInstitutesLookUp = reqBody => {
      const prom = fromPromise(
        apiV1.post(institutesLookUpApi('/GetAllInstitutions'), reqBody)
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                if (reqBody.page === 1) self._institutesLookUp = resp.data
                else
                  self._institutesLookUp.data = self._institutesLookUp.data.concat(
                    resp.data.data
                  )
              })
            }
          })
        }
      )

      return prom
    }

    const getUserInstitutesLookup = reqBody => {
      const prom = fromPromise(
        apiV1.post(institutesLookUpApi('/GetUserInstitutions'), reqBody)
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                if (reqBody.page === 1) self._institutesLookUp = resp.data
                else
                  self._institutesLookUp.data = self._institutesLookUp.data.concat(
                    resp.data.data
                  )
              })
            }
          })
        }
      )

      return prom
    }

    const getTeacherInstitutes = reqBody => {
      const prom = fromPromise(
        apiV1.post(accountApi('/GetTeacherInstitutions'), reqBody)
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                if (reqBody.page === 1) self._institutesLookUp = resp.data
                else
                  self._institutesLookUp.data = self._institutesLookUp.data.concat(
                    resp.data.data
                  )
              })
            }
          })
        }
      )
      return prom
    }

    const getStatistic = institutionId => {
      const prom = fromPromise(
        apiV1.get(instituteApi('/GetSubmissionsStatistics'), {
          params: { institutionId }
        })
      )
      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._instituteStatistic = resp.data
              })
            }
          })
        }
      )
      return prom
    }

    const getUserInstitutions = (page, pageSize, userId) => {
      const prom = fromPromise(
        apiV1.post(userProfileApi('/GetAllInstitutions'), {
          ...RequestModel.create({ page, pageSize }),
          userId
        })
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._institutes = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    return {
      listUsers,
      edit,
      create,
      list,
      deleteById,
      getById,
      getInstitutesLookUp,
      getTeacherInstitutes,
      leave,
      getStatistic,
      getUserInstitutions,
      getUserInstitutesLookup
    }
  })
  .views(self => ({
    get institutes() {
      return getSnapshot(self._institutes)
    },
    get institutesLookUp() {
      return getSnapshot(self._institutesLookUp)
    },
    getInstitutesByIds(instituteIds) {
      return getSnapshot(self._institutesLookUp).data.filter(institute =>
        instituteIds.some(
          instituteId => instituteId === institute.institutionId
        )
      )
    },
    getOneInstituteById(instituteId) {
      return getSnapshot(self._institutesLookUp).data.find(
        institute => institute.institutionId === instituteId
      )
    },
    getTeachersList() {
      return getSnapshot(self._instituteTeachers)
    },
    getStudentsList() {
      return getSnapshot(self._instituteStudents)
    },
    getInstituteStatistic() {
      return getSnapshot(self._instituteStatistic)
    },
    getInstituteInfo() {
      return getSnapshot(self._institute)
    }
  }))

export default InstituteStore
