import React from "react";
import { Wrapper, closeMenu, openMenu } from "react-aria-menubutton";

import DropDownToggle from "./DropDownToggle";
import DropDownMenu from "./DropDownMenu";
import DropDownItem from "./DropDownItem";

const DropDown = props => <Wrapper {...props} />;

DropDown.Toggle = DropDownToggle;
DropDown.Menu = DropDownMenu;
DropDown.Item = DropDownItem;
DropDown.closeMenu = closeMenu;
DropDown.openMenu = openMenu;

export default DropDown;
