import { when } from 'mobx'
import { types, getEnv, getSnapshot } from 'mobx-state-tree'
import { fromPromise, FULFILLED } from 'mobx-utils'
import { curry } from 'ramda'

import {
  DbTagsBusinessModel,
  TagBusinessLookUpModel,
  LinkModel,
  ServiceStore,
  RequestModel
} from 'internal'

export default ServiceStore.named('DbTagsStore')
  .props({
    _tags: types.optional(
      types.model('PaginationGroups', {
        data: types.optional(types.array(DbTagsBusinessModel), []),
        total: types.optional(types.number, 0)
      }),
      {}
    ),
    _tagLookups: types.optional(
      types.model('TagLookUps', {
        data: types.optional(types.array(TagBusinessLookUpModel), []),
        total: types.optional(types.number, 0)
      }),
      {}
    ),
    _tagLinks: types.optional(types.array(LinkModel), [])
  })
  .actions(self => {
    const apiV1 = getEnv(self).apiV1

    const tagsApi = curry(url => '/api/TagsApi' + url)
    const tagsLookUpApi = curry(url => '/api/DbTagsLookUp' + url)

    const list = (languageId, page, pageSize, problemTypeId) => {
      const prom = fromPromise(
        apiV1.post(tagsApi('/GetAllTags'), {
          ...RequestModel.create({
            languageId,
            page,
            pageSize,
            entityId: 0
          }),
          problemTypeId
        })
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                self._tags = data
              })
            }
          })
        }
      )

      return prom
    }

    const create = data => {
      const prom = fromPromise(apiV1.post(tagsApi('/CreateTag'), data))
      return prom
    }

    const save = data => {
      const prom = fromPromise(apiV1.put(tagsApi('/EditTag'), data))
      return prom
    }

    const deleteTag = id => {
      const prom = fromPromise(
        apiV1.delete(tagsApi('/DeleteTag'), {
          params: { id }
        })
      )

      return prom
    }

    const lookup = reqBody => {
      const prom = fromPromise(
        apiV1.post(tagsLookUpApi('/GetAllTags'), reqBody)
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                if (reqBody.page === 1) self._tagLookups = data
                else
                  self._tagLookups.data = self._tagLookups.data.concat(
                    data.data
                  )
              })
            }
          })
        }
      )

      return prom
    }

    const getLinksByTagId = id => {
      const prom = fromPromise(
        apiV1.get(tagsApi('/GetAllLinkInTag'), {
          params: { id }
        })
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                self._tagLinks = data
              })
            }
          })
        }
      )

      return prom
    }

    return {
      list,
      create,
      save,
      deleteTag,
      lookup,
      getLinksByTagId
    }
  })
  .views(self => ({
    get tags() {
      return getSnapshot(self._tags)
    },
    get tagLookups() {
      return getSnapshot(self._tagLookups)
    },
    get tagLinks() {
      return getSnapshot(self._tagLinks)
    }
  }))
