import { types } from 'mobx-state-tree'

export default types.model('AlgoDetailProblemModel', {
  success: types.optional(types.integer, 0),
  fails: types.optional(types.integer, 0),
  allSuccess: types.optional(types.integer, 0),
  allFails: types.optional(types.integer, 0),
  problemLevel: types.optional(types.integer, 1),
  problemAuthorEmail: types.maybeNull(types.string),
  timeLimit: types.optional(types.integer, 0),
  memoryLimit: types.optional(types.integer, 0),
  studentName: types.maybeNull(types.string),
  userId: types.maybeNull(types.string)
})