import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import classNames from 'classnames'
import t from 'prop-types'

import { utils } from 'internal'

import ModalHeader from './ModalHeader'
import ModalBody from './ModalBody'
import ModalFooter from './ModalFooter'

import './Modal.scss'

class Modal extends Component {
  constructor(props) {
    super(props)
    this.container = document.createElement('div')
    document.body.appendChild(this.container)
  }

  componentDidMount() {
    window.addEventListener('keyup', this.handleKeyUp, false)
    document.addEventListener('click', this.handleOutsideClick, false)
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.handleKeyUp, false)
    document.removeEventListener('click', this.handleOutsideClick, false)
    document.body.removeChild(this.container)
  }

  handleKeyUp = e => {
    const { onClose, persistent } = this.props
    if (persistent) {
      return false
    }
    const keys = {
      27: () => {
        e.preventDefault()
        onClose()
        window.removeEventListener('keyup', this.handleKeyUp, false)
      }
    }

    if (keys[e.keyCode]) {
      keys[e.keyCode]()
    }
  }

  handleOutsideClick = e => {
    const { onClose, persistent } = this.props
    if (persistent) {
      return false
    }
    if (this.modal) {
      if (!this.modal.contains(e.target)) {
        onClose()
        document.removeEventListener('click', this.handleOutsideClick, false)
      }
    }
  }

  render() {
    const { tag: Tag, className, persistent, ...props } = this.props
    const classes = classNames('sw-modal__content', className)
    return ReactDOM.createPortal(
      <div className="sw-modal">
        <Tag ref={node => (this.modal = node)} className={classes} {...props} />
      </div>,
      this.container
    )
  }
}

Modal.propTypes = {
  tag: utils.tagPropType,
  persistent: t.bool,
  className: t.string
}

Modal.defaultProps = {
  tag: 'div',
  persistent: false
}

Modal.Header = ModalHeader
Modal.Body = ModalBody
Modal.Footer = ModalFooter

export default Modal
