import { types } from 'mobx-state-tree'

export default types.model('DetailedLogModel', {
  id: types.identifierNumber,
  userName: types.string,
  problemName: types.string,
  statusBefore: types.string,
  statusAfter: types.string,
  submissionId: types.integer,
  problemId: types.integer
})
