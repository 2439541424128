import { types } from 'mobx-state-tree'

const UsersInstitutionModel = types.model('UsersInstitutionModel', {
  id: types.optional(types.string, ''),
  name: types.optional(types.string, ''),
  email: types.maybeNull(types.string),
  userPhoto: types.maybeNull(types.string)
})

export default UsersInstitutionModel
