import React from 'react'
import { observer } from 'mobx-react'
import t from 'prop-types'
import { Form, Col, Row } from 'react-bootstrap'
import classNames from 'classnames'
import TextareaAutosize from 'react-textarea-autosize'

const SimpleInput = observer(
  ({ field, form, fluid, onHandleChange, variable, as, inline, ...props }) => {
    const onChange = value => {
      field.value = value
      onHandleChange(value, variable)
    }
    const formValidated = form ? form.validated > 0 : true
    const textareaAutoSizeClasses = classNames('form-control', {
      'is-invalid': field.hasError && formValidated
    })
    return (
      <Form.Group
        {...(inline
          ? { as: Row }
          : { style: fluid ? { flexDirection: 'column' } : {} })}
      >
        <Form.Label
          htmlFor={field.id}
          {...(inline ? { column: true, xs: 3 } : { className: 'mb-2' })}
        >
          {field.label}
        </Form.Label>
        <Col {...(!inline && { className: 'p-0' })}>
          {as === 'textareaAutoSize' ? (
            <TextareaAutosize
              className={textareaAutoSizeClasses}
              {...props}
              {...field.bind()}
              value={field.value || ''}
              {...(onHandleChange && {
                onChange: e => onChange(e.target.value)
              })}
            />
          ) : (
            <Form.Control
              {...field.bind()}
              value={field.value || ''}
              isInvalid={formValidated && field.hasError}
              {...(onHandleChange && {
                onChange: e => onChange(e.target.value)
              })}
              {...props}
            />
          )}

          <Form.Control.Feedback type="invalid">
            {field.error}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
    )
  }
)

SimpleInput.propTypes = {
  field: t.any.isRequired,
  form: t.any,
  isRequired: t.bool,
  fluid: t.bool
}

export default SimpleInput
