import React from "react";
import classNames from "classnames";
import t from "prop-types";

import { utils } from 'internal';

const TabNav = ({ tag: Tag, className, ...props }) => {
  const classes = classNames("sw-tabs__nav", className);

  return <Tag className={classes} {...props} />;
};

TabNav.propTypes = {
  tag: utils.tagPropType,
  className: t.string
};

TabNav.defaultProps = {
  tag: "div"
};

export default TabNav;
