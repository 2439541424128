import React from 'react'
import classNames from 'classnames'
import t from 'prop-types'

import { utils } from 'internal'

const MaterialIcon = ({ tag: Tag, name, className, ...attributes }) => {
  const classes = classNames('material-icons', className)
  return (
    <Tag className={classes} {...attributes}>
      {name}
    </Tag>
  )
}

MaterialIcon.propTypes = {
  tag: utils.tagPropType,
  className: t.string,
  name: t.string.isRequired
}

MaterialIcon.defaultProps = {
  tag: 'i'
}

export default MaterialIcon
