import React from "react";
import { observer } from "mobx-react";
import t from "prop-types";

import {
  Column,
  Row,
  Form,
  Label,
  CustomInput,
  FormFeedback
} from "../index";

const SimpleCheckbox = observer(({ field, form, ...props }) => {
  const formValidated = form ? form.validated > 0 : true;

  return (
    <Form.Group>
      <Row className="align-centered">
        <Column col={3}>
          <Label className="center-label" for={field.id}>
            {field.label}
          </Label>
        </Column>
        <Column fluid>
          <CustomInput
            {...field.bind()}
            color="grey"
            value={!!field.value}
            label=""
            bordered
            {...props}
          />
          <FormFeedback invalid={formValidated && field.hasError}>
            {field.error}
          </FormFeedback>
        </Column>
      </Row>
    </Form.Group>
  );
});

SimpleCheckbox.propTypes = {
  field: t.any.isRequired,
  form: t.any
};

export default SimpleCheckbox;
