import React from "react";
import ReactDOM from 'react-dom';
import t from "prop-types";
import {Popper as ReactPopper} from 'react-popper';
import classNames from 'classnames';

import {utils} from 'internal';


class PopperContent extends React.Component {

  constructor(props) {
    super(props);

    this.state = {isOpen: props.isOpen};

    this.handlePlacementChange = this.handlePlacementChange.bind(this);
    this.setTargetNode = this.setTargetNode.bind(this);
    this.getTargetNode = this.getTargetNode.bind(this);
    this.getRef = this.getRef.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    return {isOpen: props.isOpen};
  }

  componentDidUpdate() {
    if (this._element && this._element.childNodes && this._element.childNodes[0] && this._element.childNodes[0].focus) {
      this._element.childNodes[0].focus();
    }
  }

  setTargetNode(node) {
    this.targetNode = node;
  }

  getTargetNode() {
    return this.targetNode;
  }

  getContainerNode() {
    return utils.getTarget(this.props.container);
  }

  getRef(ref) {
    this._element = ref;
  }

  handlePlacementChange(data) {
    if (this.state.placement !== data.placement) {
      this.setState({placement: data.placement});
    }
    return data;
  }

  renderChildren() {
    const {
      children,
      isOpen,
      flip,
      target,
      offset,
      fallbackPlacement,
      placementPrefix,
      arrowClassName: _arrowClassName,
      popperClassName: _popperClassName,
      hideArrow,
      tag,
      container,
      modifiers,
      boundariesElement,
      ...attrs
    } = this.props;

    const arrowClassName = classNames("arrow", "sw-popover-arrow", _arrowClassName);
    const placement = this.state.placement || attrs.placement;
    const placementFirstPart = placement.split('-')[0];
    const popperClassName = classNames(
      _popperClassName,
      placementPrefix ? `${placementPrefix}-${placementFirstPart}` : placementFirstPart
    );

    const extendedModifiers = {
      offset: {offset},
      flip: {enabled: flip, behavior: fallbackPlacement},
      preventOverflow: {boundariesElement},
      update: {
        enabled: true,
        order: 950,
        fn: this.handlePlacementChange,
      },
      ...modifiers,
    };

    return (
      <ReactPopper referenceElement={this.targetNode}
                   modifiers={extendedModifiers}
                   placement={placement}>
        {({ref, style, placement, arrowProps}) => (
          <div ref={ref} style={style} className={popperClassName} x-placement={placement}>
            {children}
            {!hideArrow && <span ref={arrowProps.ref} className={arrowClassName} style={arrowProps.style}/>}
          </div>
        )}
      </ReactPopper>
    );
  }

  render() {
    this.setTargetNode(utils.getTarget(this.props.target));

    if (this.state.isOpen) {
      return this.props.container === 'inline' ?
        this.renderChildren() :
        ReactDOM.createPortal((<div ref={this.getRef}>{this.renderChildren()}</div>), this.getContainerNode());
    }

    return null;
  }
}

PopperContent.propTypes = {
  tag: utils.tagPropType,
  className: t.string,
  children: t.node.isRequired,
  placement: t.string,
  placementPrefix: t.string,
  popperClassName: t.string,
  arrowClassName: t.string,
  hideArrow: t.bool,
  isOpen: t.bool.isRequired,
  container: utils.targetPropType,
  target: utils.targetPropType.isRequired,
  modifiers: t.object,
  boundariesElement: t.oneOfType([t.string, utils.DOMElement]),
  offset: t.oneOfType([t.string, t.number]),
  fallbackPlacement: t.oneOfType([t.string, t.array]),
  flip: t.bool,
};

PopperContent.defaultProps = {
  boundariesElement: 'scrollParent',
  placement: 'auto',
  hideArrow: false,
  isOpen: false,
  container: 'body',
  modifiers: {},
  offset: 0,
  fallbackPlacement: 'flip',
  flip: true,
};

export default PopperContent;
