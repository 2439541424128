import React from "react";
import t from "prop-types";
import classNames from "classnames";

import {
  utils
} from 'internal';


function Table(props) {

  const {
    tag: Tag,
    className,
    all,
    bordered,
    striped,
    hoverable,
    centered,
    ...attrs
  } = props;

  const classes = classNames(
    all ? "sw-table" : "sw-table-all",
    bordered && "sw-table-bordered",
    striped && "sw-table-striped",
    hoverable && "sw-table-hoverable",
    centered && "sw-table-centered",
    className
  );

  return (
    <Tag className={classes} {...attrs}/>
  );
}

Table.propTypes = {
  tag: utils.tagPropType,
  className: t.string,
  all: t.bool,
  bordered: t.bool,
  striped: t.bool,
  hoverable: t.bool,
  centered: t.bool,
};

Table.defaultProps = {
  tag: "table",
};

export default Table;
