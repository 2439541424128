import PropTypes from 'prop-types'
import { onSnapshot, applySnapshot, types } from 'mobx-state-tree'
import MobxReactForm from 'mobx-react-form'
import dvr from 'mobx-react-form/lib/validators/DVR'
import validatorjs from 'validatorjs'
import moment from 'moment'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'

import { LocalStorage, SessionStorage } from 'internal'

/**
 * Custom MST ISO8601 Date type
 */
export const CustomISO8601Date = types.custom({
  name: 'CustomISO8601Date',
  fromSnapshot(value) {
    return moment(value).toDate()
  },
  toSnapshot(value) {
    return moment(value).toDate()
  },
  isTargetType(value) {
    return moment(value).isValid()
  },
  getValidationMessage(value) {
    return `${value} is not valid ISO8601 format`
  }
})

export const getErrorMessage = response =>
  response.message ? response.message[0] : null

// Обрезка текста
export const stringCut = (str, count) => {
  let outText = str.slice(0, count).trim()
  if (str.length > count) {
    outText = `${outText}...`
  }
  return outText
}

export const canUseDOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
)

function isFunction(functionToCheck) {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === '[object Function]'
  )
}

export function isArrayOrNodeList(els) {
  if (els === null) {
    return false
  }
  return Array.isArray(els) || (canUseDOM && typeof els.length === 'number')
}

export function isReactRefObj(target) {
  if (target && typeof target === 'object') {
    return 'current' in target
  }
  return false
}

export function findDOMElements(target) {
  if (isReactRefObj(target)) {
    return target.current
  }
  if (isFunction(target)) {
    return target()
  }
  if (typeof target === 'string' && canUseDOM) {
    let selection = document.querySelectorAll(target)
    if (!selection.length) {
      selection = document.querySelectorAll(`#${target}`)
    }
    if (!selection.length) {
      throw new Error(
        `The target '${target}' could not be identified in the dom, tip: check spelling`
      )
    }
    return selection
  }
  return target
}

export function getTarget(target) {
  const els = findDOMElements(target)
  if (isArrayOrNodeList(els)) {
    return els[0]
  }
  return els
}

export const PopperPlacements = [
  'auto-start',
  'auto',
  'auto-end',
  'top-start',
  'top',
  'top-end',
  'right-start',
  'right',
  'right-end',
  'bottom-end',
  'bottom',
  'bottom-start',
  'left-end',
  'left',
  'left-start'
]

/** Функция кодирования значений для query string */
export const encodeQueryString = data => {
  const dataKeys = Object.keys(data)
  const arrLength = dataKeys.length
  return dataKeys.reduce((acc, dataKey, i) => {
    const isLastElementOfArray = i + 1 === arrLength
    const URIComponent = encodeURIComponent(data[dataKey])
    acc = acc + `${dataKey}=${URIComponent}${!isLastElementOfArray ? '&' : ''}`
    return acc
  }, '')
}

/**
 * Функция проверки если доступно хранилище
 *
 * @param: {string} type
 * @return: {boolean}
 * Если true, то хранилище доступно
 * Если false, то хранилище не доступно
 */
export function storageAvailable(type) {
  try {
    var storage = window[type],
      x = '__storage_test__'
    storage.setItem(x, x)
    storage.removeItem(x)
    return true
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage.length !== 0
    )
  }
}

/**
 * Функция для проверки параметра на целое число
 *
 * Пример:
 *  null => false
 *  NaN => false
 *  'string' => false
 *  true => false
 *
 * @param {any} num
 */
export function isNumber(num) {
  return !(isNaN(parseInt(num)) || !isFinite(num))
}

/**
 * Капитализация первого символа строки
 *
 * @param {str} str
 */
export function capitalize(str) {
  if (typeof str === 'string' && !str) {
    return new Error('Invalid argument type must be a string.')
  }

  const _str = str.trim()
  return _str.charAt(0).toUpperCase() + _str.slice(1)
}

// Shim Element if needed (e.g. in Node environment)
const Element = typeof window === 'object' && (window.Element || function() {})

export function DOMElement(props, propName, componentName) {
  if (!(props[propName] instanceof Element)) {
    return new Error(
      'Invalid prop `' +
        propName +
        '` supplied to `' +
        componentName +
        '`. Expected prop to be an instance of Element. Validation failed.'
    )
  }
}

/**
 * Общая утилита если тип свойства Component
 */
export const tagPropType = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.string,
  PropTypes.node,
  PropTypes.shape({ $$typeof: PropTypes.symbol, render: PropTypes.func }),
  PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.node,
      PropTypes.string,
      PropTypes.shape({ $$typeof: PropTypes.symbol, render: PropTypes.func })
    ])
  )
])

export const targetPropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.func,
  DOMElement,
  PropTypes.shape({ current: PropTypes.any })
])

/**
 * Returns a new object with the key/value pairs from `obj` that are not in the array `omitKeys`.
 */
export function omit(obj, omitKeys) {
  const result = {}
  Object.keys(obj).forEach(key => {
    if (omitKeys.indexOf(key) === -1) {
      result[key] = obj[key]
    }
  })
  return result
}

/**
 * Returns a filtered copy of an object with only the specified keys.
 */
export function pick(obj, keys) {
  const pickKeys = Array.isArray(keys) ? keys : [keys]
  let length = pickKeys.length
  let key
  const result = {}

  while (length > 0) {
    length -= 1
    key = pickKeys[length]
    result[key] = obj[key]
  }
  return result
}

export const keyCodes = {
  esc: 27,
  space: 32,
  enter: 13,
  tab: 9,
  up: 38,
  down: 40,
  home: 36,
  end: 35,
  n: 78,
  p: 80
}

/**
 * общая утилита чтобы удалить пробелы в начале и в конце строки,
 * и удалить двойные пробелы
 */
export const removeRedundantSpaces = str => {
  return str.trim().replace(/\s\s+/, ' ')
}

/**
 * Функция генерации случайного целого числа, интервал [100000, 999999]
 *
 * @return {Number}
 * */
export function generateKey() {
  return Math.floor(Math.random() * 1000000)
}

/**
 * Общие цвета по умолчанию
 * */
export const defaultColors = {
  grey: '#7F8C8D',
  yellow: '#F5CE00',
  green: '#27AE60',
  red: '#E74C3C'
}

/**
 * https://gist.github.com/benjick/c48dd2db575e79c7b0b1043de4556ebc
 * Функция сохранения Store обьектов в хранилище
 *
 * @param {String} name Ключ сохранения в хранилище
 * @param {MST instance} store Обьект типа Store
 * @param {Object} options Обьект параметров.
 * Схема:
 * {
 *     storage: <Storage> тип хранилища (напр, localStorage)
 *     jsonify: <Boolean> если true - сериализовывать, иначе false
 * }
 * @param {Object} schema Обьект указывающий какие свойства сохранить
 * Схема:
 * {
 *     [propName]: <Boolean> если true - сохранить, иначе false
 * }
 * */
export const persist = (name, store, options, schema = {}) => {
  let hydrated = false

  let storage = options.storage

  if (typeof localStorage !== 'undefined' && localStorage === storage) {
    storage = LocalStorage
  }

  if (typeof sessionStorage !== 'undefined' && sessionStorage === storage) {
    storage = SessionStorage
  }

  onSnapshot(store, _snapshot => {
    if (!hydrated) {
      return
    }
    const snapshot = { ..._snapshot }
    Object.keys(snapshot).forEach(key => {
      if (!schema[key]) {
        delete snapshot[key]
      }
    })
    const data = !options.jsonify ? snapshot : JSON.stringify(snapshot)
    storage.setItem(name, data)
  })

  storage.getItem(name).then(data => {
    if (data) {
      const snapshot = !options.jsonify ? data : JSON.parse(data)
      applySnapshot(store, snapshot)
      if (store.afterHydration && typeof store.afterHydration === 'function') {
        store.afterHydration()
      }
    }

    hydrated = true
  })
}

export const encodeToBase64 = (file, prefix = true) => {
  const cutPrefix = strFile => {
    let idx = strFile.search(/,/)

    return strFile.slice(++idx)
  }

  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () =>
      resolve(prefix ? cutPrefix(reader.result) : reader.result)
    reader.onerror = error => reject(error)
  })
}

export const decodeToFile = ({ file, name }, mime = 'image') => {
  let idx = name.lastIndexOf('.')
  const fileType = name.slice(++idx)

  return `data:${mime}/${fileType};base64,${file}`
}

export const toSmallPhoto = photoUrl => {
  if (!photoUrl) return null
  return photoUrl.replace('.', '_thumbnail.')
}

export const createForm = (firstArg, secondArg) => {
  secondArg.plugins = {
    dvr: dvr({
      package: validatorjs,
      extend: ({ validator, form }) => {
        validator.setCustomMessages = function(rule, message) {
          const validator = this
          Object.keys(message).forEach(lang => {
            validator.setMessages(lang, {
              ...validator.getMessages(lang),
              [rule]: message[lang]
            })
          })
        }

        validator.register('unique', (value, requirement, attribute) => {
          const paths = attribute.split('.')
          const [parent, index, field] = paths

          const values = form.$(parent).values()
          values.splice(index, 1)
          const val = values.map(v => v[field])

          validator.setCustomMessages('unique', {
            ky: 'Уникалдык :attribute мааниси',
            ru: 'Уникальное :attribute значение',
            en: 'Unique: attribute value'
          })

          return !val.includes(value)
        })
        validator.register('phone', value => {
          validator.setCustomMessages('phone', {
            ky: ':attribute телефон номердин форматында болуш керек',
            ru: ':attribute должен иметь формат номера телефона',
            en: ':attribute must be in the format of a phone number'
          })

          return value.match(/^[0-9+]{6,14}$/g)
        })
        validator.register('password', value => {
          validator.setCustomMessages('password', {
            ky:
              ':attribute жогорку жана төмөнкү регистрдеги латын тамгаларын, сандарды жана өзгөчө символдорду камтыш керек',
            ru:
              ':attribute должен включать латинские буквы верхнего и нижнего регистра, цифры и спецсимволы',
            en:
              ':attribute must include uppercase and lowercase latin letters, numbers and special characters'
          })

          return value.match(
            /(?=.*[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/g
          )
        })
        validator.register('word', value => {
          const rule = /^[a-z](\w+)?$/i

          validator.setCustomMessages('word', {
            ky:
              ':attribute боштуксуз болуш керек, тамга менен башталып, латын тамгаларын камтып, атайын символдорсуз болуш керек',
            ru:
              ':attribute должен быть без пробелов, начинаться на букву, буквы латинские, без спец.символов',
            en:
              ':attribute must be without spaces, begin with a letter, latin letters, without special characters'
          })

          return rule.test(value)
        })
        validator.register('contains', (value, requirement, attribute) => {
          const { message, check } = requirement

          if (message) {
            validator.setCustomMessages('contains', message)
          }

          return check({ validator, form, value, requirement, attribute })
        })
        validator.register('enrollmentCode', value => {
          validator.setCustomMessages('enrollmentCode', {
            ky:
              'Талаа жогорку жана төмөнкү регистрдеги латын тамгалардан, сандардан жана "-" символдон гана туруш керек',
            ru:
              'Поле должно содержать только заглавные и строчные латинские буквы, цифры и символ "-"',
            en:
              'Field must contain only uppercase and lowercase latin letters, numbers and "-" character'
          })
          return value.match(/^([a-z]|\d|-)+$/gi)
        })
      }
    })
  }
  return new MobxReactForm(firstArg, secondArg)
}

export const parseFileName = path => {
  if (!path) return ''

  const lastIndex = path.lastIndexOf('_')
  return path.slice(lastIndex + 1)
}

export const parseUrlParams = search => {
  const hashes = search.slice(search.indexOf('?') + 1).split('&')
  const params = {}
  hashes.forEach(hash => {
    const [key, val] = hash.split('=')
    params[key] = decodeURIComponent(val)
  })
  return params
}

export const convertMegaBytesToBytes = MegaByte => MegaByte * 1024 ** 2

export const colors = [
  'black',
  'blue',
  'bright-blue',
  'dark-blue',
  'dark-gray',
  'dark-moderate-blue',
  'flamingo',
  'gray',
  'green',
  'grey',
  'light-blue',
  'light-gray',
  'light-grayish-blue',
  'light-grey',
  'light-violet',
  'lime-green',
  'mid-blue',
  'moderate-blue',
  'none',
  'orange',
  'pure-blue',
  'purple',
  'red',
  'sea-buckthorn',
  'soft-yellow',
  'very-dark-blue',
  'very-dark-gray',
  'very-dark-gray2',
  'very-light-gray',
  'violet',
  'violet-2',
  'white',
  'yellow',
  'dark-grayish-blue',
  'very-dark-desaturated-blue',
  'very-soft-blue'
]

export const sizes = ['tiny', 'small', 'medium', 'large', 'xlarge']

export const formatTimezone = (timeUTC, userTimezone, timeFormat) =>
  moment
    .utc(moment.utc(timeUTC).format('YYYY-MM-DDTHH:mm:ss'))
    .utcOffset(userTimezone)
    .format(timeFormat)

export const exportToCSV = (csvData, fileName) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'
  const worksheet = XLSX.utils.json_to_sheet(csvData)
  const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] }
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
  const data = new Blob([excelBuffer], { type: fileType })
  FileSaver.saveAs(data, fileName + fileExtension)
}
    
export const convertLanguageName = lang => {
  switch (lang) {
    case 'ru': 
      return "Русский"
    case 'en':
      return "English"
    case 'ky':
      return "Кыргызча"
    default:
      return lang
  }
}

export const formatTo2Digits = number => (number > 99 ? '99+' : number)
