import React from 'react';
import classNames from 'classnames';

import {TooltipPopoverWrapper} from "../index";
import PopoverHeader from "./PopoverHeader";
import PopoverBody from "./PopoverBody";

import "./Popover.scss";


const Popover = (props) => {
  const popperClasses = classNames(
    'sw-popover',
  );

  const classes = classNames(
    'popover-inner',
    props.innerClassName
  );


  return (
    <TooltipPopoverWrapper
      {...props}
      popperClassName={popperClasses}
      innerClassName={classes}
    />
  );
};

Popover.propTypes = TooltipPopoverWrapper.propTypes;
Popover.defaultProps = {
  placement: 'right',
  placementPrefix: 'sw-popover',
  trigger: 'click',
};

Popover.Header = PopoverHeader;
Popover.Body = PopoverBody;

export default Popover;
