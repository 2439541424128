import {
  AUTH_ADMIN_ROLE,
  AUTH_AUTHOR_ROLE,
  AUTH_STUDENT_ROLE,
  AUTH_TEACHER_ROLE,
  DB_PROBLEM_SUBMISSIONS_ROUTE,
  DB_PROBLEMS_ROUTE,
  ALGO_PROBLEMS_ROUTE,
  ALGO_PROBLEM_SUBMISSIONS_ROUTE,
  SUGGESTED_DB_TAGS_ROUTE,
  SUGGESTED_PROBLEMS_ROUTE,
  SUGGESTED_LINKS_ROUTE,
  CONTROL_LINKS_ROUTE,
  SUBJECT_AREAS_ROUTE,
  USERS_ROUTE,
  CONTROL_DB_COLLECTIONS_ROUTE,
  CONTROL_ALGO_COLLECTIONS_ROUTE,
  GROUPS_ROUTE,
  ALGO_GROUPS_ROUTE,
  REQUESTS_ROUTE,
  CONTROL_DB_TAGS_ROUTE,
  CONTROL_ALGO_TAGS_ROUTE,
  INSTITUTES_ROUTE,
  SANDBOX_DB_PROBLEMS_ROUTE,
  SANDBOX_DB_PROBLEM_SUBMISSIONS_ROUTE,
  CONTROL_ALGO_LINKS_ROUTE,
  REPORTS_ROUTE
} from './internal'
import { REJUDGE_ROUTE } from './routes'

export default [
  {
    key: 'control-db-tags-nav',
    private: true,
    sectionId: 1,
    roles: [AUTH_AUTHOR_ROLE],
    name: {
      id: 'app.menu.tags',
      defaultMessage: 'Теги'
    },
    icon: 'local_offer',
    url: CONTROL_DB_TAGS_ROUTE
  },
  {
    key: 'control-algo-tags-nav',
    private: true,
    sectionId: 2,
    roles: [AUTH_AUTHOR_ROLE],
    name: {
      id: 'app.menu.tags',
      defaultMessage: 'Теги'
    },
    icon: 'local_offer',
    url: CONTROL_ALGO_TAGS_ROUTE
  },
  {
    key: 'control-algo-links-nav',
    private: true,
    sectionId: 2,
    roles: [AUTH_AUTHOR_ROLE],
    name: {
      id: 'app.menu.links',
      defaultMessage: 'Ссылки'
    },
    icon: 'link',
    url: CONTROL_ALGO_LINKS_ROUTE
  },
  {
    key: 'problem-list',
    private: true,
    sectionId: 1,
    roles: [AUTH_STUDENT_ROLE, AUTH_TEACHER_ROLE, AUTH_AUTHOR_ROLE],
    name: {
      id: 'app.menu.problems',
      defaultMessage: 'Задачи'
    },
    icon: 'list',
    url: DB_PROBLEMS_ROUTE
  },
  {
    key: 'algo-problem-list',
    private: true,
    sectionId: 2,
    roles: [AUTH_STUDENT_ROLE, AUTH_TEACHER_ROLE, AUTH_AUTHOR_ROLE],
    name: {
      id: 'app.menu.problems',
      defaultMessage: 'Задачи'
    },
    icon: 'list',
    url: ALGO_PROBLEMS_ROUTE
  },
  {
    key: 'problem-submissions',
    private: true,
    sectionId: 1,

    roles: [AUTH_STUDENT_ROLE, AUTH_TEACHER_ROLE],
    name: {
      id: 'app.menu.solutions',
      defaultMessage: 'Решения'
    },
    icon: 'arrow_forward',
    url: DB_PROBLEM_SUBMISSIONS_ROUTE
  },
  {
    key: 'algo-problem-submissions',
    private: true,
    sectionId: 2,
    roles: [AUTH_STUDENT_ROLE, AUTH_TEACHER_ROLE],
    name: {
      id: 'app.menu.solutions',
      defaultMessage: 'Решения'
    },
    icon: 'arrow_forward',
    url: ALGO_PROBLEM_SUBMISSIONS_ROUTE
  },
  {
    key: 'suggested-group',
    private: true,
    roles: [AUTH_AUTHOR_ROLE],
    icon: 'group_work',
    isGroupSuggested: true,
    name: 'Предложенные',
    naves: [
      {
        key: 'suggested-db-tags',
        private: true,
        roles: [AUTH_AUTHOR_ROLE],
        name: {
          id: 'app.menu.proposed_tags',
          defaultMessage: 'Теги'
        },
        icon: 'local_offer',
        url: SUGGESTED_DB_TAGS_ROUTE
      },
      {
        key: 'suggested-links',
        private: true,
        roles: [AUTH_AUTHOR_ROLE],
        name: {
          id: 'app.menu.proposed_links',
          defaultMessage: 'Ссылки'
        },
        icon: 'link',
        url: SUGGESTED_LINKS_ROUTE
      },
      {
        key: 'suggested-problems',
        private: true,
        roles: [AUTH_AUTHOR_ROLE],
        name: {
          id: 'app.menu.proposed_problems',
          defaultMessage: 'Задачи'
        },
        icon: 'feedback',
        url: SUGGESTED_PROBLEMS_ROUTE
      }
    ]
  },
  {
    key: 'subject-areas',
    private: true,
    sectionId: 1,
    roles: [AUTH_AUTHOR_ROLE],
    name: {
      id: 'app.menu.subject_area',
      defaultMessage: 'Предметная область'
    },
    icon: 'star',
    url: SUBJECT_AREAS_ROUTE
  },
  {
    key: 'users',
    private: true,
    sectionId: 1,

    roles: [AUTH_ADMIN_ROLE],
    name: {
      id: 'app.menu.users',
      defaultMessage: 'Пользователи'
    },
    icon: 'arrow_forward',
    url: USERS_ROUTE
  },
  {
    key: 'users',
    private: true,
    sectionId: 2,
    roles: [AUTH_ADMIN_ROLE],
    name: {
      id: 'app.menu.users',
      defaultMessage: 'Пользователи'
    },
    icon: 'arrow_forward',
    url: USERS_ROUTE
  },
  {
    key: 'users',
    private: true,
    sectionId: 3,
    roles: [AUTH_ADMIN_ROLE],
    name: {
      id: 'app.menu.users',
      defaultMessage: 'Пользователи'
    },
    icon: 'arrow_forward',
    url: USERS_ROUTE
  },
  {
    key: 'links',
    private: true,
    sectionId: 1,

    roles: [AUTH_AUTHOR_ROLE],
    name: {
      id: 'app.menu.links',
      defaultMessage: 'Ссылки'
    },
    icon: 'link',
    url: CONTROL_LINKS_ROUTE
  },
  {
    key: 'groups-nav',
    private: true,
    sectionId: 1,
    roles: [AUTH_STUDENT_ROLE, AUTH_TEACHER_ROLE],
    name: {
      id: 'app.menu.groups',
      defaultMessage: 'Группы'
    },
    icon: 'group',
    url: GROUPS_ROUTE
  },
  {
    key: 'algo-groups-nav',
    private: true,
    sectionId: 2,
    roles: [AUTH_STUDENT_ROLE, AUTH_TEACHER_ROLE],
    name: {
      id: 'app.menu.groups',
      defaultMessage: 'Группы'
    },
    icon: 'group',
    url: ALGO_GROUPS_ROUTE
  },
  {
    key: 'requests-nav',
    private: true,
    sectionId: 1,

    roles: [AUTH_TEACHER_ROLE, AUTH_STUDENT_ROLE],
    name: {
      id: 'app.menu.requests',
      defaultMessage: 'Запросы'
    },
    icon: 'person_add',
    url: REQUESTS_ROUTE
  },
  {
    key: 'collections',
    private: true,
    sectionId: 1,

    roles: [AUTH_TEACHER_ROLE],
    name: {
      id: 'app.menu.collections',
      defaultMessage: 'Коллекции'
    },
    icon: 'folder',
    url: CONTROL_DB_COLLECTIONS_ROUTE
  },
  {
    key: 'collections',
    private: true,
    sectionId: 2,
    roles: [AUTH_TEACHER_ROLE],
    name: {
      id: 'app.menu.collections',
      defaultMessage: 'Коллекции'
    },
    icon: 'folder',
    url: CONTROL_ALGO_COLLECTIONS_ROUTE
  },
  {
    key: 'institutes',
    private: true,
    sectionId: 1,

    roles: [AUTH_ADMIN_ROLE],
    name: {
      id: 'app.menu.institutes',
      defaultMessage: 'Institutes'
    },
    icon: 'arrow_forward',
    url: INSTITUTES_ROUTE
  },
  {
    key: 'reports',
    private: true,
    sectionId: 1,
    roles: [AUTH_TEACHER_ROLE, AUTH_ADMIN_ROLE],
    name: {
      id: 'app.menu.reports',
      defaultMessage: 'Отчеты'
    },
    icon: 'description',
    url: REPORTS_ROUTE
  },
  {
    key: 'reports',
    private: true,
    sectionId: 2,
    roles: [AUTH_TEACHER_ROLE, AUTH_ADMIN_ROLE],
    name: {
      id: 'app.menu.reports',
      defaultMessage: 'Отчеты'
    },
    icon: 'description',
    url: REPORTS_ROUTE
  },
  {
    key: 'rejudge',
    private: true,
    sectionId: 1,

    roles: [AUTH_AUTHOR_ROLE],
    name: {
      id: 'app.menu.rejudge',
      defaultMessage: 'Перепроверка'
    },
    icon: 'cached',
    url: REJUDGE_ROUTE
  },
  {
    key: 'author-problem-submissions',
    private: true,
    sectionId: 1,

    roles: [AUTH_AUTHOR_ROLE],
    name: {
      id: 'app.menu.solutions',
      defaultMessage: 'Решения'
    },
    icon: 'arrow_forward',
    url: DB_PROBLEM_SUBMISSIONS_ROUTE
  },
  {
    key: 'author-algo-problem-submissions',
    private: true,
    sectionId: 2,
    roles: [AUTH_AUTHOR_ROLE],
    name: {
      id: 'app.menu.solutions',
      defaultMessage: 'Решения'
    },
    icon: 'arrow_forward',
    url: ALGO_PROBLEM_SUBMISSIONS_ROUTE
  },
  {
    key: 'institutes',
    private: true,
    sectionId: 2,
    roles: [AUTH_ADMIN_ROLE],
    name: {
      id: 'app.menu.institutes',
      defaultMessage: 'Institutes'
    },
    icon: 'arrow_forward',
    url: INSTITUTES_ROUTE
  },
  {
    key: 'institutes',
    private: true,
    sectionId: 3,
    roles: [AUTH_ADMIN_ROLE],
    name: {
      id: 'app.menu.institutes',
      defaultMessage: 'Institutes'
    },
    icon: 'arrow_forward',
    url: INSTITUTES_ROUTE
  },
  {
    key: 'sandbox-problem-list-page',
    private: true,
    sectionId: 3,
    roles: [AUTH_STUDENT_ROLE, AUTH_TEACHER_ROLE, AUTH_AUTHOR_ROLE],
    name: {
      id: 'app.menu.problems',
      defaultMessage: 'Problems'
    },
    icon: 'list',
    url: SANDBOX_DB_PROBLEMS_ROUTE
  },
  {
    key: 'sandbox-db-problem-submissions-page',
    private: true,
    sectionId: 3,
    roles: [AUTH_STUDENT_ROLE, AUTH_TEACHER_ROLE, AUTH_AUTHOR_ROLE],
    name: {
      id: 'app.menu.solutions',
      defaultMessage: 'Submissions'
    },
    icon: 'arrow_forward',
    url: SANDBOX_DB_PROBLEM_SUBMISSIONS_ROUTE
  }
]
