import React from 'react';
import t from 'prop-types';
import classNames from 'classnames';

import {utils} from 'internal';
import './Column.scss';

const Column = ({className, col, fluid, tag: Tag, ...attributes}) => {

  let swCol = 'sw-col';

  if (fluid) {
    swCol = `${swCol}-fluid`;
  } else if (col) {
    swCol = `${swCol}-${col}`;
  }

  const classes = classNames(
    swCol,
    className,
  );

  return (<Tag className={classes} {...attributes} />);
};

Column.propTypes = {
  className: t.string,
  col: t.number,
  fluid: t.bool,
  tag: utils.tagPropType
};

Column.defaultProps = {
  fluid: false,
  tag: "div"
};

export default Column;
