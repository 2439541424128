import { getEnv, getSnapshot, types } from 'mobx-state-tree'
import { fromPromise, FULFILLED } from 'mobx-utils'
import { when } from 'mobx'
import { curry } from 'ramda'
import {
  ServiceStore,
  GroupProfileModel,
  StudentsGroupByTeacherModel,
  AllTeachersModel,
  TeacherGroupModel
} from 'internal'

const TeacherGroupStore = ServiceStore.named('TeacherGroupStore')
  .props({
    _groups: types.optional(
      types.model('PaginationGroups', {
        teacherId: types.maybeNull(types.string),
        teacherName: types.maybeNull(types.string),
        data: types.optional(types.array(TeacherGroupModel), []),
        total: types.optional(types.number, 0)
      }),
      {}
    ),
    _students: types.optional(
      types.optional(types.array(StudentsGroupByTeacherModel), [])
    ),
    _studentsAll: types.optional(
      types.array(
        types.model('StudentsAllModel', {
          id: types.optional(types.string, ''),
          fullName: types.optional(types.string, '')
        })
      ),
      []
    ),
    _groupLookUps: types.optional(
      types.model('GroupLookUps', {
        data: types.optional(
          types.array(
            types.model('TeacherGroup_groupLookUps', {
              dbGroupId: types.number,
              dbGroupName: types.string
            })
          ),
          []
        ),
        total: types.optional(types.number, 0)
      }),
      {}
    ),
    _studentsLookUps: types.optional(
      types.model('StudentsLookUps', {
        data: types.optional(
          types.array(
            types.model('TeacherGroup_studentLookUps', {
              dbStudentId: types.optional(types.string, ''),
              dbStudentName: types.maybeNull(types.string),
              dbStudentEmail: types.optional(types.string, '')
            })
          ),
          []
        ),
        total: types.optional(types.number, 0)
      }),
      {}
    ),
    _teachersLookUps: types.optional(types.array(AllTeachersModel), []),
    _group: types.optional(GroupProfileModel, {})
  })
  .actions(self => {
    const apiV1 = getEnv(self).apiV1

    const studentsLookUpApi = curry(url => '/api/StudentsLookUpApi' + url)
    const teachersLookUpApi = curry(url => '/api/TeachersLookUpApi' + url)
    const groupsLookUpApi = curry(url => '/api/GroupsLookUpApi' + url)
    const teacherGroupApi = curry(url => '/api/TeachersGroupApi' + url)
    const studentGroupApi = curry(url => '/api/StudentsGroupTeacherApi' + url)

    const getGroups = reqBody => {
      const prom = fromPromise(apiV1.post(teacherGroupApi('/Get'), reqBody))

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                if (reqBody.page === 1) self._groups = resp.data
                else
                  self._groups.data = self._groups.data.concat(resp.data.data)
              })
            }
          })
        }
      )

      return prom
    }

    function list(reqBody) {
      const prom = fromPromise(apiV1.post(teacherGroupApi('/Get'), reqBody))

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._groups = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    const getGroupById = (groupId, problemTypeId) => {
      const prom = fromPromise(
        apiV1.get(
          teacherGroupApi(
            `/GetGroupById?groupId=${groupId}&problemTypeId=${problemTypeId}`
          )
        )
      )
      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._group = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    function create(data) {
      const prom = fromPromise(apiV1.post(teacherGroupApi('/Post'), data))
      return prom
    }

    function save(data) {
      const prom = fromPromise(
        apiV1.put(teacherGroupApi('/Put'), data)
      )
      return prom
    }

    const remove = groupId => {
      const prom = fromPromise(
        apiV1.delete(teacherGroupApi('/Delete'), {
          params: {
            groupId
          }
        })
      )
      return prom
    }

    const leave = id => {
      const prom = fromPromise(
        apiV1.delete(teacherGroupApi('/LeaveGroup'), {
          params: {
            id
          }
        })
      )
      return prom
    }

    const addCollection = (groupId, collectionId) => {
      const prom = fromPromise(
        apiV1.post(teacherGroupApi(`/AddProblemCollectionToGroup?groupId=${groupId}&collectionId=${collectionId}`))
      )
      return prom
    }

    const deleteCollection = (groupId, collectionId) => {
      const prom = fromPromise(
        apiV1.delete(teacherGroupApi('/DeleteProblemCollectionFromGroup'), {
          params: {
            groupId,
            collectionId
          }
        })
      )
      return prom
    }

    const deleteStudent = (groupId, studentId) => {
      const prom = fromPromise(
        apiV1.delete(teacherGroupApi('/DeleteStudentFromGroup'), {
          params: {
            groupId,
            studentId
          }
        })
      )
      return prom
    }

    const addTeacher = (groupId, teacherId) => {
      const prom = fromPromise(
        apiV1.post(teacherGroupApi(`/AddTeacherToGroup?groupId=${groupId}&teacherId=${teacherId}`))
      )
      return prom
    }

    const deleteTeacher = (groupId, teacherId) => {
      const prom = fromPromise(
        apiV1.delete(teacherGroupApi('/DeleteTeacherFromGroup'), {
          params: {
            groupId,
            teacherId
          }
        })
      )
      return prom
    }

    function studentList(teacherId) {
      const prom = fromPromise(
        apiV1.get(studentGroupApi('/GetStudentsByTeacherId'), {
          params: {
            teacherId
          }
        })
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._students = resp.data
              })
            }
          })
        }
      )
      return prom
    }

    function lookupGroups(reqBody) {
      let prom = fromPromise(apiV1.post(groupsLookUpApi('/GetGroups'), reqBody))

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                if (reqBody.page === 1) self._groupLookUps = data
                else
                  self._groupLookUps.data = self._groupLookUps.data.concat(
                    data.data
                  )
              })
            }
          })
        }
      )

      return prom
    }

    function lookupStudents(reqBody) {
      let prom = fromPromise(
        apiV1.post(studentsLookUpApi('/GetStudents'), reqBody)
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                if (reqBody.page === 1) self._studentsLookUps = data
                else
                  self._studentsLookUps.data = self._studentsLookUps.data.concat(
                    data.data
                  )
              })
            }
          })
        }
      )

      return prom
    }

    const getStudentLookupByEmail = studentEmail => {
      const promise = fromPromise(
        apiV1.get(studentsLookUpApi(`/GetStudent?studentEmail=${studentEmail}`))
      )

      when(
        () => promise.state === FULFILLED,
        () => {
          promise.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                if (
                  data.dbStudentId &&
                  !self._studentsLookUps.data.some(
                    studentLookup =>
                      studentLookup.dbGroupId === data.dbStudentId
                  )
                ) {
                  self._studentsLookUps.data = [
                    ...self._studentsLookUps.data,
                    data
                  ]
                }
              })
            }
          })
        }
      )

      return promise
    }

    const getGroupLookupById = groupId => {
      const promise = fromPromise(
        apiV1.get(groupsLookUpApi(`/GetGroup?groupId=${groupId}`))
      )

      when(
        () => promise.state === FULFILLED,
        () => {
          promise.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                if (
                  data.dbGroupId &&
                  !self._groupLookUps.data.some(
                    groupLookup => groupLookup.dbGroupId === data.dbGroupId
                  )
                ) {
                  self._groupLookUps.data = [...self._groupLookUps.data, data]
                }
              })
            }
          })
        }
      )

      return promise
    }

    function lookupTeacherList(groupId, institutionId) {
      const prom = fromPromise(
        apiV1.get(
          teachersLookUpApi(
            `/GetAllTeachersWithinInstitution?groupId=${groupId}&institutionId=${institutionId}`
          )
        )
      )
      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._teachersLookUps = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    function sendAutoApprovalState(groupId, autoApprovalStatus) {
      const prom = fromPromise(
        apiV1.put(
          teacherGroupApi(
            `/EditGroupRequestsAutoApprovedStatus?groupId=${groupId}&isRequestsAutoApproved=${autoApprovalStatus}`
          )
        )
      )
      return prom
    }

    function editGroupEnrollmentCode(groupId, enrollmentCode) {
      const prom = fromPromise(
        apiV1.put(
          teacherGroupApi(
            `/EditGroupEnrollmentCode?groupId=${groupId}&enrollmentCode=${enrollmentCode}`
          )
        )
      )
      return prom
    }

    return {
      list,
      create,
      save,
      remove,
      leave,
      studentList,
      lookupGroups,
      lookupStudents,
      lookupTeacherList,
      getGroups,
      getStudentLookupByEmail,
      getGroupLookupById,
      getGroupById,
      sendAutoApprovalState,
      deleteCollection,
      addCollection,
      deleteStudent,
      addTeacher,
      deleteTeacher,
      editGroupEnrollmentCode
    }
  })
  .views(self => ({
    get groups() {
      return getSnapshot(self._groups)
    },
    get students() {
      return getSnapshot(self._students)
    },
    get studentsAll() {
      return getSnapshot(self._studentsAll)
    },
    get groupFilter() {
      return getSnapshot(self._groupLookUps)
    },
    get studentsFilter() {
      return getSnapshot(self._studentsLookUps)
    },
    get groupTeachers() {
      return getSnapshot(self._teachersLookUps)
    },
    get group() {
      return getSnapshot(self._group)
    }
  }))

export default TeacherGroupStore
