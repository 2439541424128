import { types } from 'mobx-state-tree'

import { UserRoleModel } from 'internal'

const UserModel = types
  .model('UserModel', {
    userId: types.optional(types.string, ''),
    roles: types.optional(types.array(UserRoleModel), []),
    expires: types.maybeNull(types.Date),
    issued: types.maybeNull(types.Date),
    accessToken: types.optional(types.string, ''),
    expiresIn: types.optional(types.number, 0),
    tokenType: types.optional(types.string, ''),
    userName: types.optional(types.string, '')
  })
  .actions(self => {
    const setData = userData => {
      self.expires = new Date(userData['expires'] || userData.expires)
      self.issued = new Date(userData['issued'] || userData.issued)
      self.accessToken = userData.accessToken
      self.expiresIn = userData.expiresIn
      self.tokenType = userData.tokenType
    }

    const setInfo = userInfo => {
      self.roles = userInfo.roles
      self.userId = userInfo.userId
      self.userName = userInfo.userName
    }

    return {
      setData,
      setInfo
    }
  })

export default UserModel
