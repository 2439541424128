import { types } from 'mobx-state-tree'

export default types.model('HintModel', {
  id: types.optional(types.identifierNumber, 0),
  sequenceNumber: types.optional(types.number, 0),
  hintText: types.optional(types.string, ''),
  subjectId: types.maybeNull(types.number),
  subjectName: types.maybeNull(types.string),
  isDeleted: types.optional(types.boolean, false),
  languageId: types.optional(types.integer, 0)
})
