import { types } from 'mobx-state-tree'

import { DbSubjectDataCodeModel } from 'internal'

export default types.model('DbSubjectJudgeTypeModel', {
  id: types.identifierNumber,
  subjectId: types.optional(types.integer, 0),
  judgeTypeId: types.optional(types.integer, 0),
  isDefault: types.optional(types.boolean, false),
  useDefault: types.optional(types.boolean, false),
  ddlSchema: types.optional(types.string, ''),
  isDeleted: types.optional(types.boolean, false),
  subjectDataCodeBusinessModel: types.optional(
    types.array(DbSubjectDataCodeModel),
    []
  )
})
