import React from 'react'
import classNames from 'classnames'
import t from 'prop-types'

import { utils } from 'internal'
import './Divider.scss'

const Divider = ({ tag: Tag, className, horz, color, ...attributes }) => {
  const classes = classNames(
    'sw-divider',
    horz && 'sw-divider--horz',
    color && `sw-border-${color}`,
    className
  )

  return <Tag className={classes} {...attributes} />
}

Divider.propTypes = {
  tag: utils.tagPropType,
  className: t.string,
  horz: t.bool,
  color: t.oneOf(utils.colors)
}

Divider.defaultProps = {
  tag: 'div',
  color: 'very-light-gray'
}

export default Divider
