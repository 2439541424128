import { getEnv, getSnapshot, types } from 'mobx-state-tree'
import { fromPromise, FULFILLED } from 'mobx-utils'
import { when } from 'mobx'
import { curry } from 'ramda'

import { ServiceStore } from 'internal'

const StudentStore = ServiceStore.named('StudentStore')
  .props({
    _studentLookUps: types.optional(
      types.model('StudentLookUps', {
        data: types.optional(
          types.array(
            types.model('StudentStore_studentLookUps', {
              studentId: types.maybeNull(types.string),
              studentName: ''
            })
          ),
          []
        ),
        total: types.optional(types.number, 0)
      }),
      {}
    ),
    _sandboxAuthorUsersLookups: types.optional(
      types.model('SandboxAuthorUsersLookups', {
        data: types.optional(
          types.array(
            types.model('AuthorUsersLookups', {
              userId: types.string,
              userFullName: types.string
            })
          ),
          []
        ),
        total: types.optional(types.number, 0)
      }),
      {}
    )
  })
  .actions(self => {
    const apiV1 = getEnv(self).apiV1

    const studentLookUpApi = curry(url => '/api/StudentsLookUpApi' + url)

    function lookup(reqBody) {
      const prom = fromPromise(
        apiV1.post(studentLookUpApi('/GetAllStudents'), reqBody)
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                if (reqBody.page === 1) self._studentLookUps = data
                else
                  self._studentLookUps.data = self._studentLookUps.data.concat(
                    data.data
                  )
              })
            }
          })
        }
      )

      return prom
    }

    const getSandboxAuthorUsersLookups = ({ page, pageSize, searchValue }) => {
      const promise = fromPromise(
        apiV1.post(studentLookUpApi('/GetSandboxAuthorUsers'), {
          page,
          pageSize,
          searchValue
        })
      )

      when(
        () => promise.state === FULFILLED,
        () => {
          promise.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                if (page === 1) self._sandboxAuthorUsersLookups = data
                else
                  self._sandboxAuthorUsersLookups.data = [
                    ...self._sandboxAuthorUsersLookups.data,
                    ...data.data
                  ]
              })
            }
          })
        }
      )

      return promise
    }

    return {
      lookup,
      getSandboxAuthorUsersLookups
    }
  })
  .views(self => ({
    get studentLookUps() {
      return getSnapshot(self._studentLookUps)
    },
    get sandboxAuthorUsersLookups() {
      return getSnapshot(self._sandboxAuthorUsersLookups)
    }
  }))

export default StudentStore
