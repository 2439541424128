import React, { Fragment } from 'react'
import './TableStatistic.scss'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

export const Footer = ({
  problemData,
  grantIsAccepted,
  grantAttempts,
  onLinkToFilterClick,
  submissionsRoute,
  group,
  collection
}) => {
  return (
    <tfoot>
      <tr>
        <th className="stickyColumn align-middle table-cell-static-height table-footer">
          <FormattedMessage id="statistic.total" defaultMessage="Всего" />
        </th>
        {problemData.map(problem => (
          <Fragment key={problem.problemId}>
            <th className="align-middle table-cell-static-height table-footer">
              {problem.grantTotalAccepted ? (
                <Link
                  to={submissionsRoute}
                  target="_blank"
                  onClick={() =>
                    onLinkToFilterClick({
                      groupList: [group],
                      collectionIdList: [collection.collectionId],
                      correct: true,
                      problemCode: problem.problemCode
                    })
                  }
                >
                  {problem.grantTotalAccepted}
                </Link>
              ) : (
                problem.grantTotalAccepted
              )}
            </th>
            <th className="align-middle table-cell-static-height table-footer">
              {problem.grantTotalAttempts ? (
                <Link
                  to={submissionsRoute}
                  target="_blank"
                  onClick={() =>
                    onLinkToFilterClick({
                      groupList: [group],
                      collectionIdList: [collection.collectionId],
                      problemCode: problem.problemCode
                    })
                  }
                >
                  {problem.grantTotalAttempts}
                </Link>
              ) : (
                problem.grantTotalAttempts
              )}
            </th>
          </Fragment>
        ))}
        <th className="align-middle table-cell-static-height table-footer">
          {grantIsAccepted ? (
            <Link
              to={submissionsRoute}
              target="_blank"
              onClick={() =>
                onLinkToFilterClick({
                  groupList: [group],
                  collectionIdList: [collection.collectionId],
                  correct: true
                })
              }
            >
              {grantIsAccepted}
            </Link>
          ) : (
            grantIsAccepted
          )}
        </th>
        <th className="align-middle table-cell-static-height table-footer">
          <Link
            to={submissionsRoute}
            target="_blank"
            onClick={() =>
              onLinkToFilterClick({
                groupList: [group],
                collectionIdList: [collection.collectionId]
              })
            }
          >
            {grantAttempts}
          </Link>
        </th>
      </tr>
    </tfoot>
  )
}