import { types } from 'mobx-state-tree'

const UserPersonalPhotoModel = types
  .model('UserPhotoModel', {
    mimeType: types.maybeNull(types.string),
    userId: types.maybeNull(types.string),
    userPhoto: types.maybeNull(types.string)
  })
  .actions(self => {
    const setInfo = photo => {
      self.mimeType = photo.mimeType
      self.userId = photo.userId
      self.userPhoto = photo.userPhoto
    }

    return {
      setInfo
    }
  })

export default UserPersonalPhotoModel
