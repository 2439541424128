import React from "react";
import t from "prop-types";
import classNames from "classnames";
import { Wrapper, Button, Menu } from "react-aria-menubutton";

import { utils } from 'internal';

class CardDropdown extends React.Component {
  state = {
    menuOpened: false
  };

  toggleMenu = () => {
    if (!this.props.disabled) {
      this.setState(prevState => ({
        menuOpened: !prevState.menuOpened
      }));
    }
  };

  render() {
    const { tag: Tag, className, icon, children, ...attributes } = this.props;

    const classes = classNames("sw-card-more-button", className);

    return (
      <Wrapper
        className={classes}
        {...attributes}
      >
        <Button>{icon}</Button>
        <Menu className="sw-card-more-button__dropdown">{children}</Menu>
      </Wrapper>
    );
  }
}

CardDropdown.propTypes = {
  tag: utils.tagPropType,
  className: t.string,
  icon: utils.tagPropType.isRequired,
  disabled: t.bool,
  children: utils.tagPropType.isRequired
};

CardDropdown.defaultProps = {
  tag: "div"
};

export default CardDropdown;
