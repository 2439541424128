Object.defineProperty(exports, '__esModule', {
  value: true
})
exports.default = void 0

var _index = _interopRequireDefault(
  require('date-fns/locale/_lib/buildLocalizeFn/index.js')
)

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj }
}

var eraValues = {
  narrow: ['б.з.ч.', 'б.з.'],
  abbreviated: ['б.з.ч.', 'б.з.'],
  wide: ['биздин заманга чейин', 'биздин замандын']
}
var quarterValues = {
  narrow: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
  abbreviated: [
    '1-чи чей.',
    '2-чи чей.',
    '3-чү чей.',
    '4-чү чей.',
    '5-чи чей.',
    '6-чи чей.',
    '7-чи чей.',
    '8-чи чей.',
    '9-чу чей.',
    '10-чу чей.'
  ],
  wide: [
    '1-чи чейрек',
    '2-чи чейрек',
    '3-чү чейрек',
    '4-чү чейрек',
    '5-чи чейрек',
    '6-чи чейрек',
    '7-чи чейрек',
    '8-чи чейрек',
    '9-чу чейрек',
    '10-чу чейрек'
  ]
}
var monthValues = {
  narrow: ['Ү', 'Б', 'Ж', 'Ч', 'Б', 'К', 'Т', 'Б', 'А', 'Т', 'Ж', 'Б'],
  abbreviated: [
    'үчт.а.',
    'бир.а.',
    'жал.к.',
    'чын.к.',
    'бугу',
    'кулж',
    'теке',
    'баш о.',
    'аяк о.',
    'тог.а.',
    'жет.а.',
    'беш.а.'
  ],
  wide: [
    'үчтүн айы',
    'бирдин айы',
    'жалган куран',
    'чын куран',
    'бугу',
    'кулжа',
    'теке',
    'баш оона',
    'аяк оона',
    'тогуздун айы',
    'жетинин айы',
    'бештин айы'
  ]
}
var formattingMonthValues = {
  narrow: ['Ү', 'Б', 'Ж', 'Ч', 'Б', 'К', 'Т', 'Б', 'А', 'Т', 'Ж', 'Б'],
  abbreviated: [
    'үчт.а.',
    'бир.а.',
    'жал.к.',
    'чын.к.',
    'бугу',
    'кулж',
    'теке',
    'баш о.',
    'аяк о.',
    'тог.а.',
    'жет.а.',
    'беш.а.'
  ],
  wide: [
    'үчтүн айынын',
    'бирдин айынын',
    'жалган курандын',
    'чын курандын',
    'бугунун',
    'кулжанын',
    'текенин',
    'баш оонанын',
    'аяк оонанын',
    'тогуздун айынын',
    'жетинин айынын',
    'бештин айынын'
  ]
}
var dayValues = {
  narrow: ['Ж', 'Д', 'Ш', 'Ш', 'Б', 'Ж', 'И'],
  short: ['жш', 'дш', 'шш', 'шр', 'бш', 'жм', 'иш'],
  abbreviated: ['жшб', 'дшб', 'шшб', 'шрб', 'бшб', 'жума', 'ишб'],
  wide: [
    'жекшемби',
    'дүйшөмбү',
    'шейшемби',
    'шаршемби',
    'бейшемби',
    'жума',
    'ишемби'
  ]
}
var dayPeriodValues = {
  narrow: {
    am: 'ТЧ',
    pm: 'ТК',
    midnight: 'түн.о.',
    noon: 'түш',
    morning: 'эрт.м.',
    afternoon: 'түш.к.',
    evening: 'кеч.',
    night: 'түн.'
  },
  abbreviated: {
    am: 'ТЧ',
    pm: 'ТК',
    midnight: 'түн.о.',
    noon: 'түш',
    morning: 'эрт.м.',
    afternoon: 'түш.к.',
    evening: 'кеч.',
    night: 'түн.'
  },
  wide: {
    am: 'ТЧ',
    pm: 'ТК',
    midnight: 'түн ортосу',
    noon: 'түш',
    morning: 'эртең менен',
    afternoon: 'түштөн кийин',
    evening: 'кечинде',
    night: 'түңкү'
  }
}
var formattingDayPeriodValues = {
  narrow: {
    am: 'ТЧ',
    pm: 'ТК',
    midnight: 'түн.о.',
    noon: 'түш.',
    morning: 'эрт.м.',
    afternoon: 'түш.к.',
    evening: 'кеч.',
    night: 'түн.'
  },
  abbreviated: {
    am: 'ТЧ',
    pm: 'ТК',
    midnight: 'түн.о.',
    noon: 'түш.',
    morning: 'эрт.м.',
    afternoon: 'түш.к.',
    evening: 'кеч.',
    night: 'түн.'
  },
  wide: {
    am: 'ТЧ',
    pm: 'ТК',
    midnight: 'түн ортосунда',
    noon: 'түштө',
    morning: 'эртең менен',
    afternoon: 'түштөн кийин',
    evening: 'кечинде',
    night: 'түнкүсү'
  }
}

function ordinalNumber(dirtyNumber, dirtyOptions) {
  var options = dirtyOptions || {}
  var unit = String(options.unit)
  var suffix

  //не понятно как меняется в русском языке, оставлено до прояснения
  if (unit === 'date') {
    suffix = '-е'
  } else if (unit === 'week' || unit === 'minute' || unit === 'second') {
    suffix = '-я'
  } else {
    suffix = '-й'
  }

  return dirtyNumber + suffix
}

var localize = {
  ordinalNumber: ordinalNumber,
  era: (0, _index.default)({
    values: eraValues,
    defaultWidth: 'wide'
  }),
  quarter: (0, _index.default)({
    values: quarterValues,
    defaultWidth: 'wide',
    argumentCallback: function(quarter) {
      return Number(quarter) - 1
    }
  }),
  month: (0, _index.default)({
    values: monthValues,
    defaultWidth: 'wide',
    formattingValues: formattingMonthValues,
    defaultFormattingWidth: 'wide'
  }),
  day: (0, _index.default)({
    values: dayValues,
    defaultWidth: 'wide'
  }),
  dayPeriod: (0, _index.default)({
    values: dayPeriodValues,
    defaultWidth: 'any',
    formattingValues: formattingDayPeriodValues,
    defaultFormattingWidth: 'wide'
  })
}
var _default = localize
exports.default = _default
module.exports = exports.default
