import { types } from 'mobx-state-tree'

export default types.model('DbProblemSubmissionsFilterModel', {
  send: types.optional(types.boolean, false),
  correct: types.optional(types.boolean, false),
  mistake: types.optional(types.boolean, false),
  fromDate: types.maybeNull(types.Date),
  toDate: types.maybeNull(types.Date),
  name: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  studentList: types.optional(
    types.array(
      types.model('StudentList', {
        dbStudentId: types.optional(types.string, ''),
        dbStudentName: types.maybeNull(types.string)
      })
    ),
    []
  ),
  groupList: types.optional(
    types.array(
      types.model('GroupList', {
        dbGroupId: types.number,
        dbGroupName: types.string
      })
    ),
    []
  ),
  problemCode: types.maybeNull(types.string),
  problemCodeList: types.optional(types.array(types.integer), []),
  subjectIdList: types.optional(types.array(types.integer), []),
  languageId: types.maybeNull(types.integer, 2),
  collectionIdList: types.optional(types.array(types.integer), []),
  rejudgeBatchList: types.optional(
    types.array(
      types.model('RejudgeBatchList', {
        id: types.identifierNumber,
        name: types.optional(types.string, '')
      })
    ),
    []
  ),

  studentIdList: types.optional(types.array(types.string), []),
  page: 1,
  pageSize: 10
})
