import { types } from 'mobx-state-tree'

const InstituteModel = types.model('InstituteModel', {
  id: types.optional(types.number, 0),
  name: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  logoFileLocation: types.maybeNull(types.string),
  registrationDate: types.maybeNull(types.string),
  isDeleted: types.maybeNull(types.boolean)
})

export default InstituteModel
