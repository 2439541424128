import {types} from "mobx-state-tree";

const SubjectModel = types.model('SubjectModel', {
  id: types.optional(types.identifierNumber, 0),
  comment: types.maybeNull(types.string),
  countProblems: types.optional(types.number, 0),
  description: types.optional(types.string, ''),
  diagram: types.maybeNull(types.string),
  file: types.maybeNull(types.string),
  schemaCode: types.optional(types.string, ''),
  selectedServers: types.maybeNull(types.array(types.number)),
  subjectName: types.optional(types.string, '')
});

export default SubjectModel;
