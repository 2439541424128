import React from 'react'
import t from 'prop-types'
import classNames from 'classnames'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import AsyncCreatableSelect from 'react-select/async-creatable'

import './CustomInput.scss'

const reactSelectStyles = {
  clearIndicator: () => ({
    color: '#828282',
    padding: '1px 3px 0px',
    cursor: 'pointer'
  }),
  dropdownIndicator: () => ({
    color: '#828282',
    padding: '1px 12px 0px 3px',
    cursor: 'pointer'
  }),
  multiValueLabel: () => ({
    backgroundColor: '#E9E9E9',
    color: '#828282',
    padding: '2px',
    overflowWrap: 'break-word',
    wordBreak: 'break-all'
  }),
  multiValueRemove: () => ({
    backgroundColor: '#E9E9E9',
    color: '#828282',
    padding: '1px'
  }),
  control: (styles, state) => {
    const provided = {
      ...styles,
      border: state.isSelected ? '2px solid #470075' : '2px solid #E9E9E9',
      backgroundColor: '#F9F9F9',
      margin: '0',
      fontSize: '1rem',
      minHeight: '30px'
    }

    if (state.selectProps.valid === true) {
      provided.border = '2px solid #27AE60'
    } else if (state.selectProps.invalid === true) {
      provided.border = '2px solid #E74C3C'
    }

    return provided
  },
  valueContainer: provided => ({
    ...provided,
    padding: '0px 10px'
  }),
  container: (styles, state) => {
    const requiredStyle = state.selectProps.isRequired
      ? {
          '::after': {
            content: '"*"',
            color: 'red',
            marginLeft: '3px',
            position: 'absolute',
            right: '-15px',
            top: 0
          }
        }
      : {}
    const provided = {
      ...styles,
      ...requiredStyle,
      width: '100%',
      borderRadius: '6px'
    }

    return provided
  },
  indicatorSeparator: provided => ({
    ...provided,
    backgroundColor: 'none'
  }),
  singleValue: styles => ({
    ...styles,
    overflow: 'visible'
  }),
  menu: styles => ({
    ...styles,
    zIndex: 999
  })
}
class CustomInput extends React.Component {
  render() {
    const {
      type,
      label,
      className,
      color,
      children,
      bordered,
      getSelectRef,
      disabled,
      ...attributes
    } = this.props

    const wrapperClass = classNames(className, `sw-custom-${type}`)

    if (type === 'checkbox') {
      return (
        <label
          className={classNames(
            wrapperClass,
            'sw-custom-checkbox__padding',
            bordered && 'sw-custom-checkbox__bordered',
            attributes.disabled && 'sw-custom-checkbox__disabled'
          )}
        >
          {label}
          <input type={type} {...attributes} />
          <span className="sw-custom-checkbox__toogle"></span>
          {children}
        </label>
      )
    }

    if (type === 'select') {
      return (
        <Select
          isDisabled={disabled}
          ref={getSelectRef}
          styles={reactSelectStyles}
          {...attributes}
        />
      )
    }

    if (type === 'async-select') {
      return (
        <AsyncSelect
          ref={getSelectRef}
          styles={reactSelectStyles}
          {...attributes}
        />
      )
    }

    if (type === 'async-creatable-select') {
      return (
        <AsyncCreatableSelect
          ref={getSelectRef}
          styles={reactSelectStyles}
          {...attributes}
        />
      )
    }

    // TODO: implement component type of "radio"
  }
}

CustomInput.propTypes = {
  className: t.string,
  type: t.oneOf([
    'checkbox',
    'radio',
    'select',
    'async-select',
    'async-creatable-select'
  ]).isRequired,
  label: t.string,
  disabled: t.bool,
  color: t.oneOf(['grey', 'green', 'red', 'white']),
  bordered: t.bool,
  getSelectRef: t.func
}

export default CustomInput
