import { getEnv, getSnapshot, types } from 'mobx-state-tree'
import { fromPromise, FULFILLED } from 'mobx-utils'
import { when } from 'mobx'
import { curry } from 'ramda'

import { ServiceStore, ResultTableModel } from 'internal'

const ResultStore = ServiceStore.named('ResultStore')
  .props({
    _resultLookUps: types.optional(ResultTableModel, {})
  })
  .actions(self => {
    const apiV1 = getEnv(self).apiV1

    const ResultLookUpApi = curry(url => '/api/ResultApi' + url)

    function lookup() {
      const prom = fromPromise(apiV1.post(ResultLookUpApi('/Get')))

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                self._resultLookUps = data
              })
            }
          })
        }
      )

      return prom
    }

    return {
      lookup
    }
  })
  .views(self => ({
    get resultLookUps() {
      return getSnapshot(self._resultLookUps)
    }
  }))

export default ResultStore
