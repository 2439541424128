const createStorage = storage => ({
  clear() {
    return new Promise((resolve, reject) => {
      try {
        window[`${storage}Storage`].clear()
        resolve(null)
      } catch (err) {
        reject(err)
      }
    })
  },
  removeItem(key) {
    return new Promise((resolve, reject) => {
      try {
        window[`${storage}Storage`].removeItem(key)
        resolve(null)
      } catch (err) {
        reject(err)
      }
    })
  },
  getItem(key) {
    return new Promise((resolve, reject) => {
      try {
        const value = window[`${storage}Storage`].getItem(key)
        resolve(value)
      } catch (err) {
        reject(err)
      }
    })
  },
  setItem(key, value) {
    return new Promise((resolve, reject) => {
      try {
        window[`${storage}Storage`].setItem(key, value)
        resolve(null)
      } catch (err) {
        reject(err)
      }
    })
  }
})

export const LocalStorage = createStorage('local')
export const SessionStorage = createStorage('session')
