import { types } from 'mobx-state-tree'

export default types.model('AlgoProblemListModel', {
  id: types.identifierNumber,
  problemLevel: types.maybeNull(types.integer),
  problemCode: types.maybeNull(types.string),
  problemName: types.maybeNull(types.string),
  raiting: types.maybeNull(types.integer),
  percentOfUsersSolved: types.maybeNull(types.integer),
  subjectName: types.maybeNull(types.string),
  isSolved: types.maybeNull(types.boolean, false)
})
