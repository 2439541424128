import React from "react";
import t from "prop-types";
import classNames from "classnames";

import {utils} from 'internal';

const PanelText = ({tag: Tag, className, text, ...attributes}) => {
  const classes = classNames("c-panel-links__text", className);

  return (
    <Tag className={classes} {...attributes}>
      {text}
    </Tag>
  );
};

PanelText.propTypes = {
  className: t.string,
  tag: utils.tagPropType,
  text: t.string
};

PanelText.defaultProps = {
  tag: 'p'
};

export default PanelText;
