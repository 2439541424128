import React from 'react'
import t from 'prop-types'

import { Spinner } from '../'
import { utils } from 'internal'

const WithLoading = ({ loading, size, children }) =>
  loading ? <Spinner size={size} /> : children

WithLoading.propTypes = {
  loading: t.bool,
  size: t.oneOf(['small', 'medium', 'big']),
  children: utils.tagPropType.isRequired
}

WithLoading.defaultProps = {
  size: 'small'
}

export default WithLoading
