import { types } from 'mobx-state-tree'

import { SortingModel } from 'internal'

export default types.model('ProblemsFilterModel', {
  languageId: types.optional(types.integer, 1),
  page: types.optional(types.number, 1),
  pageSize: types.optional(types.number, 10),
  subjectIds: types.optional(types.array(types.number), []),
  studentIds: types.optional(types.array(types.string), []),
  collections: types.optional(types.array(types.integer), []),
  excludeCollection: types.optional(types.boolean, false),
  tagIds: types.optional(types.array(types.number), []),
  tagList: types.optional(
    types.array(
      types.model('TagList', {
        tagId: types.optional(types.identifierNumber, 0),
        tagName: types.maybeNull(types.string)
      })
    ),
    []
  ),
  levelIds: types.optional(types.array(types.number), []),
  solved: types.optional(types.boolean, false),
  notSolved: types.optional(types.boolean, false),
  role: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  sorting: types.optional(SortingModel, { field: 'date', direction: 'desc' }),
  isMineSandbox: types.optional(types.boolean, false)
})
