import { types } from 'mobx-state-tree'

const StatisticModel = types.model('Statistic', {
  userData: types.array(
    types.model('UserStatisticData', {
      avatarLocation: types.maybeNull(types.string),
      usersName: types.maybeNull(types.string),
      userId: types.optional(types.string, ''),
      problems: types.array(
        types.model('', {
          problemCode: types.maybeNull(types.string),
          problemId: types.maybeNull(types.number),
          problemName: types.maybeNull(types.string),
          userFullName: types.maybeNull(types.string),
          userId: types.maybeNull(types.string),
          isAccepted: types.maybeNull(types.number),
          attempts: types.maybeNull(types.number)
        })
      ),
      totalAccepted: types.optional(types.number, 0),
      totalAttempts: types.optional(types.number, 0)
    })
  ),
  problemData: types.array(
    types.model('ProblemStatisticData', {
      problemCode: types.maybeNull(types.string),
      problemName: types.maybeNull(types.string),
      problemId: types.maybeNull(types.number),
      grantTotalAccepted: types.optional(types.number, 0),
      grantTotalAttempts: types.optional(types.number, 0)
    })
  ),
  grantIsAccepted: types.optional(types.number, 0),
  grantAttempts: types.optional(types.number, 0)
})

export default StatisticModel
