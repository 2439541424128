import { types } from 'mobx-state-tree'

export default types.model('SandboxDbDetailSubmissionModel', {
  id: types.optional(types.identifierNumber, 0),
  isPublished: types.maybeNull(types.boolean),
  subjectId: types.maybeNull(types.integer),
  subjectName: types.maybeNull(types.string),
  problemCode: types.maybeNull(types.string),
  problemName: types.maybeNull(types.string),
  problemText: types.maybeNull(types.string),
  problemLevel: types.optional(types.integer, 1),
  isSync: types.optional(types.boolean, false),
  solution: types.maybeNull(types.string),
  comment: types.maybeNull(types.string),
  languageId: types.optional(types.integer, 2),
  studentName: types.maybeNull(types.string),
  userId: types.maybeNull(types.string)
})
