import React from "react";
import classNames from "classnames";
import t from "prop-types";

import {utils} from 'internal';
import HeaderInner from "./HeaderInner";
import "./Header.scss";

const Header = ({tag: Tag, className, ...attributes}) => {
  const classes = classNames("l-header", className);

  return <Tag className={classes} {...attributes} />;
};

Header.propTypes = {
  className: t.string,
  tag: utils.tagPropType
};

Header.defaultProps = {
  tag: 'header'
};

Header.Inner = HeaderInner;

export default Header;
