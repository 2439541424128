import React, { useContext } from 'react'
import classNames from 'classnames'
import t from 'prop-types'

import { utils } from 'internal'
import AccordionContext from './AccordionContext'

const AccordionBody = ({ tag: Tag, className, id, ...props }) => {
  const classes = classNames('sw-accordion__body', className)
  const { accordions } = useContext(AccordionContext)

  if (!accordions) {
    return null
  }

  return accordions.get(id) ? <Tag className={classes} {...props} /> : null
}

AccordionBody.propTypes = {
  tag: utils.tagPropType,
  className: t.string,
  id: t.oneOfType([t.number, t.string])
}

AccordionBody.defaultProps = {
  tag: 'div'
}

export default AccordionBody
