import React from 'react'
import t from 'prop-types'
import classNames from 'classnames'

import { utils } from 'internal'

const Text = props => {
  const {
    tag: Tag,
    className,
    color,
    hoverColor,
    size,
    bold,
    inlined,
    ...attrs
  } = props

  const classes = classNames(
    'sw-text',
    `sw-text-${color}`,
    `sw-hover-text-${hoverColor}`,
    size && `sw-${size}`,
    bold && 'sw-fw-bold',
    inlined && 'sw-text-inline',
    className
  )

  return <Tag className={classes} {...attrs} />
}

Text.propTypes = {
  tag: utils.tagPropType,
  className: t.string,
  color: t.oneOf(utils.colors),
  hoverColor: t.oneOf(utils.colors),
  size: t.oneOf(utils.sizes),
  bold: t.bool,
  inlined: t.bool
}

Text.defaultProps = {
  tag: 'span',
  color: 'black',
  hoverColor: 'none',
  inlined: false
}

export default Text
