import { types } from 'mobx-state-tree'

import { utils } from 'internal'

const DbSubmissionModel = types.model('DbSubmissionModel', {
  id: types.identifierNumber,
  problemId: types.maybeNull(types.integer),
  problemName: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  status: 0,
  submitDateTime: types.maybeNull(utils.CustomISO8601Date),
  checkDateTime: types.maybeNull(utils.CustomISO8601Date),
  isAccepted: false,
  logText: types.maybeNull(types.string),
  submittedQuery: types.maybeNull(types.string),
  userResult: types.maybeNull(types.string),
  judgeTypeId: 0,
  userName: '',
  groupName: types.maybeNull(types.string),
  submissionCode: '',
  problemCode: '',
  comment: types.maybeNull(types.string)
})

export default DbSubmissionModel
