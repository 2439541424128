import { getEnv, types, getSnapshot } from 'mobx-state-tree'
import { fromPromise, FULFILLED } from 'mobx-utils'
import { when } from 'mobx'
import { curry } from 'ramda'

import {
  ServiceStore,
  UserPersonalModel,
  UserPersonalPhotoModel,
  ImageModel,
  TimeZoneModel
} from 'internal'

const ManageStore = ServiceStore.named('ManageStore')
  .props({
    _currentUser: types.optional(UserPersonalModel, {}),
    _userPhoto: types.optional(UserPersonalPhotoModel, {}),
    _imageURL: types.optional(ImageModel, {}),
    _allTimeZones: types.optional(types.array(TimeZoneModel), [])
  })
  .actions(self => {
    const apiV1 = getEnv(self).apiV1

    const accountApi = curry(url => '/api/ManageApi' + url)
    const imageApi = curry(url => '/api/ImageApi' + url)
    const InstitutionApi = curry(url => '/api/InstitutionApi' + url)

    const getUserInfo = () => {
      const prom = fromPromise(apiV1.get(accountApi('/GetUserPersonalInfo')))

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              const userInfo = resp.data
              const URL = process.env.REACT_APP_API_URL
              if (userInfo.userPhotoLocation)
                userInfo.userPhotoLocation = `${URL}${userInfo.userPhotoLocation}`
              self._currentUser.setInfo(userInfo)
            }
          })
        }
      )
      return prom
    }

    function create(userData) {
      const prom = fromPromise(
        apiV1.post(accountApi('/SetUserPersonalInfo'), userData)
      )
      return prom
    }

    function changePassword(data) {
      const prom = fromPromise(apiV1.post(accountApi('/ChangePassword'), data))
      return prom
    }

    function uploadImage(data) {
      const prom = fromPromise(
        apiV1.post(imageApi('/UploadImage'), data.formData)
      )
      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self._imageURL.setInfo(resp.data)
            }
          })
        }
      )
      return prom
    }

    const leaveById = institutionId => {
      const prom = fromPromise(
        apiV1.delete(InstitutionApi('/LeaveUserInstitute'), {
          params: { institutionId }
        })
      )

      return prom
    }

    const allTimeZonesLookup = () => {
      const prom = fromPromise(apiV1.get(accountApi('/GetAllTimeZones')))

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._allTimeZones = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    return {
      uploadImage,
      getUserInfo,
      create,
      changePassword,
      leaveById,
      allTimeZonesLookup
    }
  })
  .views(self => ({
    get userPhoto() {
      return self._userPhoto
    },
    get currentUser() {
      return getSnapshot(self._currentUser)
    },
    get timezoneLookup() {
      return getSnapshot(self._allTimeZones)
    },
    get userTimezone() {
      return self._currentUser.timeZoneValue
    },

    checkInstituteInList(instituteId) {
      return getSnapshot(self._currentUser).userInstitutes.some(
        institute => institute.id === instituteId
      )
    }
  }))

export default ManageStore
