import React from "react";
import classNames from "classnames";
import t from "prop-types";

import { utils } from 'internal';

const WidgetDrag = ({ tag: Tag, className, ...attributes }) => {
  const classes = classNames("sw-widget__drag", className);

  return (
    <Tag className={classes} {...attributes}>
      <div className="sw-widget__drag-inner" />
    </Tag>
  );
};

WidgetDrag.propTypes = {
  tag: utils.tagPropType,
  className: t.string
};

WidgetDrag.defaultProps = {
  tag: "div"
};

export default WidgetDrag;
