import { types } from 'mobx-state-tree'

export default types.model('DbSubjectDataCodeModel', {
  id: types.identifierNumber,
  subjectDatabasesId: types.optional(types.integer, 0),
  subjectJudgeTypeId: types.optional(types.integer, 0),
  dataCode: types.maybeNull(types.string),
  useDefault: types.optional(types.boolean, false),
  dbRealName: types.maybeNull(types.string),
  judgeTypeId: types.optional(types.integer, 0),
  isDeleted: types.optional(types.boolean, false)
})
