import { types } from 'mobx-state-tree'

export default types.model('AlgoProblemTestsModel', {
  id: types.optional(types.identifierNumber, 0),
  problemId: types.optional(types.integer, 0),
  inputFile: types.maybeNull(types.string, ''),
  outputFile: types.maybeNull(types.string, ''),
  isPublic: types.optional(types.boolean, false),
  isDeleted: types.optional(types.boolean, false),
  description: types.maybeNull(types.string),
  sequenceNumber: types.optional(types.integer, 0)
})