import React from 'react'
import t from 'prop-types'
import classNames from 'classnames'

import { utils } from 'internal'

import './Title.scss'

const Title = props => {
  const { tag: Tag, className, color, textAlign, ...attrs } = props

  const classes = classNames(
    'sw-title',
    color && `sw-text-${color}`,
    textAlign && `sw-text-${textAlign}`,
    className
  )

  return <Tag className={classes} {...attrs} />
}

Title.propTypes = {
  tag: t.oneOf([utils.tagPropType, 'h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  className: t.string,
  color: t.oneOf(utils.colors),
  textAlign: t.oneOf(['center', 'left', 'right', 'justified'])
}

Title.defaultProps = {
  tag: 'h1'
}

export default Title
