import { types } from 'mobx-state-tree'

export default types.model('DbProblemSubmissionsAuthorFilterModel', {
  correct: types.maybeNull(types.boolean),
  mistake: types.maybeNull(types.boolean),
  fromDate: types.maybeNull(types.Date),
  toDate: types.maybeNull(types.Date),
  rejudgeBatchIdList: types.maybeNull(types.array(types.integer)),
  problemCode: types.maybeNull(types.string),
  subjectIdList: types.maybeNull(types.array(types.integer)),
  studentIdList: types.maybeNull(types.array(types.string)),
  userId: types.maybeNull(types.string),
  languageId: types.optional(types.integer, 2),
  page: 1,
  pageSize: 10
})
