import { getEnv, getSnapshot, types } from 'mobx-state-tree'
import { fromPromise, FULFILLED } from 'mobx-utils'
import { when } from 'mobx'
import { curry } from 'ramda'

import { ServiceStore, GroupRequestModel, RequestModel } from 'internal'

const StudentApprovalStore = ServiceStore.named('StudentApprovalStore')
  .props({
    _studentIncomingRequests: types.optional(
      types.model('PaginationSubjects', {
        data: types.optional(types.array(GroupRequestModel), []),
        total: types.optional(types.number, 0)
      }),
      {}
    ),
    _studentOutgoingRequests: types.optional(
      types.model('PaginationSubjects', {
        data: types.optional(types.array(GroupRequestModel), []),
        total: types.optional(types.number, 0)
      }),
      {}
    )
  })
  .actions(self => {
    const apiV1 = getEnv(self).apiV1

    const studentApprovalApi = curry(url => '/api/StudentApprovalApi' + url)

    function incomingList(page, pageSize) {
      const prom = fromPromise(
        apiV1.post(
          studentApprovalApi('/GetIncomingRequests'),
          RequestModel.create({
            page,
            pageSize
          })
        )
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._studentIncomingRequests = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    function outgoingList(page, pageSize) {
      const prom = fromPromise(
        apiV1.post(
          studentApprovalApi('/GetOutgoingRequests'),
          RequestModel.create({
            page,
            pageSize
          })
        )
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._studentOutgoingRequests = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    function create(reqData) {
      const prom = fromPromise(apiV1.post(studentApprovalApi('/Post'), reqData))
      return prom
    }

    function save(reqData) {
      const prom = fromPromise(apiV1.post(studentApprovalApi('/Put'), reqData))

      return prom
    }

    function cancel(approvalId) {
      return fromPromise(
        apiV1.delete(studentApprovalApi('/Delete'), {
          params: {
            approvalId
          }
        })
      )
    }

    function confirm(approvalId) {
      return fromPromise(
        apiV1.post(studentApprovalApi('/Confirm'), null, {
          params: {
            approvalId
          }
        })
      )
    }

    function reject(approvalId) {
      return fromPromise(
        apiV1.post(studentApprovalApi('/Reject'), null, {
          params: {
            approvalId
          }
        })
      )
    }

    return {
      incomingList,
      outgoingList,
      create,
      save,
      cancel,
      confirm,
      reject
    }
  })
  .views(self => ({
    get incomingRequests() {
      return getSnapshot(self._studentIncomingRequests)
    },
    get outgoingRequests() {
      return getSnapshot(self._studentOutgoingRequests)
    }
  }))

export default StudentApprovalStore
