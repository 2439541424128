import { types } from 'mobx-state-tree'

export default types.model('ResultTableModel', {
  orderOfProblem: types.array(
    types.maybeNull(types.string)
  ),
  result: types.array(
    types.model('ResultTableModel__Students', {
      rank: types.maybeNull(types.integer),
      authorName: types.maybeNull(types.string),
      taskList: types.maybeNull(types.array(types.maybeNull(
          types.model('ResultTableModel__TaskList', {
            isSolved: types.maybeNull(types.boolean),
            isFirst: types.maybeNull(types.boolean),
            failedAttempt: types.maybeNull(types.integer),
            solvedTimeHour: types.maybeNull(types.integer),
            solvedTimeMinute: types.maybeNull(types.integer)
          }
        )))
      ),
      solvedTask: types.maybeNull(types.integer),
      time: types.maybeNull(types.integer)
    })
  ),
  startTime: types.maybeNull(types.string),
  duration: types.maybeNull(types.integer),
  lastSubmissionUserName: types.maybeNull(types.string),
  lastSuccessSubmissionHour: types.maybeNull(types.integer),
  lastSuccessSubmissionMinute: types.maybeNull(types.integer),
  lastSuccessSubmissionProblem: types.maybeNull(types.string)
})
