import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { components } from 'react-select'
import t from 'prop-types'

import { SimpleAsyncCreatableSelect } from './index'

import NoneUserAvatar from '../../assets/img/none-user-avatar.png'

const Option = props => {
  const { isDisabled, children, data } = props

  return !isDisabled ? (
    <components.Option {...props}>
      <div className="flexed-center">
        <img className="c-request-card-img" src={NoneUserAvatar} alt={data.value} />
        {data.__isNew__ ? (
          <Fragment>
            <FormattedMessage
              id="requests.form.field.select"
              defaultMessage="Создать"
            />
            : {data.value}
          </Fragment>
        ) : (
          children
        )}
      </div>
    </components.Option>
  ) : null
}

const NoOptionsMessage = props => (
  <components.NoOptionsMessage {...props}>
    <FormattedMessage
      id="requests.form.field.select.input"
      defaultMessage="Введите имя"
    />
  </components.NoOptionsMessage>
)

const UserSelectRequest = ({ form, field, optionModel, ...props }) => {
  const handleInputChange = newValue => newValue.replace(/\s/gi, '')

  const handleCreateOption = newUser => {
    const newField = form.$(field)

    const newUsers = Array.from(newField.values() || [])
    newUsers.push({
      [optionModel.value]: `${Date.now()}NOT_EXIST`,
      [optionModel.label]: newUser
    })

    newField.onChange(newUsers)
  }

  return (
    <SimpleAsyncCreatableSelect
      field={form.$(field)}
      form={form}
      onInputChange={handleInputChange}
      onCreateOption={handleCreateOption}
      components={{
        Option,
        NoOptionsMessage
      }}
      defaultOptions={false}
      isMulti
      isRequired
      {...props}
    />
  )
}

UserSelectRequest.propTypes = {
  form: t.any.isRequired,
  field: t.any.isRequired,
  optionModel: t.object.isRequired
}

UserSelectRequest.defaultProps = {
  optionModel: {
    label: 'label',
    value: 'value'
  }
}

export default UserSelectRequest
