import { getEnv, types, getSnapshot } from 'mobx-state-tree'
import { fromPromise, FULFILLED } from 'mobx-utils'
import { when } from 'mobx'
import { curry } from 'ramda'
import { ServiceStore, AlgoProblemTestsModel } from 'internal'

export default ServiceStore.named('AlgoProblemTestsStore')
  .props({
    _testCases: types.optional(types.array(AlgoProblemTestsModel), [])
  })
  .actions(self => {
    const apiV1 = getEnv(self).apiV1

    const algoProblemTestsApi = curry(url => '/api/AlgoProblemTestsApi' + url)

    const create = formData => {
      const prom = fromPromise(
        apiV1.post(algoProblemTestsApi('/Post'), formData)
      )
      return prom
    }

    const edit = formData => {
      const prom = fromPromise(apiV1.put(algoProblemTestsApi('/Put'), formData))
      return prom
    }

    const deleteById = testId => {
      const prom = fromPromise(
        apiV1.delete(algoProblemTestsApi('/Delete'), {
          params: {
            id: testId
          }
        })
      )
      return prom
    }

    const getById = problemId => {
      const prom = fromPromise(
        apiV1.post(algoProblemTestsApi(`/GetById?id=${problemId}`))
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._testCases = resp.data || []
              })
            }
          })
        }
      )

      return prom
    }

    const deleteFile = params => {
      const prom = fromPromise(
        apiV1.delete(algoProblemTestsApi('/DeleteTestFile'), {
          params: {
            id: params.id,
            location: params.location
          }
        })
      )

      return prom
    }

    const editTestOrder = testOrders => {
      const prom = fromPromise(
        apiV1.put(algoProblemTestsApi('/editTestOrder'), testOrders)
      )

      return prom
    }

    return {
      create,
      edit,
      deleteById,
      getById,
      deleteFile,
      editTestOrder
    }
  })
  .views(self => ({
    get testCases() {
      return getSnapshot(self._testCases)
    }
  }))
