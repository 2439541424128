import React from "react";
import t from "prop-types";
import classNames from "classnames";

import "./Alert.scss";
import {utils} from 'internal';

const Alert = props => {
  const {tag: Tag, className, color, ...attributes} = props;

  const classes = classNames("sw-alert", `sw-alert--${color}`, className);

  return (
    <Tag className={classes} {...attributes}/>
  );
};

Alert.propTypes = {
  className: t.string,
  color: t.oneOf(['success', 'error', 'warning']),
  tag: utils.tagPropType
};

Alert.defaultProps = {
  color: "success",
  tag: 'div'
};

export default Alert;
