import React from "react";
import classNames from "classnames";
import t from "prop-types";

import { utils } from 'internal';
import { MaterialIcon } from "../";

const WidgetControl = ({ tag: Tag, className, icon, ...attributes }) => {
  const classes = classNames("sw-widget__tools-icon", className);

  return (
    <div className={classes} {...attributes}>
      <MaterialIcon
        name={icon}
      />
    </div>
  );
};

WidgetControl.propTypes = {
  tag: utils.tagPropType,
  className: t.string
};

WidgetControl.defaultProps = {
  tag: "div"
};

export default WidgetControl;
