import { getEnv, types } from 'mobx-state-tree'
import { fromPromise, PENDING, REJECTED, FULFILLED } from 'mobx-utils'
import { when } from 'mobx'
import { curry } from 'ramda'

import { SuggestedLinkModel, RequestModel } from 'internal'

const SuggestedLinkAction = types
  .model({
    links: types.optional(
      types.model('PaginationGroups', {
        data: types.optional(types.array(SuggestedLinkModel), []),
        total: types.optional(types.number, 0)
      }),
      {}
    )
  })
  .actions(self => {
    const apiV1 = getEnv(self).apiV1

    const { GET_SUGGESTED_LINKS_ERROR, UPDATE_SUGGESTED_LINK_ERROR } = getEnv(
      self
    ).KEYS

    const suggestedLinkUrl = curry((url = '') => '/api/SuggestedLinksApi' + url)

    const getSuggestedLinks = (page, pageSize) => {
      const promGetSuggestedLinks = fromPromise(
        apiV1.post(
          suggestedLinkUrl('/Get'),
          RequestModel.create({
            page,
            pageSize
          })
        )
      )

      when(
        () => promGetSuggestedLinks.state === PENDING,
        () => {
          promGetSuggestedLinks.case({
            pending: () => {
              self.runInAction(() => {
                self.errors.delete(GET_SUGGESTED_LINKS_ERROR)
              })
            }
          })
        }
      )
      when(
        () => promGetSuggestedLinks.state === REJECTED,
        () =>
          promGetSuggestedLinks.case({
            rejected: ({ response }) => {
              self.runInAction(() => {
                self.errors.set(GET_SUGGESTED_LINKS_ERROR, response)
              })
            }
          })
      )
      when(
        () => promGetSuggestedLinks.state === FULFILLED,
        () => {
          promGetSuggestedLinks.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                self.links = data
              })
            }
          })
        }
      )

      return promGetSuggestedLinks
    }

    const updateSuggestedLink = newLink => {
      const promUpdateSuggestedLink = fromPromise(
        apiV1.put(suggestedLinkUrl('/Put'), newLink)
      )

      when(
        () => promUpdateSuggestedLink.state === PENDING,
        () => {
          promUpdateSuggestedLink.case({
            pending: () => {
              self.runInAction(() => {
                self.errors.delete(UPDATE_SUGGESTED_LINK_ERROR)
              })
            }
          })
        }
      )
      when(
        () => promUpdateSuggestedLink.state === REJECTED,
        () =>
          promUpdateSuggestedLink.case({
            rejected: ({ response }) => {
              self.runInAction(() => {
                self.errors.set(UPDATE_SUGGESTED_LINK_ERROR, response)
              })
            }
          })
      )

      return promUpdateSuggestedLink
    }

    return {
      getSuggestedLinks,
      updateSuggestedLink
    }
  })

export default SuggestedLinkAction
