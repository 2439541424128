import React from 'react';
import t from 'prop-types';
import classNames from 'classnames';

import {utils} from 'internal';


const FilterHeader = ({tag: Tag, className, ...attributes}) => {
  const classes = classNames(
    'filter__header',
    className
  );

  return(
    <Tag className={classes} {...attributes}/>
  );
};

FilterHeader.propTypes = {
  tag: utils.tagPropType,
  className: t.string
};

FilterHeader.defaultProps = {
  tag: 'li'
};

export default FilterHeader;
