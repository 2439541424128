import { types, getEnv, getSnapshot } from 'mobx-state-tree'
import { fromPromise, FULFILLED } from 'mobx-utils'
import { when } from 'mobx'
import { curry } from 'ramda'

import {
  DbProblemSubmissionModel,
  ServiceStore,
  DbProblemSubmissionsAuthorFilterModel
} from 'internal'

const DbProblemSubmissionStore = ServiceStore.named('DbProblemSubmissionStore')
  .props({
    _problemDbSubmissions: types.optional(
      types.model('Paginate', {
        data: types.optional(types.array(DbProblemSubmissionModel), []),
        total: types.optional(types.integer, 0)
      }),
      {}
    )
  })
  .actions(self => {
    const apiV1 = getEnv(self).apiV1

    const getSLUrl = curry(url => '/api/DbSubmissionsListApi' + url)

    const getStudentDbSubmissionList = filter => {
      const prom = fromPromise(
        apiV1.post(
          getSLUrl('/StudentSubmissionsList'), filter)
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._problemDbSubmissions = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    const getTeacherDbSubmissionList = filter => {
      const prom = fromPromise(
        apiV1.post(getSLUrl('/TeacherSubmissionsList'), filter)
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._problemDbSubmissions = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    const getAuthorDbSubmissionList = filter => {
      const prom = fromPromise(
        apiV1.post(
          getSLUrl('/AuthorSubmissionsList'),
          DbProblemSubmissionsAuthorFilterModel.create(filter)
        )
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._problemDbSubmissions = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    return {
      getStudentDbSubmissionList,
      getTeacherDbSubmissionList,
      getAuthorDbSubmissionList
    }
  })
  .views(self => ({
    get problemDbSubmissions() {
      return getSnapshot(self._problemDbSubmissions)
    }
  }))

export default DbProblemSubmissionStore
