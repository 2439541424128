import React from "react";
import t from "prop-types";
import classNames from "classnames";

import { utils } from 'internal';

const TagItem = ({ tag: Tag, className, ...attributes }) => {
  const classes = classNames("sw-tags__item", className);

  return <Tag className={classes} {...attributes} />;
};

TagItem.propTypes = {
  tag: utils.tagPropType,
  className: t.string
};

TagItem.defaultProps = {
  tag: "span"
};

export default TagItem;
