import React from "react";
import t from "prop-types";
import classNames from "classnames";

import {utils} from 'internal';

import "./Row.scss";

const Row = ({tag: Tag, className, ...attributes}) => {
  const classes = classNames(
    'sw-row',
    className
  );

  return <Tag className={classes} {...attributes} />;
};

Row.propTypes = {
  tag: utils.tagPropType,
  className: t.string
};

Row.defaultProps = {
  tag: "div"
};

export default Row;
