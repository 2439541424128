import { types } from 'mobx-state-tree'

import { DbSubjectJudgeTypeModel, DbSubjectDataCodeModel } from 'internal'

export default types.model('DbSubjectDatabasesModel', {
  id: types.optional(types.identifierNumber, 0),
  subjectId: types.optional(types.integer, 0),
  isPublic: types.optional(types.boolean, false),
  isEnabled: types.optional(types.boolean, false),
  dbRealName: types.maybeNull(types.string),
  dbPublicName: types.maybeNull(types.string),
  sequenceNumber: types.optional(types.integer, 1),
  description: types.maybeNull(types.string),
  comment: types.maybeNull(types.string),
  isDeleted: types.optional(types.boolean, false),
  languageId: types.optional(types.integer, 0),
  subjectJudgeTypeBusinessModels: types.optional(
    types.array(DbSubjectJudgeTypeModel),
    []
  ),
  dbSubjectDataCodeBusinessModel: types.optional(
    types.array(DbSubjectDataCodeModel),
    []
  )
})
