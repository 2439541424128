import React from "react";
import classNames from "classnames";
import t from "prop-types";

import {utils} from 'internal';

const NavigationBody = ({tag: Tag, className, ...attributes}) => {
  const classes = classNames("l-navigation__body", className);

  return (<Tag className={classes} {...attributes}/>);
};

NavigationBody.propTypes = {
  className: t.string,
  tag: utils.tagPropType
};

NavigationBody.defaultProps = {
  tag: 'div'
};

export default NavigationBody;
