import { types } from 'mobx-state-tree'

const ImageModel = types
  .model('ImageModel', {
    type: types.maybeNull(types.string),
    image: types.optional(types.array(types.string), [])
  })
  .actions(self => {
    const setInfo = image => {
        self.type = image.type
        self.image = image.image
    }

    return {
      setInfo
    }
  })

export default ImageModel
