import React from "react";
import t from "prop-types";
import classNames from "classnames";

import FilterHeader from "./FilterHeader";
import FilterBody from "./FilterBody";
import FilterFooter from "./FilterFooter";
import {utils} from 'internal';
import "./Filter.scss";


const Filter = ({tag: Tag, className, ...attributes}) => {
  const classes = classNames(
    "filter",
    className
  );

  return (
    <Tag className={classes} {...attributes}/>
  );
};

Filter.defaultProps = {
  tag: 'ul'
};

Filter.propTypes = {
  tag: utils.tagPropType,
  className: t.string
};

Filter.Header = FilterHeader;
Filter.Body = FilterBody;
Filter.Footer = FilterFooter;

export default Filter;
