import React from "react";
import classNames from "classnames";
import t from "prop-types";

import {utils} from 'internal';
import ListItem from "./ListItem";
import ListLabel from "./ListLabel";
import "./List.scss";


const List = ({tag: Tag, className, flexed, ...attributes}) => {
  const classes = classNames(
    "sw-list",
    flexed && "sw-list--flexed",
    className
  );

  return (<Tag className={classes} {...attributes} />);
};

List.propTypes = {
  className: t.string,
  tag: utils.tagPropType,
  flexed: t.bool
};

List.defaultProps = {
  tag: 'ul'
};

List.Item = ListItem;
List.Label = ListLabel;

export default List;
