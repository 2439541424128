import React from "react";
import t from "prop-types";
import classNames from "classnames";

import {utils} from 'internal';


const FilterContainerTitle = ({tag: Tag, className, ...attributes}) => {
  const classes = classNames("sw-filter-container__title", className);

  return (<Tag className={classes} {...attributes}/>);
};

FilterContainerTitle.propTypes = {
  className: t.string,
  tag: utils.tagPropType
};

FilterContainerTitle.defaultProps = {
  tag: "span"
};

export default FilterContainerTitle;
