import React from "react";
import classNames from "classnames";
import t from "prop-types";

import { utils } from 'internal';

const TooltipText = ({ tag: Tag, text, position, children, ...attributes }) => {
  const classes = classNames("tooltip__text", position);

  return (
    <Tag className={classes} {...attributes}>
      {text || children}
    </Tag>
  );
};

TooltipText.propTypes = {
  tag: utils.tagPropType,
  children: utils.tagPropType,
  text: t.string.isRequired,
  position: t.string
};

TooltipText.defaultProps = {
  tag: "div",
  position: "right"
};

export default TooltipText;
