import React, { Component } from "react";
import classNames from "classnames";
import t from "prop-types";

import { utils } from 'internal';

import WidgetBody from "./WidgetBody";
import WidgetControl from "./WidgetControl";
import WidgetControlGroup from "./WidgetControlGroup";
import WidgetDrag from "./WidgetDrag";
import WidgetHeader from "./WidgetHeader";
import WidgetTitle from "./WidgetTitle";
import WidgetFooter from "./WidgetFooter";

// sw-context-card--- rename sw-widget
import "./Widget.scss";

class Widget extends Component {
  render() {
    const { tag: Tag, className, ...attributes } = this.props;
    const classes = classNames("sw-widget", className);

    return <Tag className={classes} {...attributes} />;
  }
}

Widget.propTypes = {
  tag: utils.tagPropType,
  className: t.string
};

Widget.defaultProps = {
  tag: "div"
};

Widget.Body = WidgetBody;
Widget.Control = WidgetControl;
Widget.ControlGroup = WidgetControlGroup;
Widget.Drag = WidgetDrag;
Widget.Header = WidgetHeader;
Widget.Title = WidgetTitle;
Widget.Footer = WidgetFooter;

export default Widget;
