import React from "react";
import classNames from "classnames";
import t from "prop-types";

import "./Spinner.scss";
import { utils } from 'internal';

const Spinner = ({ tag: Tag, className, size, ...attributes }) => {
  const classes = classNames("sw-spinner", className);

  return (
    <Tag className={classes} {...attributes}>
      <figure className={`sw-spinner__icon sw-spinner__icon--${size}`}>
        <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0)">
            <path
              d="M30 15.4838C29.9614 13.462 29.5227 11.4465 28.7126 9.60355C27.9051 7.75899 26.7332 6.08809 25.2944 4.70737C23.8565 3.32559 22.1512 2.23323 20.3055 1.51262C18.4609 0.789222 16.4774 0.443663 14.5179 0.48558C12.5582 0.524363 10.6072 0.950449 8.82299 1.73609C7.03748 2.51917 5.41994 3.65536 4.08362 5.0495C2.74638 6.44294 1.68996 8.09474 0.993672 9.88159C0.294773 11.6674 -0.0383041 13.5863 0.00349744 15.4838C0.0423961 17.3816 0.455941 19.2681 1.21708 20.9934C1.97572 22.72 3.07603 24.2842 4.4257 25.576C5.77461 26.8688 7.37306 27.8894 9.10097 28.5611C10.8281 29.2355 12.6826 29.556 14.5179 29.5144C16.3537 29.4754 18.1756 29.0743 19.8422 28.3377C21.5099 27.6035 23.0207 26.5391 24.268 25.2339C25.5164 23.9294 26.501 22.3844 27.1483 20.7154C27.5425 19.7036 27.81 18.6471 27.9509 17.5767C27.9886 17.5788 28.0265 17.5803 28.0647 17.5803C29.1335 17.5803 30 16.7139 30 15.6451C30 15.5908 29.9971 15.537 29.9928 15.4839L30 15.4838ZM26.925 20.6227C26.2152 22.2315 25.1867 23.6889 23.926 24.8918C22.666 26.0955 21.1743 27.0444 19.5643 27.6674C17.9547 28.2927 16.2293 28.5881 14.518 28.5467C12.8064 28.5074 11.1133 28.1314 9.56433 27.4438C8.01465 26.7584 6.61053 25.7659 5.4521 24.5497C4.2928 23.3341 3.37996 21.8958 2.78139 20.3448C2.18055 18.7939 1.89757 17.1329 1.93885 15.4837C1.97822 13.8341 2.34183 12.2055 3.00485 10.7154C3.66578 9.2246 4.62251 7.87383 5.79423 6.75988C6.96538 5.64506 8.35028 4.76809 9.84237 4.19402C11.3343 3.61762 12.9308 3.34713 14.518 3.38835C16.1055 3.42783 17.6697 3.77896 19.101 4.41748C20.5328 5.05397 21.8302 5.97482 22.8997 7.10213C23.97 8.22874 24.811 9.56035 25.3608 10.9934C25.9126 12.4265 26.1706 13.9586 26.1295 15.4838H26.1368C26.1324 15.5369 26.1295 15.5906 26.1295 15.645C26.1295 16.6432 26.8853 17.4645 27.8557 17.5688C27.6675 18.6219 27.3559 19.6499 26.925 20.6227Z"
              fill="#00BAE3"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="30" height="30" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </figure>
    </Tag>
  );
};

Spinner.propTypes = {
  tag: utils.tagPropType,
  className: t.string,
  size: t.oneOf(["small", "medium", "big"])
};

Spinner.defaultProps = {
  size: "small",
  tag: "div"
};

export default Spinner;
