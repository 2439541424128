import { types, getSnapshot } from 'mobx-state-tree'

import { BaseUIStore, AnchorModel } from 'internal'

const ChatUIStore = BaseUIStore.named('ChatUIStore')
  .props({
    _anchor: types.optional(AnchorModel, {})
  })
  .actions(self => {
    const setAnchor = anchor => {
      self._anchor = anchor
    }

    const resetAnchor = anchor => {
      self._anchor = {}
    }

    return {
      setAnchor,
      resetAnchor
    }
  })
  .views(self => ({
    get anchor() {
      return getSnapshot(self._anchor)
    }
  }))

export default ChatUIStore
