import React from "react";
import t from "prop-types";
import classNames from "classnames";

import {
  utils
} from 'internal';


function Cell(props) {

  const {
    tag,
    className,
    head,
    textAlign,
    ...attrs
  } = props;

  let Tag = head ? "th" : tag;

  const classes = classNames(
    `sw-text-${textAlign}`,
    className
  );

  return (
    <Tag className={classes} {...attrs}/>
  );
}

Cell.propTypes = {
  tag: utils.tagPropType,
  className: t.string,
  head: t.bool,
  textAlign: t.oneOf(["center", "right", "justify", "left"]),
};

Cell.defaultProps = {
  tag: "td",
  textAlign: "left"
};

export default Cell;
