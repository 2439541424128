import { types } from 'mobx-state-tree'

export default types.model('DbSubjectModel', {
  id: types.optional(types.identifierNumber, 0),
  subjectName: types.optional(types.string, ''),
  description: types.optional(types.string, ''),
  comment: types.optional(types.string, ''),
  languageId: types.optional(types.integer, 0),
  isActive: types.optional(types.boolean, true),
  authorId: types.optional(types.string, '')
})
