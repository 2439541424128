import { types, getSnapshot } from 'mobx-state-tree'

import { BaseUIStore, UserRoleModel } from 'internal'

const AppUIStore = BaseUIStore.named('AppUIStore')
  .props({
    hydrated: false,
    _currentRole: types.optional(UserRoleModel, {}),
    _currentLanguage: types.optional(types.string, 'en'),
    _currentSectionId: types.optional(types.integer, 1)
  })
  .actions(self => {
    const afterHydration = () => (self.hydrated = true)

    function setRole(role) {
      self._currentRole = role
    }

    function setLanguage(language) {
      self._currentLanguage = language
    }

    function setSectionId(sectionId) {
      self._currentSectionId = sectionId
    }

    return {
      setRole,
      setLanguage,
      afterHydration,
      setSectionId
    }
  })
  .views(self => ({
    get currentRole() {
      return getSnapshot(self._currentRole)
    },
    get currentLanguage() {
      return self._currentLanguage
    },
    get currentSectionId() {
      return self._currentSectionId
    }
  }))

export default AppUIStore
