import React from "react";
import t from "prop-types";
import classNames from "classnames";

import {utils} from 'internal';


const NavigationItems = ({tag: Tag, className, ...attributes}) => {
  const classes = classNames(
    "l-navigation__items",
    className
  );

  return (<Tag className={classes} {...attributes} />);
};

NavigationItems.propTypes = {
  className: t.string,
  tag: utils.tagPropType
};

NavigationItems.defaultProps = {
  tag: 'nav'
};

export default NavigationItems;
