import React from 'react'
import { observer } from 'mobx-react'
import t from 'prop-types'

import { CustomInput, FormFeedback } from '../index'
import { Form, Row, Col } from 'react-bootstrap'

const SimpleAsyncSelect = observer(
  ({ field, form, isRequired, inline, ...props }) => {
    const formValidated = form.validated > 0
    return (
      <Form.Group {...(inline && { as: Row })}>
        <Form.Label
          htmlFor={field.id}
          {...(inline ? { column: true, xs: 3 } : { className: 'mb-2' })}
        >
          {field.label}
          {isRequired && <span className="required">*</span>}
        </Form.Label>
        <Col {...(!inline && { className: 'p-0' })}>
          <CustomInput
            {...field.bind()}
            type="async-select"
            isClearable
            backspaceRemovesValue
            cacheOptions
            defaultOptions
            name={field.name}
            placeholder={field.placeholder}
            value={field.value}
            valid={formValidated && field.isValid}
            invalid={formValidated && field.hasError}
            {...props}
          />
          <FormFeedback invalid={formValidated && field.hasError}>
            {field.error}
          </FormFeedback>
        </Col>
      </Form.Group>
    )
  }
)

SimpleAsyncSelect.propTypes = {
  field: t.any.isRequired,
  form: t.any.isRequired,
  isRequired: t.bool
}

export default SimpleAsyncSelect
