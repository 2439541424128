import React from 'react'
import classNames from 'classnames'
import { utils } from 'internal'
import { Table } from 'components'

const DBSolutionResultTable = ({ rows }) => (
  <Table className="mt-3 bg-transparent">
    <Table.Body className="bg-transparent">
      {rows.map(row => (
        <Table.Row key={utils.generateKey()} className="bg-transparent">
          {row.cells.map(cell => (
            <Table.Cell
              className={classNames(
                !cell.isValid && 'sw-text-red',
                'py-2 px-3'
              )}
              head={row.isHeader}
              key={utils.generateKey()}
            >
              {cell.value}
            </Table.Cell>
          ))}
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
)

export default DBSolutionResultTable
