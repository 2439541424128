import { types, getEnv, getSnapshot } from 'mobx-state-tree'
import { when } from 'mobx'
import { fromPromise, FULFILLED } from 'mobx-utils'
import { curry } from 'ramda'

import { ServiceStore, ChatMessageModel } from 'internal'

const ChatStore = ServiceStore.named('ChatStore')
  .props({
    _chats: types.optional(types.array(ChatMessageModel), []),
    _users: types.optional(
      types.model('Users', {
        data: types.optional(
          types.array(
            types.model('User', {
              id: types.optional(types.string, ''),
              name: types.optional(types.string, ''),
              email: types.optional(types.string, ''),
              userPhoto: types.maybeNull(types.string)
            })
          ),
          []
        ),
        total: types.optional(types.integer, 0)
      }),
      {}
    ),
    _messages: types.optional(
      types.model('ChatHistory', {
        data: types.optional(types.array(ChatMessageModel), []),
        total: types.optional(types.integer, 0)
      }),
      {}
    ),
    _threadMessages: types.optional(
      types.model('ChatHistory', {
        data: types.optional(types.array(ChatMessageModel), []),
        total: types.optional(types.integer, 0)
      }),
      {}
    ),
    _allUnreadMessagesAmount: types.optional(types.integer, 0),
    _currentChannelId: types.maybeNull(types.integer)
  })
  .actions(self => {
    const apiV1 = getEnv(self).apiV1
    const chatsUrl = curry(url => '/api/ChatApi' + url)

    const clearChatHistory = () => {
      self._messages = {}
    }

    const clearUsersList = () => {
      self._users = {}
    }

    const updateGroupRequestStatus = (approvalId, status) => {
      const requestAnchorIndex = self.messagesHistory.findIndex(
        message => message.anchorId === approvalId
      )
      if (requestAnchorIndex === undefined) return
      const updatedAnchor = {
        ...self.messagesHistory[requestAnchorIndex],
        anchorData: JSON.stringify({
          ...JSON.parse(self.messagesHistory[requestAnchorIndex].anchorData),
          status
        })
      }

      self.runInAction(() => {
        self._messages.data[requestAnchorIndex] = updatedAnchor
      })
    }

    const getChatHistory = requestBody => {
      const prom = fromPromise(
        apiV1.post(chatsUrl('/GetChatHistory'), {
          ...requestBody,
          userId: null,
          threadId: 0,
          count: 20
        })
      )
      when(
        () => prom.state === FULFILLED,
        () =>
          prom.case({
            fulfilled: ({ data }) => {
              if (requestBody.channelId === self.currentChannelId)
                self.runInAction(() => {
                  self._messages = data
                })
            }
          })
      )
      return prom
    }

    const getUsers = data => {
      const prom = fromPromise(
        apiV1.post(chatsUrl('/AllUsers'), {
          search: data.search,
          count: 6,
          start: data.start
        })
      )
      when(
        () => prom.state === FULFILLED,
        () =>
          prom.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                self._users.data = self._users.data.concat(data.data)
                self._users.total = data.total
              })
            }
          })
      )

      return prom
    }

    const getAllChats = () => {
      const prom = fromPromise(apiV1.get(chatsUrl('/GetAllChats')))

      when(
        () => prom.state === FULFILLED,
        () =>
          prom.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                self._chats = data
              })
            }
          })
      )

      return prom
    }

    const createNewChannel = data => {
      const prom = fromPromise(apiV1.post(chatsUrl('/CreateNewChannel'), data))

      return prom
    }

    const getThreadHistory = data => {
      const prom = fromPromise(
        apiV1.post(chatsUrl('/GetTreadHistory'), {
          ...data,
          userId: null
        })
      )
      when(
        () => prom.state === FULFILLED,
        () =>
          prom.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                self._threadMessages = data
              })
            }
          })
      )
      return prom
    }
    const getAllUnreadMessagesAmount = () => {
      const prom = fromPromise(apiV1.get(chatsUrl('/GetAllUnreadMessages')))

      when(
        () => prom.state === FULFILLED,
        () =>
          prom.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                self._allUnreadMessagesAmount = data
              })
            }
          })
      )

      return prom
    }

    const subtractUnreadMessages = (channelId, subtrahend) => {
      const formatToNaturalNumber = number => (number > 0 ? number : 0)

      const idx = getSnapshot(self._chats).findIndex(
        user => user.channelId === channelId
      )

      self._chats[idx].unreadMessageAmount = formatToNaturalNumber(
        self._chats[idx].unreadMessageAmount - subtrahend
      )

      self._allUnreadMessagesAmount = formatToNaturalNumber(
        self._allUnreadMessagesAmount - subtrahend
      )
    }

    const updateAllUnreadMessagesAmount = allUnreadMessagesAmount => {
      self._allUnreadMessagesAmount = allUnreadMessagesAmount
    }

    const setCurrentChannelId = channelId => {
      self._currentChannelId = channelId
    }

    return {
      clearChatHistory,
      getChatHistory,
      getUsers,
      clearUsersList,
      getAllChats,
      createNewChannel,
      getThreadHistory,
      subtractUnreadMessages,
      getAllUnreadMessagesAmount,
      updateAllUnreadMessagesAmount,
      updateGroupRequestStatus,
      setCurrentChannelId
    }
  })
  .views(self => ({
    get messagesHistory() {
      return getSnapshot(self._messages.data)
    },
    get messages() {
      return getSnapshot(self._messages)
    },
    get allUsers() {
      return getSnapshot(self._users.data)
    },
    get allChats() {
      return getSnapshot(self._chats)
    },
    get threadMessages() {
      return getSnapshot(self._threadMessages)
    },
    get allUnreadMessagesAmount() {
      return self._allUnreadMessagesAmount
    },
    get currentChannelId() {
      return self._currentChannelId
    }
  }))

export default ChatStore
