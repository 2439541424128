import React from "react";
import classNames from "classnames";
import t from "prop-types";

import {utils} from 'internal';


const ListLabel = ({tag: Tag, className, padding,...attributes}) => {
  const classes = classNames(
    "sw-list__label",
    padding && 'sw-list__label--padding',
    className
  );

  return (<Tag className={classes} {...attributes}/>);
};

ListLabel.propTypes = {
  tag: utils.tagPropType,
  className: t.string,
  padding: t.bool
};

ListLabel.defaultProps = {
  tag: "span"
};

export default ListLabel;
