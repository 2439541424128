import { types } from 'mobx-state-tree'
import { utils } from 'internal'

export default types.model('DbProblemSubmissionModel', {
  id: types.optional(types.identifierNumber, 0),
  problemId: types.maybeNull(types.number),
  problemName: types.optional(types.string, ''),
  problemCode: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  submitDateTime: types.maybeNull(utils.CustomISO8601Date),
  checkDateTime: types.maybeNull(utils.CustomISO8601Date),
  isAccepted: types.optional(types.boolean, false),
  logText: types.maybeNull(types.string),
  submittedQuery: types.maybeNull(types.string),
  userName: types.maybeNull(types.string),
  groupName: types.maybeNull(types.string),
  submissionCode: types.maybeNull(types.string),
  comment: types.maybeNull(types.string),
  userResult: types.maybeNull(types.frozen()),
  usersFirstName: types.maybeNull(types.string),
  usersLastName: types.maybeNull(types.string),
  subjectName: types.maybeNull(types.string),
  judgeTypeName: types.maybeNull(types.string),
  userPhoto: types.maybeNull(types.string)
})
