import { types } from 'mobx-state-tree'
import AnchorDataModel from './AnchorData.model'

export default types.model('AnchorModel', {
  messageText: types.optional(types.string, ''),
  anchorType: types.optional(types.integer, 0),
  participantUserId: types.optional(types.string, ''),
  anchorId: types.optional(types.number, 0),
  participantUserName: types.maybeNull(types.string),
  anchorData: types.maybeNull(AnchorDataModel)
})
