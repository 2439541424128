import React from "react";
import classNames from "classnames";
import t from "prop-types";

import { utils } from 'internal';

const WidgetHeader = ({ tag: Tag, className, ...attributes }) => {
  const classes = classNames("sw-widget__heading", className);

  return <Tag className={classes} {...attributes} />;
};

WidgetHeader.propTypes = {
  tag: utils.tagPropType,
  className: t.string
};

WidgetHeader.defaultProps = {
  tag: "header"
};

export default WidgetHeader;
