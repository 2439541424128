import React from 'react'
import { Table } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { Header } from './Header'
import { Body } from './Body'
import { Footer } from './Footer'
import './TableStatistic.scss'

const TableStatistic = ({
  data,
  onLinkToFilterClick,
  submissionsRoute,
  problemsRoute,
  group,
  collection,
  ...tableProps
}) => {
  return (
    <div className="scroll-container">
      <Table bordered {...tableProps}>
        <Header problemsRoute={problemsRoute} problemData={data.problemData} />
        <Body
          collection={collection}
          group={group}
          submissionsRoute={submissionsRoute}
          userData={data.userData}
          onLinkToFilterClick={onLinkToFilterClick}
        />
        <Footer
          collection={collection}
          group={group}
          submissionsRoute={submissionsRoute}
          problemData={data.problemData}
          grantIsAccepted={data.grantIsAccepted}
          grantAttempts={data.grantAttempts}
          onLinkToFilterClick={onLinkToFilterClick}
        />
      </Table>
    </div>
  )
}

TableStatistic.propTypes = {
  submissionsRoute: PropTypes.string,
  problemsRoute: PropTypes.string,
  onLinkToFilterClick: PropTypes.func,
  data: PropTypes.shape({
    userData: PropTypes.arrayOf(
      PropTypes.shape({
        usersName: PropTypes.string,
        userId: PropTypes.string,
        problems: PropTypes.arrayOf(
          PropTypes.shape({
            problemId: PropTypes.number,
            problemName: PropTypes.string,
            userFullName: PropTypes.string,
            userId: PropTypes.string,
            isAccepted: PropTypes.number,
            attempts: PropTypes.number
          })
        ),
        totalAccepted: PropTypes.number,
        totalAttempts: PropTypes.number
      })
    ),
    problemData: PropTypes.arrayOf(
      PropTypes.shape({
        problemName: PropTypes.string,
        problemId: PropTypes.number,
        grantTotalAccepted: PropTypes.number,
        grantTotalAttempts: PropTypes.number
      })
    ),
    grantIsAccepted: PropTypes.number,
    grantAttempts: PropTypes.number
  })
}

export default TableStatistic