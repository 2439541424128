import React from 'react';
import t from 'prop-types';
import classNames from 'classnames';

import {utils} from 'internal';


const PopoverBody = (props) => {
  const {
    className,
    tag: Tag,
    ...attributes
  } = props;

  const classes = classNames(
    className,
    'sw-popover-body'
  );

  return (
    <Tag {...attributes} className={classes}/>
  );
};

PopoverBody.propTypes = {
  tag: utils.tagPropType,
  className: t.string,
};
PopoverBody.defaultProps = {
  tag: "div"
};

export default PopoverBody;
