import React, { useContext } from 'react'
import classNames from 'classnames'
import t from 'prop-types'

import { utils } from 'internal'
import { MaterialIcon } from '../index'
import AccordionContext from './AccordionContext'

const AccordionHeader = ({ tag: Tag, className, id, children, ...props }) => {
  const classes = classNames('sw-accordion__header', className)
  const { onClickToggle, accordions } = useContext(AccordionContext)

  const expandClasses = classNames(
    'sw-accordion__expand',
    accordions.get(id) && 'sw-accordion__expand--is-active'
  )

  const handleClickToggle = () => onClickToggle(id)

  return (
    <Tag className={classes} onClick={handleClickToggle} {...props}>
      {children}
      <div className={expandClasses}>
        <MaterialIcon name="expand_more" />
      </div>
    </Tag>
  )
}

AccordionHeader.propTypes = {
  tag: utils.tagPropType,
  className: t.string,
  id: t.oneOfType([t.string, t.number])
}

AccordionHeader.defaultProps = {
  tag: 'div'
}

export default AccordionHeader
