import React from "react";
import classNames from "classnames";

import './SubTitle.scss';
import {utils} from 'internal';

const SubTitle = ({tag: Tag, className, ...attributes}) => {
  const classes = classNames(
    "sw-subtitle",
    className
  );

  return (<Tag className={classes} {...attributes}/>);
};

SubTitle.propTypes = {
  tag: utils.tagPropType
};

SubTitle.defaultProps = {
  tag: 'h2'
};

export default SubTitle;
