import { types } from 'mobx-state-tree'

import { utils } from 'internal'

const AlgoSubmissionModel = types.model('AlgoSubmissionModel', {
  id: types.identifierNumber,
  problemId: types.maybeNull(types.integer),
  userId: types.maybeNull(types.string),
  statusCode: types.optional(types.string, ''),
  submitDateTime: types.maybeNull(utils.CustomISO8601Date),
  checkDateTime: types.maybeNull(utils.CustomISO8601Date),
  submissionCode: types.optional(types.string, ''),
  logText: types.maybeNull(types.string),
  compilerId: types.maybeNull(types.integer),
  executionTime: types.maybeNull(types.integer),
  executionMemory: types.maybeNull(types.integer)
})

export default AlgoSubmissionModel
