import React from 'react';
import t from 'prop-types';
import classNames from 'classnames';

import {utils} from 'internal';


const PopoverHeader = (props) => {
  const {
    className,
    tag: Tag,
    ...attributes
  } = props;

  const classes = classNames(
    className,
    'sw-popover-header'
  );

  return (
    <Tag {...attributes} className={classes}/>
  );
};

PopoverHeader.propTypes = {
  tag: utils.tagPropType,
  className: t.string,
};
PopoverHeader.defaultProps = {
  tag: 'h3'
};

export default PopoverHeader;
