import { getEnv, types, getSnapshot } from 'mobx-state-tree'
import { fromPromise, PENDING, REJECTED, FULFILLED } from 'mobx-utils'
import { when } from 'mobx'
import { curry } from 'ramda'

import { ServiceStore, DbProblemsTagsModel, RequestModel } from 'internal'

const DbProblemsTagsStore = ServiceStore.named('DbProblemsTagsStore')
  .props({
    _tags: types.optional(
      types.model('PaginationGroups', {
        data: types.optional(types.array(DbProblemsTagsModel), []),
        total: types.optional(types.number, 0)
      }),
      {}
    )
  })
  .actions(self => {
    const apiV1 = getEnv(self).apiV1

    const {
      GET_DB_PROBLEMS_TAGS_ERROR,
      UPDATE_DB_PROBLEMS_TAG_ERROR,
      SEND_TAG_FOR_ATTACH_DB_PROBLEM_ERROR
    } = getEnv(self).KEYS

    const problemsTagUrl = curry((url = '') => '/api/DbProblemsTagsApi' + url)

    const getProblemsTags = (languageId, page, pageSize, problemTypeId) => {
      const prom = fromPromise(
        apiV1.post(
          problemsTagUrl('/Get'),
          RequestModel.create({
            languageId: languageId,
            page,
            pageSize
          }),
          problemTypeId
        )
      )

      when(
        () => prom.state === PENDING,
        () => {
          prom.case({
            pending: () => {
              self.runInAction(() => {
                self.errors.delete(GET_DB_PROBLEMS_TAGS_ERROR)
              })
            }
          })
        }
      )
      when(
        () => prom.state === REJECTED,
        () =>
          prom.case({
            rejected: ({ response }) => {
              self.runInAction(() => {
                self.errors.set(GET_DB_PROBLEMS_TAGS_ERROR, response)
              })
            }
          })
      )
      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                self._tags = data
              })
            }
          })
        }
      )

      return prom
    }

    const updateDbProblemsTag = newTag => {
      const prom = fromPromise(apiV1.put(problemsTagUrl('/Put'), newTag))

      when(
        () => prom.state === PENDING,
        () => {
          prom.case({
            pending: () => {
              self.runInAction(() => {
                self.errors.delete(UPDATE_DB_PROBLEMS_TAG_ERROR)
              })
            }
          })
        }
      )
      when(
        () => prom.state === REJECTED,
        () =>
          prom.case({
            rejected: ({ response }) => {
              self.runInAction(() => {
                self.errors.set(UPDATE_DB_PROBLEMS_TAG_ERROR, response)
              })
            }
          })
      )

      return prom
    }

    const sendTagForAttachDbProblem = params => {
      const prom = fromPromise(
        apiV1({
          method: 'POST',
          url: problemsTagUrl('/AttachTags'),
          params
        })
      )

      when(
        () => prom.state === PENDING,
        () => {
          prom.case({
            pending: () => {
              self.runInAction(() => {
                self.errors.delete(SEND_TAG_FOR_ATTACH_DB_PROBLEM_ERROR)
              })
            }
          })
        }
      )

      when(
        () => prom.state === REJECTED,
        () =>
          prom.case({
            rejected: ({ response }) => {
              self.runInAction(() => {
                self.errors.set(
                  SEND_TAG_FOR_ATTACH_DB_PROBLEM_ERROR,
                  response.data.Message
                )
              })
            }
          })
      )

      return prom
    }

    return {
      getProblemsTags,
      updateDbProblemsTag,
      sendTagForAttachDbProblem
    }
  })
  .views(self => ({
    get tags() {
      return getSnapshot(self._tags)
    }
  }))

export default DbProblemsTagsStore
