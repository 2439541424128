import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { STUDENT_PROFILE_ROUTE } from 'internal'
import { CloseIcon, ChatQuestionIcon } from '../../icons'
import './QuestionModal.scss'
import { FormattedMessage, injectIntl } from 'react-intl'

const QuestionModal = ({ userDataList, onWriteToButtonClick }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <button
        type="button"
        className={`header-btn ${isOpen ? 'header-btn--active' : ''}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <ChatQuestionIcon />
      </button>

      {isOpen ? (
        <div className="question-modal">
          <div className="d-flex mb-3 justify-content-between align-items-center">
            <p className="sw-text-very-dark-gray2 question-modal-text">
              <FormattedMessage
                id="algo.submission.modal.question.title"
                defaultMessage="Возникли вопросы?"
              />
            </p>
            <button className="header-btn" onClick={() => setIsOpen(false)}>
              <CloseIcon width="14px" height="14px" />
            </button>
          </div>
          {userDataList.map(({ userId, userEmail, userFullName, isAuthor }) => (
            <React.Fragment key={isAuthor ? userId : `${userId}-author`}>
              <hr />
              <div className="d-flex mb-3">
                <p className="sw-text-very-dark-gray2 question-modal-text">
                  <FormattedMessage
                    id={`algo.submission.modal.question.${
                      isAuthor ? 'author' : 'teacher'
                    }`}
                    defaultMessage={isAuthor ? 'Автор' : 'Преподаватель'}
                  />
                  :
                </p>
                <p className="sw-text-moderate-blue question-modal-text">
                  <Link
                    to={STUDENT_PROFILE_ROUTE.replace(':studentId', userId)}
                  >
                    {userFullName}
                  </Link>
                </p>
              </div>
              <Button
                type="button"
                className="btn-send w-100"
                onClick={() => onWriteToButtonClick(userId, userEmail)}
              >
                <FormattedMessage
                  id={`algo.submission.modal.question.btn.${
                    isAuthor ? 'author' : 'teacher'
                  }`}
                  defaultMessage={
                    isAuthor ? 'Написать автору' : 'Написать преподавателю'
                  }
                />
              </Button>
            </React.Fragment>
          ))}
        </div>
      ) : null}
    </>
  )
}

QuestionModal.propTypes = {
  onWriteToButtonClick: PropTypes.func,
  userDataList: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.string,
      userEmail: PropTypes.string,
      userFullName: PropTypes.string,
      isAuthor: PropTypes.bool
    })
  )
}

export default injectIntl(QuestionModal)
