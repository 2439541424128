import { types } from 'mobx-state-tree'
import { BaseStore } from 'internal'

const BaseUIStore = BaseStore.named('BaseUIStore')
  .props({
    loaders: types.map(types.boolean)
  })
  .actions(self => ({
    toggleLoading(key) {
      if (self.loaders.has(key)) {
        self.loaders.set(key, !self.loaders.get(key))
      } else {
        self.loaders.set(key, true)
      }
    },

    setLoading(key) {
      self.loaders.set(key, true)
    },

    resetLoading(key) {
      self.loaders.set(key, false)
    }
  }))

export default BaseUIStore
