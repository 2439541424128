import { types } from 'mobx-state-tree'

import { UserRoleBusinessModel } from 'internal'
import InstitutesLookUpModel from '../models/InstitutesLookUp.model'

const UserBusinessModel = types.model('UserBusinessModel', {
  id: types.identifier,
  userId: types.maybeNull(types.string),
  usersFirstName: types.maybeNull(types.string),
  usersLastName: types.maybeNull(types.string),
  userName: types.maybeNull(types.string),
  roles: types.optional(types.array(types.string), []),
  roleModels: types.optional(types.array(UserRoleBusinessModel), []),
  userInstitutions: types.optional(types.array(InstitutesLookUpModel), [])
})

export default UserBusinessModel
