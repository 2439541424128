import { types, getSnapshot, applySnapshot, getEnv } from 'mobx-state-tree'
import { fromPromise, FULFILLED } from 'mobx-utils'
import { when } from 'mobx'
import { curry } from 'ramda'

import {
  ServiceStore,
  StatisticModel,
  ActiveUsersStatisticsModel
} from 'internal'

const ReportsStore = ServiceStore.named('ReportsStore')
  .props({
    _statistics: types.optional(StatisticModel, {}),
    _activeUsersStatistics: types.optional(
      types.array(ActiveUsersStatisticsModel),
      []
    )
  })
  .actions(self => {
    const apiV1 = getEnv(self).apiV1
    const statisticsUrl = curry(url => '/api/StatisticsApi' + url)

    const getGroupCollectionStatistic = requestBody => {
      const prom = fromPromise(
        apiV1.post(statisticsUrl(`/GetGroupCollectionStatistic`), requestBody)
      )

      when(
        () => prom.state === FULFILLED,
        () =>
          prom.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                self._statistics = data
              })
            }
          })
      )

      return prom
    }

    const loadUserActivityStatistics = reqBody => {
      const prom = fromPromise(
        apiV1.post(statisticsUrl(`/GetActiveUsersStatistic`), reqBody)
      )

      when(
        () => prom.state === FULFILLED,
        () =>
          prom.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                self._activeUsersStatistics = data
              })
            }
          })
      )

      return prom
    }

    const clearStatistic = () => applySnapshot(self._statistics, {})

    const clearActiveUsersStatistics = () => {
      self._activeUsersStatistics = []
    }

    return {
      getGroupCollectionStatistic,
      clearStatistic,
      loadUserActivityStatistics,
      clearActiveUsersStatistics
    }
  })
  .views(self => ({
    get statistics() {
      return getSnapshot(self._statistics)
    },

    get activeUsersStatistics() {
      return getSnapshot(self._activeUsersStatistics)
    }
  }))

export default ReportsStore
