import { types } from "mobx-state-tree";

const DbSubmissionsModel = types.model("DbSubmissionsModel", {
  id: types.identifierNumber,
  problemId: types.maybeNull(types.number),
  problemName: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  status: types.optional(types.number, 0),
  submitDateTime: types.maybeNull(types.string),
  checkDateTime: types.maybeNull(types.string),
  isAccepted: types.optional(types.boolean, false),
  logText: types.maybeNull(types.string),
  submittedQuery: types.maybeNull(types.string),
  userResult: types.maybeNull(types.string),
  judgeTypeId: types.optional(types.number, 0)
});

export default DbSubmissionsModel;
