import React from 'react'
import classNames from 'classnames'
import t from 'prop-types'

import { utils } from 'internal'
import './Rank.scss'

const Rank = ({ level, color, tag: Tag, className, ...attributes }) => {
  const classes = classNames('sw-rank', 'sw-rank--' + color, className)
  return (
    <Tag className={classes} {...attributes}>
      {level}
    </Tag>
  )
}

Rank.propTypes = {
  tag: utils.tagPropType,
  className: t.string,
  color: t.oneOf(['green', 'orange', 'red']).isRequired,
  level: t.number.isRequired
}

Rank.defaultProps = {
  tag: 'div',
  color: 'green'
}

export default Rank
