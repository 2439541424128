import { useMemo } from 'react'
import * as msal from '@azure/msal-browser'

const MicrosoftLogin = ({
  render,
  clientId,
  redirectUri,
  scopes,
  onSuccess,
  onError
}) => {
  const msalInstance = useMemo(
    () =>
      new msal.PublicClientApplication({
        auth: {
          clientId,
          redirectUri
        }
      }),
    [clientId, redirectUri]
  )

  const logIn = async () => {
    try {
      const loginResponse = await msalInstance.loginPopup({
        scopes
      })
      onSuccess(loginResponse)
    } catch (error) {
      typeof onError === 'function' ? onError(error) : console.error(error)
    }
  }

  return render(logIn)
}

export default MicrosoftLogin
