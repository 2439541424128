import React from "react";
import t from "prop-types";
import classNames from "classnames";

import {utils} from 'internal';


const FilterBody = ({tag: Tag, className, ...attributes}) => {
  const classes = classNames(
    'filter__body',
    className
  );

  return (
    <Tag className={classes} {...attributes}/>
  );
};

FilterBody.propTypes = {
  tag: utils.tagPropType,
  className: t.string
};

FilterBody.defaultProps = {
  tag: 'li'
};

export default FilterBody;
