import { getEnv, types } from 'mobx-state-tree'
import { fromPromise, PENDING, REJECTED, FULFILLED } from 'mobx-utils'
import { when } from 'mobx'
import { curry } from 'ramda'

import { SuggestedSimilarProblemModel, RequestModel } from 'internal'

const SuggestedSimilarProblemAction = types
  .model({
    _problems: types.optional(
      types.model('PaginationGroups', {
        data: types.optional(types.array(SuggestedSimilarProblemModel), []),
        total: types.optional(types.number, 0)
      }),
      {}
    )
  })
  .actions(self => {
    const apiV1 = getEnv(self).apiV1

    const {
      GET_SUGGESTED_SIMILAR_PROBLEMS_ERROR,
      UPDATE_SUGGESTED_SIMILAR_PROBLEM_ERROR
    } = getEnv(self).KEYS

    const suggestedSimilarProblemUrl = curry(
      (url = '') => '/api/SuggestedSimilarProblemsApi' + url
    )

    const getSuggestedSimilarProblems = (languageId, page, pageSize) => {
      const promGetSuggestedSimilarProblems = fromPromise(
        apiV1.post(
          suggestedSimilarProblemUrl('/Get'),
          RequestModel.create({
            languageId,
            page,
            pageSize
          })
        )
      )

      when(
        () => promGetSuggestedSimilarProblems.state === PENDING,
        () => {
          promGetSuggestedSimilarProblems.case({
            pending: () => {
              self.runInAction(() => {
                self.errors.delete(GET_SUGGESTED_SIMILAR_PROBLEMS_ERROR)
              })
            }
          })
        }
      )
      when(
        () => promGetSuggestedSimilarProblems.state === REJECTED,
        () =>
          promGetSuggestedSimilarProblems.case({
            rejected: ({ response }) => {
              self.runInAction(() => {
                self.errors.set(GET_SUGGESTED_SIMILAR_PROBLEMS_ERROR, response)
              })
            }
          })
      )
      when(
        () => promGetSuggestedSimilarProblems.state === FULFILLED,
        () => {
          promGetSuggestedSimilarProblems.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                self._problems = data
              })
            }
          })
        }
      )

      return promGetSuggestedSimilarProblems
    }

    const updateSuggestedSimilarProblem = newProblem => {
      const promUpdateSuggestedProblem = fromPromise(
        apiV1.put(suggestedSimilarProblemUrl('/Put'), newProblem)
      )

      when(
        () => promUpdateSuggestedProblem.state === PENDING,
        () => {
          promUpdateSuggestedProblem.case({
            pending: () => {
              self.runInAction(() => {
                self.errors.delete(UPDATE_SUGGESTED_SIMILAR_PROBLEM_ERROR)
              })
            }
          })
        }
      )
      when(
        () => promUpdateSuggestedProblem.state === REJECTED,
        () =>
          promUpdateSuggestedProblem.case({
            rejected: ({ response }) => {
              self.runInAction(() => {
                self.errors.set(
                  UPDATE_SUGGESTED_SIMILAR_PROBLEM_ERROR,
                  response
                )
              })
            }
          })
      )

      return promUpdateSuggestedProblem
    }

    return {
      getSuggestedSimilarProblems,
      updateSuggestedSimilarProblem
    }
  })
  .views(self => ({
    get problems() {
      return self._problems
    }
  }))

export default SuggestedSimilarProblemAction
