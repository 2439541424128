import { types } from 'mobx-state-tree'

export default types.model('DbSubjectsModel', {
  id: types.optional(types.integer, 0),
  subjectName: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  comment: types.maybeNull(types.string),
  countProblems: types.optional(types.integer, 0),
  countDatabase: types.optional(types.integer, 0),
  judgeTypeName: types.array(
    types.model('JudgeTypes', {
      id: types.identifierNumber,
      name: types.maybeNull(types.string, ''),
      isDefault: types.optional(types.boolean, false)
    }),
    []
  ),
  isActive: types.optional(types.boolean, false)
})
