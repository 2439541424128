import { types } from 'mobx-state-tree'

export default types.model('TeacherGroupModel', {
  groupId: types.optional(types.integer, 0),
  groupName: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  enrollmentCode: types.maybeNull(types.string),
  studentsCount: types.optional(types.number, 0),
  institutionId: types.maybeNull(types.integer),
  institutionLogoLocation: types.maybeNull(types.string),
  groupImageLocation: types.maybeNull(types.string)
})
