import { types } from "mobx-state-tree";

import {
  ServiceStore,
  SuggestedSimilarProblemAction,
  SuggestedLinkAction
} from 'internal';

const SuggestedStore = ServiceStore.named("SuggestedStore");

export default types
  .compose(
    SuggestedStore,
    SuggestedSimilarProblemAction,
    SuggestedLinkAction
  )
  .named("SuggestedStore");
