import { getEnv, types, getSnapshot, getParent } from 'mobx-state-tree'
import { fromPromise, FULFILLED } from 'mobx-utils'
import { when } from 'mobx'
import { curry } from 'ramda'
import moment from 'moment'

import {
  ServiceStore,
  UserModel,
  LanguageModel,
  CountriesLookUpModel,
  PROVIDER_GOOGLE,
  PROVIDER_FACEBOOK,
  PROVIDER_MICROSOFT,
  config
} from 'internal'

const AuthStore = ServiceStore.named('AuthStore')
  .props({
    hydrated: false,
    _currentUser: types.optional(UserModel, {}),
    _languages: types.optional(types.array(LanguageModel), []),
    _externalProviders: types.optional(
      types.array(
        types.model('AuthStore_externalProviders', {
          authenticationType: types.string,
          caption: types.string
        })
      ),
      []
    ),
    _countries: types.optional(types.array(CountriesLookUpModel), [])
  })
  .actions(self => {
    const apiV1 = getEnv(self).apiV1
    const KEYS = getEnv(self).KEYS

    const accountApi = curry(url => '/api/AccountApi' + url)

    const afterHydration = () => (self.hydrated = true)

    function getLanguages() {
      const prom = fromPromise(apiV1.get(accountApi('/GetAllLanguage')))
      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._languages = resp.data
              })
            }
          })
        }
      )
      return prom
    }

    const checkIsAuth = () => {
      const user = JSON.parse(
        window.localStorage.getItem(KEYS.LS_KEY_PERSIST_AUTH_STORE)
      )

      return !!user && !!user._currentUser.accessToken
    }

    const getUserInfo = () => {
      const prom = fromPromise(apiV1.get(accountApi('/GetCurrentUserInfo')))

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self._currentUser.setInfo(resp.data)
            }
          })
        }
      )

      return prom
    }

    function getExternalAvailableProviders() {
      const prom = fromPromise(
        apiV1.get(accountApi('/GetExternalProvidersAvailable'))
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self._externalProviders = resp.data
            }
          })
        }
      )

      return prom
    }

    function getCountries() {
      const prom = fromPromise(apiV1.get(accountApi('/GetAllCountries')))

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._countries = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    const signinAsExternal = userData => {
      const formData = new FormData()
      for (const key in userData) {
        if (userData.hasOwnProperty(key) && userData[key]) {
          formData.append(key, userData[key])
        }
      }
      const prom = fromPromise(
        apiV1.post('/api/Token/GetInternalAccessTokenByExternal', formData)
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self._currentUser.setData(resp.data)
            }
          })
        }
      )

      return prom
    }

    const confirmEmail = data => {
      let prom = fromPromise(apiV1.post(accountApi('/ConfirmEmail'), data))
      return prom
    }

    const confirmEmailAgain = data => {
      let prom = fromPromise(apiV1.post(accountApi('/ConfirmEmailAgain'), data))
      return prom
    }

    const forgotPassword = data => {
      let prom = fromPromise(apiV1.post(accountApi('/ForgotPassword'), data))
      return prom
    }

    const newPassword = data => {
      let prom = fromPromise(apiV1.post(accountApi('/ResetPassword'), data))
      return prom
    }
    const signIn = data => {
      const prom = fromPromise(
        apiV1.post('/api/Token/GetInternalAccessToken', data)
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: response => {
              self._currentUser.setData(response.data)
            }
          })
        }
      )

      return prom
    }

    const signOut = () => {
      self._currentUser = UserModel.create({})
    }

    const signUp = data => {
      const prom = fromPromise(apiV1.post(accountApi('/Register'), data))
      return prom
    }

    const getUserPhoto = async imageUrl => {
      const blobFile = await new Promise(resolve => {
        const xhr = new XMLHttpRequest()
        xhr.open('GET', imageUrl)
        xhr.responseType = 'blob'
        xhr.onload = () => {
          resolve(xhr.response)
        }
        xhr.send()
      })
      return new File([blobFile], 'avatar.jpeg', {
        type: 'image/jpeg'
      })
    }

    const signinWithGoogle = async resp => {
      const profileInfo = resp.profileObj
      const [file, userDataResponse] = await Promise.all([
        getUserPhoto(profileInfo.imageUrl),
        fetch(
          `${config.GOOGLE_API_V1}/people/me?personFields=birthdays,locales`,
          {
            headers: {
              Authorization: `Bearer ${resp.accessToken}`
            }
          }
        )
      ])
      const { locales, birthdays } = await userDataResponse.json()
      const userPrimaryLanguage =
        locales && locales.find(({ metadata }) => metadata && metadata.primary)
      const userValidDateOfBirth =
        birthdays &&
        birthdays.find(
          ({ date }) => date && date.year && date.month && date.day
        )
      const userDateOfBirth = userValidDateOfBirth
        ? moment(
            new Date(
              userValidDateOfBirth.date.year,
              userValidDateOfBirth.date.month - 1,
              userValidDateOfBirth.date.day
            )
          )
            .utc(true)
            .toISOString()
        : null

      return signinAsExternal({
        provider: PROVIDER_GOOGLE,
        externalAccessToken: resp.accessToken,
        userName: profileInfo.email,
        userFirstName: profileInfo.givenName,
        userLastName: profileInfo.familyName,
        dateOfBirth: userDateOfBirth,
        defaultLanguage: userPrimaryLanguage ? userPrimaryLanguage.value : null,
        file
      })
    }

    const signinWithFacebook = async response => {
      const file = await getUserPhoto(
        response.picture && response.picture.data.url
      )
      return signinAsExternal({
        provider: PROVIDER_FACEBOOK,
        externalAccessToken: response.accessToken,
        userName: response.email,
        userFirstName: response.first_name,
        userLastName: response.last_name,
        file
      })
    }

    const signinWithMicrosoft = async ({ accessToken }) => {
      const headers = {
        Authorization: `Bearer ${accessToken}`
      }
      const [userInfoResponse, userPhotoResponse] = await Promise.all([
        fetch(`${config.MICROSOFT_GRAPH_API_V1}/me`, {
          headers
        }),
        fetch(`${config.MICROSOFT_GRAPH_API_V1}/me/photo/$value`, {
          headers
        })
      ])
      const {
        mail,
        givenName,
        surname,
        userPrincipalName,
        preferredLanguage
      } = await userInfoResponse.json()
      if (!mail && !userPrincipalName) throw Error()
      const userPhotoBlob = userPhotoResponse.ok
        ? await userPhotoResponse.blob()
        : null
      const file = userPhotoBlob
        ? new File([userPhotoBlob], 'avatar.jpeg', {
            type: 'image/jpeg'
          })
        : null

      return signinAsExternal({
        provider: PROVIDER_MICROSOFT,
        externalAccessToken: accessToken,
        userName: mail || userPrincipalName,
        userFirstName: givenName,
        userLastName: surname,
        defaultLanguage: preferredLanguage
          ? preferredLanguage.split('-')[0]
          : null,
        file
      })
    }

    return {
      signIn,
      signOut,
      signUp,
      getLanguages,
      confirmEmail,
      confirmEmailAgain,
      forgotPassword,
      newPassword,
      signinAsExternal,
      getUserInfo,
      getExternalAvailableProviders,
      afterHydration,
      checkIsAuth,
      getCountries,
      signinWithGoogle,
      signinWithFacebook,
      signinWithMicrosoft
    }
  })
  .views(self => ({
    get languagesList() {
      return getSnapshot(self._languages)
    },
    get currentUser() {
      return getSnapshot(self._currentUser)
    },
    get externalProviders() {
      return getSnapshot(self._externalProviders)
    },
    get isAuth() {
      return !!self._currentUser.accessToken || self.checkIsAuth()
    },
    get languageId() {
      const defaultLangId = 2
      if (!self._languages.length) return defaultLangId
      const currentLanguage = getParent(self).appStore.currentLanguage
      return getSnapshot(self._languages).find(
        lang => currentLanguage === lang.name
      ).id
    },
    get countries() {
      return getSnapshot(self._countries)
    }
  }))

export default AuthStore
