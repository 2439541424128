import React from "react";
import t from "prop-types";
import classNames from "classnames";

import { utils } from 'internal';
import TagItem from "./TagItem";
import "./Tags.scss";

const Tags = ({ tag: Tag, className, innerRef, ...attributes }) => {
  const classes = classNames("sw-tags", className);

  return <Tag ref={innerRef} className={classes} {...attributes} />;
};

Tags.propTypes = {
  tag: utils.tagPropType,
  className: t.string,
  innerRef: t.oneOfType([
    t.object,
    t.string,
    t.func,
  ]),
};

Tags.defaultProps = {
  tag: "ul"
};

Tags.Item = TagItem;

export default Tags;
