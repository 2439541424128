import { types, getEnv, getSnapshot } from 'mobx-state-tree'
import { when } from 'mobx'
import { fromPromise, FULFILLED } from 'mobx-utils'
import { curry } from 'ramda'

import {
  LinkForTagLookUpModel,
  ServiceStore,
  LinkModel,
  LinksTypesModel,
  RequestModel
} from 'internal'

const LinksStore = ServiceStore.named('LinksStore')
  .props({
    _linksLookUp: types.optional(types.array(LinkForTagLookUpModel), []),
    _links: types.optional(
      types.model('PaginationGroups', {
        data: types.optional(types.array(LinkModel), []),
        total: types.optional(types.number, 0)
      }),
      {}
    ),
    _linksTypes: types.optional(types.array(LinksTypesModel), [])
  })
  .actions(self => {
    const apiV1 = getEnv(self).apiV1

    const lookUpUrl = curry((url = '') => '/api/LinksLookUp' + url)
    const linksUrl = curry((url = '') => '/api/LinksApi' + url)
    const linksTypesUrl = curry((url = '') => '/api/LinksTypes' + url)

    const getLinksLookUp = () => {
      const prom = fromPromise(apiV1.get(lookUpUrl('/Get')))

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._linksLookUp = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    const getLinks = (page, pageSize, problemTypeId) => {
      const prom = fromPromise(
        apiV1.post(
          linksUrl('/Get'),
          RequestModel.create({
            page,
            pageSize
          }),
          problemTypeId
        )
      )

      when(
        () => prom.state === FULFILLED,
        () =>
          prom.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                self._links = data
              })
            }
          })
      )

      return prom
    }

    const getLinksTypes = () => {
      const prom = fromPromise(apiV1.get(linksTypesUrl('/Get')))

      when(
        () => prom.state === FULFILLED,
        () =>
          prom.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                self._linksTypes = data
              })
            }
          })
      )

      return prom
    }

    const addLinks = newLink => {
      const prom = fromPromise(apiV1.post(linksUrl('/Post'), newLink))
      return prom
    }

    const editLinks = (newLink, problemTypeId) =>
      fromPromise(apiV1.put(linksUrl('/Put'), { ...newLink, problemTypeId }))

    const deleteLinks = id => {
      const prom = fromPromise(
        apiV1.delete(linksUrl('/Delete'), { params: { id } })
      )

      return prom
    }

    return {
      getLinksLookUp,
      getLinks,
      getLinksTypes,
      addLinks,
      editLinks,
      deleteLinks
    }
  })
  .views(self => ({
    get linksLookUp() {
      return getSnapshot(self._linksLookUp).map(link => ({
        id: link.linkId,
        name: link.linkName
      }))
    },
    get links() {
      return getSnapshot(self._links)
    },
    get linksTypes() {
      return getSnapshot(self._linksTypes)
    }
  }))

export default LinksStore
