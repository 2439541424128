import { getEnv, types, getSnapshot } from 'mobx-state-tree'
import { fromPromise, FULFILLED } from 'mobx-utils'
import { when } from 'mobx'
import { curry } from 'ramda'

import { ServiceStore, LinkModel } from 'internal'

const DbRightBlockLinksStore = ServiceStore.named('DbRightBlockLinksStore')
  .props({
    _links: types.optional(types.array(LinkModel), [])
  })
  .actions(self => {
    const apiV1 = getEnv(self).apiV1

    const rightBlockLinksApi = curry(url => '/api/RightBlockLinksApi' + url)

    function getByProblemId(problemId, problemTypeId) {
      const prom = fromPromise(
        apiV1.get(rightBlockLinksApi('/Get'), {
          params: {
            id: problemId,
            problemTypeId
          }
        })
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._links = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    function create(_data) {
      const prom = fromPromise(apiV1.post(rightBlockLinksApi('/Post'), _data))
      return prom
    }

    return {
      getByProblemId,
      create
    }
  })
  .views(self => ({
    get links() {
      return getSnapshot(self._links)
    }
  }))

export default DbRightBlockLinksStore
