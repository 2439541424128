import { types, getEnv, getSnapshot } from 'mobx-state-tree'
import { when } from 'mobx'
import { fromPromise, FULFILLED } from 'mobx-utils'
import { curry } from 'ramda'

import { ServiceStore } from 'internal'

const ImageRepositoryStore = ServiceStore.named('ImageRepositoryStore')
  .props({
    _images: types.optional(
      types.array(
        types.model('Image', {
          id: types.optional(types.number, 0),
          location: types.optional(types.string, ''),
          originalName: types.optional(types.string, '')
        })
      ),
      []
    )
  })
  .actions(self => {
    const apiV1 = getEnv(self).apiV1
    const imageApi = curry(url => '/api/ImageApi' + url)

    const getTypeImages = (type = '') => {
      const prom = fromPromise(
        apiV1.get(imageApi('/GetUserFileLocations'), {
          params: { type }
        })
      )
      when(
        () => prom.state === FULFILLED,
        () =>
          prom.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                self._images = data
              })
            }
          })
      )
      return prom
    }

    const uploadNewImage = data => {
      const prom = fromPromise(
        apiV1.post(imageApi('/UploadImage'), data.formData)
      )
      return prom
    }

    const removeImage = id => {
      const prom = fromPromise(
        apiV1.delete(imageApi('/Delete'), {
          params: {
            id
          }
        })
      )
      return prom
    }

    return {
      getTypeImages,
      removeImage,
      uploadNewImage
    }
  })
  .views(self => ({
    get images() {
      return getSnapshot(self._images)
    }
  }))

export default ImageRepositoryStore
