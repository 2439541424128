import { types } from 'mobx-state-tree'

const DbSubjectJudgeTypeForProblemBusinessModel = types.model(
  'DbSubjectJudgeTypeForProblemBusinessModel',
  {
    id: types.identifierNumber,
    judgeTypeId: types.integer,
    judgeTypeName: types.string,
    ddlSchema: types.string
  }
)

export default DbSubjectJudgeTypeForProblemBusinessModel
