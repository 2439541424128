import { types } from 'mobx-state-tree'

const StudentsInGroupModel = types.model('StudentsInGroupModel', {
  studentId: types.identifier,
  studentName: '',
  studentFirstName: types.maybeNull(types.string),
  studentLastName: types.maybeNull(types.string),
  mimeType: types.maybeNull(types.string),
  studentPhoto: types.maybeNull(types.string)
})

export default StudentsInGroupModel
