import React from "react";
import classNames from "classnames";
import t from "prop-types";

import { utils } from 'internal';

const ListItem = ({
  tag: Tag,
  className,
  flexed,
  ...attributes
}) => {
  const classes = classNames(
    "sw-list__item",
    flexed && "sw-list__item--flexed",
    className
  );

  return <Tag className={classes} {...attributes} />;
};

ListItem.propTypes = {
  tag: utils.tagPropType,
  className: t.string,
  flexed: t.bool
};

ListItem.defaultProps = {
  tag: "li"
};

export default ListItem;
