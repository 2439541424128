import React, { PureComponent } from 'react'
import t from 'prop-types'
import AceEditor from 'react-ace'
import { CustomInput } from 'components'

import 'brace/theme/chrome'
import 'brace/ext/searchbox'
import 'brace/ext/language_tools'
import { Col } from 'react-bootstrap'

/* eslint-disable no-unused-expressions */
const modes = ['sqlserver', 'mysql', 'pgsql', 'sql', 'javascript']
modes.forEach(mode => {
  import(`brace/mode/${mode}`)
})

class CodeEditor extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      editorOptions: this.codeEditorSettings,
      mode: { value: 'sqlserver', label: 'sqlserver' },
      fontSize: { value: 14, label: 14 }
    }
  }

  get codeEditorSettings() {
    return {
      theme: 'chrome',
      name: 'codeEditor',
      height: '380px',
      width: '100%',
      showPrintMargin: true,
      showGutter: true,
      highlightActiveLine: true,
      editorProps: { $blockScrolling: Infinity },
      setOptions: {
        displayIndentGuides: false,
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        showLineNumbers: true,
        tabSize: 2
      },
      commands: [
        {
          name: 'Send',
          bindKey: { win: 'Ctrl-Enter' },
          exec: () => alert('Send code')
        }
      ]
    }
  }

  handleClickChangeMode = selected => this.setState({ mode: selected })

  handleClickChangeFontSize = selected => this.setState({ fontSize: selected })

  render() {
    const {
      handleClickChangeMode,
      handleClickChangeFontSize,
      state: { editorOptions, mode, fontSize },
      props: { value, onChange, ...props }
    } = this

    return (
      <div>
        <div className="d-flex flex-row mb-3 no-gutters">
          <Col xs={2} className="mr-2">
            <CustomInput
              value={[mode]}
              options={modes.map(mode => ({ value: mode, label: mode }))}
              type="select"
              onChange={handleClickChangeMode}
            />
          </Col>
          <Col xs={2}>
            <CustomInput
              value={[fontSize]}
              options={[12, 14, 16, 18].map(size => ({
                label: size,
                value: size
              }))}
              type="select"
              onChange={handleClickChangeFontSize}
            />
          </Col>
          <Col></Col>
        </div>
        <div>
          <AceEditor
            value={value}
            onChange={onChange}
            mode={mode.value}
            fontSize={`${fontSize.value}px`}
            {...editorOptions}
            {...props}
          />
        </div>
      </div>
    )
  }
}

CodeEditor.propTypes = {
  onChange: t.func.isRequired
}

export default CodeEditor
