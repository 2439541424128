export const SIGNIN_ERROR = 'sw-key-signin-error'
export const SIGNUP_ERROR = 'sw-key-signup-error'
export const CONFIRM_EMAIL_ERROR = 'sw-key-confirm-email-error'
export const CONFIRM_EMAIL_AGAIN_ERROR = 'sw-key-confirm-email-again-error'
export const FORGOT_PASSWORD_ERROR = 'sw-key-forgot-password-error'
export const NEW_PASSWORD_ERROR = 'sw-key-new-password-error'
export const EMAIL_FIELD_ERROR = 'sw-key-email-field-error'
export const PASSWORD_FIELD_ERROR = 'sw-key-password-field-error'
export const CONFIRM_PASSWORD_FIELD_ERROR =
  'sw-key-confirm-password-field-error'
export const SAVE_ERROR = 'sw-key-save-error'
export const CREATE_ERROR = 'sw-key-create-error'
export const CHANGE_PASSWORD_ERROR = 'sw-key-change-password-error'
export const ADD_USER_PHOTO_ERROR = 'sw-key-add-user-photo-error'
export const UPLOAD_IMAGE_ERROR = 'sw-key-upload-image-error'
export const GET_HINTS_ERROR = 'sw-key-get-hints-error'
export const GET_RIGHT_LINKS_ERROR = 'sw-key-get-right-links-error'
export const SIGNIN_AS_EXTERNAL_ERROR = 'sw-key-signin-external-error'
export const SIGNUP_AS_EXTERNAL_ERROR = 'sw-key-signup-external-error'

export const GET_DB_PROBLEM_ERROR = 'sw-key-get-db-problem-error'
export const GET_DB_PROBLEMS_ERROR = 'sw-key-get-db-problems-error'
export const GET_DB_SUBMISSION_DETAILS_ERROR =
  'sw-key-get-db-submission-details-error'

export const GET_DB_PROBLEM_TAGS_ERROR = 'sw-key-get-db-problem-tags-error'
export const GET_TAGS_NOT_ATTACHED_IN_DB_PROBLEM_ERROR =
  'sw-key-get-tags-not-attached-in-db-problem-error'
export const CREATE_TAG_ERROR = 'sw-key-create-tag-error'
export const EDIT_TAG_ERROR = 'sw-key-edit-tag-error'
export const DELETE_TAG_ERROR = 'sw-key-delete-tag-error'

export const SEND_SIMILAR_PROBLEM_ERROR = 'sw-key-send-similar-problem-error'
export const GET_SIMILAR_PROBLEMS_ERROR = 'sw-key-get-similar-problems-error'
export const GET_SIMILAR_PROBLEM_LOOK_UP_ERROR =
  'sw-key-get-similar-problem-look-up-error'

export const GET_DB_SUBMISSION_ERROR = 'sw-key-get-db-submission-error'
export const GET_DB_SUBMISSIONS_ERROR = 'sw-key-get-db-submissions-error'
export const SEND_PROBLEM_SUBMISSION_ERROR =
  'sw-key-send-problem-submission-error'
export const GET_DB_SUBMISSION_USER_PROBLEM_ERROR =
  'sw-key-get-db-submission-user-problem-error'
export const ADD_DB_SUBMISSION_USER_COMMENT_ERROR =
  'sw-key-add-db-submission-user-comment-error'
export const CREATE_DB_SUBMISSION_ERROR = 'sw-key-create-db-submission-error'

export const SEND_TAG_FOR_ATTACH_DB_PROBLEM_ERROR =
  'sw-key-send-tag-for-attach-db-problem-error'

export const GET_DB_PROBLEMS_TAGS_ERROR = 'sw-key-get-db-problems-tags-error'
export const GET_SUGGESTED_SIMILAR_PROBLEMS_ERROR =
  'sw-key-get-suggested-similar-problems-error'
export const GET_SUGGESTED_LINKS_ERROR = 'sw-key-get-suggested-links-error'

export const UPDATE_DB_PROBLEMS_TAG_ERROR =
  'sw-key-update-db-problems-tag-error'
export const UPDATE_SUGGESTED_SIMILAR_PROBLEM_ERROR =
  'sw-key-update-suggested-similar-problem-error'
export const UPDATE_SUGGESTED_LINK_ERROR = 'sw-key-update-suggested-link-error'
export const GET_SUBJECT_JUDGE_TYPES_ERROR =
  'sw-key-get-subject-judge-types-error'
export const GET_JUDGE_TYPES_ERROR = 'sw-key-get-judge-types-error'

export const GET_DB_PROBLEMS_FOR_TAG_LOOK_UP_ERROR =
  'sw-key-get-db-problems-for-tag-look-up-error'

export const EDIT_DB_SUBJECT_TRANSLATE_ERROR =
  'sw-key-edit-db-subject-translate-model-error'
export const EDIT_DB_SUBJECT_DIAGRAM_ERROR =
  'sw-key-edit-db-subject-diagram-error'
export const ADD_DB_SUBJECT_DIAGRAM_ERROR =
  'sw-key-add-db-subject-diagram-error'
export const EDIT_DB_SUBJECT_ERROR = 'sw-key-edit-db-subject-error'
export const EDIT_DB_SUBJECT_HINT_ERROR = 'sw-key-edit-db-subject-hint-error'
export const EDIT_DB_SUBJECT_JUDGE_TYPE_ERROR =
  'sw-key-edit-db-subject-judge-type-error'
export const EDIT_DB_SUBJECT_DATABASES_AND_DATA_CODE_ERROR =
  'sw-key-edit-db-subject-databases-and-data-code-error'
export const EDIT_DB_SUBJECT_SECURITY_PROFILE_ERROR =
  'sw-key-edit-db-subject-security-error'
export const GET_DB_SUBJECT_SECURITY_PROFILE_ERROR =
  'sw-key-get-db-subject-security-error'
export const DELETE_DB_SUBJECT_ERROR = 'sw-key-delete-db-subject-error'
export const DELETE_DB_SUBJECT_DIAGRAM_ERROR =
  'sw-key-delete-db-subject-diagram-error'
export const CREATE_SUBJECT_DB_ERROR = 'sw-key-create-subject-db-error'

export const DELETE_DB_PROBLEM_ERROR = 'sw-key-delete-db-problem-error'
export const GET_DB_PROBLEM_LOOKUP_ERROR = 'sw-key-db-problem-lookup-error'

export const UPDATE_DB_PROBLEMS_TAG_LOADING =
  'sw-key-update-suggested-tag-loading'
export const UPDATE_SUGGESTED_SIMILAR_PROBLEM_LOADING =
  'sw-key-update-suggested-similar-problem-loading'
export const UPDATE_SUGGESTED_LINK_LOADING =
  'sw-key-update-suggested-link-loading'

export const COMMON_LOADING = 'common-loading'
export const GET_DB_SUBMISSION_LOADING = 'get-db-submission-loading'
export const GET_DB_SUBMISSIONS_LOADING = 'get-db-submissions-loading'
export const GET_DB_SUBMISSION_DETAIL_LOADING =
  'get-db-submission-detail-loading'
export const GET_PROBLEM_LOADING = 'get-problem-loading'
export const GET_ALGO_PROBLEM_LOADING = 'get-algo-problem-loading'
export const GET_DB_SUBMISSION_USER_PROBLEM_LOADING =
  'get-db-submission-user-problem-loading'
export const GET_SUBMISSION_LOADING = 'get-submission-loading'
export const GET_SIMILAR_PROBLEMS_LOADING = 'get-similar-problems-loading'
export const GET_SIMILAR_PROBLEM_ERROR = 'sw-key-get-similar-problem-loading'
export const CREATE_SIMILAR_PROBLEM_ERROR =
  'sw-key-create-similar-problem-loading'
export const GET_HINTS_LOADING = 'get-hints-loading'
export const GET_LINKS_LOADING = 'get-links-loading'
export const GET_TAGS_LOADING = 'get-tags-loading'
export const GET_LANGUAGES_LOADING = 'get-languages-loading'

export const GET_TAGS_NOT_ATTACHED_IN_DB_PROBLEM_LOADING =
  'get-tags-not-attached-in-db-problem-loading'
export const GET_SIMILAR_PROBLEM_LOOK_UP_LOADING =
  'get-similar-problem-look-up-loading'
export const GET_SUBJECT_JUDGE_TYPES_LOADING =
  'sw-key-get-subject-judge-types-loading'
export const GET_SUBJECT_LOADING = 'get-subject-loading'
export const SAVE_SUBJECT_AREA_LOADING = 'sw-key-save-subject-area-loading'
export const SAVE_ENGINES_LOADING = 'sw-key-save-engines-loading'
export const GET_DIAGRAMS_AND_DATABASES_LOADING =
  'sw-key-get-diagrams-and-databases-loading'
export const SEND_SIMILAR_PROBLEM_LOADING =
  'sw-key-send-similar-problem-loading'
export const SEND_TAG_FOR_ATTACH_IN_DB_PROBLEM_LOADING =
  'sw-key-send-tag-for-attach-in-db-problem-loading'
export const SEND_DB_PROBLEM_RATING_LOADING =
  'sw-key-send-db-problem-rating-loading'
export const SEND_DB_PROBLEM_COMMENT_LOADING =
  'sw-key-send-db-problem-comment-loading'
export const GET_COLLECTION_LOADING = 'get-collection-loading'
export const GET_USER_ROLES_LOADING = 'sw-key-get-user-roles-loading'

export const CREATE_RIGHT_BLOCK_LINK_ERROR =
  'sw-key-create-right-block-link-error'

export const LS_KEY_PERSIST_AUTH_STORE = 'sw-ls-key-persist-auth-store'
export const LS_KEY_PERSIST_APP_STORE = 'sw-ls-key-persist-app-store'
export const LS_KEY_PERSIST_PROBLEMS_UI_STORE =
  'sw-ls-key-persist-problems-ui-filter-store'
export const LS_KEY_PERSIST_DB_PROBLEM_SUBMISSIONS_UI_STORE =
  'sw-ls-key-persist-db-problem-submissions-ui-filter-store'
export const LS_KEY_PERSIST_PAGINATION_UI_STORE =
  'sw-ls-key-persist-pagination-ui-store'
export const LOCAL_STORAGE_KEY_PERSIST_ADMIN_FILTER = 'sw-ls-key-persist-admin-filter'
export const LOCAL_STORAGE_KEY_SANDBOX_DB_PROBLEM_WIDGETS = 'sandboxDbWidgets'
export const LOCAL_STORAGE_KEY_DB_PROBLEM_WIDGETS = 'dbWidgets'

export const SEND_COLLECTION = 'sw-key-send-collection'
export const SEND_GROUP = 'sw-key-send-group'
export const SEND_TAG = 'sw-key-send-tag'
export const SEND_PROBLEM = 'sw-key-send-problem'
export const SEND_SOLUTION = 'sw-key-send-solution'

export const DELETE_TEST_CASE_ERROR = 'sw-key-delete-test-case-error'

export const GET_ALGO_SUBMISSIONS_LOADING = 'get-algo-submissions-loading'
export const GET_ALGO_SUBMISSION_LOADING = 'get-algo-submission-loading'
export const GET_ALGO_SUBMISSION_DETAIL_LOADING =
  'get-algo-submission-detail-loading'
export const GET_ALGO_SUBMISSIONS_ERROR = 'sw-key-get-algo-submissions-error'

export const GET_GROUP_COLLECTION_STATISTIC_LOADING =
  'get-group-collection-statistic-loading'
