import { types, getEnv, getSnapshot } from 'mobx-state-tree'
import { fromPromise, FULFILLED } from 'mobx-utils'
import { when } from 'mobx'
import { curry } from 'ramda'

import {
  DbProblemSubmissionModel,
  ServiceStore,
  DbProblemSubmissionsFilterModel,
  DbProblemSubmissionsAuthorFilterModel,
  DbSubmissionModel,
  SandboxDbDetailSubmissionModel
} from 'internal'

const SandboxDbSubmissionStore = ServiceStore.named('SandboxDbSubmissionStore')
  .props({
    _sandboxDbSubmissions: types.optional(
      types.model('Paginate', {
        data: types.optional(types.array(DbProblemSubmissionModel), []),
        total: types.optional(types.integer, 0)
      }),
      {}
    ),
    _sandboxDbSubmissionDetails: types.optional(
      SandboxDbDetailSubmissionModel,
      {}
    ),
    _prevSandboxDbSubmissions: types.optional(
      types.array(DbSubmissionModel),
      []
    ),
    _submission: types.optional(
      types.model('SandboxDbSubmissionStore__submission', {
        solution: '',
        logText: types.maybeNull(types.string),
        userResult: types.frozen(),
        judgeType: types.maybeNull(types.integer, 0)
      }),
      {}
    )
  })
  .actions(self => {
    const apiV1 = getEnv(self).apiV1

    const sandboxSubmissionApi = curry(url => '/api/SandboxSubmissionApi' + url)

    const getStudentDbSubmissionList = filter => {
      const prom = fromPromise(
        apiV1.post(
          sandboxSubmissionApi('/GetStudentDbSubmissionsList'),
          DbProblemSubmissionsFilterModel.create(filter)
        )
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._sandboxDbSubmissions = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    const getTeacherDbSubmissionList = filter => {
      const prom = fromPromise(
        apiV1.post(
          sandboxSubmissionApi('/GetTeacherDbSubmissionsList'),
          DbProblemSubmissionsFilterModel.create(filter)
        )
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._sandboxDbSubmissions = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    const getAuthorDbSubmissionList = filter => {
      const prom = fromPromise(
        apiV1.post(
          sandboxSubmissionApi('/GetAuthorDbSubmissionsList'),
          DbProblemSubmissionsAuthorFilterModel.create(filter)
        )
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._sandboxDbSubmissions = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    const getDetailSandboxDbSubmission = (submissionId, languageId) => {
      const prom = fromPromise(
        apiV1.get(
          sandboxSubmissionApi(
            `/DetailsSubmissions?submissionId=${submissionId}&languageId=${languageId}`
          )
        )
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._sandboxDbSubmissionDetails = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    function getByProblemId(problemId) {
      const prom = fromPromise(
        apiV1.get(
          sandboxSubmissionApi(
            `/GetDbSubmissionsByProblemId?problemId=${problemId}`
          )
        )
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._prevSandboxDbSubmissions = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    function getBySubmissionId(submissionId) {
      const prom = fromPromise(
        apiV1.get(
          sandboxSubmissionApi(
            `/GetDbSubmissionsBySubmissionId?submissionId=${submissionId}`
          )
        )
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._prevSandboxDbSubmissions = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    function getSubmissionByRole(subId, userRole) {
      const prom = fromPromise(
        apiV1.get(sandboxSubmissionApi('/GetSubmissionByRole'), {
          params: {
            submissionId: subId,
            role: userRole
          }
        })
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._submission = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    return {
      getStudentDbSubmissionList,
      getTeacherDbSubmissionList,
      getAuthorDbSubmissionList,
      getDetailSandboxDbSubmission,
      getByProblemId,
      getBySubmissionId,
      getSubmissionByRole
    }
  })
  .views(self => ({
    get sandboxDbSubmissions() {
      return getSnapshot(self._sandboxDbSubmissions)
    },
    get prevSandboxDbSubmissions() {
      return getSnapshot(self._prevSandboxDbSubmissions)
    },
    get sandboxDbSubmissionDetails() {
      return getSnapshot(self._sandboxDbSubmissionDetails)
    },
    get submission() {
      return getSnapshot(self._submission)
    }
  }))

export default SandboxDbSubmissionStore
