import React from 'react'
import { observer } from 'mobx-react'
import t from 'prop-types'

import { Column, Row, Form, Label, CustomInput, FormFeedback } from '../index'

const SimpleAsyncCreatableSelect = observer(
  ({ field, form, isRequired, fluid, children, ...props }) => {
    const formValidated = form.validated > 0
    return (
      <Form.Group>
        <Row
          style={fluid ? { flexDirection: 'column' } : {}}
        >
          <Column col={fluid ? 12 : 3}>
            <Label className="center-label" for={field.id}>
              {field.label}
              {isRequired && <span className="required">*</span>}
            </Label>
          </Column>
          <Column fluid>
            <CustomInput
              {...field.bind()}
              type="async-creatable-select"
              valid={formValidated && field.isValid}
              invalid={formValidated && field.hasError}
              {...props}
            />
            <FormFeedback invalid={formValidated && field.hasError}>
              {field.error}
            </FormFeedback>
          </Column>
          {children}
        </Row>
      </Form.Group>
    )
  }
)

SimpleAsyncCreatableSelect.propTypes = {
  field: t.any.isRequired,
  form: t.any.isRequired,
  isRequired: t.bool
}

export default SimpleAsyncCreatableSelect
