import { types, applySnapshot, getSnapshot, getParent } from 'mobx-state-tree'

import {
  BaseUIStore,
  ProblemsFilterModel,
  UserSubmissionModel,
  SortingModel,
  AUTH_TEACHER_ROLE,
  AUTH_AUTHOR_ROLE,
  DbProblemAuthorFilterModel
} from 'internal'

const ProblemsUIStore = BaseUIStore.named('ProblemsUIStore')
  .props({
    hydrated: false,
    _userSubmission: types.optional(UserSubmissionModel, {}),
    _filterDbStudent: types.optional(ProblemsFilterModel, {}),
    _filterDbTeacher: types.optional(ProblemsFilterModel, {}),
    _filterSandboxDbStudent: types.optional(ProblemsFilterModel, {}),
    _filterSandboxDbTeacher: types.optional(ProblemsFilterModel, {}),
    _filterAlgoStudent: types.optional(ProblemsFilterModel, {}),
    _filterAlgoTeacher: types.optional(ProblemsFilterModel, {}),
    _sortProblem: types.optional(SortingModel, {
      field: 'date',
      direction: 'desc'
    }),
    _filterDbAuthor: types.optional(ProblemsFilterModel, {}),
    _filterSandboxDbAuthor: types.optional(ProblemsFilterModel, {}),
    _filterAlgoAuthor: types.optional(ProblemsFilterModel, {}),
    _editProblemId: types.maybeNull(types.integer)
  })
  .actions(self => {
    const afterHydration = () => (self.hydrated = true)

    const getUserRole = () => {
      const { appStore } = getParent(self)
      return appStore.currentRole.role
    }
    const checkIsTeacher = () => self.getUserRole() === AUTH_TEACHER_ROLE
    const checkIsAuthor = () => getUserRole() === AUTH_AUTHOR_ROLE

    const resetFilter = isDbProblemType => {
      return isDbProblemType
        ? applySnapshot(
            checkIsTeacher() ? self._filterDbTeacher : self._filterDbStudent,
            self.emptyFilter
          )
        : applySnapshot(
            checkIsTeacher()
              ? self._filterAlgoTeacher
              : self._filterAlgoStudent,
            self.emptyFilter
          )
    }

    const setUserSubmission = (newUserSubmission = {}) => {
      self._userSubmission = UserSubmissionModel.create(newUserSubmission)
    }

    const applyFilter = (newFilterValue, currentSectionId) => {
      switch (currentSectionId) {
        case 2:
          if (checkIsTeacher())
            applySnapshot(self._filterAlgoTeacher, newFilterValue)
          else if (checkIsAuthor())
            applySnapshot(self._filterAlgoAuthor, newFilterValue)
          else applySnapshot(self._filterAlgoStudent, newFilterValue)
          break
        case 3:
          if (checkIsTeacher())
            applySnapshot(self._filterSandboxDbTeacher, newFilterValue)
          else if (checkIsAuthor())
            applySnapshot(self._filterSandboxDbAuthor, newFilterValue)
          else applySnapshot(self._filterSandboxDbStudent, newFilterValue)
          break
        default:
          if (checkIsTeacher())
            applySnapshot(self._filterDbTeacher, newFilterValue)
          else if (checkIsAuthor())
            applySnapshot(self._filterDbAuthor, newFilterValue)
          else applySnapshot(self._filterDbStudent, newFilterValue)
          break
      }
    }

    function changeFilter(sort, callBackFilter) {
      const [field, type] = sort.split('.')

      if (field === 'rating') {
        if (type === 'desc') {
          return callBackFilter('rating', 'desc')
        }

        return callBackFilter('rating', 'asc')
      }
      if (field === 'complexity') {
        if (type === 'desc') {
          return callBackFilter('complexity', 'desc')
        }

        return callBackFilter('complexity', 'asc')
      }
      if (field === 'date') {
        if (type === 'desc') {
          return callBackFilter('date', 'desc')
        }

        return callBackFilter('date', 'asc')
      }
    }

    function problemsFilter(sort) {
      return changeFilter(sort, (field, direction) => {
        if (checkIsTeacher())
          self._filterDbTeacher.sorting = { field, direction }
        else if (checkIsAuthor())
          self._filterDbAuthor.sorting = { field, direction }
        else self._filterDbStudent.sorting = { field, direction }
      })
    }

    const applyAuthorFilter = (newFilterValue, isDbProblemType) => {
      return isDbProblemType
        ? applySnapshot(self._filterDbAuthor, newFilterValue)
        : applySnapshot(self._filterAlgoAuthor, newFilterValue)
    }

    const setEditProblemId = problemId => {
      self._editProblemId = problemId
    }

    return {
      afterHydration,
      setUserSubmission,
      resetFilter,
      getUserRole,
      checkIsTeacher,
      applyFilter,
      problemsFilter,
      applyAuthorFilter,
      setEditProblemId
    }
  })
  .views(self => ({
    get userSubmission() {
      return getSnapshot(self._userSubmission)
    },
    get filterDbTeacher() {
      return getSnapshot(self._filterDbTeacher)
    },
    get filterDbStudent() {
      return getSnapshot(self._filterDbStudent)
    },
    get filterDbAuthor() {
      return getSnapshot(self._filterDbAuthor)
    },
    get filterAlgoTeacher() {
      return getSnapshot(self._filterAlgoTeacher)
    },
    get filterAlgoStudent() {
      return getSnapshot(self._filterAlgoStudent)
    },
    get filterAlgoAuthor() {
      return getSnapshot(self._filterAlgoAuthor)
    },
    get filterSandboxDbTeacher() {
      return getSnapshot(self._filterSandboxDbTeacher)
    },
    get filterSandboxDbStudent() {
      return getSnapshot(self._filterSandboxDbStudent)
    },
    get filterSandboxDbAuthor() {
      return getSnapshot(self._filterSandboxDbAuthor)
    },
    get emptyFilter() {
      return getSnapshot(ProblemsFilterModel.create())
    },
    get emptyFilterAuthor() {
      return getSnapshot(DbProblemAuthorFilterModel.create())
    },
    get sortProblem() {
      return getSnapshot(self._filterDbAuthor.sorting)
    },
    get editProblemId() {
      return self._editProblemId
    },
    getOptions(intl) {
      return [
        {
          value: 'rating.desc',
          label: intl.formatMessage({
            id: 'problem.page.sort.rating.desc',
            defaultMessage: 'Рейтинг (по убыванию)'
          })
        },
        {
          value: 'rating.asc',
          label: intl.formatMessage({
            id: 'problem.page.sort.rating.asc',
            defaultMessage: 'Рейтинг (по возрастанию)'
          })
        },
        {
          value: 'complexity.desc',
          label: intl.formatMessage({
            id: 'problem.page.sort.complexity.desc',
            defaultMessage: 'Сложность (по убыванию)'
          })
        },
        {
          value: 'complexity.asc',
          label: intl.formatMessage({
            id: 'problem.page.sort.complexity.asc',
            defaultMessage: 'Сложность (по возрастанию)'
          })
        },
        {
          value: 'date.desc',
          label: intl.formatMessage({
            id: 'problem.page.sort.date.desc',
            defaultMessage: 'Дата добавления: (по убыванию)'
          })
        },
        {
          value: 'date.asc',
          label: intl.formatMessage({
            id: 'problem.page.sort.date.asc',
            defaultMessage: 'Дата добавления: (по возрастанию)'
          })
        }
      ]
    }
  }))

export default ProblemsUIStore
