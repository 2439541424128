const API_V1_URL = process.env.REACT_APP_API_URL

if (!API_V1_URL) {
  console.error('ERROR API_V1_URL')
  throw new Error('Error: you not set API_V1_URL')
}

const MOUNT_FILTER_ID = 'filters'

// google app
const GOOGLE_CLIENT_ID =
  '796763910673-hiniejcsi3g9je3m730mi9mkr99ffftu.apps.googleusercontent.com'
const GOOGLE_CLIENT_SECRET = '6VGFywJeDf8r2EQSfy_sHvKb'
const GOOGLE_API_V1 = 'https://people.googleapis.com/v1'

// theFacebook app
const FACEBOOK_APP_ID = '720945331573120'
const FACEBOOK_CLIENT_SECRET = 'f9b8681458f34fcbf45ea504617d8a9a'

// microsoft app
const MICROSOFT_CLIENT_ID = 'bfce4774-e33c-4d42-b49f-d510f44d0b0a'
const MICROSOFT_GRAPH_API_V1 = 'https://graph.microsoft.com/v1.0'

export {
  API_V1_URL,
  MOUNT_FILTER_ID,
  GOOGLE_CLIENT_ID,
  GOOGLE_CLIENT_SECRET,
  GOOGLE_API_V1,
  FACEBOOK_APP_ID,
  FACEBOOK_CLIENT_SECRET,
  MICROSOFT_CLIENT_ID,
  MICROSOFT_GRAPH_API_V1
}
