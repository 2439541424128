import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Row } from 'react-bootstrap'
import { STUDENT_PROFILE_ROUTE, utils } from 'internal'
import NoneUserAvatar from '../../assets/img/none-user-avatar.png'
import ReactTooltip from 'react-tooltip'
import './TableStatistic.scss'

export const Body = ({
  userData,
  submissionsRoute,
  onLinkToFilterClick,
  group,
  collection
}) => {
  const apiUrl = process.env.REACT_APP_API_URL
  return (
    <tbody>
      {userData.map(user => (
        <tr key={user.userId}>
          <td className="stickyColumn table-cell-static-height">
            <Link
              to={STUDENT_PROFILE_ROUTE.replace(':studentId', user.userId)}
              target="_blank"
            >
              <Row className="static-height-50px d-flex align-items-center pl-2 py-2">
                <img
                  alt="avatar"
                  className="sw-profile ml-4 mr-2"
                  src={
                    !user.avatarLocation
                      ? NoneUserAvatar
                      : apiUrl.concat(utils.toSmallPhoto(user.avatarLocation))
                  }
                />
                <span data-tip data-for={user.userId} className="user-name">
                  {user.usersName || '-'}
                </span>
              </Row>
            </Link>
            <ReactTooltip place="right" effect="solid" id={user.userId}>
              <span>{user.usersName}</span>
            </ReactTooltip>
          </td>
          {user.problems.map(problem => (
            <Fragment key={problem.problemName}>
              <td className="align-middle table-cell-static-height">
                {problem.isAccepted ? (
                  <Link
                    to={submissionsRoute}
                    target="_blank"
                    onClick={() =>
                      onLinkToFilterClick({
                        groupList: [group],
                        collectionIdList: [collection.collectionId],
                        studentList: [
                          {
                            dbStudentId: user.userId,
                            dbStudentName: user.usersName
                          }
                        ],
                        correct: true,
                        problemCode: problem.problemCode
                      })
                    }
                  >
                    {problem.isAccepted}
                  </Link>
                ) : (
                  problem.isAccepted
                )}
              </td>
              <td className="align-middle table-cell-static-height">
                {problem.attempts ? (
                  <Link
                    to={submissionsRoute}
                    target="_blank"
                    onClick={() =>
                      onLinkToFilterClick({
                        groupList: [group],
                        collectionIdList: [collection.collectionId],
                        studentList: [
                          {
                            dbStudentId: user.userId,
                            dbStudentName: user.usersName
                          }
                        ],
                        problemCode: problem.problemCode
                      })
                    }
                  >
                    {problem.attempts}
                  </Link>
                ) : (
                  problem.attempts
                )}
              </td>
            </Fragment>
          ))}
          <td className="align-middle table-cell-static-height">
            {user.totalAccepted ? (
              <Link
                to={submissionsRoute}
                target="_blank"
                onClick={() =>
                  onLinkToFilterClick({
                    groupList: [group],
                    collectionIdList: [collection.collectionId],
                    correct: true,
                    studentList: [
                      {
                        dbStudentId: user.userId,
                        dbStudentName: user.usersName
                      }
                    ]
                  })
                }
              >
                {user.totalAccepted}
              </Link>
            ) : (
              user.totalAccepted
            )}
          </td>
          <td className="align-middle table-cell-static-height">
            <Link
              to={submissionsRoute}
              target="_blank"
              onClick={() =>
                onLinkToFilterClick({
                  groupList: [group],
                  collectionIdList: [collection.collectionId],
                  studentList: [
                    {
                      dbStudentId: user.userId,
                      dbStudentName: user.usersName
                    }
                  ]
                })
              }
            >
              {user.totalAttempts}
            </Link>
          </td>
        </tr>
      ))}
    </tbody>
  )
}
