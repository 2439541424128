import React from 'react';
import t from 'prop-types';
import classNames from 'classnames';

import {utils} from 'internal';

const CardStatus = (props) => {
  const {tag: Tag, className, ...attributes} = props;
  const classes = classNames('sw-card-status', className);

  return(
    <Tag className={classes} {...attributes}/>
  );
};

CardStatus.propTypes = {
  tag: utils.tagPropType,
  className: t.string
};

CardStatus.defaultProps = {
  tag: 'div'
};

export default CardStatus;
