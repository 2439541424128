import { types } from 'mobx-state-tree'

export default types.model('DbTagsBusinessModel', {
  id: types.optional(types.identifierNumber, 0),
  tagName: types.optional(types.string, ''),
  description: types.maybeNull(types.string, ''),
  problemType: types.optional(types.integer, 0),
  linkLookUp: types.maybeNull(
    types.array(
      types.model('DbTagsBusinessModel__LinkLookUp', {
        id: types.optional(types.identifierNumber, 0),
        name: types.maybeNull(types.string)
      })
    ),
    []
  ),
  problemLookUp: types.maybeNull(
    types.array(
      types.model('DbTagsBusinessModel__ProblemLookUp', {
        id: types.optional(types.identifierNumber, 0),
        problemName: types.maybeNull(types.string),
        problemCode: types.maybeNull(types.string)
      })
    ),
    []
  )
})
