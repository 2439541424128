import { getEnv, getSnapshot, types } from 'mobx-state-tree'
import { fromPromise, FULFILLED } from 'mobx-utils'
import { when } from 'mobx'
import { curry } from 'ramda'

import {
  ServiceStore,
  DbRejudgeBatchesModel,
  RequestModel,
  RejudgeBatchesLookUpModel,
  DetailedLogModel
} from 'internal'

// FIXME: FIX discuss pagination on this page
const PAGESIZE = 10000

export default ServiceStore.named('RejudgeStore')
  .props({
    _rejudges: types.optional(
      types.model('PaginationRejudge', {
        data: types.optional(types.array(DbRejudgeBatchesModel), []),
        total: 0
      }),
      {}
    ),
    _rejudgeReport: types.maybeNull(DbRejudgeBatchesModel),
    _rejudgeLog: types.optional(
      types.model('RejudgePagination', {
        data: types.optional(types.array(DetailedLogModel), []),
        total: types.integer
      }),
      {
        data: [],
        total: PAGESIZE
      }
    ),
    _rejudgeBatchesLookUps: types.optional(
      types.model('RejudgeBatchesLookUps', {
        data: types.optional(types.array(RejudgeBatchesLookUpModel), []),
        total: types.optional(types.number, 0)
      }),
      {}
    )
  })
  .actions(self => {
    const apiV1 = getEnv(self).apiV1
    const rejudgeApi = curry(url => '/api/DbRejudgeApi' + url)
    const rejudgeBatchLookUpApi = curry(
      url => '/api/RejudgeBatchesLookUp' + url
    )

    function getList(languageId, page, pageSize) {
      const prom = fromPromise(
        apiV1.post(rejudgeApi('/Get'), {
          ...RequestModel.create({
            languageId,
            page,
            pageSize
          }),
          problemTypeId: 1
        })
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._rejudges = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    function create(newRejudge) {
      return fromPromise(apiV1.post(rejudgeApi('/Post'), newRejudge))
    }

    function update(newRejudge) {
      return fromPromise(apiV1.put(rejudgeApi('/Put'), newRejudge))
    }

    function remove(rejudgeId) {
      return fromPromise(
        apiV1.delete(rejudgeApi('/Delete'), {
          params: {
            id: rejudgeId
          }
        })
      )
    }

    function runRejudge(rejudgeId) {
      return fromPromise(
        apiV1.get(rejudgeApi('/RunRejudgeBatch'), {
          params: {
            id: rejudgeId
          }
        })
      )
    }

    function selectRejudge(newRejudge) {
      self._rejudgeReport = newRejudge
    }

    function getRejudgeLog(rejudgeId, languageId) {
      const prom = fromPromise(
        apiV1.post(
          rejudgeApi('/GetDetailedLog'),
          RequestModel.create({
            entityId: rejudgeId,
            languageId,
            page: 1,
            pageSize: PAGESIZE
          })
        )
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._rejudgeLog = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    const rejudgeBatchesLookUp = reqBody => {
      const prom = fromPromise(
        apiV1.post(rejudgeBatchLookUpApi('/GetAllRejudgeBatches'), reqBody)
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                if (reqBody.page === 1) self._rejudgeBatchesLookUps = data
                else
                  self._rejudgeBatchesLookUps.data = self._rejudgeBatchesLookUps.data.concat(
                    data.data
                  )
              })
            }
          })
        }
      )

      return prom
    }

    return {
      getList,
      create,
      update,
      remove,
      runRejudge,
      getRejudgeLog,
      rejudgeBatchesLookUp,
      selectRejudge
    }
  })
  .views(self => ({
    get rejudges() {
      return getSnapshot(self._rejudges)
    },
    get rejudgeReport() {
      return self._rejudgeReport
    },
    get rejudgeLog() {
      return getSnapshot(self._rejudgeLog)
    },
    get rejudgeBatchesLookUps() {
      return getSnapshot(self._rejudgeBatchesLookUps)
    }
  }))
