import React from 'react'
import t from 'prop-types'
import classNames from 'classnames'

import { utils } from 'internal'
import Thumb from './Thumb'
import './Thumbnails.scss'

const Thumbnails = ({ tag: Tag, className, ...props }) => {
  const classes = classNames('sw-thumbnails', className)

  return <Tag className={classes} {...props} />
}

Thumbnails.propTypes = {
  tag: utils.tagPropType,
  className: t.string
}

Thumbnails.defaultProps = {
  tag: 'div'
}

Thumbnails.Thumb = Thumb

export default Thumbnails
