import { getEnv, types, getSnapshot } from 'mobx-state-tree'
import { fromPromise, FULFILLED, REJECTED } from 'mobx-utils'
import { when } from 'mobx'
import { curry } from 'ramda'

import {
  ServiceStore,
  AlgoProblemModel,
  AlgoCompilerModel,
  AlgoSubmissionModel,
  AlgoProblemSolutionModel,
  AlgoProblemSubmissionModel,
  AlgoProblemSubmissionsFilterModel,
  AlgoProblemSubmissionsAuthorFilterModel
} from 'internal'

export default ServiceStore.named('AlgoSubmissionStore')
  .props({
    _algoSubmissions: types.optional(types.array(AlgoSubmissionModel), []),
    _problem: types.optional(AlgoProblemModel, {}),
    _solution: types.optional(AlgoProblemSolutionModel, {}),
    _result: types.optional(types.integer, 0),
    _compilers: types.optional(
      types.optional(types.array(AlgoCompilerModel), [])
    ),
    _problemAlgoSubmissions: types.optional(
      types.model('paginate', {
        data: types.optional(types.array(AlgoProblemSubmissionModel), []),
        total: types.optional(types.integer, 0)
      }),
      {}
    ),
    _resultError: types.optional(types.string, '')
  })
  .actions(self => {
    const apiV1 = getEnv(self).apiV1

    const algoSubmissionsApi = curry(url => '/api/AlgoSubmissionsApi' + url)

    function getByAlgoProblemId(problemId) {
      const prom = fromPromise(
        apiV1.get(
          algoSubmissionsApi(
            `/GetAlgoSubmissionsByProblemId?problemId=${problemId}`
          )
        )
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._algoSubmissions = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    function getByAlgoSubmissionId(submissionId) {
      const prom = fromPromise(
        apiV1.get(
          algoSubmissionsApi(
            `/GetAlgoSubmissionsBySubmissionId?submissionId=${submissionId}`
          )
        )
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._algoSubmissions = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    function getAlgoSubmissionById(submissionId) {
      const prom = fromPromise(
        apiV1.get(algoSubmissionsApi('/AlgoSubmissionById'), {
          params: {
            id: submissionId
          }
        })
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._solution = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    function getAlgoSubmissionByIdAndUserId(submissionId, role) {
      const prom = fromPromise(
        apiV1.get(algoSubmissionsApi('/GetAlgoSubmission'), {
          params: {
            id: submissionId,
            role
          }
        })
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._solution = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    function processSolution(subData) {
      const prom = fromPromise(
        apiV1.post(algoSubmissionsApi('/SendAlgoSubmission'), subData)
      )

      when(
        () => prom.state === REJECTED,
        () =>
          prom.case({
            rejected: ({ response }) => {
              self.runInAction(() => {
                self._resultError = response.data
              })
            }
          })
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._result = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    const getStudentAlgoSubmissionList = filter => {
      const prom = fromPromise(
        apiV1.post(
          algoSubmissionsApi('/StudentSubmissionsList'),
          AlgoProblemSubmissionsFilterModel.create(filter)
        )
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._problemAlgoSubmissions = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    const getTeacherAlgoSubmissionList = filter => {
      const prom = fromPromise(
        apiV1.post(
          algoSubmissionsApi('/TeacherSubmissionsList'),
          AlgoProblemSubmissionsFilterModel.create(filter)
        )
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._problemAlgoSubmissions = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    const getAuthorAlgoSubmissionList = filter => {
      const prom = fromPromise(
        apiV1.post(
          algoSubmissionsApi('/AuthorSubmissionsList'),
          AlgoProblemSubmissionsAuthorFilterModel.create(filter)
        )
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._problemAlgoSubmissions = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    const getAlgoCompilers = () => {
      const prom = fromPromise(
        apiV1.get(algoSubmissionsApi('/GetAlgoCompilers'))
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._compilers = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    const clearSubmission = () => {
      self._submission = {}
    }

    const clearSolution = () => {
      self._solution = {}
    }

    const clearResultError = () => {
      self._resultError = ''
    }

    return {
      processSolution,
      getAlgoCompilers,
      getByAlgoSubmissionId,
      getAlgoSubmissionById,
      getStudentAlgoSubmissionList,
      getTeacherAlgoSubmissionList,
      getAuthorAlgoSubmissionList,
      getByAlgoProblemId,
      getAlgoSubmissionByIdAndUserId,
      clearSubmission,
      clearSolution,
      clearResultError
    }
  })
  .views(self => ({
    get problem() {
      return getSnapshot(self._problem)
    },
    get solution() {
      return getSnapshot(self._solution)
    },
    get result() {
      return getSnapshot(self._result)
    },
    get problemAlgoSubmissions() {
      return getSnapshot(self._problemAlgoSubmissions)
    },
    get submissions() {
      return getSnapshot(self._algoSubmissions)
    },
    get compilers() {
      return getSnapshot(self._compilers)
    },
    getCompiler(compilerId) {
      const compilers = getSnapshot(self._compilers)
      return (
        compilers.find(compiler => compiler.id === compilerId) || compilers[0]
      )
    },
    get resultError() {
      return self._resultError
    }
  }))
