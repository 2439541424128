import { getEnv, types, getSnapshot } from 'mobx-state-tree'
import { fromPromise, FULFILLED } from 'mobx-utils'
import { when } from 'mobx'
import { curry } from 'ramda'

import {
  ServiceStore,
  SandboxDbProblemModel,
  RequestModel,
  SortingModel,
  DbProblemAuthorFilterModel,
  DbProblemSolutionModel,
  ProblemSubTypeLookUpModel
} from 'internal'

export default ServiceStore.named('SandboxDbProblemStore')
  .props({
    _problem: types.optional(SandboxDbProblemModel, {}),
    _problems: types.optional(
      types.model('PaginationProblems', {
        data: types.optional(types.array(SandboxDbProblemModel), []),
        total: types.optional(types.number, 0)
      }),
      {}
    ),
    _validation: types.optional(DbProblemSolutionModel, {}),
    _problemTranslate: types.optional(
      types.array(
        types.model('DbProblemTranslationModel', {
          id: types.optional(types.integer, 0),
          problemId: types.optional(types.integer, 0),
          languageId: types.optional(types.integer, 0),
          problemName: types.optional(types.string, ''),
          problemText: types.optional(types.string, '')
        })
      ),
      []
    ),
    _problemSubTypes: types.optional(types.array(ProblemSubTypeLookUpModel), [])
  })
  .actions(self => {
    const apiV1 = getEnv(self).apiV1

    const sandboxProblemApi = curry(url => '/api/SandboxProblemApi' + url)

    const list = (request, filter, sort, isMineAuthor) => {
      const prom = fromPromise(
        apiV1.post(
          sandboxProblemApi(
            isMineAuthor ? '/GetAuthorDbProblems' : '/GetUserDbProblems'
          ),
          {
            ...RequestModel.create(request),
            ...DbProblemAuthorFilterModel.create(filter),
            studentIds: isMineAuthor ? filter.studentIds : [],
            sorting: SortingModel.create(sort)
          }
        )
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._problems = resp.data
              })
            }
          })
        }
      )
      return prom
    }

    const create = data => {
      const prom = fromPromise(apiV1.post(sandboxProblemApi('/Post'), data))
      return prom
    }

    const validateProblem = (problemId, languageId) => {
      const prom = fromPromise(
        apiV1.post(
          sandboxProblemApi(
            `/ValidateDbProblem?problemId=${problemId}&languageId=${languageId}`
          )
        )
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._validation = resp.data || {}
              })
            }
          })
        }
      )

      return prom
    }

    const publishProblem = problemId => {
      const prom = fromPromise(
        apiV1.post(sandboxProblemApi('/PublishProblem'), null, {
          params: { problemId }
        })
      )

      return prom
    }

    const getProblemByHash = problemHash => {
      const prom = fromPromise(
        apiV1.get(sandboxProblemApi('/GetProblemIdByHash'), {
          params: {
            problemHash
          }
        })
      )

      return prom
    }

    const copy = problemId => {
      const prom = fromPromise(
        apiV1.post(sandboxProblemApi(`/CopyProblem?id=${problemId}`))
      )
      return prom
    }

    const save = data => {
      const prom = fromPromise(apiV1.put(sandboxProblemApi('/Put'), data))
      return prom
    }

    const deleteById = problemId => {
      const prom = fromPromise(
        apiV1.delete(sandboxProblemApi('/Delete'), {
          params: {
            id: problemId
          }
        })
      )

      return prom
    }

    const getProblemTranslate = problemId => {
      const prom = fromPromise(
        apiV1.post(
          sandboxProblemApi('/GetProblemTranslate'),
          RequestModel.create({
            entityId: problemId
          })
        )
      )

      when(
        () => prom.state === FULFILLED,
        () =>
          prom.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                self._problemTranslate = data
              })
            }
          })
      )

      return prom
    }

    const editProblemTranslate = data => {
      const prom = fromPromise(
        apiV1.post(sandboxProblemApi('/EditProblemTranslate'), data)
      )

      return prom
    }

    const getDbProblemSubTypes = () => {
      const prom = fromPromise(
        apiV1.get(sandboxProblemApi('/GetDbProblemSubTypes'))
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._problemSubTypes = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    const getProblemById = (problemId, languageId) => {
      const prom = fromPromise(
        apiV1.get(
          sandboxProblemApi(
            `/GetAuthorDbProblem?problemId=${problemId}&languageId=${languageId}`
          )
        )
      )

      when(() => {
        prom.case({
          fulfilled: ({ data }) => {
            self.runInAction(() => {
              self._problems.data = [
                data,
                ...self.problems.data.filter(problem => problem.id !== data.id)
              ]
            })
          }
        })
      })

      return prom
    }

    const clearValidation = () => {
      self._validation = {}
    }

    return {
      list,
      create,
      deleteById,
      validateProblem,
      publishProblem,
      getProblemByHash,
      clearValidation,
      copy,
      save,
      getProblemTranslate,
      editProblemTranslate,
      getDbProblemSubTypes,
      getProblemById
    }
  })
  .views(self => ({
    get problems() {
      return getSnapshot(self._problems)
    },
    get problem() {
      return getSnapshot(self._problem)
    },
    get validation() {
      return getSnapshot(self._validation)
    },
    get problemTranslate() {
      return getSnapshot(self._problemTranslate)
    },
    get dbProblemSubTypesLookUp() {
      return getSnapshot(self._problemSubTypes)
    }
  }))
