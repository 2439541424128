import React from 'react'
import { IntlProvider, addLocaleData } from 'react-intl'
import { observer, inject } from 'mobx-react'
import { pipe } from 'ramda'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import en from 'react-intl/locale-data/en'
import ru from 'react-intl/locale-data/ru'
import ky from 'react-intl/locale-data/ky'
import localeRu from 'date-fns/locale/ru'
import localeEn from 'date-fns/locale/en-US'
import validatorjs from 'validatorjs'
import moment from 'moment'
import 'moment/locale/ru'
import 'moment/locale/ky'

import localeKy from './core/locale/datepicker/ky'
import validatorKy from './core/locale/validatorjs/ky.js'

import langRu from './translations/ru.json'
import langEn from './translations/en.json'
import langKy from './translations/ky.json'
const messages = {
  ru: langRu,
  en: langEn,
  ky: langKy
}

validatorjs.setMessages('ky', validatorKy)

addLocaleData([...en, ...ru, ...ky])
registerLocale('ru', localeRu)
registerLocale('en', localeEn)
registerLocale('ky', localeKy)

moment.locale('ru')
moment.locale('ky')

const Context = React.createContext()

class IntlProviderWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      switchToEnglish: this.switchToEnglish,
      switchToRussian: this.switchToRussian,
      switchToKyrgyz: this.switchToKyrgyz
    }
  }

  switchToEnglish = () => {
    this.props.stores.appStore.setLanguage('en')
    validatorjs.useLang('en')
    setDefaultLocale('en')
    moment.locale('en')
  }

  switchToRussian = () => {
    this.props.stores.appStore.setLanguage('ru')
    validatorjs.useLang('ru')
    setDefaultLocale('ru')
    moment.locale('ru')
  }

  switchToKyrgyz = () => {
    this.props.stores.appStore.setLanguage('ky')
    validatorjs.useLang('ky')
    setDefaultLocale('ky')
    moment.locale('ky')
  }

  render() {
    const { children } = this.props
    let lang = this.props.stores.appStore.currentLanguage || 'en'
    validatorjs.useLang(lang)
    setDefaultLocale(lang)
    moment.locale(lang)

    return (
      <Context.Provider value={this.state}>
        <IntlProvider
          key={lang}
          locale={lang}
          messages={messages[lang]}
          defaultLocale={lang}
        >
          {children}
        </IntlProvider>
      </Context.Provider>
    )
  }
}

function applyContext(WrappedComponent) {
  const injectContext = pipe(observer, inject('stores'))

  return injectContext(WrappedComponent)
}

export default applyContext(IntlProviderWrapper)
export { Context as IntlContext }
