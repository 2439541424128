import { types } from 'mobx-state-tree'

import { DbTagsBusinessModel } from 'internal'

export default types.model('SuggestedLinkModel', {
  id: types.optional(types.identifierNumber, 0),
  name: types.optional(types.string, ''),
  link: types.optional(types.string, ''),
  comments: types.optional(types.string, ''),
  type: types.optional(types.number, 0),
  typeName: types.optional(types.string, ''),
  tags: types.optional(types.array(DbTagsBusinessModel), []),
  isApproved: types.optional(types.boolean, false),
  isDeleted: types.optional(types.boolean, false)
})
