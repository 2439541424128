import 'react-app-polyfill/ie9' // For IE 9-11 support
import 'react-app-polyfill/ie11' // For IE 11 support
import './polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'mobx-react'
import axios from 'axios'
import { ToastContainer } from 'react-toastify'
import * as serviceWorker from './serviceWorker'
import IntlProviderWrapper from './IntlProviderWrapper'

import {
  RootStore,
  apiV1,
  KEYS,
  utils,
  LocalStorage,
  SessionStorage
} from 'internal'

// vendor styles
import 'react-datepicker/dist/react-datepicker.min.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-markdown-editor-lite/lib/index.css'
// custom styles
import 'bootstrap/scss/bootstrap.scss'
import './index.scss'

import App from './App'

// create root store
const rootStore = RootStore.create(
  // initial values
  {
    chatStore: {},
    imageRepositoryStore: {},
    authStore: {},
    signinStore: {},
    signupStore: {},
    appStore: {},
    problemsUIStore: {},
    suggestedStore: {},
    suggestedUIStore: {},
    problemDbSubmissionsStore: {},
    problemSubmissionsUIStore: {},
    dbTagsStore: {},
    tagsUIStore: {},
    linksStore: {},
    dbSubjectStore: {},
    collectionsStore: {},
    dbProblemStore: {},
    sandboxDbProblemStore: {},
    sandboxDbSubmissionStore: {},
    algoProblemStore: {},
    algoSubmissionStore: {},
    algoProblemTestsStore: {},
    userStore: {},
    userRoleStore: {},
    hintStore: {},
    linksUIStore: {},
    subjectUIStore: {},
    teacherGroupStore: {},
    studentGroupStore: {},
    studentsGroupTeacherStore: {},
    teacherApprovalStore: {},
    studentStore: {},
    resultStore: {},
    studentApprovalStore: {},
    teacherStore: {},
    dbSubmissionStore: {},
    dbJudgeTypeStore: {},
    dbSimilarProblemStore: {},
    dbRightBlockLinksStore: {},
    dbProblemTagsStore: {},
    manageStore: {},
    collectionUIStore: {},
    paginationUIStore: {},
    rejudgeStore: {},
    problemAlgoSubmissionsUIStore: {},
    instituteStore: {},
    testTableStore: {},
    reportsStore: {},
    reportsUIStore: {},
    chatUIStore: {},
    usersUIStore: {},
    adminUIStore: {}
  },
  // injected env dependencies
  {
    apiV1,
    axios,
    KEYS,
    LocalStorage,
    SessionStorage
  }
)

utils.persist(
  KEYS.LS_KEY_PERSIST_AUTH_STORE,
  rootStore.authStore,
  {
    storage: window.localStorage,
    jsonify: true
  },
  { _currentUser: true }
)

utils.persist(
  KEYS.LS_KEY_PERSIST_PAGINATION_UI_STORE,
  rootStore.paginationUIStore,
  {
    storage: window.localStorage,
    jsonify: true
  },
  { _problems: true, _perPage: true }
)

utils.persist(
  KEYS.LS_KEY_PERSIST_APP_STORE,
  rootStore.appStore,
  {
    storage: window.sessionStorage,
    jsonify: true
  },
  { _currentRole: true, _currentLanguage: true }
)

utils.persist(
  KEYS.LS_KEY_PERSIST_PROBLEMS_UI_STORE,
  rootStore.problemsUIStore,
  {
    storage: window.sessionStorage,
    jsonify: true
  },
  { _filterDbStudent: true, _filterDbTeacher: true, _sortProblem: true }
)

utils.persist(
  KEYS.LS_KEY_PERSIST_DB_PROBLEM_SUBMISSIONS_UI_STORE,
  rootStore.problemSubmissionsUIStore,
  {
    storage: window.sessionStorage,
    jsonify: true
  },
  {
    _filterDbTeacher: true,
    _filterAlgoTeacher: true,
    _filterDbStudent: true,
    _problemJudgeType: true,
    _submissionId: true
  }
)

utils.persist(
  KEYS.LOCAL_STORAGE_KEY_PERSIST_ADMIN_FILTER,
  rootStore.adminUIStore,
  {
    storage: window.sessionStorage,
    jsonify: true
  },
  {
    _adminFilter: true
  }
)

apiV1.interceptors.request.use(config => {
  const { tokenType, accessToken } = rootStore.authStore.currentUser
  config.headers['Authorization'] = `${tokenType} ${accessToken}`
  return config
})

apiV1.interceptors.response.use(
  response => response,
  error => {
    const {
      authStore,
      problemsUIStore,
      problemSubmissionsUIStore,
      adminUIStore
    } = rootStore
    if (error.response.status === 401) {
      problemsUIStore.resetFilter()
      problemSubmissionsUIStore.resetFilter()
      adminUIStore.resetAdminFilter()
      authStore.signOut()
    }
    return Promise.reject(error)
  }
)

ReactDOM.render(
  <Provider stores={rootStore}>
    <IntlProviderWrapper>
      <App />
    </IntlProviderWrapper>
    <ToastContainer
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnVisibilityChange
      draggable
      pauseOnHover
    />
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
