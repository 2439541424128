import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import t from 'prop-types'

import { utils } from 'internal'

import './Button.scss'

class Button extends React.Component {
  constructor(props) {
    super(props)

    this.onClick = this.onClick.bind(this)
  }

  onClick(e) {
    if (this.props.disabled) {
      e.preventDefault()
      return
    }

    if (this.props.onClick) {
      this.props.onClick(e)
    }
  }

  render() {
    let {
      tag: Tag,
      className,
      color,
      block,
      border,
      ripple,
      round,
      size,
      link,
      icon,
      borderColor,
      textColor,
      ...attrs
    } = this.props

    const linkAttrs = {}

    if (attrs.href && Tag === 'button') {
      Tag = Link
      linkAttrs.to = attrs.href
    }

    const classes = classNames(
      'sw-button',
      `sw-${color}`,
      `sw-${size}`,
      `sw-round-${round}`,
      attrs.disabled && 'sw-disabled',
      icon && 'sw-button__icon',
      link && 'sw-button__link',
      block && 'sw-block',
      border && 'sw-border',
      borderColor && `sw-border-${borderColor}`,
      textColor && `sw-text-${textColor}`,
      ripple && "sw-ripple",
      className
    )

    return (
      <Tag
        type={Tag === 'button' && attrs.onClick ? 'button' : undefined}
        className={classes}
        onClick={this.onClick}
        {...linkAttrs}
        {...attrs}
      />
    )
  }
}

Button.propTypes = {
  tag: utils.tagPropType,
  className: t.string,
  onClick: t.func,
  color: t.oneOf(utils.colors),
  textColor: t.oneOf(utils.colors),
  borderColor: t.oneOf(utils.colors),
  disabled: t.bool,
  block: t.bool,
  border: t.bool,
  ripple: t.bool,
  icon: t.bool,
  round: t.oneOf(['small', 'medium', 'large', 'xlarge']),
  size: t.oneOf(['tiny', 'small', 'normal', 'medium', 'large', 'xlarge']),
  link: t.bool
}

Button.defaultProps = {
  icon: false,
  tag: 'button',
  disabled: false,
  round: 'medium',
  size: 'normal',
  // borderColor: 'grey'
}

export default Button
