import React, { Component } from 'react'
import classNames from 'classnames'
import t from 'prop-types'

import { utils } from 'internal'
import AccordionContext from './AccordionContext'

class AccordionBlock extends Component {
  state = {
    accordions: new Map()
  }

  handleClickToggle = accordionId => {
    const {
      props: { isAccordion }
    } = this

    this.setState(prevState => {
      const { accordions } = prevState
      const invertOpen = !accordions.get(accordionId)

      if (isAccordion) {
        return { accordions: new Map([[accordionId, invertOpen]]) }
      }

      accordions.set(accordionId, invertOpen)

      return {
        accordions
      }
    })
  }

  addAccordion = (accordionId, isOpen) => {
    this.setState(prevState => {
      const { accordions } = prevState
      accordions.set(accordionId, isOpen)

      return {
        accordions
      }
    })
  }

  render() {
    const {
      addAccordion,
      handleClickToggle,
      props: { tag: Tag, className, isAccordion, ...props },
      state: { accordions }
    } = this

    const classes = classNames('sw-accordion-block', className)

    return (
      <AccordionContext.Provider
        value={{
          accordions,
          addAccordion,
          onClickToggle: handleClickToggle
        }}
      >
        <Tag className={classes} {...props} />
      </AccordionContext.Provider>
    )
  }
}

AccordionBlock.propTypes = {
  tag: utils.tagPropType,
  className: t.string,
  isAccordion: t.bool
}

AccordionBlock.defaultProps = {
  tag: 'div',
  isAccordion: false
}

export default AccordionBlock
