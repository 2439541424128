import React from 'react'
import classNames from 'classnames'
import t from 'prop-types'

import { utils } from 'internal'

const ModalHeader = ({ tag: Tag, className, ...attributes }) => {
  const classes = classNames('sw-modal__header', className)
  return <Tag className={classes} {...attributes} />
}

ModalHeader.propTypes = {
  tag: utils.tagPropType,
  className: t.string
}

ModalHeader.defaultProps = {
  tag: 'div'
}

export default ModalHeader
