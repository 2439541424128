import { getEnv, types, getSnapshot } from 'mobx-state-tree'
import { fromPromise, FULFILLED } from 'mobx-utils'
import { when } from 'mobx'
import { curry } from 'ramda'

import { ServiceStore, UserBusinessModel, UserProfileModel } from 'internal'

const UserStore = ServiceStore.named('UserStore')
  .props({
    _users: types.optional(
      types.model('PaginationGroups', {
        data: types.optional(types.array(UserBusinessModel), []),
        total: types.optional(types.number, 0)
      }),
      {}
    ),
    _userProfile: types.optional(UserProfileModel, {})
  })
  .actions(self => {
    const apiV1 = getEnv(self).apiV1

    const userApi = curry(url => '/api/UserEditApi' + url)
    const userProfileApi = curry(url => '/api/UserProfileApi' + url)

    function save(userData) {
      const prom = fromPromise(apiV1.put(userApi('/Put'), userData))
      return prom
    }

    function sortList(body) {
      const prom = fromPromise(apiV1.post(userApi('/SortUsers'), body))

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._users = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    const getUserById = userId => {
      const prom = fromPromise(
        apiV1.get(userProfileApi('/GetUserById'), {
          params: {
            userId
          }
        })
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._userProfile = resp.data.model
              })
            }
          })
        }
      )

      return prom
    }

    return {
      save,
      sortList,
      getUserById
    }
  })
  .views(self => ({
    get users() {
      return getSnapshot(self._users)
    },
    get userProfile() {
      return getSnapshot(self._userProfile)
    }
  }))

export default UserStore
