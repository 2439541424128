import { types } from "mobx-state-tree";

const UserSubmissionModel = types.model("UsetSubmissionModel", {
  codeValue: types.optional(types.string, ""),
  status: types.frozen(),
  message: types.frozen(""),
  result: types.frozen(""),
  correctResult: types.frozen("")
});

export default UserSubmissionModel;
