import React from 'react'
import { observer } from 'mobx-react'

import { Form, FormFeedback } from '../../index'
import CodeEditor from './CodeEditor'

export default observer(({ field, ...props }) => {
  return (
    <Form.Group className="sw-form-group sw-form-group--column">
      {field.label && (
        <label
          htmlFor={field.id}
          className="sw-form-group__label sw-form-group__label--mb"
        >
          {field.label}
        </label>
      )}
      <div className="sw-width-100">
        <CodeEditor
          {...field.bind()}
          {...props}
          value={field.value}
          onChange={newValue => {
            field.sync(newValue)
          }}
        />
      </div>
      <FormFeedback invalid={field.hasError}>{field.error}</FormFeedback>
    </Form.Group>
  )
})
