import { getEnv, types, getSnapshot } from 'mobx-state-tree'
import { fromPromise, FULFILLED } from 'mobx-utils'
import { when } from 'mobx'
import { curry } from 'ramda'

import { ServiceStore } from 'internal'

const DbJudgeTypeStore = ServiceStore.named('DbJudgeTypeStore')
  .props({
    _judgeTypes: types.optional(
      types.array(
        types.model('DbJudgeTypeStore__judgeTypes', {
          id: types.identifierNumber,
          name: ''
        })
      ),
      []
    )
  })
  .actions(self => {
    const apiV1 = getEnv(self).apiV1

    const dbJudgeTypeApi = curry(url => '/DbJudgeTypeApi' + url)

    function list() {
      const prom = fromPromise(apiV1.get(dbJudgeTypeApi('/Get'), {}))

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._judgeTypes = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    return {
      list
    }
  })
  .views(self => ({
    get judgeTypes() {
      return getSnapshot(self._judgeTypes)
    }
  }))

export default DbJudgeTypeStore
