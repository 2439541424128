module.exports = {
  accepted: 'Сиз :attribute кабыл алышыңыз керек.',
  alpha: ':attribute талаасы тамгаларды гана камтый алат.',
  alpha_dash:
    ':attribute талаасы тамгаларды, сандарды, дефис жана ылдыйкы сызыкчаны гана камтый алат.',
  alpha_num: ':attribute талаасы тамгаларды жана сандарды гана камтый алат.',
  between: ':attribute талаасы :min жана :max ортосунда болуш керек.',
  confirmed: ':attribute талаасы ырастоо менен дал келбейт.',
  email: ':attribute талаасы чыныгы электрондук дарек болушу керек.',
  def: ':attribute талаасы каталарды камтыйт.',
  digits: ':attribute санарип талаасынын узундугу :digits болуш керек.',
  digits_between:
    ':attribute санарип талаасынын узундугу :min белгиден :max белгиге чейин болуш керек.',
  different: ':attribute менен :different талаалары айырмаланыш керек.',
  in: ':attribute үчүн тандалган маани туура эмес.',
  integer: ':attribute талаасы бүтүн сан болуш керек.',
  hex: ':attribute талаасы он алтылык форматта болуш керек',
  min: {
    numeric: ':attribute талаасынын мааниси :min барабар же ашык болуш керек.',
    string:
      ':attribute талаасындагы символдордун саны аз дегенде :min болуш керек.'
  },
  max: {
    numeric: ':attribute талаасынын мааниси :max барабар же аз болуш керек.',
    string:
      ':attribute талаасындагы символдордун саны көп дегенде :max болуш керек.'
  },
  not_in: ':attribute үчүн тандалган маани туура эмес.',
  numeric: ':attribute талаасы сан болуш керек.',
  present: ':attribute талаасы бар болуш керек (бирок бош болушу мүмкүн).',
  required: ':attribute талаасы сөзсүз толтурулушу керек.',
  required_if:
    ':attribute талаасы :other талаасынын мааниси :value талаасы менен барабар болгон учурда талап кылынат.',
  same: ':attribute талаасынын мааниси :same менен дал келиш керек.',
  size: {
    numeric: ':attribute талаасынын мааниси :size менен барабар болуш керек.',
    string:
      ':attribute талаасындагы символдордун саны :size барабар болуш керек.'
  },
  url: ':attribute талаасы оң URL камтыш керек.',
  regex: ':attribute талаасынын жараксыз форматы.',
  attributes: {}
}
