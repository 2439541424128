import React from "react";
import classNames from "classnames";
import t from "prop-types";

import {utils} from 'internal';

const NavigationInner = ({tag: Tag, className, ...attributes}) => {
  const classes = classNames("l-navigation__inner", className);

  return (<Tag className={classes} {...attributes}/>);
};

NavigationInner.propTypes = {
  className: t.string,
  tag: utils.tagPropType
};

NavigationInner.defaultProps = {
  tag: 'div'
};

export default NavigationInner;
