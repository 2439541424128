import React from "react";
import t from "prop-types";
import classNames from "classnames";

import {utils} from 'internal';

import CardBody from "./CardBody";
import CardPrimeSide from "./CardPrimeSide";
import CardSecondarySide from "./CardSecondarySide";
import CardHeading from "./CardHeading";
import CardStatus from "./CardStatus";
import CardDropdown from "./CardDropdown";
import CardDropdownItem from "./CardDropdownItem";

import "./Card.scss";


class Card extends React.Component {

  renderAsInner({tag: Tag, className, outerClassName, ...attributes}) {
    const outerClasses = classNames(
      "sw-card",
      outerClassName
    );

    const innerClasses = classNames(
      "sw-card__inner",
      className
    );

    return (
      <Tag className={outerClasses}>
        <Tag className={innerClasses} {...attributes}/>
      </Tag>
    );
  }

  renderAsDefault({tag: Tag, className, ...attributes}) {
    const classes = classNames(
      "sw-card",
      className
    );

    return (<Tag className={classes} {...attributes}/>);
  }

  render() {
    const {inner, ...attributes} = this.props;

    return (
      inner
        ? this.renderAsInner(attributes)
        : this.renderAsDefault(attributes)
    );
  }
}

Card.propTypes = {
  className: t.string,
  outerClassName: t.string,
  tag: utils.tagPropType,
  inner: t.bool
};

Card.defaultProps = {
  tag: "div"
};

Card.Body = CardBody;
Card.PrimeSide = CardPrimeSide;
Card.SecondarySide = CardSecondarySide;
Card.Heading = CardHeading;
Card.Status = CardStatus;
Card.Dropdown = CardDropdown;
Card.DropdownItem = CardDropdownItem;

export default Card;
