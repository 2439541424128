import React from 'react'
import t from 'prop-types'

import { GoogleLogin, GoogleLogout } from 'react-google-login'
import FacebookLogin from 'react-facebook-login'
import FacebookLogin2 from 'react-facebook-login/dist/facebook-login-render-props'
import MicrosoftLogin from '../MicrosoftLogin'

import {
  PROVIDER_GOOGLE,
  PROVIDER_FACEBOOK,
  PROVIDER_MICROSOFT
} from 'internal'

const SocialAuth = ({ provider, type, ...props }) => {
  switch (provider) {
    case PROVIDER_GOOGLE:
      return type === 'logout' ? (
        <GoogleLogout {...props} />
      ) : (
        <GoogleLogin {...props} />
      )
    case PROVIDER_FACEBOOK:
      return type === 'custom' ? (
        <FacebookLogin2 {...props} />
      ) : (
        <FacebookLogin {...props} />
      )
    case PROVIDER_MICROSOFT:
      return <MicrosoftLogin {...props} />
    default:
      return null
  }
}

SocialAuth.propTypes = {
  provider: t.oneOf([PROVIDER_FACEBOOK, PROVIDER_GOOGLE, PROVIDER_MICROSOFT]),
  type: t.oneOf(['login', 'logout', 'custom'])
}

export default SocialAuth
