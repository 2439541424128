import React from "react";
import t from "prop-types";
import classNames from "classnames";

import {utils} from 'internal';


const CardSecondarySide = ({tag: Tag, className, ...attributes}) => {
  const classes = classNames("sw-card__secondary-side", className);

  return (<Tag className={classes} {...attributes}/>);
};

CardSecondarySide.propTypes = {
  tag: utils.tagPropType,
  className: t.string
};

CardSecondarySide.defaultProps = {
  tag: "div"
};

export default CardSecondarySide;

