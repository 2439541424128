import React from "react";
import classNames from "classnames";
import t from "prop-types";

import { utils } from 'internal';

const TabPanes = ({ tag: Tag, className, ...props }) => {
  const classes = classNames("sw-tabs__panes", className);

  return <Tag className={classes} {...props} />;
};

TabPanes.propTypes = {
  tag: utils.tagPropType,
  className: t.string
};

TabPanes.defaultProps = {
  tag: "div"
};

export default TabPanes;
