import React from 'react'
import classNames from 'classnames'
import t from 'prop-types'

import FormGroup from './FormGroup'

import './Form.scss'

import { utils } from 'internal'

const Form = ({ tag: Tag, className, ...attributes }) => {
  const classes = classNames('sw-form', className)

  return <Tag className={classes} {...attributes} />
}

Form.propTypes = {
  tag: utils.tagPropType,
  className: t.string,
  onSubmit: t.func
}

Form.defaultProps = {
  tag: 'form'
}

Form.Group = FormGroup

export default Form
