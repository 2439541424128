import React from "react";
import t from "prop-types";
import classNames from "classnames";
import { MenuItem } from "react-aria-menubutton";

import { utils } from 'internal';

const CardDropdownItem = ({ tag: Tag, className, onClick, ...attributes }) => {
  const classes = classNames("sw-card-more-button__dropdown-item", className);

  return (
    <MenuItem>
      <Tag className={classes} onClick={onClick} {...attributes} />
    </MenuItem>
  );
};

CardDropdownItem.propTypes = {
  tag: utils.tagPropType,
  className: t.string,
  onClick: t.func
};
CardDropdownItem.defaultProps = {
  tag: "div"
};

export default CardDropdownItem;
