import React from "react";
import classNames from "classnames";
import t from "prop-types";

import {utils} from 'internal';

const NavigationHeader = ({tag: Tag, className, ...attributes}) => {
  const classes = classNames("l-navigation__heading", className);

  return (<Tag className={classes} {...attributes} />);
};

NavigationHeader.propTypes = {
  className: t.string,
  tag: utils.tagPropType
};

NavigationHeader.defaultProps = {
  tag: "header"
};

export default NavigationHeader;
