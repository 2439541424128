import React from "react";
import t from "prop-types";

import {
  utils
} from 'internal';


function Header(props) {

  const {
    tag: Tag,
    className,
    ...attrs
  } = props;

  return (
    <Tag className={className} {...attrs}/>
  );
}

Header.propTypes = {
  tag: utils.tagPropType,
  className: t.string,
};

Header.defaultProps = {
  tag: "thead",
};

export default Header
