import React from "react";
import classNames from "classnames";
import t from "prop-types";

import { utils } from 'internal';

const WidgetTitle = ({ tag: Tag, className, ...attributes }) => {
  const classes = classNames("sw-widget__heading-title", className);

  return <Tag className={classes} {...attributes} />;
};

WidgetTitle.propTypes = {
  tag: utils.tagPropType,
  className: t.string
};

WidgetTitle.defaultProps = {
  tag: "h2"
};

export default WidgetTitle;
