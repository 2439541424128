import React from "react";
import ReactDOM from "react-dom";
import t from "prop-types";


class PortalComponent extends React.Component {

  componentDidMount() {
    this.props.mountAt.appendChild(
      this.props.container
    );
  }

  componentWillUnmount() {
    this.props.mountAt.removeChild(
      this.props.container
    );
  }

  render() {
    return ReactDOM.createPortal(
      this.props.children,
      this.props.container
    );
  }

}

PortalComponent.propTypes = {
  mountAt: t.instanceOf(Element).isRequired,
  container: t.instanceOf(Element).isRequired
};

export default PortalComponent;
