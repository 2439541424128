import { types } from 'mobx-state-tree'

const SubjectJudgeTypesModel = types.model('SubjectJudgeTypesModel', {
  id: types.identifierNumber,
  name: types.maybeNull(types.string),
  isDefault: types.optional(types.boolean, false),
  languageModeName: types.optional(types.string, ''),
  dbSubjectJudgeTypeId: types.optional(types.number, 0)
})

export default SubjectJudgeTypesModel
