import { types } from 'mobx-state-tree'

import {
  AlgoProblemCompilersModel,
  ProblemSubTypeLookUpModel,
  AlgoCollectionLookUpModel
} from 'internal'

import InstitutesLookUpModel from '../models/InstitutesLookUp.model'

export default types.model('AlgoProblemModel', {
  id: types.optional(types.identifierNumber, 0),
  algoProblemId: types.optional(types.integer, 0),
  problemName: types.optional(types.string, ''),
  problemCode: types.optional(types.string, ''),
  problemLevel: types.optional(types.integer, 1),
  isSync: types.optional(types.boolean, false),
  isActive: types.optional(types.boolean, false),
  timeLimit: types.optional(types.integer, 0),
  memoryLimit: types.optional(types.integer, 0),
  solutionCompiler: types.maybeNull(AlgoProblemCompilersModel),
  solution: types.maybeNull(types.string, ''),
  problemText: types.optional(types.string, ''),
  comment: types.optional(types.string, ''),
  problemType: types.maybeNull(ProblemSubTypeLookUpModel),
  languageId: types.optional(types.integer, 2),
  isDeleted: types.optional(types.boolean, false),
  isChecker: types.optional(types.boolean, false),
  checkerCode: types.maybeNull(types.string),
  checkerExecutable: types.maybeNull(types.string),
  authorUserId: types.optional(types.string, ''),
  authorUserEmail: types.maybeNull(types.string),
  algoCollectionLookUpModels: types.optional(
    types.array(AlgoCollectionLookUpModel),
    []
  ),
  institution: types.maybeNull(InstitutesLookUpModel)
})
