import { types } from 'mobx-state-tree'

export default types.model('AnchorDataModel', {
  anchorProblemName: types.maybeNull(types.string),
  anchorProblemCode: types.maybeNull(types.string),
  anchorJudge: types.maybeNull(types.string),
  anchorSentDate: types.maybeNull(types.string),
  anchorProblemLevel: types.maybeNull(types.integer),
  anchorSubjectAreaName: types.maybeNull(types.string),
  groupName: types.maybeNull(types.string),
  groupId: types.maybeNull(types.number),
  approvalId: types.maybeNull(types.number),
  status: types.maybeNull(types.number)
})
