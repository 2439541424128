import { types } from 'mobx-state-tree'

const StudentGroupModel = types.model('', {
  id: types.identifierNumber,
  studentId: types.maybeNull(types.string),
  studentName: types.maybeNull(types.string),
  groupId: types.maybeNull(types.integer),
  groupName: types.maybeNull(types.string),
  institutionLogoLocation: types.maybeNull(types.string),
  studentsCount: types.optional(types.number, 0),
  groupImageLocation: types.maybeNull(types.string),
  isRequestApproved: types.optional(types.boolean, false),
  description: types.maybeNull(types.string)
})

export default StudentGroupModel
