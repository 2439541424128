import React from "react";
import classNames from "classnames";
import t from "prop-types";

import {
  utils
} from 'internal';

const WidgetBody = (props) => {

  const {
    tag: Tag,
    className,
    ...attrs
  } = props;

  const classes = classNames("sw-widget__body", className);

  return (
    <Tag className={classes} {...attrs}/>
  );
};

WidgetBody.propTypes = {
  tag: utils.tagPropType,
  className: t.string
};

WidgetBody.defaultProps = {
  tag: "div"
};

export default WidgetBody;
