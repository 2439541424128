import { types } from 'mobx-state-tree'

export default types.model('SimilarProblemModel', {
  id: types.optional(types.identifierNumber, 0),
  problemId: types.maybeNull(types.number),
  problemName: types.maybeNull(types.string),
  similarProblemId: types.maybeNull(types.number),
  similarProblemName: types.maybeNull(types.string),
  similarProblemCode: types.maybeNull(types.string),
  counter: types.optional(types.number, 0),
  isApproved: types.maybeNull(types.boolean),
  isDeleted: types.maybeNull(types.boolean),
  similarProblemRaiting: types.optional(types.number, 0),
  similarProblemLevel: types.optional(types.number, 0),
  similarProblemIsFlagged: types.optional(types.boolean, false),
  similarProblemIsSolved: types.optional(types.boolean, false)
})
