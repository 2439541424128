import { types } from 'mobx-state-tree'

import {
  DbSubjectLookUpModel,
  TagBusinessLookUpModel,
  DbCollectionLookUpModel,
  SimilarProblemLookUpModel
} from 'internal'

export default types.model('SandboxDbProblemModel', {
  id: types.optional(types.identifierNumber, 0),
  subjectId: types.maybeNull(types.integer),
  subjectSecurityProfilesId: types.maybeNull(types.integer),
  problemText: types.maybeNull(types.string),
  problemLevel: types.optional(types.integer, 1),
  isSync: types.optional(types.boolean, false),
  authorId: types.maybeNull(types.string),
  problemName: types.maybeNull(types.string),
  problemCode: types.maybeNull(types.string),
  author: types.maybeNull(types.string),
  authorFullName: types.maybeNull(types.string),
  isOrdered: types.optional(types.boolean, false),
  stopWords: types.maybeNull(types.string),
  isCheckedColumnNames: types.optional(types.boolean, false),
  tags: types.optional(types.array(types.integer), []),
  dbCollections: types.optional(types.array(types.integer), []),
  similarProblem: types.optional(types.array(types.integer), []),
  dbCollectionLookUpModels: types.optional(
    types.array(DbCollectionLookUpModel),
    []
  ),
  tagBusinessLookUpModels: types.optional(
    types.array(TagBusinessLookUpModel),
    []
  ),
  problemSubTypeLookUpModel: types.maybeNull(
    types.model('DbProblemModel__ProblemSubTypeLookUpModel', {
      id: types.maybeNull(types.integer),
      name: types.maybeNull(types.string)
    })
  ),
  similarProblemLookUpModels: types.optional(
    types.array(SimilarProblemLookUpModel),
    []
  ),
  solution: types.maybeNull(types.string),
  dbSubjectLookBusinessModel: types.maybeNull(DbSubjectLookUpModel),
  comment: types.maybeNull(types.string),
  isActive: types.maybeNull(types.boolean),
  dbSubjectSecurityProfileLookUpModel: types.maybeNull(
    types.model('DbProblemModel__DbSubjectSecurityProfileLookUpModel', {
      id: types.identifierNumber,
      name: ''
    })
  ),
  languageId: types.optional(types.integer, 2),
  isPublished: types.maybeNull(types.boolean),
  isValid: types.maybeNull(types.boolean),
  problemLink: types.maybeNull(types.string)
})
