import React, { Component } from 'react'
import t from 'prop-types'
import classNames from 'classnames'
import TextareaAutoSize from 'react-textarea-autosize'

import { utils } from 'internal'

import './Input.scss'

class Input extends Component {
  render() {
    const { tag, className, size, valid, invalid, ...attrs } = this.props

    let Tag = tag

    const classes = classNames(
      'sw-input',
      valid && 'sw-input--valid',
      invalid && 'sw-input--invalid',
      size && `sw-input--${size}`,
      attrs.type === 'checkbox' && 'sw-input__checkbox',
      attrs.type === 'textarea' && 'sw-input__textarea',
      attrs.type === 'textareaAutoSize' && 'sw-input--auto-size',
      className
    )

    if (['select', 'textarea'].includes(attrs.type)) {
      Tag = attrs.type
      delete attrs.type
    }

    if ('textareaAutoSize' === attrs.type) {
      Tag = TextareaAutoSize
      delete attrs.type
    }

    return <Tag className={classes} {...attrs} />
  }
}

Input.propTypes = {
  tag: utils.tagPropType,
  type: t.oneOf([
    'text',
    'email',
    'number',
    'checkbox',
    'radio',
    'textarea',
    'textareaAutoSize',
    'select',
    'password'
  ]),
  className: t.string,
  size: t.oneOf(['bg', 'sm']),
  valid: t.bool,
  invalid: t.bool
}

Input.defaultProps = {
  tag: 'input',
  type: 'text'
}

export default Input
