import React from "react";
import t from "prop-types";
import classNames from "classnames";

import {utils} from 'internal';

import "./Subject.scss";
import SubjectTitle from "./SubjectTitle";


const Subject = ({tag: Tag, className, ...attributes}) => {
  const classes = classNames(
    "sw-subject",
    className
  );

  return (<Tag className={classes} {...attributes} />);
};

Subject.propTypes = {
  className: t.string,
  tag: utils.tagPropType,
};

Subject.defaultProps = {
  tag: "div"
};

Subject.Title = SubjectTitle;

export default Subject;
