import { types } from 'mobx-state-tree'

export default types.model('DbProblemListModel', {
  id: types.identifierNumber,
  problemLevel: 1,
  problemCode: '',
  problemName: '',
  raiting: 0,
  percentOfUsersSolved: 0,
  subjectName: '',
  isSolved: false
})
