import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import './TestDetect.scss'

class TestDetect extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isTest: false
    }
  }
  componentDidMount () {
    const apiUrl = window.location.hostname
    if (apiUrl === 'localhost' || apiUrl === 'staging.solveway.club' || apiUrl === 'feature.solveway.club' ) {
      this.setState({
        isTest: true
      })
    }
  }
  render () {
    const isTest = this.state.isTest
    return (
      isTest && (
        <div className="test-mode">
          <p>
            <FormattedMessage
              id="app.test.mode"
              defaultMessage="Тестовый режим"
            />
          </p>
        </div>
      )
    )
  }
}

export default TestDetect