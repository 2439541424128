import { types } from 'mobx-state-tree'

import {
  ChatStore,
  ImageRepositoryStore,
  AuthStore,
  AppUIStore,
  SigninUIStore,
  SignupUIStore,
  SuggestedStore,
  ProblemsUIStore,
  DbProblemSubmissionStore,
  ProblemSubmissionsUIStore,
  DbTagsStore,
  HintStore,
  TagsUIStore,
  LinksStore,
  DbSubjectStore,
  CollectionsStore,
  DbProblemStore,
  SandboxDbProblemStore,
  AlgoProblemStore,
  AlgoSubmissionStore,
  SandboxDbSubmissionStore,
  AlgoProblemTestsStore,
  SuggestedUIStore,
  SubjectUIStore,
  LinksUIStore,
  UserStore,
  UserRoleStore,
  TeacherGroupStore,
  StudentGroupStore,
  TeacherApprovalStore,
  StudentStore,
  ResultStore,
  StudentApprovalStore,
  TeacherStore,
  DbSubmissionStore,
  DbJudgeTypeStore,
  DbSimilarProblemStore,
  DbRightBlockLinksStore,
  DbProblemsTagsStore,
  ManageStore,
  DbCollectionUIStore,
  PaginationUIStore,
  RejudgeStore,
  InstituteStore,
  TestTableStore,
  ReportsStore,
  ReportsUIStore,
  ChatUIStore,
  UsersUIStore,
  AdminUIStore
} from 'internal'

const RootStore = types.model(
  'RootStore',
  // injected stores
  {
    // domain stores
    authStore: AuthStore,
    chatStore: ChatStore,
    imageRepositoryStore: ImageRepositoryStore,
    suggestedStore: SuggestedStore,
    problemDbSubmissionsStore: DbProblemSubmissionStore,
    dbTagsStore: DbTagsStore,
    linksStore: LinksStore,
    dbSubjectStore: DbSubjectStore,
    collectionsStore: CollectionsStore,
    dbProblemStore: DbProblemStore,
    sandboxDbProblemStore: SandboxDbProblemStore,
    algoProblemStore: AlgoProblemStore,
    algoSubmissionStore: AlgoSubmissionStore,
    sandboxDbSubmissionStore: SandboxDbSubmissionStore,
    algoProblemTestsStore: AlgoProblemTestsStore,
    userStore: UserStore,
    userRoleStore: UserRoleStore,
    teacherGroupStore: TeacherGroupStore,
    studentGroupStore: StudentGroupStore,
    teacherApprovalStore: TeacherApprovalStore,
    studentStore: StudentStore,
    resultStore: ResultStore,
    studentApprovalStore: StudentApprovalStore,
    dbSubmissionStore: DbSubmissionStore,
    dbJudgeTypeStore: DbJudgeTypeStore,
    dbSimilarProblemStore: DbSimilarProblemStore,
    dbRightBlockLinksStore: DbRightBlockLinksStore,
    hintStore: HintStore,
    dbProblemTagsStore: DbProblemsTagsStore,
    manageStore: ManageStore,
    rejudgeStore: RejudgeStore,
    instituteStore: InstituteStore,
    testTableStore: TestTableStore,
    reportsStore: ReportsStore,
    // UI stores
    appStore: AppUIStore,
    signinStore: SigninUIStore,
    signupStore: SignupUIStore,
    problemsUIStore: ProblemsUIStore,
    problemSubmissionsUIStore: ProblemSubmissionsUIStore,
    suggestedUIStore: SuggestedUIStore,
    tagsUIStore: TagsUIStore,
    subjectUIStore: SubjectUIStore,
    linksUIStore: LinksUIStore,
    teacherStore: TeacherStore,
    collectionUIStore: DbCollectionUIStore,
    paginationUIStore: PaginationUIStore,
    chatUIStore: ChatUIStore,
    usersUIStore: UsersUIStore,
    reportsUIStore: ReportsUIStore,    
    adminUIStore: AdminUIStore
  }
)

export default RootStore
