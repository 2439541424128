import { types, getSnapshot } from 'mobx-state-tree'

import { BaseUIStore, AdminFilterModel } from 'internal'

const AdminUIStore = BaseUIStore.named('AdminUIStore')
  .props({
    _adminFilter: types.optional(AdminFilterModel, {})
  })
  .actions(self => {
    const setAdminFilter = filter => {
      self._adminFilter = filter
    }

    const resetAdminFilter = () => {
      self._adminFilter = {}
    }

    return {
      setAdminFilter,
      resetAdminFilter
    }
  })
  .views(self => ({
    get adminFilter() {
      return getSnapshot(self._adminFilter)
    }
  }))

export default AdminUIStore
