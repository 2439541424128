import { types } from 'mobx-state-tree'

const SuggestedSimilarProblemModel = types.model(
  'SuggestedSimilarProblemModel',
  {
    id: types.optional(types.identifierNumber, 0),
    problemId: types.maybeNull(types.number),
    problemName: types.maybeNull(types.string),
    similarProblemId: types.maybeNull(types.number),
    similarProblemName: types.optional(types.string, ''),
    counter: types.optional(types.number, 0),
    isApproved: types.optional(types.boolean, false),
    isDeleted: types.maybeNull(types.boolean),
    similarProblemRaiting: types.optional(types.number, 0),
    similarProblemLevel: types.optional(types.number, 0),
    similarProblemIsFlagged: types.optional(types.boolean, false),
    similarProblemIsSolved: types.optional(types.boolean, false)
  }
)

export default SuggestedSimilarProblemModel
