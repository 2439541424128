import React, { Component, Children, Fragment } from 'react'
import t from 'prop-types'
import classNames from 'classnames'

import { utils } from 'internal'

import TabContext from './TabContext'
import TabNav from './TabNav'
import Tab from './Tab'
import TabPanes from './TabPanes'
import TabPane from './TabPane'

import './Tabs.scss'

class Tabs extends Component {
  static defaultProps = {
    isLazy: false
  }

  state = {
    active: null
  }

  componentDidMount() {
    if (this.props.defActive) this.setState({ active: this.props.defActive })
  }

  handleSelectTab = active => {
    this.setState({ active })
  }

  renderContent = () => {
    const {
      props: { children }
    } = this

    const [nav, pane] = Children.toArray(children).reduce(
      (prev, current, idx) => {
        const [n, p] = prev

        const {
          props: { title, ...props }
        } = current

        n.push(
          <Tab key={idx} tabKey={props.tabKey}>
            {title}
          </Tab>
        )
        p.push(<TabPane key={idx} {...props} />)

        return [n, p]
      },
      [[], []]
    )

    return (
      <Fragment>
        <TabNav>{nav}</TabNav>
        <TabPanes>{pane}</TabPanes>
      </Fragment>
    )
  }

  render() {
    const {
      handleSelectTab,
      renderContent,
      props: {
        tag: Tag,
        children,
        className,
        defActive,
        activeTab,
        onSelect,
        isLazy,
        custom,
        ...props
      },
      state: { active }
    } = this

    const content = custom ? children : renderContent()
    const classes = classNames('sw-tabs', className)

    return (
      <TabContext.Provider
        value={{
          active: active || defActive || activeTab,
          onSelectTab: onSelect || handleSelectTab,
          isLazy
        }}
      >
        <Tag className={classes} {...props}>
          {content}
        </Tag>
      </TabContext.Provider>
    )
  }
}

Tabs.propTypes = {
  tag: utils.tagPropType,
  children: utils.tagPropType,
  className: t.string,
  defActive: t.oneOfType([t.string, t.number]).isRequired,
  activeTab: t.oneOfType([t.string, t.number]),
  onSelect: t.func,
  isLazy: t.bool,
  custom: t.bool
}

Tabs.defaultProps = {
  tag: 'div',
  isLazy: false,
  custom: false
}

Tabs.Nav = TabNav
Tabs.Tab = Tab
Tabs.Panes = TabPanes
Tabs.Pane = TabPane

export default Tabs
