import { values } from 'mobx'
import { types, destroy } from 'mobx-state-tree'
import { BaseUIStore } from 'internal'

const SubjectJudgeType = types.model('SubjectJudgeType', {
  value: types.optional(types.string, ''),
  label: types.optional(types.string, ''),
  color: types.optional(types.string, ''),
  isDisabled: types.optional(types.boolean, false)
})

const subjectJudgeTab = types.model('SubjectJudgeTab', {
  judgeTypeId: types.identifierNumber,
  judgeTypeName: types.string,
  languageModeName: types.string,
  type: types.maybeNull(SubjectJudgeType)
})

const colorsMap = new Map([
  [
    'default',
    {
      value: 'default',
      color: 'red',
      writable: true
    }
  ],
  [
    'independent',
    {
      value: 'independent',
      color: 'purple',
      writable: true
    }
  ],
  [
    'useDefault',
    {
      value: 'useDefault',
      color: 'green',
      writable: true
    }
  ]
])

const SubjectUIStore = BaseUIStore.named('SubjectUIStore')
  .props({
    _tabs: types.map(subjectJudgeTab),
    _colors: types.optional(types.frozen())
  })
  .actions(self => {
    const afterCreate = () => {
      self._colors = new Map(colorsMap)
    }

    const addTab = newTab => {
      self._tabs.put(newTab)
    }

    const getTab = tabId => self._tabs.get(tabId)

    const updateTab = (tabId, newType) => {
      const oldTab = self._tabs.get(tabId)
      self._tabs.set(tabId, {
        ...oldTab,
        type: newType
      })
    }

    const clearTabs = () => {
      self._tabs.clear()
    }

    const deleteTab = tab => {
      destroy(tab)
    }

    const updateColor = (colorId, newColor) => {
      const oldColor = self._colors.get(colorId, newColor)
      const newColors = new Map(self._colors)

      newColors.set(colorId, {
        ...oldColor,
        ...newColor
      })

      self._colors = newColors
    }

    const resetColor = () => {
      self._colors = new Map(colorsMap)
    }

    const getColor = colorId => self._colors.get(colorId)

    const callbackEngineTab = (engine, judgeTypes) => {
      const { judgeTypeId, isDefault, useDefault } = engine

      const { judgeTypeName } = judgeTypes.find(
        judgeType => judgeType.judgeTypeId === judgeTypeId
      )

      const judgeType =
        !isDefault && !useDefault
          ? 'independent'
          : isDefault && !useDefault
          ? 'default'
          : !isDefault && useDefault
          ? 'useDefault'
          : null

      const type = self.getColor(judgeType)

      if (type.value === 'default') {
        self.updateColor('default', { isDisabled: true })
      }

      return { judgeTypeId, judgeTypeName, type }
    }

    return {
      afterCreate,
      addTab,
      updateTab,
      deleteTab,
      clearTabs,
      getTab,
      updateColor,
      resetColor,
      getColor,
      callbackEngineTab
    }
  })
  .views(self => ({
    get tabs() {
      const tabs = values(self._tabs)
      return tabs
    },
    get colors() {
      return Array.from(self._colors.values())
    }
  }))

export default SubjectUIStore
