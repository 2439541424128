import { types } from 'mobx-state-tree'

export default types.model('DbProblemUserModel', {
  id: types.optional(types.identifierNumber, 0),
  userId: types.maybeNull(types.string),
  comment: types.maybeNull(types.string),
  isFlagged: types.optional(types.boolean, false),
  problemId: types.maybeNull(types.number),
  raiting: types.optional(types.number, 0),
  savedCode: types.maybeNull(types.string),
  statusId: types.optional(types.number, 0)
})
