import { types } from 'mobx-state-tree'

import { ResultTableStructureModel } from 'internal'

const DbProblemSolutionModel = types.model('DbProblemSolutionModel', {
  description: types.optional(types.array(types.string), []),
  result: false,
  solutionResult: types.frozen(),
  userResult: types.maybeNull(types.array(ResultTableStructureModel))
})

export default DbProblemSolutionModel
