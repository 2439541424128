import { types } from 'mobx-state-tree'

export default types.model('AlgoProblemSubmissionsFilterModel', {
  send: types.optional(types.boolean, false),
  correct: types.optional(types.boolean, false),
  mistake: types.optional(types.boolean, false),
  fromDate: types.maybeNull(types.Date),
  toDate: types.maybeNull(types.Date),
  userId: types.maybeNull(types.string),
  studentId: types.optional(types.array(types.string), []),
  groupIdList: types.optional(types.array(types.integer), []),
  problemCode: types.maybeNull(types.string),
  languageId: types.maybeNull(types.integer, 2),
  collectionIdList: types.optional(types.array(types.integer), []),
  page: 1,
  pageSize: 10
})
