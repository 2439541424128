import React from "react";
import t from "prop-types";
import classNames from "classnames";

import {utils} from 'internal';


const FilterContainerFooter = ({tag: Tag, className, ...attributes}) => {
  const classes = classNames("sw-filter-container__footer", className);

  return (<Tag className={classes} {...attributes}/>);
};

FilterContainerFooter.propTypes = {
  className: t.string,
  tag: utils.tagPropType
};

FilterContainerFooter.defaultProps = {
  tag: "div"
};

export default FilterContainerFooter;

