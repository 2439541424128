import { types } from 'mobx-state-tree'

export default types.model('AlgoProblemSubmissionModel', {
  id: types.optional(types.identifierNumber, 0),
  problemId: types.maybeNull(types.integer),
  problemName: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  submitDateTime: types.maybeNull(types.string),
  checkDateTime: types.maybeNull(types.string),
  isAccepted: types.optional(types.boolean, false),
  logText: types.maybeNull(types.string),
  userName: types.maybeNull(types.string),
  usersFirstName: types.maybeNull(types.string),
  usersLastName: types.maybeNull(types.string),
  userPhoto: types.maybeNull(types.string),
  groupName: types.maybeNull(types.string),
  submissionCode: types.maybeNull(types.string),
  problemCode: types.maybeNull(types.string),
  compilerId: types.maybeNull(types.integer),
  executionTime: types.maybeNull(types.integer),
  executionMemory: types.maybeNull(types.integer),
  comment: types.maybeNull(types.string)
})
