import { types } from "mobx-state-tree";

import { ResultRowModel } from 'internal';

const ResultTableStructureModel = types.model("ResultTableStructureModel", {
  dbName: types.maybeNull(types.string),
  rows: types.optional(types.array(ResultRowModel), []),
});


export default ResultTableStructureModel;
