import { getEnv, types, getSnapshot } from 'mobx-state-tree'
import { fromPromise, FULFILLED } from 'mobx-utils'
import { when } from 'mobx'
import { curry } from 'ramda'

import {
  ServiceStore,
  RequestModel,
  AlgoProblemModel,
  SortingModel,
  DbProblemAuthorFilterModel,
  ProblemSubTypeLookUpModel,
  AlgoProblemCompilersModel,
  AlgoProblemListModel,
  DbProblemLookUpModel,
  AlgoDetailProblemModel
} from 'internal'

export default ServiceStore.named('AlgoProblemStore')
  .props({
    _problem: types.optional(AlgoProblemModel, {}),
    _problems: types.optional(
      types.model('PaginationProblems', {
        data: types.optional(types.array(AlgoProblemModel), []),
        total: types.optional(types.number, 0)
      }),
      {}
    ),
    _sortProblems: types.optional(
      types.model('PaginationSortProblems', {
        total: types.optional(types.number, 0),
        data: types.optional(types.array(AlgoProblemListModel), [])
      }),
      {}
    ),
    _algoProblemsLookUp: types.optional(
      types.model('LookUpProblems', {
        data: types.optional(types.array(DbProblemLookUpModel), []),
        total: types.optional(types.number, 0)
      }),
      {}
    ),
    _problemSubTypes: types.optional(
      types.array(ProblemSubTypeLookUpModel),
      []
    ),
    _algoCompilers: types.optional(types.array(AlgoProblemCompilersModel), []),
    _algoProblemTranslate: types.optional(
      types.array(
        types.model('AlgoProblemTranslationModel', {
          id: types.optional(types.integer, 0),
          problemId: types.optional(types.integer, 0),
          languageId: types.optional(types.integer, 0),
          problemName: types.optional(types.string, ''),
          problemText: types.optional(types.string, '')
        })
      ),
      []
    ),
    _detailProblem: types.optional(AlgoDetailProblemModel, {})
  })
  .actions(self => {
    const apiV1 = getEnv(self).apiV1

    const algoProblemApi = curry(url => '/api/AlgoProblemsApi' + url)

    const problemLookUpApi = curry(url => '/api/ProblemsLookUp' + url)

    function getById(problemId, languageId, collectionTypeId) {
      const prom = fromPromise(
        apiV1.post(
          algoProblemApi('/GetById'),
          RequestModel.create({
            languageId: languageId,
            entityId: +problemId,
            collectionTypeId
          })
        )
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._problem = resp.data || {}
              })
            }
          })
        }
      )

      return prom
    }

    const list = (request, filter, sort) => {
      const prom = fromPromise(
        apiV1.post(algoProblemApi('/GetAuthorAlgoProblems'), {
          ...RequestModel.create({
            ...request,
            entityId: 0
          }),
          ...DbProblemAuthorFilterModel.create(filter),
          sorting: SortingModel.create(sort)
        })
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._problems = resp.data
              })
            }
          })
        }
      )
      return prom
    }

    const sortList = filter => {
      const prom = fromPromise(
        apiV1.post(algoProblemApi('/SortAlgoProblems'), { ...filter })
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._sortProblems = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    const save = data => {
      const prom = fromPromise(apiV1.put(algoProblemApi('/Put'), data))
      return prom
    }

    function create(data) {
      const prom = fromPromise(apiV1.post(algoProblemApi('/Post'), data))
      return prom
    }

    function copy(problemId) {
      const prom = fromPromise(
        apiV1.post(algoProblemApi(`/Copy?id=${problemId}`))
      )
      return prom
    }

    const deleteById = problemId => {
      const prom = fromPromise(
        apiV1.delete(algoProblemApi('/Delete'), {
          params: {
            id: problemId
          }
        })
      )
      return prom
    }

    const lookup = reqBody => {
      const prom = fromPromise(
        apiV1.post(problemLookUpApi('/GetAllProblems'),reqBody),
      )
      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                if (reqBody.page === 1) self._algoProblemsLookUp = resp.data
                else
                  self._algoProblemsLookUp.data = self._algoProblemsLookUp.data.concat(
                    resp.data.data
                  )
              })
            }
          })
        }
      )

      return prom
    }

    const getDbProblemSubTypes = () => {
      const prom = fromPromise(
        apiV1.get(algoProblemApi('/GetAlgoProblemTypes'))
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._problemSubTypes = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    const getAlgoCompilers = () => {
      const prom = fromPromise(apiV1.get(algoProblemApi('/GetAlgoCompilers')))

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._algoCompilers = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    const getAlgoProblemTranslate = problemId => {
      const prom = fromPromise(
        apiV1.post(
          algoProblemApi('/GetAlgoProblemTranslate'),
          RequestModel.create({
            entityId: problemId
          })
        )
      )

      when(
        () => prom.state === FULFILLED,
        () =>
          prom.case({
            fulfilled: ({ data }) => {
              self.runInAction(() => {
                self._algoProblemTranslate = data
              })
            }
          })
      )

      return prom
    }

    const editAlgoProblemTranslate = data => {
      const prom = fromPromise(
        apiV1.post(algoProblemApi('/EditAlgoProblemTranslate'), data)
      )
      return prom
    }

    function getDetailByProblemId(problemId) {
      const prom = fromPromise(
        apiV1.get(algoProblemApi(`/GetAlgoProblemInfoWidgetByProblemId?problemId=${problemId}`))
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._detailProblem = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    function getDetailBySubmissionId(submissionId) {
      const prom = fromPromise(
        apiV1.get(algoProblemApi(`/GetAlgoProblemInfoWidgetBySubmissionId?submissionId=${submissionId}`))
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._detailProblem = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    return {
      getById,
      list,
      sortList,
      create,
      copy,
      save,
      deleteById,
      lookup,
      getDbProblemSubTypes,
      getAlgoCompilers,
      getAlgoProblemTranslate,
      editAlgoProblemTranslate,
      getDetailByProblemId,
      getDetailBySubmissionId
    }
  })
  .views(self => ({
    get problem() {
      return getSnapshot(self._problem)
    },
    get problems() {
      return getSnapshot(self._problems)
    },
    get detailProblem() {
      return getSnapshot(self._detailProblem)
    },
    get sortProblems() {
      return getSnapshot(self._sortProblems)
    },
    get algoProblemTranslate() {
      return getSnapshot(self._algoProblemTranslate)
    },
    get algoProblemsLookUp() {
      return getSnapshot(self._algoProblemsLookUp)
    },
    get algoProblemSubTypesLookUp() {
      return getSnapshot(self._problemSubTypes)
    },
    get algoCompilers() {
      return getSnapshot(self._algoCompilers)
    }
  }))
