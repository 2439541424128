import { getEnv, types, getSnapshot } from 'mobx-state-tree'
import { fromPromise, FULFILLED } from 'mobx-utils'
import { when } from 'mobx'
import { curry } from 'ramda'

import {
  ServiceStore,
  SimilarProblemModel,
  SimilarProblemLookUpModel,
  DbProblemLookUpModel,
  RequestModel
} from 'internal'

const DbSimilarProblemStore = ServiceStore.named('DbSimilarProblemStore')
  .props({
    _similarProblems: types.optional(types.array(SimilarProblemModel), []),
    _similarAllProblemLookups: types.optional(
      types.model('SimilarAllProblemLookups', {
        data: types.optional(types.array(DbProblemLookUpModel), []),
        total: types.optional(types.number, 0)
      }),
      {}
    ),
    _similarProblemLookups: types.optional(
      types.array(SimilarProblemLookUpModel),
      []
    )
  })
  .actions(self => {
    const apiV1 = getEnv(self).apiV1

    const similarProblemApi = curry(url => '/api/SimilarProblemApi' + url)
    const getASPLUUrl = curry(url => '/api/AllSimilarProblemLookUp' + url)
    const getSPLUUrl = curry(url => '/api/SimilarProblemLookUp' + url)

    const getSimilarProblems = (problemId, languageId) => {
      const prom = fromPromise(
        apiV1.get(
          similarProblemApi(
            `/Get?problemId=${problemId}&languageId=${languageId}`
          )
        )
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._similarProblems = resp.data
              })
            }
          })
        }
      )

      return prom
    }

    function create(_data) {
      const prom = fromPromise(apiV1.post(similarProblemApi('/Create'), _data))

      return prom
    }

    const sendSimilarProblem = (problemId, similarProblemId) => {
      const prom = fromPromise(
        apiV1.post(similarProblemApi('/Post'), {
          problemId,
          similarProblemId
        })
      )

      return prom
    }

    function lookupAllSimilar(reqBody) {
      let prom = fromPromise(apiV1.post(getASPLUUrl('/Get'), reqBody))

      when(
        () => prom.state === FULFILLED,
        () =>
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                if (reqBody.page === 1)
                  self._similarAllProblemLookups = resp.data
                else
                  self._similarAllProblemLookups.data = self._similarAllProblemLookups.data.concat(
                    resp.data.data
                  )
              })
            }
          })
      )

      return prom
    }

    const lookupSimilar = (problemId, languageId) => {
      const prom = fromPromise(
        apiV1.post(
          getSPLUUrl('/Get'),
          RequestModel.create({
            entityId: +problemId,
            languageId: languageId
          })
        )
      )

      when(
        () => prom.state === FULFILLED,
        () =>
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                self._similarProblemLookups = resp.data
              })
            }
          })
      )

      return prom
    }

    return {
      getSimilarProblems,
      create,
      lookupSimilar,
      lookupAllSimilar,
      sendSimilarProblem
    }
  })
  .views(self => ({
    get similarProblems() {
      return getSnapshot(self._similarProblems)
    },
    get similarProblemLookups() {
      return getSnapshot(self._similarProblemLookups)
    },
    get similarAllProblemLookups() {
      return getSnapshot(self._similarAllProblemLookups)
    }
  }))

export default DbSimilarProblemStore
