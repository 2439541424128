import { types, getSnapshot } from 'mobx-state-tree'

import { BaseUIStore } from 'internal'

const PerPageModel = types.model('PerPageModel', {
  value: types.optional(types.number, 5),
  label: types.optional(types.string, '5')
})

// TODO: Replace perPage and pages to Map
export default BaseUIStore.named('PaginationUIStore')
  .props({
    hydrated: false,
    _perPage: types.optional(PerPageModel, {
      value: 10,
      label: '10'
    }),
    _perPageSecond: types.optional(PerPageModel, {
      value: 10,
      label: '10'
    }),
    problems: types.optional(types.integer, 1),
    controlCollections: types.optional(types.integer, 1),
    groups: types.optional(types.integer, 1),
    links: types.optional(types.integer, 1),
    controlSubjectArea: types.optional(types.integer, 1),
    controlTags: types.optional(types.integer, 1),
    users: types.optional(types.integer, 1),
    suggestedTag: types.optional(types.integer, 1),
    similarProblems: types.optional(types.integer, 1),
    comeRequests: types.optional(types.integer, 1),
    outRequests: types.optional(types.integer, 1),
    problemSubmissions: types.optional(types.integer, 1),
    rejudges: types.optional(types.integer, 1),
    institutes: types.optional(types.integer, 1),
    sandboxDbSubmissions: types.optional(types.integer, 1),
    tabInstitutes: types.optional(types.integer, 1)
  })
  .actions(self => ({
    afterHydration() {
      self.hydrated = true
    },
    updateCurrentPage(currentPage, pageName) {
      if (!currentPage || !pageName) {
        throw Error('Input currentPage or pageName')
      }

      self[pageName] = currentPage
    },
    updatePerPage(newPerPage) {
      self._perPage = newPerPage
    },
    updateSecondPerPage(newPerPage) {
      self._perPageSecond = newPerPage
    },
    calculate(currentPage, perPage) {
      const until = currentPage * perPage
      const from = until - perPage

      return {
        until,
        from
      }
    }
  }))
  .views(self => ({
    get perPage() {
      return getSnapshot(self._perPage)
    },
    get perPageSecond() {
      return getSnapshot(self._perPageSecond)
    },
    paginationByName(pageName) {
      const currentPage = self[pageName]
      const { value } = self.perPage

      return self.calculate(currentPage, value)
    },
    paginationByNameSecond(pageName) {
      const currentPage = self[pageName]
      const { value } = self.perPageSecond

      return self.calculate(currentPage, value)
    },
    getCurrentPageByName(pageName) {
      return self[pageName]
    }
  }))
