import { getEnv, getSnapshot, types } from 'mobx-state-tree'
import { fromPromise, FULFILLED } from 'mobx-utils'
import { when } from 'mobx'
import { curry } from 'ramda'

import { ServiceStore } from 'internal'

const TeacherStore = ServiceStore.named('TeacherStore')
  .props({
    _lookups: types.optional(
      types.model('TeacherLookups', {
        data: types.optional(
          types.array(
            types.model('TeacherStore_lookups', {
              id: types.maybeNull(types.string),
              userName: types.maybeNull(types.string),
              usersFirstName: types.maybeNull(types.string),
              usersLastName: types.maybeNull(types.string)
            })
          ),
          []
        ),
        total: types.optional(types.number, 0)
      }),
      {}
    ),
    _userTeachers: types.optional(
      types.array(
        types.model('UserTeachers', {
          teacherId: types.string,
          teacherEmail: types.string,
          teacherFullName: types.string
        })
      ),
      []
    )
  })
  .actions(self => {
    const apiV1 = getEnv(self).apiV1

    const teacherLUApi = curry(url => '/api/TeachersLookUpApi' + url)

    function lookup(reqBody) {
      const prom = fromPromise(
        apiV1.post(teacherLUApi('/GetAllTeachers'), reqBody)
      )

      when(
        () => prom.state === FULFILLED,
        () => {
          prom.case({
            fulfilled: resp => {
              self.runInAction(() => {
                reqBody.page === 1
                  ? (self._lookups = resp.data)
                  : (self._lookups.data = self._lookups.data.concat(
                      resp.data.data
                    ))
              })
            }
          })
        }
      )

      return prom
    }

    const getUserTeachers = () => {
      const prom = fromPromise(apiV1.get(teacherLUApi('/GetUserTeachers')))

      when(() => {
        prom.case({
          fulfilled: ({ data }) => {
            self.runInAction(() => {
              self._userTeachers = data
            })
          }
        })
      })

      return prom
    }

    return {
      lookup,
      getUserTeachers
    }
  })
  .views(self => ({
    get lookups() {
      return getSnapshot(self._lookups)
    },
    get userTeachers() {
      return getSnapshot(self._userTeachers)
    }
  }))

export default TeacherStore
