import React, { Fragment } from 'react'
import './TableStatistic.scss'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { MaterialIcon, Icon } from 'components'
import { SentIcon } from '../../icons'
import { FormattedMessage } from 'react-intl'

export const Header = ({ problemData, problemsRoute }) => {
  return (
    <thead>
      <tr>
        <th rowSpan="2" className="stickyColumn table-empty-cell"></th>
        {problemData.map(problem => (
          <Fragment key={problem.problemCode}>
            <th
              colSpan="2"
              className="table-cell-static-height align-middle table-header"
            >
              <Link
                data-tip
                data-for={problem.problemCode}
                to={problemsRoute.replace(':problemId', problem.problemId)}
                target="_blank"
              >
                {problem.problemCode}
              </Link>
              <ReactTooltip
                effect="solid"
                place="top"
                offset={{top: 5}}
                id={problem.problemCode}
              >
                <span>{problem.problemName}</span>
              </ReactTooltip>
            </th>
          </Fragment>
        ))}
        <th colSpan="2" className="table-cell-static-height align-middle table-header">
          <FormattedMessage id="statistic.total" defaultMessage="Всего" />
        </th>
      </tr>
      <tr>
        {[...problemData, { problemId: 'totalSums' }].map(problem => (
          <Fragment key={problem.problemId}>
            <th className="table-cell-static-height align-middle table-subheader">
              <Icon color="green">
                <MaterialIcon
                  className="submission-card-status__icon"
                  name="check_circle"
                />
              </Icon>
            </th>
            <th className="table-cell-static-height align-middle table-subheader">
              <SentIcon height="18" width="18" />
            </th>
          </Fragment>
        ))}
      </tr>
    </thead>
  )
}