import { types } from 'mobx-state-tree'

export default types.model('DbProblemsTagsModel', {
  id: types.optional(types.identifierNumber, 0),
  problemId: types.maybeNull(types.number),
  tagId: types.maybeNull(types.number),
  tagName: types.optional(types.string, ''),
  problemName: types.optional(types.string, ''),
  counter: types.optional(types.number, 0),
  isApproved: types.optional(types.boolean, false),
  isDeleted: types.optional(types.boolean, false)
})
