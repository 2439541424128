import React from "react";
import t from "prop-types";
import classNames from "classnames";

import {utils} from 'internal';


const FilterContainerBody = ({tag: Tag, className, ...attributes}) => {
  const classes = classNames("sw-filter-container__body", className);

  return (<Tag className={classes} {...attributes} />);
};

FilterContainerBody.propTypes = {
  className: t.string,
  tag: utils.tagPropType
};

FilterContainerBody.defaultProps = {
  tag: "div"
};

export default FilterContainerBody;
