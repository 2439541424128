import { types } from 'mobx-state-tree'

const UserProfileModel = types.model('UserProfileModel', {
  avatarLocation: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  dateOfBirth: types.maybeNull(types.string),
  dateOfRegistration: types.optional(types.string, ''),
  phoneNumber: types.maybeNull(types.string),
  country: types.maybeNull(types.string),
  city: types.maybeNull(types.string),
  occupation: types.maybeNull(types.string),
  organization: types.maybeNull(types.string)
})

export default UserProfileModel
