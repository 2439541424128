import { types } from 'mobx-state-tree'

export default types.model('DbDetailProblemModel', {
  id: types.maybe(types.integer),
  success: 0,
  fails: 0,
  allSuccess: 0,
  allFails: 0,
  studentName: types.maybeNull(types.string),
  userId: types.maybeNull(types.string)
})
