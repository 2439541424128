import React from 'react'
import { BrowserRouter, Switch, Redirect } from 'react-router-dom'
import { observer, inject } from 'mobx-react'
import { pipe } from 'ramda'
import BodyClassName from 'react-body-classname'
import { deviceType } from 'react-device-detect'
import ReactGA from 'react-ga'

import { AuthRoute } from 'components'
import { TYPE_ROUTE, TYPE_REDIRECT, pages } from './internal'
import { ROOT_ROUTE, SIGN_IN_ROUTE } from './routes'

ReactGA.initialize('G-Y7X9RHD5FJ')
ReactGA.pageview(window.location.pathname + window.location.search)
class App extends React.Component {
  render() {
    const {
      stores: { authStore }
    } = this.props

    return (
      <BodyClassName className={deviceType}>
        <BrowserRouter>
          <Switch>
            {pages.map(route => {
              switch (route.type) {
                case TYPE_ROUTE: {
                  if (route.isAuth) {
                    return (
                      <AuthRoute
                        redirectUrl={SIGN_IN_ROUTE}
                        auth={authStore.isAuth}
                        {...route}
                      />
                    )
                  }

                  return (
                    <AuthRoute
                      redirectUrl={ROOT_ROUTE}
                      auth={!authStore.isAuth}
                      {...route}
                    />
                  )
                }
                case TYPE_REDIRECT: {
                  return <Redirect {...route} />
                }
                default: {
                  return null
                }
              }
            })}
          </Switch>
        </BrowserRouter>
      </BodyClassName>
    )
  }
}

function applyContext(WrappedComponent) {
  const injectContext = pipe(observer, inject('stores'))

  return injectContext(WrappedComponent)
}

export default applyContext(App)
