import { types } from 'mobx-state-tree'

import {
  DbCollectionLookUpModel
} from 'internal'

export default types.model('DbProblemCollectionsLookupModel', {
  id: types.optional(types.identifierNumber, 0),
  dbCollectionLookUpModels: types.optional(
    types.array(DbCollectionLookUpModel),
    []
  )
})
