import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import t from 'prop-types'

const AuthRoute = ({ auth, redirectUrl, ...props }) =>
  auth ? <Route {...props} /> : <Redirect to={redirectUrl} />

AuthRoute.propTypes = {
  auth: t.bool.isRequired,
  redirectUrl: t.string
}

export default AuthRoute
