import React from 'react'
import classNames from 'classnames'
import MarkdownIt from 'markdown-it'
import insert from 'markdown-it-ins'
import './Markdown.scss'

class Markdown extends React.Component {
  mdParser = null
  constructor(props) {
    super(props)
    this.mdParser = new MarkdownIt({
      html: true,
      linkify: true,
      typographer: true
    }).use(insert)
  }
  render() {
    const { html, className } = this.props
    const classes = classNames('description-preview', className)
    return (
      <div
        className={classes}
        dangerouslySetInnerHTML={{
          __html: this.mdParser.render(html || '')
        }}
      />
    )
  }
}

export default Markdown
