export const AUTH_ADMIN_ROLE = 'Admin'
export const AUTH_AUTHOR_ROLE = 'Author'
export const AUTH_TEACHER_ROLE = 'Teacher'
export const AUTH_STUDENT_ROLE = 'Student'

export const PROBLEM_LEVEL_EASY = [1, 2]
export const PROBLEM_LEVEL_MEDIUM = [3, 4]
export const PROBLEM_LEVEL_HARD = [5]

export const PROVIDER_GOOGLE = 'Google'
export const PROVIDER_FACEBOOK = 'Facebook'
export const PROVIDER_MICROSOFT = 'Microsoft'

export const DB_SECTION_ID = 1
export const ALGO_SECTION_ID = 2
export const SANDBOX_DB_SECTION_ID = 3
