import React, { Component } from "react";
import classNames from "classnames";
import t from "prop-types";

import { utils } from 'internal';

import TabContext, { checkActive } from "./TabContext";

class Tab extends Component {
  static contextType = TabContext;

  render() {
    const {
      context: { onSelectTab, active },
      props: { tag: Tag, tabKey, className, activeClassName, ...props }
    } = this;

    const activeTab = checkActive(tabKey, active);

    const classes = classNames(
      "sw-tabs__tab",
      activeTab && ( activeClassName || "sw-tabs__tab--active" ),
      className
    );

    return (
      <Tag
        className={classes}
        onClick={onSelectTab.bind(this, tabKey)}
        {...props}
      />
    );
  }
}

Tab.propTypes = {
  tag: utils.tagPropType,
  tabKey: t.oneOfType([t.string, t.number]).isRequired,
  className: t.string
};

Tab.defaultProps = {
  tag: "div"
};

export default Tab;
