Object.defineProperty(exports, '__esModule', {
  value: true
})
exports.default = formatRelative

var _index = _interopRequireDefault(
  require('date-fns/_lib/isSameUTCWeek/index.js')
)

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj }
}

var accusativeWeekdays = [
  'жекшемби',
  'дүйшөмбү',
  'шейшемби',
  'шаршемби',
  'бейшемби',
  'жума',
  'ишемби'
]

function lastWeek(day) {
  var weekday = accusativeWeekdays[day]

  switch (day) {
    case 0:
    case 2:
    case 3:
    case 4:
    case 6:
      return "'мурунку " + weekday + "де ' p"

    case 1:
      return "'мурунку " + weekday + "дө ' p"

    case 5:
      return "'мурунку " + weekday + "да ' p"
    default:
  }
}

function thisWeek(day) {
  var weekday = accusativeWeekdays[day]

  switch (day) {
    case 0:
    case 2:
    case 3:
    case 4:
    case 6:
      return weekday + "де ' p"

    case 1:
      return weekday + "дө ' p"

    case 5:
      return weekday + "да ' p"
    default:
  }
}

function nextWeek(day) {
  var weekday = accusativeWeekdays[day]

  switch (day) {
    case 0:
    case 2:
    case 3:
    case 4:
    case 6:
      return "'эмки " + weekday + "де ' p"

    case 1:
      return "'эмки " + weekday + "дө ' p"

    case 5:
      return "'эмки " + weekday + "да ' p"
    default:
  }
}

var formatRelativeLocale = {
  lastWeek: function(date, baseDate, options) {
    var day = date.getUTCDay()

    if ((0, _index.default)(date, baseDate, options)) {
      return thisWeek(day)
    } else {
      return lastWeek(day)
    }
  },
  yesterday: "'кечээ ' p",
  today: "'бүгүн ' p",
  tomorrow: "'эртең ' p",
  nextWeek: function(date, baseDate, options) {
    var day = date.getUTCDay()

    if ((0, _index.default)(date, baseDate, options)) {
      return thisWeek(day)
    } else {
      return nextWeek(day)
    }
  },
  other: 'P'
}

function formatRelative(token, date, baseDate, options) {
  var format = formatRelativeLocale[token]

  if (typeof format === 'function') {
    return format(date, baseDate, options)
  }

  return format
}

module.exports = exports.default
