import React from 'react'
import t from 'prop-types'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import AsyncCreatableSelect from 'react-select/async-creatable'

const reactSelectStyles = {
  clearIndicator: () => ({
    color: '#828282',
    padding: '5px'
  }),
  dropdownIndicator: () => ({
    color: '#828282',
    padding: '5px'
  }),
  menu: styles => ({
    ...styles,
    backgroundColor: '#362D68',
    border: '1px solid #4B4284',
    borderRadius: '1px',
    padding: 0
  }),
  menuList: styles => ({
    ...styles,
    padding: 0,
    margin: 0,
    '::-webkit-scrollbar': {
      width: '3px',
      height: '2px'
    },
    '::-webkit-scrollbar-track': {
      background: '#594F95'
    },
    '::-webkit-scrollbar-thumb': {
      background: '#EAE7FF',
      borderRadius: '2px'
    }
  }),
  input: styles => ({
    ...styles,
    padding: 0,
    color: '#fff'
  }),
  container: (styles, state) => {
    const requiredStyle = state.selectProps.isRequired
      ? {
          '::after': {
            content: '"*"',
            color: 'red',
            marginLeft: '3px',
            position: 'absolute',
            right: '-15px',
            top: 0
          }
        }
      : {}
    const provided = {
      ...styles,
      ...requiredStyle,
      width: '100%',
      borderRadius: '6px',
      border: '2px solid #4B4284',
    }

    return provided
  },
  indicatorsContainer: styles => ({
    ...styles,
    display: 'none'
  }),

  control: (styles) => {
    const provided = {
      ...styles,
      backgroundColor: '#362d68',
      margin: '0',
      minHeight: '21px',
      border: 'none',
    }
    return provided
  },
  placeholder: styles => ({
    ...styles,
    color: '#776EAB',
    fontSize: '13px',
    marginLeft: '5px'
  }),
  multiValue: styles => ({
    ...styles,
    backgroundColor: '#4B4284',
    borderRadius: '2px',
    margin: '1px',
    height: '21px'
  }),
  multiValueLabel: styles => ({
    ...styles,
    overflowWrap: 'break-word',
    wordBreak: 'break-all',
    color: '#fff',
    padding: '4px 3px',
    fontSize: '13px'
  }),
  multiValueRemove: styles => ({
    ...styles,
    padding: '1px',
    color: '#aaa',
    ':hover': {
      color: '#fff'
    }
  }),
  valueContainer: styles => ({
    ...styles,
    padding: '0px 1px'
  }),
  option: (styles, provided) =>({
    ...styles,
    fontSize: '13px',
    padding: '5px 7px',
    backgroundColor: provided.isFocused ? '#594F95' : '#362D68',
    ':hover': {
      backgroundColor: '#594F95'
    }
  }),
  indicatorSeparator: provided => ({
    ...provided,
    backgroundColor: 'none'
  }),

}

class FilterInput extends React.Component {
  render() {
    const {
      type,
      label,
      className,
      color,
      children,
      bordered,
      getSelectRef,
      ...attributes
    } = this.props

    if (type === 'select') {
      return (
        <Select ref={getSelectRef} styles={reactSelectStyles} {...attributes} />
      )
    }

    if (type === 'async-select') {
      return (
        <AsyncSelect
          ref={getSelectRef}
          styles={reactSelectStyles}
          {...attributes}
        />
      )
    }

    if (type === 'async-creatable-select') {
      return (
        <AsyncCreatableSelect
          ref={getSelectRef}
          styles={reactSelectStyles}
          {...attributes}
        />
      )
    }
  }
}

FilterInput.propTypes = {
  className: t.string,
  type: t.oneOf([
    'select',
    'async-select',
    'async-creatable-select'
  ]).isRequired,
  label: t.string,
  disabled: t.bool,
  color: t.oneOf(['grey', 'green', 'red', 'white']),
  bordered: t.bool,
  getSelectRef: t.func
}

export default FilterInput
