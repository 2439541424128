import React from "react";
import t from "prop-types";
import classNames from "classnames";

import "./Navigation.scss";
import NavigationBody from "./NavigationBody";
import NavigationItems from "./NavigationItems";
import NavigationInner from "./NavigationInner";
import NavigationHeader from "./NavigationHeader";

import {utils} from 'internal';


const Navigation = ({tag: Tag, className, ...attributes}) => {
  const classes = classNames(
    "l-navigation",
    className
  );

  return (<Tag className={classes} {...attributes} />);
};

Navigation.propTypes = {
  className: t.string,
  tag: utils.tagPropType
};

Navigation.defaultProps = {
  tag: 'nav'
};

Navigation.Body = NavigationBody;
Navigation.Items = NavigationItems;
Navigation.Inner = NavigationInner;
Navigation.Header = NavigationHeader;

export default Navigation;
