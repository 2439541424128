import { types } from 'mobx-state-tree'

import {
  DbSubjectModel,
  DbSubjectDiagramModel,
  HintModel,
  DbSubjectJudgeTypeModel,
  DbSubjectDatabasesModel,
  DbSubjectSecurityProfilesModel
} from 'internal'

export default types.model('DbSubjectAllModel', {
  dbSubject: types.optional(DbSubjectModel, {}),
  dbSubjectDiagram: types.optional(types.array(DbSubjectDiagramModel), []),
  dbSubjectHints: types.optional(types.array(HintModel), []),
  dbSubjectJudgeTypeBusinessModels: types.optional(
    types.array(DbSubjectJudgeTypeModel),
    []
  ),
  dbSubjectDatabases: types.optional(types.array(DbSubjectDatabasesModel), []),
  dbSubjectSecurityProfiles: types.optional(
    types.array(DbSubjectSecurityProfilesModel),
    []
  )
})
