import { types } from 'mobx-state-tree'

export default types.model('StudentsGroupByTeacherModel', {
  groupName: types.maybeNull(types.string),
  id: types.maybeNull(types.string),
  students: types.array(
    types.model('StudentsGroupByTeacherModel__Students', {
      firstName: types.maybeNull(types.string),
      id: types.maybeNull(types.string),
      lastName: types.maybeNull(types.string),
      userName: types.maybeNull(types.string),
    })
  )
})
