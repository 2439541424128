import React from "react";
import t from "prop-types";
import classNames from "classnames";

import {utils} from 'internal';


const SubjectTitle = ({subtitle, tag: Tag, className, ...attributes}) => {
  const classes = classNames(
    "sw-subject" + (subtitle ? "__subtitle" : "__title"),
    className
  );

  return (<Tag className={classes} {...attributes}/>);
};

SubjectTitle.propTypes = {
  className: t.string,
  tag: utils.tagPropType,
  subtitle: t.bool
};

SubjectTitle.defaultProps = {
  tag: "p"
};

export default SubjectTitle;
