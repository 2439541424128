import React, { Component } from "react";
import t from "prop-types";
import classNames from "classnames";

import { utils } from 'internal';

import TooltipText from "./TooltipText";
import "./Tooltip.scss";

class Tooltip extends Component {
  state = {
    open: false
  };

  handleEvents = event => {
    this.setState({
      open: !this.state.open
    });
  };

  render() {
    const {
      tag: Tag,
      className,
      children,
      trigger,
      text,
      position,
      events,
      ...attributes
    } = this.props;
    const { open } = this.state;

    const classes = classNames("tooltip__text", position, className);
    const handlers = events.reduce((count, item) => {
      if (!count[item]) {
        count[item] = this.handleEvents;
      }

      return count;
    }, {});
    return (
      <Tag className="tooltip" {...handlers} {...attributes}>
        {trigger}
        {open &&
          (children || (
            <TooltipText className={classes} text={text} position={position} />
          ))}
      </Tag>
    );
  }
}

Tooltip.propTypes = {
  tag: utils.tagPropType,
  children: utils.tagPropType,
  className: t.string,
  position: t.string,
  text: t.string,
  trigger: utils.tagPropType,
  events: t.arrayOf(t.string)
};

Tooltip.defaultProps = {
  tag: "div",
  events: ["onMouseOver", "onMouseOut"]
};

Tooltip.Text = TooltipText;

export default Tooltip;
