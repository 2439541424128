import React from 'react';
import t from 'prop-types';
import classNames from 'classnames';

import {utils} from 'internal';


const FilterFooter = ({tag: Tag, className, ...attributes}) => {
  const classes = classNames(
    'filter__footer',
    className
  );

  return(
    <Tag className={classes} {...attributes} />
  );
};

FilterFooter.defaultProps = {
  tag: 'div'
};

FilterFooter.propTypes = {
  tag: utils.tagPropType,
  className: t.string
};

export default FilterFooter;
