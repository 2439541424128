import React from "react";
import t from "prop-types";
import classNames from "classnames";

import {utils} from 'internal';

const PanelIcon = ({tag: Tag, className, icon, alt, ...attributes}) => {
  const classes = classNames("c-panel-links__icon", className);

  return (
    <Tag className={classes} {...attributes}>
      <img src={icon} alt={alt}/>
    </Tag>
  );
};


PanelIcon.propTypes = {
  className: t.string,
  tag: utils.tagPropType,
  icon: t.any,
  alt: t.string
};

PanelIcon.defaultProps = {
  tag: 'figure',
  alt: 'Icon'
};

export default PanelIcon;
