import { types } from 'mobx-state-tree'

export default types.model('SubjectTranslateModel', {
  languageId: types.optional(types.integer, 0),
  translations: types.optional(
    types.model('Translations', {
      databaseTranslations: types.array(
        types.model('DatabaseTranslations', {
          id: types.optional(types.integer, 0),
          databaseId: types.optional(types.integer, 0),
          dbPublicName: types.optional(types.string, ''),
          description: types.optional(types.string, '')
        }),
        []
      ),
      hintTranslations: types.array(
        types.model('HintTranslations', {
          id: types.optional(types.integer, 0),
          hintId: types.optional(types.integer, 0),
          hintText: types.optional(types.string, '')
        }),
        []
      ),
      subjectTranslations: types.array(
        types.model('SubjectTranslations', {
          id: types.optional(types.integer, 0),
          subjectId: types.optional(types.integer, 0),
          subjectName: types.optional(types.string, ''),
          description: types.optional(types.string, '')
        }),
        []
      )
    }),
    {}
  )
})
