import React from "react";
import t from "prop-types";

import {
  utils
} from 'internal';


function Row(props) {

  const {
    tag: Tag,
    className,
    ...attrs
  } = props;

  return (
    <Tag className={className} {...attrs}/>
  );
}

Row.propTypes = {
  tag: utils.tagPropType,
  className: t.string,
};

Row.defaultProps = {
  tag: "tr"
};

export default Row;
