import {types} from "mobx-state-tree";

import {SubjectTaskGroupModel} from 'internal';

const SubjectTaskModel = types.model('SubjectTaskModel', {
  disabled: types.optional(types.boolean, false),
  group: SubjectTaskGroupModel,
  selected: types.optional(types.boolean, false),
  text: types.optional(types.string, ''),
  value: types.optional(types.string, '')
});

export default SubjectTaskModel;
