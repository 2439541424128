import React from 'react';
import t from 'prop-types';

import {utils} from 'internal';
import {PopperContent} from "../index";


const DEFAULT_DELAYS = {
  show: 0,
  hide: 0
};

class TooltipPopoverWrapper extends React.Component {

  constructor(props) {
    super(props);

    this.state = {isOpen: props.isOpen};

    this._target = null;
    this.addTargetEvents = this.addTargetEvents.bind(this);
    this.handleDocumentClick = this.handleDocumentClick.bind(this);
    this.removeTargetEvents = this.removeTargetEvents.bind(this);
    this.toggle = this.toggle.bind(this);
    this.showWithDelay = this.showWithDelay.bind(this);
    this.hideWithDelay = this.hideWithDelay.bind(this);
    this.onMouseOverTooltipContent = this.onMouseOverTooltipContent.bind(this);
    this.onMouseLeaveTooltipContent = this.onMouseLeaveTooltipContent.bind(this);
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.onEscKeyDown = this.onEscKeyDown.bind(this);
    this.getRef = this.getRef.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    return {isOpen: props.isOpen};
  }

  componentDidMount() {
    this.updateTarget();
  }

  componentWillUnmount() {
    this.removeTargetEvents();
  }

  onMouseOverTooltipContent() {
    if (this.props.trigger.indexOf('hover') > -1 && !this.props.autohide) {
      if (this._hideTimeout) {
        this.clearHideTimeout();
      }
      if (this.state.isOpen && !this.props.isOpen) {
        this.toggle();
      }
    }
  }

  onMouseLeaveTooltipContent(e) {
    if (this.props.trigger.indexOf('hover') > -1 && !this.props.autohide) {
      if (this._showTimeout) {
        this.clearShowTimeout();
      }
      e.persist();
      this._hideTimeout = setTimeout(
        this.hide.bind(this, e),
        this.getDelay('hide')
      );
    }
  }

  onEscKeyDown(e) {
    if (e.key === 'Escape') {
      this.hide(e);
    }
  }

  getRef(ref) {
    const {innerRef} = this.props;
    if (innerRef) {
      if (typeof innerRef === 'function') {
        innerRef(ref);
      } else if (typeof innerRef === 'object') {
        innerRef.current = ref;
      }
    }
    this._popover = ref;
  }

  getDelay(key) {
    const {delay} = this.props;
    if (typeof delay === 'object') {
      return isNaN(delay[key]) ? DEFAULT_DELAYS[key] : delay[key];
    }
    return delay;
  }

  show(e) {
    if (!this.props.isOpen) {
      this.clearShowTimeout();
      this.toggle(e);
    }
  }

  showWithDelay(e) {
    if (this._hideTimeout) {
      this.clearHideTimeout();
    }
    this._showTimeout = setTimeout(
      this.show.bind(this, e),
      this.getDelay('show')
    );
  }

  hide(e) {
    if (this.props.isOpen) {
      this.clearHideTimeout();
      this.toggle(e);
    }
  }

  hideWithDelay(e) {
    if (this._showTimeout) {
      this.clearShowTimeout();
    }
    this._hideTimeout = setTimeout(
      this.hide.bind(this, e),
      this.getDelay('hide')
    );
  }


  clearShowTimeout() {
    clearTimeout(this._showTimeout);
    this._showTimeout = undefined;
  }

  clearHideTimeout() {
    clearTimeout(this._hideTimeout);
    this._hideTimeout = undefined;
  }

  handleDocumentClick(e) {
    const triggers = this.props.trigger.split(' ');

    if (triggers.indexOf('legacy') > -1 && (this.props.isOpen || isInDOMSubtree(e.target, this._target))) {
      if (this._hideTimeout) {
        this.clearHideTimeout();
      }
      if (this.props.isOpen && !isInDOMSubtree(e.target, this._popover)) {
        this.hideWithDelay(e);
      } else if (!this.props.isOpen) {
        this.showWithDelay(e);
      }
    } else if (triggers.indexOf('click') > -1 && isInDOMSubtree(e.target, this._target)) {
      if (this._hideTimeout) {
        this.clearHideTimeout();
      }

      if (!this.props.isOpen) {
        this.showWithDelay(e);
      } else {
        this.hideWithDelay(e);
      }
    }
  }

  addTargetEvents() {
    if (this.props.trigger) {
      let triggers = this.props.trigger.split(' ');
      if (triggers.indexOf('manual') === -1) {
        if (triggers.indexOf('click') > -1 || triggers.indexOf('legacy') > -1) {
          document.addEventListener('click', this.handleDocumentClick, true);
        }

        if (this._target) {
          if (triggers.indexOf('hover') > -1) {
            this._target.addEventListener(
              'mouseover',
              this.showWithDelay,
              true
            );
            this._target.addEventListener(
              'mouseout',
              this.hideWithDelay,
              true
            );
          }
          if (triggers.indexOf('focus') > -1) {
            this._target.addEventListener('focusin', this.show, true);
            this._target.addEventListener('focusout', this.hide, true);
          }
          this._target.addEventListener('keydown', this.onEscKeyDown, true);
        }
      }
    }
  }

  removeTargetEvents() {
    if (this._target) {
      this._target.removeEventListener(
        'mouseover',
        this.showWithDelay,
        true
      );
      this._target.removeEventListener(
        'mouseout',
        this.hideWithDelay,
        true
      );
      this._target.removeEventListener('keydown', this.onEscKeyDown, true);
      this._target.removeEventListener('focusin', this.show, true);
      this._target.removeEventListener('focusout', this.hide, true);
    }

    document.removeEventListener('click', this.handleDocumentClick, true)
  }

  updateTarget() {
    const newTarget = utils.getTarget(this.props.target);
    if (newTarget !== this._target) {
      this.removeTargetEvents();
      this._target = newTarget;
      this.addTargetEvents();
    }
  }

  toggle(e) {
    if (this.props.disabled) {
      return e && e.preventDefault();
    }

    return this.props.toggle(e);
  }

  render() {
    if (!this.state.isOpen) {
      return null;
    }

    this.updateTarget();

    const {
      className,
      innerClassName,
      target,
      isOpen,
      hideArrow,
      boundariesElement,
      placement,
      placementPrefix,
      arrowClassName,
      popperClassName,
      container,
      modifiers,
      offset,
      flip,
    } = this.props;

    const attrs = utils.omit(this.props, [
      "placement",
      "placementPrefix",
      "target",
      "container",
      "isOpen",
      "disabled",
      "hideArrow",
      "boundariesElement",
      "className",
      "innerClassName",
      "arrowClassName",
      "popperClassName",
      "toggle",
      "autohide",
      "delay",
      "modifiers",
      "offset",
      "innerRef",
      "trigger",
      "flip",
    ]);

    return (
      <PopperContent className={className}
                     isOpen={isOpen}
                     target={target}
                     hideArrow={hideArrow}
                     boundariesElement={boundariesElement}
                     placement={placement}
                     placementPrefix={placementPrefix}
                     arrowClassName={arrowClassName}
                     popperClassName={popperClassName}
                     container={container}
                     modifiers={modifiers}
                     offset={offset}
                     flip={flip}>
        <div ref={this.getRef}
             className={innerClassName}
             role="tooltip"
             aria-hidden={isOpen}
             onMouseOver={this.onMouseOverTooltipContent}
             onMouseLeave={this.onMouseLeaveTooltipContent}
             onKeyDown={this.onEscKeyDown}
             {...attrs}/>
      </PopperContent>
    );
  }
}

function isInDOMSubtree(element, subtreeRoot) {
  return subtreeRoot && (element === subtreeRoot || subtreeRoot.contains(element));
}

TooltipPopoverWrapper.propTypes = {
  placement: t.oneOf(utils.PopperPlacements),
  placementPrefix: t.string,
  target: utils.targetPropType.isRequired,
  container: utils.targetPropType,
  isOpen: t.bool,
  disabled: t.bool,
  hideArrow: t.bool,
  boundariesElement: t.oneOfType([t.string, utils.DOMElement]),
  className: t.string,
  innerClassName: t.string,
  arrowClassName: t.string,
  popperClassName: t.string,
  toggle: t.func,
  autohide: t.bool,
  delay: t.oneOfType([
    t.shape({show: t.number, hide: t.number}),
    t.number
  ]),
  modifiers: t.object,
  offset: t.oneOfType([t.string, t.number]),
  innerRef: t.oneOfType([
    t.func,
    t.string,
    t.object
  ]),
  trigger: t.string,
  flip: t.bool,
};

TooltipPopoverWrapper.defaultProps = {
  placement: "auto",
  isOpen: false,
  hideArrow: false,
  autohide: false,
  delay: DEFAULT_DELAYS,
  toggle: function () {
  },
  trigger: 'click',
};

export default TooltipPopoverWrapper;
