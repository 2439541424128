import { types } from 'mobx-state-tree'

import {
  DbSubjectDiagramModel,
  DbSubjectDatabasesForProblemBusinessModel,
  DbSubjectJudgeTypeForProblemBusinessModel
} from 'internal'

const DiagramAndDatabaseForProblemBusinessModel = types.model(
  'DiagramAndDatabaseForProblemBusinessModel',
  {
    subjectName: types.optional(types.string, ''),
    description: types.optional(types.string, ''),
    diagrams: types.optional(types.array(DbSubjectDiagramModel), []),
    databasesForProblem: types.optional(
      types.array(DbSubjectDatabasesForProblemBusinessModel),
      []
    ),
    subjectJudgeTypeForProblem: types.optional(
      types.array(DbSubjectJudgeTypeForProblemBusinessModel),
      []
    )
  }
)

export default DiagramAndDatabaseForProblemBusinessModel
